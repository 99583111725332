import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { objectSelector } from 'store/selectors';
import produce from 'immer';

import { openShowItem } from 'store/actions/modalActions';
import ItemMover from './ItemMover';

import {
    NumberFormat,
    DataTable as Table,
    DataTableHeader as TableHeader,
    DataTableBody as TableBody,
    DataTableHeaderCell as HeaderCell,
    DataTableRow as TableRow,
    DataTableCell as TableCell,
    RowSelector,

    AttributeValue,
    Icon,
    Dropdown
} from 'Components';

import ItemInfo from './ItemInfo';

const ItemTable = props => {
    const { collection, category, items } = props;

    const [ selected, setSelected ] = useState([]);
    const [ itemToMove, setItemToMove ] = useState(null);
    const [ columns, setColumns ] = useState([]);

    const { dragging } = useSelector(state => state.drag);

    const dispatch = useDispatch();

    const handleDelete = item => {
        if (typeof props.onDelete === 'function') {
            props.onDelete(item);
        }
    };

    const handleViewNotes = item => {
        if (typeof props.onViewNotes === 'function') {
            props.onViewNotes(item);
        }
    };

    // Handle Marketplace:
    const handleMarketplaceAdd = item => {
        if (typeof props.onMarketplaceAdd === 'function') {
            props.onMarketplaceAdd(item);
        }
    };

    const handleMarketplaceEdit = item => {
        if (typeof props.onMarketplaceEdit === 'function') {
            props.onMarketplaceEdit(item);
        }
    };

    const handleEditItem = item => {
        if (typeof props.onEditItem === 'function') {
            props.onEditItem(item);
        }
    };

    const handleItemBarcode = item => {
        if (typeof props.onShowBarcode === 'function') {
            props.onShowBarcode(item);
        }
    };

    // Handle sharing manager:
    const handleManageSharing = item => {
        if (typeof props.onShareItem === 'function') {
            props.onShareItem(item);
        }
    };

    // Handle toggle all functionality:
    const toggleAll = checked => {
        const newSelected = [ ];

        if (checked === true) {
            objectSelector(items).map(item =>  ( newSelected.push(item.id) ));
        }

        setSelected(newSelected);
    };

    const handleSelect = (checked, id) => {
        if (checked === true) {
            setSelected(produce(selected, draft => { draft.push(id) }));
        }
        else {
            setSelected(produce(selected, draft => {
                draft.splice(selected.indexOf(id), 1);
            }));
        }
    };

    const handleClick = (e, item) => {
        e.preventDefault();

        // If there is a selection, add this item to it:
        if (selected.length > 0) {
            handleSelect(
                (selected.indexOf(item.id) === -1),
                item.id
            );
        }

        // No selection, but if they're dragging, don't open it:
        else if( ! dragging ) {
            dispatch(openShowItem(item));
        }
    };

    const handleMove = (moved, target) => {
        if (typeof props.onMove === 'function') {
            props.onMove(
                moved,
                target
            );
        }
    };

    const getDropdownItems = item => {
        if (item.inMarketplace === true) {
            return [
                { icon: 'eye', text: 'View item', onClick: ((e) => { e.preventDefault(); dispatch(openShowItem(item)) }) },
                { icon: 'pencil', text: 'Edit item', onClick: (() => handleEditItem(item) ) },
                { icon: 'sticky-note', text: 'View item notes', onClick: (() => handleViewNotes(item)) },
                { icon: 'qrcode', text: 'View asset tag', onClick: (() => handleItemBarcode(item)) },
                { icon: 'share-alt', text: 'Manage sharing address', onClick: (() => handleManageSharing(item) )},
                { sep: true },
                { icon: 'store', text: 'Manage Marketplace listing', onClick: (() => handleMarketplaceEdit(item)) },
                { icon: 'shopping-bag', text: 'View Marketplace listing', link: item.marketplaceURL, target: '_blank' },
                { sep: true },
                { icon: 'trash', text: 'Delete item', className: 'dropdown-menu_item-danger', onClick: (() => handleDelete(item)) }
            ];
        }

        return [
            { icon: 'eye', text: 'View item', onClick: ((e) => { e.preventDefault(); dispatch(openShowItem(item)) }) },
            { icon: 'pencil', text: 'Edit item', onClick: (() => handleEditItem(item) ) },
            { icon: 'sticky-note', text: 'View item notes', onClick: (() => handleViewNotes(item)) },
            { icon: 'qrcode', text: 'View asset tag', onClick: (() => handleItemBarcode(item)) },
            { icon: 'share-alt', text: 'Manage sharing address', onClick: (() => handleManageSharing(item) )},
            { sep: true },
            { icon: 'store', text: 'Sell in Marketplace', onClick: (() => handleMarketplaceAdd(item)) },
            { sep: true },
            { icon: 'trash', text: 'Delete item', className: 'dropdown-menu_item-danger', onClick: (() => handleDelete(item)) }
        ];
    };

    useEffect(() => {
        const cols = [ ];
        const collectionProps = Object.keys(collection.attributes);

        props.columns.forEach((col) => {
            if (collectionProps.indexOf(col.toString()) >= 0) {
                cols.push(col);
            }
        });

        setColumns(cols);
    }, [ collection, props.columns]);


    return (
        <div className={ (props.loading) ? 'loading' : '' }>
            <ItemMover onMove={ handleMove } selected={ selected } item={ itemToMove } category={ category }>
                <Table>
                    <TableHeader>
                        <HeaderCell className="checkbox">
                            <RowSelector onChange={ (e, checked) => { toggleAll(checked) }} />
                        </HeaderCell>
                        <HeaderCell text="Item info" />

                        {(columns.map((id, i) => {
                            return (id === 'quantity')
                                ? <HeaderCell className="table-header_quantity" key={i} text="Quantity" />
                                : <HeaderCell className={ `table-header_${collection.attributes[id]?.type}`} key={ i } text={ collection.attributes[id]?.name } />
                        }))}

                        <HeaderCell className="actions" />
                    </TableHeader>
                    <TableBody>
                        {objectSelector(items).map((item, i) => (
                            <TableRow
                                key={ i }
                                onMouseDown={() => { setItemToMove(item) }}
                                onClick={(e) => { handleClick(e, item) }}
                                className={ (i === objectSelector(items).length - 1) ? 'last' : '' }
                            >
                                <TableCell className="checkbox">
                                    <RowSelector
                                        checked={ (selected.indexOf(item.id) > -1) }
                                        onChange={(e, checked) => { handleSelect(checked, item.id) } } />
                                </TableCell>
                                <TableCell>
                                    <ItemInfo item={item} />
                                </TableCell>

                                {columns.map(id => {
                                    if (id === 'quantity') {
                                        return (
                                            <TableCell>
                                                <NumberFormat value={ item.quantity } />
                                            </TableCell>
                                        );
                                    }

                                    return (
                                        <TableCell>
                                            <AttributeValue attribute={ collection.attributes[id] } value={ item.attributes[id]?.value } />
                                        </TableCell>
                                    );
                                })}

                                <TableCell className="actions">
                                    <Dropdown
                                        className="dropdown-btn"
                                        position="bottom-end"
                                        size="sm"
                                        toggleClassName="btn-actions"
                                        toggleContent={ <Icon name="ellipsis-h" /> }
                                        items={ getDropdownItems(item) } />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </ItemMover>
        </div>
    );
};

export default ItemTable;
