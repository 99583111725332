import React from 'react';

const StatisticCard = props => {

    const { className, title, value } = props;

    return (
        <div className={ `stat-card ${className}` }>
            <span class="stat-card__title">{ title }</span>
            <span class="stat-card__value">{ value }</span>
        </div>
    )
};

export default StatisticCard;
