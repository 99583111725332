import { 
    FETCH_AVATAR_BEGIN,
    FETCH_AVATAR_SUCCESS,
    FETCH_AVATAR_FAILURE,
    SET_AVATAR,

    FETCH_USER_BEGIN,
    FETCH_USER_SUCCESS,
    FETCH_USER_FAILURE,

    UPDATE_USER_BEGIN,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAILURE,

    UPDATE_PREFS_BEGIN,
    UPDATE_PREFS_SUCCESS,
    UPDATE_PREFS_FAILURE,

    SET_FORENAME,
    SET_SURNAME,
    SET_EMAIL,
    SET_ABOUT,
    SET_DARK_MODE,
    SET_TIMEZONE,
    SET_TIME_FORMAT,
    SET_DATE_FORMAT,
    SET_2FA_ENABLED,
    SET_CURRENCY,
    SET_COUNTRY,
    SET_WEBSITE,

    ADD_PAYMENT_METHOD,
    REMOVE_PAYMENT_METHOD,
    UPDATE_PAYMENT_METHOD
} from '../actions/userActions';

import produce from 'immer';

const initialState = {
    loading: true,
    error: null,
    
    about: null,
    avatar: null,
    id: null,
    uuid: null,
    forename: null,
    surname: null,
    email: null,
    fullName: null,
    registered: null,
    modified: null,

    preferences: {
        timezone: null,
        dateFormat: null,
        timeFormat: null,
        darkMode: false,
        twoFactorEnabled: false
    },

    paymentMethods: { }
};
  
const userReducer = (state = initialState, action) => {
    switch(action.type)
    {
        case FETCH_USER_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_AVATAR_BEGIN:
        case UPDATE_USER_BEGIN:
        case UPDATE_PREFS_BEGIN:
            return {
                ...state,
                error: null
            };

        case FETCH_AVATAR_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                avatar: action.payload.avatar
            };

        case FETCH_USER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        case FETCH_AVATAR_FAILURE:
        case UPDATE_PREFS_FAILURE:
        case UPDATE_USER_FAILURE:
            return {
                ...state,
                loading: false
            };

        case SET_AVATAR:
            return {
                ...state,
                avatar: action.avatar
            };

        case FETCH_USER_SUCCESS:
            if( ! action.payload.user )
            {
                return {
                    ...state
                };
            }
            
            return {
                ...state,
                ...action.payload.user,
                loading: false
            };

        case UPDATE_USER_SUCCESS:
        case UPDATE_PREFS_BEGIN:
            return {
                ...state,
                loading: false
            };
        
        case SET_FORENAME:
            return {
                ...state,
                forename: action.forename
            };

        case SET_SURNAME:
            return {
                ...state,
                surname: action.surname
            };

        case SET_EMAIL:
            return {
                ...state,
                email: action.email
            };

        case SET_ABOUT:
            return {
                ...state,
                about: action.about
            };

        case SET_TIMEZONE:
            return {
                ...state,
                preferences: {
                    ...state.preferences,
                    timezone: action.timezone
                }
            };

        case SET_DARK_MODE:
            return {
                ...state,
                preferences: {
                    ...state.preferences,
                    darkMode: action.darkMode
                }
            };

        case SET_DATE_FORMAT:
            return {
                ...state,
                preferences: {
                    ...state.preferences,
                    dateFormat: action.format
                }
            };

        case SET_TIME_FORMAT:
            return {
                ...state,
                preferences: {
                    ...state.preferences,
                    timeFormat: action.format
                }
            };

        case SET_2FA_ENABLED:
            return {
                ...state,
                preferences: {
                    ...state.preferences,
                    twoFactorEnabled: action.payload.enabled
                }
            };
            
        case SET_CURRENCY:
            return {
                ...state,
                preferences: {
                    ...state.preferences,
                    currency: action.payload.currency
                }
            };

        case SET_COUNTRY:
            return {
                ...state,
                country: action.payload.country
            };

        case SET_WEBSITE:
            return {
                ...state,
                website: action.payload.website
            };

        case ADD_PAYMENT_METHOD:
            return produce(state, draftState => {
               draftState.paymentMethods[action.payload.id] = action.payload.method
            });

        case REMOVE_PAYMENT_METHOD:
            return produce(state, draftState => {
                delete draftState.paymentMethods[action.payload.id];
            });

        case UPDATE_PAYMENT_METHOD:
            return produce(state, draftState => {
                draftState.paymentMethods[action.payload.id] = action.payload.method;
            });

        default:
            return state;
    };
};

export default userReducer;