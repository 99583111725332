import React, { useState, useEffect } from 'react';
import { APIService } from 'Services';
import produce from 'immer';

import { MessageList, Conversation } from './';

const Messenger = props => {

    const className = [ 'messenger' ];

    const [ conversationsLoading, setConversationsLoading ] = useState(true);
    const [ conversations, setConversations ] = useState([ ]);
    const [ conversation, setConversation ] = useState(null);
    const [ selectedConversationId, setSelectedConversationId ] = useState(null);

    // Fetch the messages:
    useEffect(() => { 
        APIService.get('/conversations/')
            .then(resp => { 
                setConversationsLoading(false);
                setConversations(resp.conversations);
            });
    }, [ ]);

    // When conversations changes, select the first one:
    useEffect(() => { 
        if( Object.keys(conversations).length && conversation === null )
        {
            const first = conversations[Object.keys(conversations)[0]];

            setConversation(first);
            setSelectedConversationId(first.id);
        }
    }, [ conversations ]);

    if( props.className )
    {
        className.push(props.className);
    }

    // Handle when they select a message:
    const showConversation = conversation => {
        setConversation(conversation);
        setSelectedConversationId(conversation.id);
    };

    // Mark the conversation as read:
    const handleMarkAsRead = id => {
        setConversations(produce(conversations, draft => {
            draft[`message_${id}`].hasUnreadMessages = false;
        }));
    };

    // Delete the conversation:
    const onConversationDelete = id => {
        setConversations(produce(conversations, draft => {
            delete draft[`message_${id}`];
        }));

        setConversation(null);
    };

    const conversationElement = (conversation !== null)
        ?  <Conversation 
                onRead={ (id) => { handleMarkAsRead(id) }}
                onDelete={ (id) => { onConversationDelete(id) } }
                conversation={ conversation } />
        : null;

    return (
        <div className={ className.join(' ') }>
            <MessageList 
                selected={ selectedConversationId }
                onMessageSelect={ showConversation } 
                loading={ conversationsLoading } 
                conversations={ conversations } />

            { conversationElement }
        </div>
    );
};

export default Messenger;