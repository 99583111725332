import React, { useEffect, useState } from 'react';
import { objectSelector } from 'store/selectors';

import GridItem from './GridItem';
import ItemMover from './ItemMover';

const ItemGrid = props => {
    const { items, loading, category } = props;

    //const [ selected, setSelected ] = useState([]);
    const [ itemToMove, setItemToMove ] = useState(null);

    const className = [ 'grid-collection-items'];

    const handleDelete = item => {
        if( typeof props.onDelete === 'function' )
        {
            props.onDelete(item);
        }
    };

    const handleViewNotes = item => {
        if( typeof props.onViewNotes === 'function' )
        {
            props.onViewNotes(item);
        } 
    };

    const handleMarketplaceAdd = item => {
        if( typeof props.onMarketplaceAdd === 'function' )
        {
            props.onMarketplaceAdd(item);
        }
    };

    const handleMarketplaceEdit = item => {
        if( typeof props.onMarketplaceEdit === 'function' )
        {
            props.onMarketplaceEdit(item);
        }
    };
    
    const handleEditItem = item => {
        if( typeof props.onEditItem === 'function' )
        {
            props.onEditItem(item);
        }
    };

    const handleShowBarcode = item => {
        if( typeof props.onShowBarcode === 'function' )
        {
            props.onShowBarcode(item);
        }
    }

    const handleShareItem = item => {
        if( typeof props.onShareItem === 'function' )
        {
            props.onShareItem(item);
        }
    };

    useEffect(() => {
        if( loading === true )
        {   
            className.push('loading');
        }
    }, [ loading, className ]);

    const handleMove = (moved, target) => {
        if( typeof props.onMove === 'function' )
        {
            props.onMove(moved, target);
        }
    };

    return (
        <ItemMover onMove={ handleMove } item={ itemToMove } category={ category }>
            <div className={ className.join(' ') }>
                {objectSelector(items).map((item, i) => ( 
                    <GridItem 
                        onMouseDown={() => { setItemToMove(item) }}
                        onViewNotes={ handleViewNotes }
                        onDelete={ handleDelete } 
                        onEditItem={ handleEditItem }
                        onMarketplaceAdd={ handleMarketplaceAdd }
                        onMarketplaceEdit={ handleMarketplaceEdit }
                        onShareItem={ handleShareItem }
                        onShowBarcode={ handleShowBarcode }
                        item={ item } 
                        key={ i } /> 
                ))}
            </div>
        </ItemMover>
    );
};

export default ItemGrid;