import React, { useCallback, useState } from 'react';
import { objectSelector } from 'store/selectors';

import { APIService } from 'Services';
import { PageHeader, ItemForm } from 'Components';

const AddItem = props => {

    const className = [ 'add-item', 'item-form' ];

    const { category, collection } = props;

    const [ alertVisible, setAlertVisible ] = useState(false);
    const [ alertText, setAlertText ] = useState('');
    const [ buttonLoading, setButtonLoading ] = useState(false);

    if( props.visible === true )
    {
        className.push('item-form-in');
    }

    const handleClose = e => {
        if( typeof props.onClose === 'function' )
        {
            props.onClose(e);
        }
    };

    const handleSubmit = useCallback((e, data) => {
        e.preventDefault();

        const endpoint = '/category/' + category.id + '/items/';

        setButtonLoading(true);

        APIService.post(endpoint, data)
            .then(response => {
                setButtonLoading(false);
                handleClose();

                if( typeof props.onAddItem === 'function' )
                {
                    props.onAddItem(response.item);
                }
            })
            .catch(error => {
                setButtonLoading(false);
                setAlertVisible(true);
                setAlertText(error?.response?.data?.message || 'Something went wrong, please try again.');
            })
    }, [ category ]);

    const attributes = [ ];

    if( props.collection )
    {
        objectSelector(props.collection.attributes).map(attr => { attributes.push({ id: attr.id, value: '' }); return attributes; });
    }

    return (
        <div className={ className.join(' ') }>
            <PageHeader
                title="Add new item"
                subtitle={ `${category?.name} (${collection?.name})` }
                sticky={true} />

            <ItemForm
                attributes={ attributes }
                alertVisible={ alertVisible }
                alertText={ alertText }
                onCancel={(e) => { handleClose(e) } }
                collection={ props.collection }
                category={ props.category }
                onSubmit={ (e, data) => handleSubmit(e, data) }
                buttonText="Create item"
                buttonLoading={ buttonLoading } />
        </div>
    );
};

export default AddItem;
