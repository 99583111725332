import { React, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import HTMLEntities from 'Services/HTMLEntities';

import { setForename, setSurname, setEmail, setAbout, setWebsite, setCountry, updateUser } from 'store/actions/userActions';
import {  UPDATE_USER_FAILURE } from 'store/actions/userActions';
import config from 'config';

import { openChangePassword } from 'store/actions/modalActions';
import { useToasts } from 'react-toast-notifications'

import { AvatarUploader, Alert, Button, Input, Select, Textarea, Row, Column } from 'Components';

const Profile = (props) => {

    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const [ buttonLoading, setButtonLoading ] = useState(false);
    
    const [ alertType, setAlertType ] = useState(null);
    const [ alertText, setAlertText ] = useState(null);
    const [ alertVisible, setAlertVisbible ] = useState(false);

    const { forename, surname, email, about, website, country } = useSelector(state => state.user);

    const countries = [ ];

    config.countries.map(country => ( countries.push(
        { text: country.name, value: country.code }
    )));

    const handleSubmit = e => {
        e.preventDefault();
        setButtonLoading(true);

        const updateData = {
            forename: forename, 
            surname: surname, 
            email: email, 
            about: about, 
            website: website, 
            country: country
        }

        // Update the user:
        dispatch(updateUser(updateData))
            .then(response => { 
                setButtonLoading(false);

                if (response.type && response.type === UPDATE_USER_FAILURE) {
                    setAlertType('danger');
                    setAlertText(response.payload.error.response.data.message);
                    setAlertVisbible(true);
                }
                else {
                    addToast('Your profile was successfully updated.', {
                        appearance: 'success',
                        autoDismiss: true,
                    });
                }
            })
    };

    return (
        <form className="container container-narrow" onSubmit={(e) => { handleSubmit(e) }}>
            <p className="form-explain">
                Use the form below to manage your Kolekto profile information.
            </p>

            <Row>
                <Column width={ 25 }>
                    <AvatarUploader />
                </Column>
                <Column>
                    <Alert visible={ alertVisible } className="mb25 alert-icon" type={ alertType }>{ alertText }</Alert>

                    <Row>
                        <Column><Input id="input_forename" label="First name" onChange={(e) => dispatch(setForename(e.target.value)) } value={ forename } /></Column>
                        <Column><Input id="input_surname" label="Last name" onChange={(e) => dispatch(setSurname(e.target.value)) } value={ surname } /></Column>
                    </Row>

                    <Input 
                        type="email" 
                        className="my35" 
                        id="input_email" 
                        label="Email address" 
                        onChange={(e) => dispatch(setEmail(e.target.value)) }  
                        value={ email } />

                    <Input 
                        type="text" 
                        className="my35" 
                        id="input_website" 
                        label="Website" 
                        onChange={(e) => dispatch(setWebsite(e.target.value)) }  
                        value={ website } />
                    
                    <Select 
                        className="w50 mb35" 
                        label="Country"
                        value={ country || 'US' }
                        onChange={(e) => dispatch(setCountry(e.target.value)) }  
                        options={ countries } />

                    <Textarea 
                        label="Biography"
                        id="textarea_about" 
                        onChange={(e) => dispatch(setAbout(e.target.value)) } 
                        value={ HTMLEntities.decode(about) } />

                    <div className="mt35">
                        <Button text="Save changes" loading={buttonLoading} className="btn-primary btn-rounded btn-upper" />
                        <Button type="button" text="Change password" onClick={(e) => { dispatch(openChangePassword()) }} className="btn-white btn-rounded btn-upper" />
                    </div>
                </Column>
            </Row>
        </form>
    );
};

export default Profile;