import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeDeleteCollection } from 'store/actions/modalActions';
import { removeCollection } from 'store/actions/collectionActions';
import { collectionSelector } from 'store/selectors';

import APIService from 'Services/APIService';

import { Alert, Modal, Button, Input } from 'Components';

const ModalDeleteCollection = (props) => {

    const dispatch = useDispatch();

    const deleteCollectionVisible = useSelector(state => state.modals.deleteCollection);
    const collection = useSelector(state => state.modals.collectionToDelete);
    const hierarchy = useSelector(state => state.collections.hierarchy);
    const currentCollection = collectionSelector(hierarchy);

    // Alert state:
    const [ alertVisible, setAlertVisible ] = useState(false);
    const [ alertClass, setAlertClass ] = useState(null);
    const [ alertText, setAlertText ] = useState('');

    const [ deleteDisabled, setDeleteDisabled ] = useState(true);
    const [ nameValue, setNameValue ] = useState('');

    // Button state:
    const [ buttonLoading, setButtonLoading ] = useState(false);
    
    // Reset onclose:
    const onBeforeClose = event => {
        setAlertVisible(false);
        setButtonLoading(false);
        setDeleteDisabled(true);
        setNameValue('');
    };

    const handleNameInput = e => {
        const value = e.target.value;
        setNameValue(value);
        setDeleteDisabled(value.trim() !== collection?.name);
    };

    // Handle the form submission:
    const handleSubmit = e => {
        e.preventDefault();
        setButtonLoading(true);
        setAlertVisible(false);

        APIService.delete('/collection/' + collection?.id)
            .then(response => { 
                // It's the current one:
                if( currentCollection && currentCollection.id === collection?.id )
                {
                    window.location.replace('/collections/');
                }
                else
                {
                    dispatch(removeCollection(collection?.id));
                    dispatch(closeDeleteCollection());
                }
            })
            .catch(error => { 
                setAlertVisible(true);
                setAlertClass('danger');
                setAlertText(error.response.data.message);
            })
            .finally(() => { 
                setButtonLoading(false);
            });
    };

    let content = '';
    let button = '';

    // It's not empty!
    if( collection?.numCategories )
    {
        content = <p>Cannot delete <b>{ collection?.name}</b> because it contains categories. You must delete or move the categories before deleting your collection.</p>

        button = <Button 
                    onClick={(e) => { dispatch(closeDeleteCollection()) }}
                    className="btn-primary btn-upper btn-rounded" 
                    text="Dismiss" />
    }
    else
    {
        content = <>
                      <p className="my0">Are you sure you want to delete <b>{ collection?.name }</b>? <b>This action is irreversible!</b></p>
                      <p className="mb0 mt10">Enter the collection name below to confirm deletion:</p>

                      <Input
                        className="my20"
                        onChange={(e) => { handleNameInput(e) }}
                        value={ nameValue }
                        label="Collection name" />
                  </>;

        button = <Button 
                     className="btn-danger btn-upper btn-rounded" 
                     text="Delete collection" 
                     onClick={(e) => { handleSubmit(e) }}
                     disabled={ deleteDisabled }
                     loading={ buttonLoading } />
    }

    return (
        <Modal size="medium" onClose={(e) => { onBeforeClose(e); dispatch(closeDeleteCollection()) }} title="Delete collection" visible={ deleteCollectionVisible }>
            <Alert className={ `alert-${alertClass} alert-icon mb25` } visible={alertVisible}>
                { alertText }
            </Alert>

            { content }
            { button }
        </Modal>
    );
};

export default ModalDeleteCollection;