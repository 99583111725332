import React from 'react';
import { FormHelp } from 'Components';

const SelectGroup = (props) => {
    const className = [ 'select-group' ];

    if( props.className !== undefined ) 
    {
        className.push(props.className);
    }

    const helpElement = (props.help === undefined) 
		? '' 
        : <FormHelp text={ props.help } />;
        
    const labelElement = (props.label === undefined)
        ? ''
        : <span className="label">{ props.label }</span>

    return (
        <>
            <div className={ className.join(' ') }>
                { labelElement }
                { props.children }
            </div>
            { helpElement }
        </>
    )
}

export default SelectGroup;