import React from 'react';
import { NavLink } from 'react-router-dom';

const NavbarMenuItem = (props) => {

	const { exact, link, icon, label, hasDot, onClick } = props;

	const notifDot = (hasDot) ? <span className="notification-dot"></span> : '';

	const handleClick = (e) => {
		if(typeof onClick == 'function')
		{
			onClick.call(null, e);
		}
	};

	const id = (props.id === undefined) ? null : props.id;

	// If it's got a "to" property, use a NavLink:
	if( link )
	{
		return (
			<NavLink id={ id } exact={exact} to={ link } activeClassName="nav-tab_active" className="nav-tab" onClick={(e) => { handleClick(e) }}>
	            <i className={ `nav-tab_icon far fa-${icon}` }></i>
	            <span className="nav-tab_label">{ label }</span>
	            { notifDot }
	        </NavLink>
		);
	}

	return (
		<button id={ id } className="nav-tab" onClick={(e) => { handleClick(e) }}>
            <i className={ `nav-tab_icon far fa-${icon}` }></i>
            <span className="nav-tab_label">{ label }</span>
            { notifDot }
        </button>
	);
}

export default NavbarMenuItem;