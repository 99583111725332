import React from 'react';

const FormExplain = (props) => {

	const { text, className } = props;

	const classArr = [ 'form-explain' ];

	if( className !== undefined )
	{
		classArr.push(className);
	}

	// Build the button content:
	const content = (text !== undefined) ? text : props.children;

	return (
		<p className={ classArr.join(' ') }>
			{ content }
		</p>
	);
}

export default FormExplain;