import React from 'react';
import { Button, NumberFormat, Icon } from 'Components';

const MarketplaceGridItem = props => {

    const { id, actions, thumbnail, title, url, seller, views } = props;

    const sellerElement = (seller !== undefined)
        ? <div className="marketplace-grid-item_seller">
              <img src={ seller.avatar.thumbnail } alt={ seller.fullName} className="marketplace-grid-item_seller-avatar" />
              <span className="marketplace-grid-item_seller-name">{ seller.fullName }</span>
          </div>
        : null;

    const viewsElement = (views !== undefined)
        ? <div className="marketplace-grid-item_views">
              <Icon name="eye" />
              <NumberFormat value={ views } className="count" />
          </div>
        : null;

    let overlay = null;

    if( actions !== undefined && actions !== null )
    {
        overlay = (
            <div className="marketplace-grid-item_image-overlay">
                { actions }
            </div>
        );
    };

    const thumb = (thumbnail !== undefined && thumbnail !== null)
        ? <img src={ thumbnail } alt={ title } />
        : null;

    return (
        <div className="marketplace-grid-item">
            <div className="marketplace-grid-item_image">
                { thumb }
                { overlay }
            </div>
            <div className="marketplace-grid-item_info">
                <a href={ url } target="_blank" title={ `View item: ${title} `} className="marketplace-grid-item_title">
                    { title }
                </a>

                { sellerElement }
                { viewsElement }
            </div>
        </div>
    );  
};

export default MarketplaceGridItem;