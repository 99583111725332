import { React, useEffect, useState } from 'react';
import { Alert, Modal, Button, Input } from 'Components';

const PasswordModal = (props) => {

    const [ password, setPassword ] = useState('');   
    const [ focused, setFocused ] = useState(false);

    // Normalise the values:
    const [ alertVisible, setAlertVisible ] = useState(false);
    const [ alertClass, setAlertClass ] = useState(null);
    const [ alertText, setAlertText ] = useState(null);

    const buttonLoading = (props.loading === undefined) ? false : props.loading;
    const title = (props.title === undefined) ? 'Confirm password' : props.title;

    useEffect(() => { if(props.visible) window.setTimeout(() => { setFocused(true); }, 50) }, [ props.visible ]);

    const handleSubmit = event => {
        event.preventDefault();

        if( password === '' || password === null || password === undefined )
        {
            setAlertText('Please enter your password.');
            setAlertClass('danger');
            setAlertVisible(true);
        }
        else
        {
            if( typeof props.onSubmit === 'function' ) 
            { 
                props.onSubmit.call(
                    null,
                    event,
                    password
                );
            }
        }
    };

    const handleClose = event => {
        event.preventDefault();

        if( typeof props.onClose === 'function' )
        {
            props.onClose.call(
                null,
                event
            );
        }
    }

    return (
        <Modal loading={ props.loading === true } size="small" title={ title } visible={ props.visible } onClose={ handleClose }>
            <p>Please enter your account password to continue</p>

            <Alert className={ `alert-${alertClass} alert-icon mb25` } visible={alertVisible}>
                { alertText }
            </Alert>

            <Input 
                className="form-control-lg" 
                onChange={(e) => { setPassword(e.target.value) }}
                type="password" 
                focused={ focused } 
                value={ password }
                label="Password" />

            <Button 
                className="btn-primary btn-upper btn-center btn-rounded w75 btn-lg mt35" 
                text="Continue" 
                loading={ buttonLoading }
                onClick={(e) => { handleSubmit(e) }}
                />
        </Modal>
    );
};

export default PasswordModal;