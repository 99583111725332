import React, { useEffect, useState } from 'react';
import APIService from 'Services/APIService';
import { useDispatch, useSelector } from 'react-redux';
import { openShowItem } from 'store/actions/modalActions';

import { DateFormat, Image, EmptyText } from 'Components';
import ItemViewer from 'Components/Collections/ItemView/ItemViewer';

const DashboardItems = (props) => {

    const [ loading, setLoading ] = useState(true);
    const [ items, setItems ] = useState([]);

    const dispatch = useDispatch();

     // Get the item info from the Store:
     const { showItem, itemToShow } = useSelector(state => state.modals);

	useEffect(() => {
        APIService.get('/items/', { limit: 5 })
            .then(response => { setItems(response.items); })
            .catch(error => { })
            .finally(() => { setLoading(false); });
    }, [ ]);

    const handleClick = (e, item) => {
        e.preventDefault();
        dispatch(openShowItem(item));
    };

    let content = <EmptyText text="No items found" />;

    if( items.length )
    {
        content = (
            <>
                {items.map((item, index) => {
                    let img = (item.images.length)
                        ? <Image height={40} width={40} className="activity-item_image" src={ item.images[0].thumbnail } />
                        : <></>;

                    return (
                        <div class="activity-item activity-item_hover" index={index} style={{ cursor: 'pointer' }} onClick={(e) => { handleClick(e, item); }}>
                            { img }
                            <span class="activity-item_text">
                                <strong>{ item.name }</strong><br />
                                <span class="text-muted">{ item.categoryHierarchy }</span>
                            </span>
                            <DateFormat className="activity-item_timestamp" date={ item.created } />
                        </div>
                    );
                })}
            </>
        );
    }
    
	return (
        <div className={ `dashboard-activity ${(loading) ? ' loading' : ''}` }>
            { content }

            <ItemViewer
                visible={ showItem }
                item={ itemToShow } />
        </div>
	);
}

export default DashboardItems;