import React, { useEffect } from 'react';

import { CurrencyFormat, DateFormat } from 'Components';

const InvoiceMenuItem = props => {
    const { invoice, onClick, active } = props;

    const handleClick = e => {
        e.preventDefault();

        if( typeof onClick === 'function' )
        {
            onClick(invoice.id);
        }
    };

    const className = [ 'invoices_menu-item' ];

    if( active === invoice.id )
    {
        className.push('active');
    }

    return (
        <div className={ className.join(' ') } onClick={(e) => handleClick(e)}>
            <div className="invoices_menu-item-info">
                <span className="invoices_menu-item-number">{ invoice.number }</span>
                <span className="invoices_menu-item-price">
                    <CurrencyFormat 
                        currency="GBP" 
                        value={ invoice.amount / 100 } />
                </span>
            </div>

            <DateFormat 
                className="invoices_menu-item-date"
                format="Do MMMM Y"
                date={ invoice.issued } />
        </div>
    );
};

export default InvoiceMenuItem;