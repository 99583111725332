import React from 'react';

import ChangePassword from './ChangePassword';
import AddAddress from './AddAddress';
import EditAddress from './EditAddress';
import AddPaymentMethod from './AddPaymentMethod';
import AddAttribute from './AddAttribute';
import EditAttribute from './EditAttribute';
import AddCollection from './AddCollection';
import EditCollection from './EditCollection';
import DeleteCollection from './DeleteCollection';
import AddCategory from './AddCategory';
import AddSubcategory from './AddSubcategory';
import EditCategory from './EditCategory';
import DeleteCategory from './DeleteCategory';
import EntityInfo from './EntityInfo';

export const Modals = (props) => {
    return (
        <div id="modals">
            <ChangePassword />

            <AddAddress />
            <EditAddress />

            <AddPaymentMethod />

            <AddAttribute />
            <EditAttribute />

            <AddCollection />
            <EditCollection />
            <DeleteCollection />
            
            <AddCategory />
            <EditCategory />
            <DeleteCategory />
            <AddSubcategory />

            <EntityInfo />
        </div>
    )
}