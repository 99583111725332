import React from 'react';

const TabContent = props => {

    const { active } = props;

    const className = [ 
        'tab-group_content',
        (active === true ? 'active' : null)
    ];

    if( props.className )
    {
        className.push(props.className);
    }

    return (
        <div className={ className.join(' ') }>
            { props.children }
        </div>
    );
};

export default TabContent;