import React from 'react';
import nl2br from 'react-nl2br';

import { DateFormat } from 'Components';

const NoteItem = props => {
    const { note } = props;

    const handleDelete = (e, id) => {
        e.preventDefault();

        if( typeof props.onDelete === 'function' )
        {
            props.onDelete(id);
        }
    }

    return (
        <div className="note-item">
            <img 
                className="note-item_avatar" 
                alt={ note.author.fullName }
                title={ note.author.fullName } 
                src={ note.author.avatar.thumbnail } />
            
            <div className="note-item_content">
                <div className="note-item_content-note">{ nl2br(note.note) }</div>
                <div className="note-item_content-data">
                    <DateFormat date={ note.created } className="note-item_content-data-date" />
                    &middot;
                    <a href="#" onClick={(e) => { handleDelete(e, note.id) }} className="note-item_content-data-delete">Delete note</a>
                </div>
            </div>
        </div>
    )
}

export default NoteItem;