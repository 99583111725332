import React from 'react';
import { objectSelector } from 'store/selectors';

import { 
    CurrencyFormat, 
    DateFormat, 
    Icon,
    Dropdown,

    DataTable, 
    DataTableCell, 
    DataTableHeader, 
    DataTableHeaderCell, 
    DataTableRow, 
    DataTableBody
} from 'Components';

const OfferTable = props => {

    const { offers, showBuyer, showSeller } = props;

    // Handle when they click to contact a seller:
    const onContactShow = (e, item, user, userType) => {
        e.preventDefault();

        if( typeof props.onContactShow === 'function' )
        {
            props.onContactShow(e, item, user, userType);
        }
    };

    // Handle when they click to show the message:
    const onMessageShow = (e, offer) => {
        e.preventDefault();

        if( typeof props.onMessageShow === 'function' )
        {
            props.onMessageShow(offer);
        }
    };

    // Handle when they accept an offer:
    const handleAcceptOffer = (e, offer) => {
        e.preventDefault();

        if( typeof props.onAccept === 'function' )
        {
            props.onAccept(offer);
        }
    };

    // Handle when they decline an offer:
    const handleDeclineOffer = (e, offer) => {
        e.preventDefault();

        if( typeof props.onDecline === 'function' )
        {
            props.onDecline(offer);
        }
    };

    const getDropdownElement = offer => {

        const dropdownItems = [
            { icon: 'eye', text: 'View item', link: offer.item.url, target: '_blank' },
        ];

        // If we're showing the buyer column, allow them to message the buyer:
        if( showBuyer )
        {
            dropdownItems.push({ 
                icon: 'comments-alt', 
                text: 'Contact buyer',
                onClick: (e) => { onContactShow(e, offer.item, offer.buyer, 'Buyer') }
            });
        }

        // If we're showing the seller column, allow them to message the seller:
        else if( showSeller )
        {
            dropdownItems.push({ 
                icon: 'comments-alt', 
                text: 'Contact seller',
                onClick: (e) => { onContactShow(e, offer.item, offer.seller, 'Seller' )}
            });
        }

        // Does it have a message, and are they the seller?
        if( showBuyer && offer.message !== null )
        {
            dropdownItems.push({
                icon: 'pencil',
                text: 'View buyer\'s message',
                onClick: (e) => { onMessageShow(e, offer) }
            });
        }

        // It's still a pending offer, and we're showing the buyer so it's theirs:
        if( !offer.accepted && !offer.declined && showBuyer )
        {
            dropdownItems.push(
                { sep: true },
                { icon: 'check', text: 'Accept offer', className: 'dropdown-menu_item-success', onClick: (e) => { handleAcceptOffer(e, offer); } },
                { icon: 'times', text: 'Decline offer', className: 'dropdown-menu_item-danger', onClick: (e) => { handleDeclineOffer(e, offer); } }
            )
        }

        return  <Dropdown
                    arrow={ true }
                    position="bottom-end"
                    className="dropdown-btn"
                    size="sm"
                    toggleClassName="btn-actions"
                    toggleContent={ <Icon name="ellipsis-h" /> }
                    items={ dropdownItems } />;
    };

    return (
        <DataTable>
            <DataTableHeader>
                <DataTableHeaderCell>Item</DataTableHeaderCell>
                <DataTableHeaderCell>Offer</DataTableHeaderCell>
                { (showBuyer ? <DataTableHeaderCell>Buyer</DataTableHeaderCell> : null) }
                { (showSeller ? <DataTableHeaderCell>Seller</DataTableHeaderCell> : null) }
                <DataTableHeaderCell>Date</DataTableHeaderCell>
                <DataTableHeaderCell>&nbsp;</DataTableHeaderCell>
            </DataTableHeader>
            <DataTableBody>
                { objectSelector(offers).map((offer, i) => 
                    <DataTableRow key={ i } className={ (offer.accepted ? 'row-success row-fade' : (offer.declined ? 'row-danger row-fade' : null)) }>
                        <DataTableCell>
                            <div className="item-info">
                                { (offer.item.images.length) ? <img className="item-thumb marketplace-table_image" alt={ offer.item.title } src={ offer.item.images[0].thumbnail } /> : null }
                                <span className="marketplace-table_title">
                                    { offer.item.title }
                                    <span className="marketplace-table_subtitle">
                                        List price: <CurrencyFormat currency={ offer.item.currency } value={ offer.item.price } />
                                    </span>
                                </span>
                            </div>
                        </DataTableCell>
                        <DataTableCell className="nowrap"><CurrencyFormat currency={ offer.item.currency } value={ offer.amount } /></DataTableCell>
                        { (showBuyer ? <DataTableCell className="nowrap">{ offer.buyer.fullName }</DataTableCell> : null) }
                        { (showSeller ? <DataTableCell className="nowrap">{ offer.seller.fullName }</DataTableCell> : null) }
                        <DataTableCell className="nowrap"><DateFormat date={ offer.created } /></DataTableCell>
                        <DataTableCell className="actions">
                            { getDropdownElement(offer) }
                        </DataTableCell>
                    </DataTableRow>
                )}
            </DataTableBody>
        </DataTable>
    );
};

export default OfferTable;