import React from 'react';

const Row = props => {
    const { className, onClick } = props;

    return (
        <>
            <tr className="sep"></tr>

            <tr 
                onMouseDown={ props?.onMouseDown }
                onMouseUp={ props?.onMouseUp }
                onClick={ onClick }
                className={ className }
            >{props.children}</tr>
        </>
    )
};

export default Row;