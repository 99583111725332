class Utilities
{
	SITE_NAME = 'Kolekto';

	getPageTitle(title) 
	{
		if( title == '' || title == null )
		{
			return this.SITE_NAME;
		}

		return `${title} | ${this.SITE_NAME}`;
	};
}

export default new Utilities();