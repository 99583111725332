import React, { useEffect, useState } from 'react';
import storage from 'store/localstorage';
import { objectSelector } from 'store/selectors';
import { APIService } from 'Services';
import { useToasts } from 'react-toast-notifications';
import produce from 'immer';

import { 
    CurrencyFormat, 
    DateFormat,
    NumberFormat,

    Dropdown, 
    Icon, 
    PageHeader, 
    ButtonGroup, 
    Button, 
    EmptyState,
    
    DataTable, 
    DataTableCell, 
    DataTableHeader, 
    DataTableHeaderCell, 
    DataTableRow, 
    DataTableBody,

    Modal,
    Alert
} from 'Components';

import GridItem from './MarketplaceGridItem';

const Items = props => {

    const { addToast } = useToasts();

    const [ loading, setLoading ] = useState(true);
    const [ items, setItems ] = useState([ ]);
    const [ view, setView ] = useState(storage.get('marketplaceitem-view', 'grid'));

    // Modal state:
    const [ itemToDelete, setItemToDelete ] = useState(null);
    const [ deleteButtonLoading, setDeleteButtonLoading ] = useState(false);
    const [ modalDeleteVisible, setModalDeleteVisible ] = useState(false);
    const [ deleteAlertVisible, setDeleteAlertVisible ] = useState(false);
    const [ deleteAlertText, setDeleteAlertText ] = useState(null);

    // Pull in the favourites:
    useEffect(() => { 
        APIService.get('/marketplace/items/')
            .then(resp => { setItems(resp.items); })
            .finally(() => { setLoading(false); });
    }, [ ]);

    let content = null;
    let actionButtons = null;

    // Handle the view change:
    const handleViewChange = (e, view) => {
        e.preventDefault();
        setView(view);
        localStorage.setItem('marketplaceitem-view', view);
    };

    // Handle the removal of an item:
    const removeItem = item => {
        setDeleteButtonLoading(true);

        const endpoint = `/marketplace/item/${item.id}/`;

        APIService.delete(endpoint)
            .then(resp => {
                // Update the favourites:
                setItems(produce(items, draft => { delete draft[`item_${resp.id}`]; }));
                setItemToDelete(null);
                setModalDeleteVisible(false);
                setDeleteAlertVisible(false);
                setDeleteAlertText(null);

                addToast('Item successfully removed from Marketplace.', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                const message = error.response.data.message 
                    ? error.response.data.message 
                    : 'There was a problem removing the item from the Marketplace. Please try again.';

                setDeleteAlertVisible(true);
                setDeleteAlertText(message);
            })
            .finally(() => { setDeleteButtonLoading(false) });
    };

    const showDeleteModal = item => { 
        setItemToDelete(item);
        setModalDeleteVisible(true); 
    };

    const hideDeleteModal = () => { 
        setItemToDelete(null);
        setModalDeleteVisible(false);
        setDeleteAlertVisible(false);
        setDeleteAlertText(null);
    };

    // There are no favourites, and it's loaded:
    if( items.length === 0 && loading === false )
    {
        content = (
            <EmptyState 
                title="No Items!"
                description="You haven't added any favourite items from the Marketplace. Browse the Marketplace to find your next addition!"
                image="EmptyCollection"
                button={  
                    <Button 
                        href="" 
                        title="Start Selling" 
                        target="_blank"
                        className="btn-rounded btn-primary btn-upper"
                        text="Browse Marketplace" />
                } />
        );
    }

    // There are some items:
    else if( loading === false )
    {
        // Set the action buttons:
        actionButtons = (
            <ButtonGroup>
                <Button 
                    className={ `btn-sm icon-only ${view === 'list' ? 'btn-active' : ''}` } 
                    icon="list"
                    onClick={ (e) => { handleViewChange(e, 'list') }} />
                <Button 
                    className={ `btn-sm icon-only ${view === 'grid' ? 'btn-active' : ''}` } 
                    icon="th"
                    onClick={ (e) => { handleViewChange(e, 'grid') }} />
            </ButtonGroup>
        );

        if( view === 'grid' )
        {
            content = <div className="marketplace-item-grid">
                        { objectSelector(items).map((item, i) => 
                            <div className="marketplace-item-grid-column">
                                <GridItem 
                                    actions={ 
                                        <>
                                            <Button 
                                                icon="eye"
                                                href={ item.url }
                                                target="_blank"
                                                className="btn-icon-white mr10"
                                                title={ `View item: ${item.title}`} />

                                            <Button
                                                onClick={(e) => { showDeleteModal(item) }}
                                                icon="trash"
                                                className="btn-icon-danger"
                                                title="Remove item from Marketplace" />
                                        </>
                                    }
                                    onDelete={() => { removeItem(item) }}
                                    key={ i } 
                                    id={ item.id }
                                    url={ item.url }
                                    views={ item.views }
                                    title={ item.title }
                                    thumbnail={ item.images.length ? item.images[0].thumbnail : null } /> 
                            </div>
                        )}
                    </div>
        }
        else
        {
            content = <DataTable>
                        <DataTableHeader>
                            <DataTableHeaderCell>Item Info</DataTableHeaderCell>
                            <DataTableHeaderCell>Price</DataTableHeaderCell>
                            <DataTableHeaderCell className="text-center">Views</DataTableHeaderCell>
                            <DataTableHeaderCell>Added</DataTableHeaderCell>
                            <DataTableHeaderCell>&nbsp;</DataTableHeaderCell>
                        </DataTableHeader>
                        <DataTableBody>
                            { objectSelector(items).map((item, i) => 
                                <DataTableRow key={ i }>
                                    <DataTableCell>
                                        <div className="item-info">
                                            { (item.images.length) ? <img className="item-thumb marketplace-table_image" alt={ item.title } src={ item.images[0].thumbnail } /> : null }
                                            <span className="marketplace-table_title">{ item.title }</span>
                                        </div>
                                    </DataTableCell>
                                    <DataTableCell className="nowrap"><CurrencyFormat currency={ item.currency } value={ item.price } /></DataTableCell>
                                    <DataTableCell className="nowrap text-center">
                                        <NumberFormat value={ item.views } />
                                    </DataTableCell>
                                    <DataTableCell className="nowrap"><DateFormat date={ item.created } /></DataTableCell>
                                    <DataTableCell>
                                        <Dropdown
                                            position="bottom-end"
                                            className="dropdown-btn"
                                            size="sm"
                                            toggleClassName="btn-actions"
                                            toggleContent={ <Icon name="ellipsis-h" /> }
                                            items={[
                                                { icon: 'eye', text: 'View item', link: item.url, target: '_blank' },
                                                { sep: true },
                                                { icon: 'trash', text: 'Remove favourite', className: 'dropdown-menu_item-danger', onClick: (() => { showDeleteModal(item) }) }
                                            ]} />
                                    </DataTableCell>
                                </DataTableRow>
                            )}
                        </DataTableBody>
                    </DataTable>;
        }
    };
    
    return (
        <div className="marketplace-page">
            <PageHeader 
                sticky={ true } 
                title="My Marketplace Items"
                buttons={ actionButtons } />

            <div className={ `marketplace-page_content ${loading ? 'loading' : ''}` }>
                { content }
            </div>

            <Modal visible={ modalDeleteVisible } title="Remove from Marketplace" subtitle={ itemToDelete?.title } onClose={ hideDeleteModal }>

                <Alert
                    type="danger nowrap"
                    visible={ deleteAlertVisible }
                    text={ deleteAlertText } />

                <p>
                    Removing your item from the Marketplace means that potential buyers will no longer be able to find
                    your item through search or categories. They won't be able to make offers or commit to buy your item.
                </p>

                <p>Are you sure you wish to remove the specified item from the Kolekto Marketplace?</p>

                <Button
                    loading={ deleteButtonLoading }
                    className="btn-rounded btn-upper btn-primary"
                    onClick={ () => { removeItem(itemToDelete) } }
                    text="Yes, remove" /> 

                <Button
                    className="btn-text-muted"
                    onClick={ () => { hideDeleteModal() } }
                    text="Cancel" />
            </Modal>
        </div>
    );
};

export default Items;