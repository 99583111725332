import React, { useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications'
import { AuthService } from 'Services';
import { Modal } from 'Components';

const QRCodeModal = props => {

    const { visible, onClose, item } = props;
    const { addToast } = useToasts();

    const [ loading, setLoading ] = useState(false);
    const [ qrCode, setQrCode ] = useState(null);
    const [ qrCodeURL, setQrCodeURL ] = useState(null);

    useEffect(() => { 
        if( item !== null )
        {
            setLoading(true);

            let img = new Image();
            img.src = `https://api.kolekto.io/item/${item?.id}/asset-tag.png?token=${AuthService.getToken()}`;

            img.onload = () => {
                setQrCode(
                    <div className="modal-asset-tag">
                        <img src={ img.src } alt={ item?.name } />
                    </div>
                );

                setLoading(false);
                setQrCodeURL(img.src);
            };

            img.onerror = () => {
                setLoading(false);
                onClose();

                // Now show a toast error:
                addToast('There was a problem loading the barcode. Please try again.', {
                    appearance: 'error',
                    autoDismiss: true
                });
            };
        }
    }, [ item ]);
    
    const handleClose = () => {
        if( typeof onClose === 'function' )
        {
            onClose();
        }
    };

    return (
        <Modal visible={ visible } title={ item?.name } onClose={ handleClose } loading={ loading }>
            <p>
                The barcode below is unique to your item. You can scan it using our mobile app to quickly identify items
                in your collection. Simply print it out and secure it to your item.
            </p>

            <p>
                You can also print out all of the barcodes for a particular category by choosing the "Download Barcodes" option
                from the category menu.
            </p>

            { qrCode }

            <div className="mt25">
                <a href={ qrCodeURL } download target="_blank" rel="noreferrer" className="btn btn-rounded btn-primary btn-upper">Download asset tag</a>
            </div>
        </Modal>
    );
};

export default QRCodeModal;