import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { hideBackdrop, showBackdrop } from 'store/actions/modalActions';

const Modal = (props) => {

	const dispatch = useDispatch();

	const dismissable = (props.dismissable === true || props.dismissable === undefined);
	const { visible, onBeforeOpen, onBeforeClose, onClose } = props;
	const closable = (props.closable === true || props.closable === undefined);

	const closeButton = (closable) ? <button type="button" className="modal-dismiss" onClick={(e) => { props.onClose(e) }}>
									    	&times;
									  	</button> 
									  : '';

	const className = [ 'modal'];

	// Handle escape key:
	const escFunction = useCallback((e) => { 
		if( e.keyCode === 27 && dismissable ) { 
			if( typeof onClose === 'function' )
			{
				onClose(e); 
			}
		} 
	}, [ dismissable, onClose ]);

	// Listen for the escape key:
	useEffect(() => { 
		document.addEventListener('keydown', escFunction, false); 
		return () => document.removeEventListener('keydown', escFunction);
	}, [ escFunction ]);

	let sizeClass = '';

	// Handle the size:
	switch( props.size )
	{
		case 'sm':
		case 'small':
			sizeClass = 'modal-sm';
			break;

		case 'lg':
		case 'large':
			sizeClass = 'modal-lg';
			break;
		
		default:
			sizeClass = '';
	}
	  
	let footer = '';

	if( props.status !== undefined || props.buttons !== undefined )
	{
		footer = <div className="modal-footer">
					<span className="modal-footer_status">
						{ (props.status !== undefined) ? props.status : '' }
					</span>
					<div className="modal-footer_actions">
						{ props.buttons }
					</div>
				</div>
	}

	// Are we doing something onOpen or onClose?
	useEffect(() => { 
		
		if( visible === true  )
		{
			if( onBeforeOpen !== undefined )
			{
				onBeforeOpen();
			}
		}
		else
		{
			if( onBeforeClose !== undefined )
			{
				onBeforeClose();
			}
		}
	}, [ visible, onBeforeOpen, onBeforeClose ]);

	// Show the backdrop:
	useEffect(() => {
		if( visible === true )
		{
			dispatch(showBackdrop());
		}
		else
		{
			dispatch(hideBackdrop());
		}
	}, [ visible ]);

	useEffect(() => {
		if( visible  )
		{
			document.body.classList.add('modal-in');
		}
		else
		{
			document.body.classList.remove('modal-in');
		}
	}, [ visible ]);

	const content = (props.content !== undefined) ? props.content : props.children;

	if( props.className !== undefined )
	{
		className.push(props.className);
	}

	if( props.visible === true )
	{
		className.push('modal-in');
		
	}

	let subtitle = null;

	if( props.subtitle !== undefined )
	{
		subtitle = <span className="modal-subtitle">{ props.subtitle }</span>
	}

	const id = (props.id) ? { id: props.id } : null;

	return (
		<div { ...id } className={ className.join(' ') }>
            <div className={ `modal-dialog ${sizeClass} ${props.loading === true ? 'loading' : ''}` }>
                <div className="modal-header">
                    <h4 className="modal-title">
						{ props.title }
						{ subtitle }
					</h4>
                    { closeButton }
                </div>
                <div className="modal-content">
					{ content }
                </div>
				{ footer }
            </div>
        </div>
	);
}

export default Modal;