import React from 'react';
import { useDispatch } from 'react-redux';

import APIService from 'Services/APIService';
import { setDefaultAddress } from 'store/actions/addressActions';
import { openEditAddress } from 'store/actions/modalActions';

import { Icon, Dropdown } from 'Components';

const Address = (props) => {

    const dispatch = useDispatch();

    // Grab the properties:
    const { address, index } = props;

    const className = [ 'address' ];

    const lines = [ 
        address.name, 
        address.organization,
        address.line1, 
        address.line2, 
        address.city, 
        address.county, 
        address.postcode, 
        address.country 
    ];

    if( address.default === true )
    {
        className.push('address_default');
    }

    const dropdownItems = [
        {
            icon: 'pencil',
            text: 'Edit address',
            onClick: e => {
                e.preventDefault();
                dispatch(openEditAddress(address));
            }
        },
        {
            icon: 'star',
            text: 'Make default',
            disabled: (address.default === true),
            onClick: e => {
                e.preventDefault();

                APIService.put('/address/' + address.id, { default: true})
                    .then(response => { dispatch(setDefaultAddress(address.id)) });
            }
        },
        {
            separator: true
        },
        {
            icon: 'trash',
            text: 'Delete address',
            className: 'dropdown-menu_item-danger',
            onClick: (e => { props.onDelete(e, address.id); })
        }
    ];
	
	return (
		<div key={ index } className={ className.join(' ') }>
            <Dropdown
                toggleClassName="btn btn-icon-plain"
                toggleContent={ <Icon name="ellipsis-h" /> }
                position="top-end"
                size="small"
                items={ dropdownItems }
                />
                
			<address>
                { lines.map((line, i) => {
                    if( line !== null && line !== '' )
                    {
                        return (
                            <span key={i} className="address-line">{ line }</span>
                        );
                    }
                    
                    return '';
                })}
            </address>
		</div>
	);
}

export default Address;