import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import Main from 'Main';

Sentry.init({
    dsn: "https://9824686b292a4aff8e6fad65fc4e2e5a@o571559.ingest.sentry.io/5719956",
    integrations: [ new Integrations.BrowserTracing() ],
    tracesSampleRate: 1.0,
});

ReactDOM.render(
    <Main />,
    document.getElementById('root')
);