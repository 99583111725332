import React from 'react';

const DataTable = props => {

    return (
        <table className="table-collection-items">
            { props.children }
        </table>
    );
};

export default DataTable;