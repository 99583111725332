import React, { useState } from 'react';

import { DateFormat } from 'Components';

const ItemInfo = props => {
    const { item, onImageMousein, onImageMouseout } = props;

    const [ imagePopoverLoading, setImagePopoverLoading ] = useState(true);
    const [ imagePopoverVisible, setImagePopoverVisible ] = useState(false);

    const handleImageMouseIn = () => {
        setImagePopoverVisible(true);
    };

    const handleImageMouseOut = () => {
        setImagePopoverLoading(false);
        setImagePopoverVisible(false);
    };

    let image = null;

    if (item.images.length > 0) {
        image = (
            <div class="item-info_image">
                <img alt={item.mame}
                    className="item-thumb"
                    onMouseEnter={handleImageMouseIn}
                    onMouseLeave={handleImageMouseOut}
                    src={item.images[0].thumbnail}
                />

                <div className={ `image-popover ${imagePopoverVisible === true ? 'image-popover--visible' : '' } ${imagePopoverLoading === true ? 'image-popover--loading': ''}` }>
                    <img
                        loading="lazy"
                        src={ `${item.images[0].url}?tr=h-400` }
                    />
                </div>
            </div>
        );
    }

    return (
        <div className="item-info">
            { image }
            <div className="item-info_info">
                <span className="item-info_name">{ item.name }</span>
                <span className="item-info_meta">Added <DateFormat date={ item.created } /></span>
            </div>
        </div>
    )
};

export default ItemInfo;
