import React, { useEffect, useState } from 'react';
import produce from 'immer';

import { Button, ToggleSwitch, Modal } from 'Components';
import { objectSelector } from 'store/selectors';

const ColumnModal = props => {

    const { columns, collection, visible, onClose } = props;

    const [ cols, setCols ] = useState(columns || []);

    useEffect(() => { 
        setCols(columns);
    }, [ columns ]);

    const handleClose = () => {
        if( typeof onClose === 'function' )
        {
            onClose();
        }
    };

    const handleChange = (id, enabled) => {
        setCols(produce(cols, draft => {
            if( enabled )
            {
                draft.push(id);
            }
            else
            {
                draft.splice(draft.indexOf(id), 1);
            }
        }));  
    };

    const handleSubmit = e => {
        e.preventDefault();

        if( typeof props.onSubmit === 'function' )
        {
            props.onSubmit(cols);
        }
    }

    const available = [ ];

    objectSelector(collection.attributes).map((attr, i) => ( available.push({ id: attr.id, name: attr.name })));

    return (
        <Modal visible={ visible } title="Manage columns" onClose={ handleClose }>
            
            <p>Enable or disable columns to show or hide them:</p>

            <div className="column-sorter">
                <ToggleSwitch 
                    checked={ cols.indexOf('quantity') >= 0 }
                    onChange={(e, checked) => { handleChange('quantity', checked) }} 
                    label="Quantity" />

                {available.map((col, i) => (
                    <ToggleSwitch 
                        checked={ cols.indexOf(col.id) >= 0 }
                        onChange={(e, checked) => { handleChange(col.id, checked) }} 
                        key={ i } 
                        label={ col.name } />
                ))}
            </div>

            <div className="mt25">
                <Button 
                    onClick={ handleSubmit }
                    className="btn-rounded btn-upper btn-primary"
                    text="Save changes" />

                <Button 
                    onClick={ handleClose }
                    className="btn-text-muted btn-upper" 
                    text="Cancel" />
            </div>
        </Modal>
    );
};

export default ColumnModal;