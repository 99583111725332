import React from 'react';
import AuthService from '../../Services/AuthService';

import { Button } from 'Components';

const InvoiceActions = props => {

   const { onDownload, onPrint, invoice } = props;

   const handleDownload = e => {
       e.preventDefault();

       if (typeof onDownload === 'function') {
           onDownload(invoice);
       }
   };

   const handlePrint = e => {
       e.preventDefault();

       if (typeof onPrint === 'function') {
           onPrint(invoice);
       }
   };

   return (
       <div className="invoice-actions">
           <Button
                onClick={ handleDownload }
                title="Download PDF invoice"
                className="btn-icon-white"
                icon="download" />

           <Button 
                onClick={ handlePrint }
                title="Print invoice"
                className="btn-icon-white"
                style={{ display: 'inline-block' }}
                icon="print" />
       </div>
   );
};

export default InvoiceActions;