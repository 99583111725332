import React, { useState, useEffect } from 'react';

const Dragger = props => {

    const { text, visible } = props;
    const [ pos, setPos ] = useState({ x: 0, y: 0 });

    const className = [ 'drag-element' ];

    if( visible )
    {
        className.push('drag-element_in');
    }

    useEffect(() => {
        window.addEventListener('mousemove', e => { 
            setPos({
                x: e.clientX + 5,
                y: e.clientY + 5
            });
        });
    }, [ ]);

    return (
        <div className={ className.join(' ') } style={{ top: pos.y,  left: pos.x }}>{ text }</div>
    );
};  

export default Dragger;