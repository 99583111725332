export const SHOW_BACKDROP = 'SHOW_BACKDROP';
export const HIDE_BACKDROP = 'HIDE_BACKDROP';

export const OPEN_CHANGE_PASSWORD = 'OPEN_CHANGE_PASSWORD';
export const CLOSE_CHANGE_PASSWORD = 'CLOSE_CHANGE_PASSWORD';

export const OPEN_ADD_ADDRESS = 'OPEN_ADD_ADDRESS';
export const CLOSE_ADD_ADDRESS = 'CLOSE_ADD_ADDRESS';
export const OPEN_EDIT_ADDRESS = 'OPEN_EDIT_ADDRESS';
export const CLOSE_EDIT_ADDRESS = 'CLOSE_EDIT_ADDRESS';

export const OPEN_ADD_PAYMENT = 'OPEN_ADD_PAYMENT';
export const CLOSE_ADD_PAYMENT = 'CLOSE_ADD_PAYMENT';

export const OPEN_ADD_ATTRIBUTE = 'OPEN_ADD_ATTRIBUTE';
export const CLOSE_ADD_ATTRIBUTE = 'CLOSE_ADD_ATTRIBUTE';

export const OPEN_EDIT_ATTRIBUTE = 'OPEN_EDIT_ATTRIBUTE';
export const CLOSE_EDIT_ATTRIBUTE = 'CLOSE_EDIT_ATTRIBUTE';

export const OPEN_ADD_COLLECTION = 'OPEN_ADD_COLLECTION';
export const CLOSE_ADD_COLLECTION = 'CLOSE_ADD_COLLECTION';
export const OPEN_EDIT_COLLECTION = 'OPEN_EDIT_COLLECTION';
export const CLOSE_EDIT_COLLECTION = 'CLOSE_EDIT_COLLECTION';
export const OPEN_DELETE_COLLECTION = 'OPEN_DELETE_COLLECTION';
export const CLOSE_DELETE_COLLECTION = 'CLOSE_DELETE_COLLECTION';

export const OPEN_ADD_CATEGORY = 'OPEN_ADD_CATEGORY';
export const OPEN_ADD_SUBCATEGORY = 'OPEN_ADD_SUBCATEGORY';
export const CLOSE_ADD_CATEGORY = 'CLOSE_ADD_CATEGORY';
export const CLOSE_ADD_SUBCATEGORY = 'CLOSE_ADD_SUBCATEGORY';
export const OPEN_EDIT_CATEGORY = 'OPEN_EDIT_CATEGORY';
export const CLOSE_EDIT_CATEGORY = 'CLOSE_EDIT_CATEGORY';
export const OPEN_DELETE_CATEGORY = 'OPEN_DELETE_CATEGORY';
export const CLOSE_DELETE_CATEGORY = 'CLOSE_DELETE_CATEGORY';

export const OPEN_ENTITY_INFO = 'OPEN_ENTITY_INFO';
export const CLOSE_ENTITY_INFO = 'CLOSE_ENTITY_INFO';

export const OPEN_SHOW_ITEM = 'OPEN_SHOW_ITEM';
export const CLOSE_SHOW_ITEM = 'CLOSE_SHOW_ITEM';

export const showBackdrop = () => ({ type: SHOW_BACKDROP });
export const hideBackdrop = () => ({ type: HIDE_BACKDROP });

export const openChangePassword = () => ({ type: OPEN_CHANGE_PASSWORD });
export const closeChangePassword = () => ({ type: CLOSE_CHANGE_PASSWORD });

export const openAddAddress = () => ({ type: OPEN_ADD_ADDRESS });
export const closeAddAddress = () => ({ type: CLOSE_ADD_ADDRESS });
export const openEditAddress = address => ({ type: OPEN_EDIT_ADDRESS, payload: { address: address } });
export const closeEditAddress = () => ({ type: CLOSE_EDIT_ADDRESS });

export const openAddPaymentMethod = () => ({ type: OPEN_ADD_PAYMENT });
export const closeAddPaymentMethod = () => ({ type: CLOSE_ADD_PAYMENT });

export const openAddAttribute = () => ({ type: OPEN_ADD_ATTRIBUTE });
export const closeAddAttribute = () => ({ type: CLOSE_ADD_ATTRIBUTE });

export const openEditAttribute = attribute => ({ type: OPEN_EDIT_ATTRIBUTE, payload: { attribute: attribute } });
export const closeEditAttribute = () => ({ type: CLOSE_EDIT_ATTRIBUTE });

export const openAddCollection = () => ({ type: OPEN_ADD_COLLECTION });
export const closeAddCollection = () => ({ type: CLOSE_ADD_COLLECTION });
export const openEditCollection = collection => ({ type: OPEN_EDIT_COLLECTION, payload: { collection: collection }});
export const closeEditCollection = () => ({ type: CLOSE_EDIT_COLLECTION });
export const openDeleteCollection = collection => ({ type: OPEN_DELETE_COLLECTION, payload: { collection: collection }});
export const closeDeleteCollection = () => ({ type: CLOSE_DELETE_COLLECTION });

export const openAddCategory = collection => ({ type: OPEN_ADD_CATEGORY, payload: { collection: collection } });
export const closeAddCategory = () => ({ type: CLOSE_ADD_CATEGORY });
export const openAddSubcategory = category => ({ type: OPEN_ADD_SUBCATEGORY, payload: { parent: category } });
export const closeAddSubcategory = () => ({ type: CLOSE_ADD_SUBCATEGORY });
export const openEditCategory = category => ({ type: OPEN_EDIT_CATEGORY, payload: { category: category }});
export const closeEditCategory = () => ({ type: CLOSE_EDIT_CATEGORY });
export const openDeleteCategory = category => ({ type: OPEN_DELETE_CATEGORY, payload: { category: category }});
export const closeDeleteCategory = () => ({ type: CLOSE_DELETE_CATEGORY });

export const openEntityInfo = (title, data) => ({ type: OPEN_ENTITY_INFO, payload: { title: title, items: data } });
export const closeEntityInfo = () => ({ type: CLOSE_ENTITY_INFO });

export const openShowItem = item => ({ type: OPEN_SHOW_ITEM, payload: { item: item } });
export const closeShowItem = () => ({ type: CLOSE_SHOW_ITEM });