import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { categorySelector, collectionSelector } from 'store/selectors';
import { useToasts } from 'react-toast-notifications'
import { APIService } from 'Services';
import { objectSelector } from 'store/selectors';
import produce from 'immer';
import Utilities from 'Utilities';

import { openAddCategory, openAddCollection } from 'store/actions/modalActions';
import { updateCategoryItemCount } from 'store/actions/collectionActions';

import { EmptyState, Button } from 'Components';
import AddItem from './Forms/AddItem';
import ItemView from './ItemView/ItemView';
import { NotFoundPage } from 'Components/Pages';

const CollectionView = props => {

    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const className = [ 'collections_main' ];
    const { collections, hierarchy } = useSelector(state => state.collections);
    
    const [ addItemVisible, setAddItemVisible ] = useState(false);

    const [ loading, setIsLoading ] = useState(false);
    const [ items, setItems ] = useState([]);
    const [ content, setContent ] = useState(null);

    if( loading === true )
    {
        className.push('loading');
    }

    const activeCategory = categorySelector(collections, hierarchy);
    const activeCollection = collectionSelector(collections, hierarchy[0]);

    // Update the title:
    useEffect(() => { 
        document.title = Utilities.getPageTitle(activeCategory?.name); 
    }, [ activeCategory ]);

    // Handle the category change:
    useEffect(() => {
        setAddItemVisible(false);

        // We have a category, so load it:
        if(activeCategory !== null && activeCategory !== undefined)
        {
            setIsLoading(true);

            APIService.get(`/category/${activeCategory.id}/items/`)
                .then(resp => { 
                    setItems(resp.items);
                })
                .catch(error => { 
                    if( error.response.status !== 404 )
                    {
                        addToast(error.response.data.message, {
                            appearance: 'error',
                            autoDismiss: true
                        });
                    }
                    else
                    {
                        setItems([ ]);
                    }
                })
                .finally(() => setIsLoading(false));
        }

    }, [ activeCategory, addToast ]);

    // Add an item:
    const handleAddItem = item => {
        setItems(produce(items, draft => {
            draft['item' + item.id] = item;
        }));

        // Update the item count when deleting:
        dispatch(updateCategoryItemCount(activeCollection?.id, activeCategory?.id, activeCategory, 1));
    };

    // Delete an item:
    const handleDeleteItem = item => {
        setItems(produce(items, draft => {
            delete draft[`item${item.id}`];
        }));

        dispatch(updateCategoryItemCount(activeCollection?.id, activeCategory?.id, activeCategory, -1));
    }

    // Handle move:
    const handleMove = (items, numMoved, target) => {
        setItems(items);

        // Update the item count in the current category:
        dispatch(updateCategoryItemCount(activeCollection?.id, activeCategory?.id, activeCategory, (0 - numMoved)));
        dispatch(updateCategoryItemCount(target.collectionID, target.id, target, numMoved));
    }

    useEffect(() => {
        // No collections!
        if( ! objectSelector(collections).length )
        {
            setContent(
                <EmptyState
                    title="No collections"
                    description="You don't have any collections. Why not create one now?"
                    image="EmptyCollection"
                    button={ 
                        <Button 
                            className="btn-primary btn-rounded btn-upper" 
                            text="Create collection" 
                            onClick={() => { dispatch(openAddCollection()) }} /> 
                    } />
            );
        }

        // They don't have any:
        else if( ! activeCollection )
        {
            setContent(
                <EmptyState
                    title="No category selected"
                    description="Select a category from the list to view items."
                    image="EmptyCollection" />
            );
        }

        // No categories:
        else if( activeCollection.numCategories === 0 )
        {
            setContent(
                <EmptyState
                    title="No categories found"
                    description="The selected collection does not contain any categories."
                    image="EmptyCollection"
                    button={ 
                        <Button 
                            className="btn-primary btn-rounded btn-upper" 
                            text="Create category" 
                            onClick={() => { dispatch(openAddCategory(activeCollection)) }} /> 
                    } />
            );
        }

        else
        {
            if( ! activeCategory )
            {
                setContent(<NotFoundPage />);
            }
            else
            {
                setContent(
                    <ItemView
                        onAddItemOpen={() => { setAddItemVisible(true) }}
                        category={ activeCategory }
                        collection={ activeCollection }
                        onMove={ handleMove }
                        onDelete={ handleDeleteItem }
                        items={ items } />
                );
            }
        }
    }, [ collections, activeCollection, activeCategory, items ]);

    return (
        <div className={ className.join(' ') }>

            { content }

            <AddItem 
                collection={ activeCollection }
                category={ activeCategory } 
                visible={ addItemVisible } 
                onAddItem={ handleAddItem }
                onClose={() => { setAddItemVisible(false) }} />
        </div>
    )
};

export default CollectionView;