import React from 'react';

import { Icon } from 'Components';

const SearchForm = props => {
    return (
        <form className="messenger_message-list_search">
            <input type="text" placeholder="Search messages" />
            <label><Icon name="search" /></label>
        </form>
    );
};

export default SearchForm;