import { React, useState } from 'react';

// Elements:
import LoginInput from 'Components/FormControls/LoginInput';
import ToggleSwitch from 'Components/FormControls/ToggleSwitch';
import Button from 'Components/FormControls/Button';
import Alert from 'Components/Elements/Alert';
import Row from 'Components/Grid/Row';
import Column from 'Components/Grid/Column';

const RegisterForm = () => {

	// Registration fields:
    // const [ registerForename, setRegisterForename ] = useState(null);
    // const [ registerSurname, setRegisterSurname ] = useState(null);
    // const [ registerEmail, setRegisterEmail ] = useState(null);
    // const [ registerPassword, setRegisterPassword ] = useState(null);
    // const [ registerPassword2, setRegisterPassword2 ] = useState(null);

    // Alert:
    const [ alert, setAlert ] = useState({ text: null, visible: false, className: '' });

    // Handle the form submit:
    const handleSubmit = (e) => {
    	e.preventDefault();

    };

	return (
		// <form id="form-register" className="mt30" onSubmit={(e) => { handleSubmit(e) }}>
		// 	<Alert visible={ alert.visible } className={ `alert-icon alert-${alert.className} mb30`}>
	    //         { alert.text }
	    //     </Alert>

        //     <Row className="row-narrow">
        //         <Column>
        //         	<LoginInput required={ true } id="input-register-forename" label="First name" />
        //         </Column>
        //         <Column>
        //         	<LoginInput required={ true } id="input-register-surname" label="Last name" />
        //         </Column>
        //     </Row>

        //     <LoginInput className="mt25" type="email" required={ true } id="input-register-email" label="Email address" />

        //     <Row className="row-narrow mt25">
        //         <Column>
        //             <LoginInput type="password"  required={ true } id="input-register-password" label="Password" />
        //         </Column>
        //         <Column>
        //         	<LoginInput type="password" required={ true } id="input-register-password2" label="Confirm password" />
        //         </Column>
        //     </Row>

        //     <ToggleSwitch 
        //         className="mt30" 
        //         checked={ true }
        //         label="By signing up, I accept the <a href='#'>Terms & Conditions</a>" />

        //     <Button className="btn-shadow btn-primary btn-rounded btn-upper btn-lg w40 mt50">Register</Button>
        // </form>
        <></>
	);
};

export default RegisterForm;