import React from 'react';

const HeaderCell = props => {

    const className = (props.className !== undefined) ? props.className : '';
    const content = (props.text !== undefined) ? props.text : props.children;
    const width = (props.width !== undefined) ? `${props.width}%` : '';

    return (
        <th className={ className } width={ width }>
            { content }
        </th>
    );
};

export default HeaderCell;