import React, { useEffect, useState } from 'react';

import { ToggleSwitch, Datepicker, Input, TextArea, Select } from 'Components';

const CustomAttributeInput = (props) => {

    const { attribute, value } = props;

    const [ val, setVal ] = useState(value);

    useEffect(() => {
        setVal(value)
    }, [ value ]);

    const handleOnChange = (e, value) => {
        setVal(value);

        if (typeof props.onChange === 'function') {
            props.onChange(attribute.id, value);
        }
    };

    switch ( attribute?.type) {
        case 'short_text':
            return (
                <Input
                    style={{ ...props.style }}
                    className={ props.className }
                    value={ value }
                    onChange={ e => handleOnChange(e, e.target.value) }
                    label={ attribute.name } />
            );

        case 'number':
            return (
                <Input
                    style={{ ...props.style }}
                    type='number'
                    className={ props.className }
                    onKeyDown={ e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                    value={ value }
                    label={ attribute.name }
                    onChange={ e => handleOnChange(e, e.target.value) } />
            );

        case 'long_text':
            return (
                <TextArea
                    style={{ ...props.style }}
                    className={ props.className }
                    label={ attribute.name }
                    value={ value }
                    onChange={ e => handleOnChange(e, e.target.value) } />
            )

        case 'price':
            // Get the relevant fee icon:
            let icon = '£';

            if (attribute.metadata.currency === 'USD') {
                icon = '$';
            }
            else if (attribute.metadata.currency === 'EUR') {
                icon = '€;'
            }
            return (
                <Input
                    style={{ ...props.style }}
                    type="number"
                    className={ props.className }
                    prepend={ <span className="input-icon">{ icon }</span> }
                    value={ value }
                    label={ attribute.name }
                    onChange={ e => handleOnChange(e, e.target.value) }
                    onKeyDown={ e => ['e', 'E', '+', '-', ','].includes(e.key) && e.preventDefault()} />
            );

        case 'enum':
            const options = [ ];
            attribute.metadata.map((item, i) => { options.push({ value: item, text: item}); return options; });

            const placeholder = (value === '' || value === null || value === undefined) ? attribute.name : undefined;

            return (
                <Select
                    style={{ ...props.style }}
                    className={ props.className }
                    value={ value }
                    placeholder={ placeholder }
                    label={ attribute.name }
                    options={ options }
                    onChange={ e => handleOnChange(e, e.target.value) } />
            );

        case 'year':
        case 'date':
        case 'time':
        case 'datetime':
            let parsed = Date.parse(value);
            let val = (isNaN(parsed)) ? null : new Date(parsed);

            console.log(value, attribute);

            return (
                <Datepicker
                    className={ props.className }
                    type={ attribute.type }
                    value={ val }
                    label={ attribute.name }
                    onChange={ (date, e) => handleOnChange(e, date) } />
            );

        case 'boolean':
            return (
                <ToggleSwitch
                    className={ props.className }
                    label={ attribute.name }
                    checked={ value === 1 || value === true }
                    onChange={ e => handleOnChange(e, e.target.checked) } />
            );

        default:
            return <></>;
    }
}

export default CustomAttributeInput;
