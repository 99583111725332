import React from 'react';

import { Icon } from 'Components';
import BlankImage from 'Components/Elements/BlankImage';

const ImageUploadButton = props => {

    const multiple = (props.multiple === undefined || props.multiple === true);

    const title = (props.title === undefined) ? 'Add images from my device' : props.title;
    const icon = (props.icon === undefined) ? 'desktop' : props.icon;

    const className = [ 'image-uploader_btn' ];

    if( props.className !== undefined )
    {
        className.push(props.className);
    }

    const handleChange = e => {
        if( typeof props.onSelect === 'function' )
        {
            props.onSelect(
                e,
                e.target.files
            );
        }
    };

    return (
        <label className={ className.join(' ') }>
            <span className="image-uploader_btn-content">
                <Icon name={ icon } type="light" className="image-uploader_btn-icon" />
                <span className="image-uploader_btn-label">{ title }</span>
            </span>
            <BlankImage />
            <input multiple={ multiple } type="file" accept="image/*" onChange={ handleChange } />
        </label>
    );
};

export default ImageUploadButton;