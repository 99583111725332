import { React, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import config from 'config';
import moment from 'moment';
import { objectSelector } from 'store/selectors';

import { setTimezone, setDarkMode, setDateFormat, setTimeFormat, setCurrency, updateUserPrefs } from 'store/actions/userActions';

import { useToasts } from 'react-toast-notifications'

import { Alert, Button, Select, ToggleSwitch, Row, Column } from 'Components';

const Preferences = (props) => {

    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const [ buttonLoading, setButtonLoading ] = useState(false);

    const [ alertType, setAlertType ] = useState(null);
    const [ alertText, setAlertText ] = useState(null);
    const [ alertVisible, setAlertVisbible ] = useState(false);

    const { timezone, dateFormat, timeFormat, darkMode, currency } = useSelector(state => state.user.preferences);
    const { currencies } = useSelector(state => state.config);

    const handleSubmit = e => {
        e.preventDefault();
        setButtonLoading(true);
        
        // Update the user:
        dispatch(updateUserPrefs({ currency: currency, timezone: timezone, dateFormat: dateFormat, timeFormat: timeFormat, darkMode: darkMode }))
            .then(response => {
                addToast('Your profile was successfully updated.', {
                    appearance: 'success',
                    autoDismiss: true,
                });
            })
            .catch(error => {
                addToast(error.response.data.message, {
                    appearance: 'error',
                    autoDismiss: true,
                });
            })
            .finally(() => { setButtonLoading(false) });
    };

    const timeFormatOptions = [ ];
    const dateFormatOptions = [ ];
    const timezoneOptions = [ ];
    const currencyOptions = [ ];

    const selectedTimezone = (timezone === null) ? Intl.DateTimeFormat().resolvedOptions().timeZone : timezone;
    const selectedDateFormat = (dateFormat === null || dateFormat === '') ? 'D MMM Y' : dateFormat;
    const selectedTimeFormat = (timeFormat === null || timeFormat === '') ? 'HH:mm' : timeFormat;
    const selectedCurrency = currency;

    config.formats.time.map(item => {
        timeFormatOptions.push({
            value: item,
            text: moment().format(item)
        });
    });

    config.formats.date.map(item => {
        dateFormatOptions.push({
            value: item,
            text: moment().format(item)
        });
    });

    config.timezones.map(item => {
        timezoneOptions.push({
            value: item,
            text: item
        });
    });

    objectSelector(currencies).map(currency => {
        currencyOptions.push({
            value: currency.code,
            text: currency.name_plural
        });
    });

    return (
        <form className="container container-narrow" onSubmit={(e) => handleSubmit(e) }>
            <Row className="row-center">
                <Column width={ 50 }>
                    <p className="form-explain">Manage your application preferences and settings.</p>

                    <Alert visible={ alertVisible } className="mb25 alert-icon" type={ alertType }>{ alertText }</Alert>

                    <ToggleSwitch
                        className="mb40"
                        help="Dark mode will turn the user interface darker, which can be useful to reduce eye strain." 
                        label="Enable Dark Mode"
                        onChange={(e) => { dispatch(setDarkMode(e.target.checked)) }}
                        checked={ darkMode === true } />

                    <Select
                        className="mb40"
                        options={ timeFormatOptions }
                        label="Time format"
                        value={ selectedTimeFormat }
                        onChange={(e) => { dispatch(setTimeFormat(e.target.value)) }} />
                    
                    <Select
                        className="mb40"
                        options={ dateFormatOptions }
                        label="Date format"
                        value={ selectedDateFormat }
                        onChange={(e) => { dispatch(setDateFormat(e.target.value)) }} />
                    
                    <Select
                        className="mb40"
                        options={ timezoneOptions }
                        label="Timezone"
                        value={ selectedTimezone }
                        onChange={(e) => { dispatch(setTimezone(e.target.value)) }} />
                    
                    <Select
                        options={ currencyOptions }
                        label="Currency"
                        value={ selectedCurrency }
                        onChange={(e) => { dispatch(setCurrency(e.target.value)) }} />

                    <Button 
                        className="btn-upper btn-rounded btn-primary mt50" 
                        text="Save Changes"
                        loading={ buttonLoading } />
                </Column>
            </Row>
        </form>
    );
};

export default Preferences;