import { React, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications'
import produce from 'immer';

import { FormGroup, ToggleSwitch } from 'Components';
import { APIService } from 'Services';

const EmailPreferences = (props) => {

    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const { emailPreferences } = useSelector(state => state.user);

    const [ preferences, setPreferences ] = useState({
        news: false,
        security: false,
        marketing: false,
        logins: false,

        'marketplace.newMessage': false, 
        'marketplace.offerReceived': false, 
        'marketplace.offerAccepted': false, 
        'marketplace.offerDeclined': false
    });

    useEffect(() => { 
        setPreferences({
            news: emailPreferences.news ?? false,
            security: emailPreferences.security ?? false,
            marketing: emailPreferences?.marketing ?? false,
            logins: emailPreferences?.logins ?? false,
    
            'marketplace.newMessage': emailPreferences['marketplace.newMessage'] ?? false, 
            'marketplace.offerReceived': emailPreferences['marketplace.offerReceived'] ?? false, 
            'marketplace.offerAccepted': emailPreferences['marketplace.offerAccepted'] ?? false, 
            'marketplace.offerDeclined': emailPreferences['marketplace.offerDeclined'] ?? false
        });
    }, [ emailPreferences ]);

    const handleChange = (param, checked) => {

        const data = {
            preference: param,
            enabled: checked ? true : false
        };

        setPreferences(produce(preferences, draft => {
            draft[param] = checked;
        }));

        // Send the PUT request:
        APIService.put('/user/email-preferences/', data)
            .then(() => { 
                
            })
            .catch(error => {
                addToast(error.response.data.message, {
                    appearance: 'error',
                    autoDismiss: true
                });

                setPreferences(produce(preferences, draft => {
                    draft[param] = !checked;
                }));
            });
    };  

    return (
        <>
            <FormGroup title="General email preferences" description="Control when Kolekto sends you emails about our services or your account.">
                <ToggleSwitch
                    className="mb30"
                    help="If enabled, we will send you updates and news about Kolekto." 
                    label="Service updates and latest news"
                    checked={ preferences.news }
                    onChange={ e => handleChange('news', e.target.checked) } />

                <ToggleSwitch
                    className="my30"
                    help="Receive account security suggestions and information." 
                    label="Account security"
                    checked={ preferences.security }
                    onChange={ e => handleChange('security', e.target.checked) } />

                <ToggleSwitch
                    className="my30"
                    help="From time to time we may send you information regarding special offers."
                    label="Marketing emails"
                    checked={ preferences.marketing }
                    onChange={ e => handleChange('marketing', e.target.checked) } />

                <ToggleSwitch
                    className="my30"
                    help="Get notified about login activity to your Kolekto account from unknown devices."
                    label="Login activity"
                    checked={ preferences.logins }
                    onChange={ e => { handleChange('logins', e.target.checked) }} />
            </FormGroup>
            <FormGroup title="Marketplace email preferences" description="Kolekto Marketplace notifications can be delivered directly to your inbox. Control which Marketplace email notifications you receive.">
                <ToggleSwitch
                    className="my30"
                    help="Get notified via email when a Kolekto user sends a message to you via the Marketplace"
                    label="New message received"
                    checked={ preferences['marketplace.newMessage'] }
                    onChange={ e => { handleChange('marketplace.newMessage', e.target.checked) }} />

                <ToggleSwitch
                    className="mb30"
                    help="Receive an email when a potential buyer submits a new offer for your item."
                    label="New offer received"
                    checked={ preferences['marketplace.offerReceived'] }
                    onChange={ e => { handleChange('marketplace.offerReceived', e.target.checked) }} />

                <ToggleSwitch
                    className="my30"
                    help="Get notified as soon as a seller accepts your offer for an item."
                    label="Best offer accepted"
                    checked={ preferences['marketplace.offerAccepted'] }
                    onChange={ e => { handleChange('marketplace.offerAccepted', e.target.checked) }} />

                <ToggleSwitch
                    className="my30"
                    help="Get notified when a seller declines your best offer."
                    label="Best offer declined"
                    checked={ preferences['marketplace.offerDeclined'] }
                    onChange={ e => { handleChange('marketplace.offerDeclined', e.target.checked) }} />
            </FormGroup>
        </>
    );
};

export default EmailPreferences;