import { React, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { closeAddAddress } from 'store/actions/modalActions';
import { addAddressItem } from 'store/actions/addressActions';

import APIService from 'Services/APIService';
import ValidationService from 'Services/ValidationService';

import { CountrySelect, Row, Column, Alert, Modal, Button, Input, ToggleSwitch } from 'Components';

const validator = new ValidationService({
    name: {
        isRequired: { message: 'Recipient name is required.' },
        maxLength: { data: 50, message: 'Recipient name cannot be longer than 50 characters.' }
    }
});

const ModalAddAddress = (props) => {

    const { addAddress } = useSelector(state => state.modals);
    const dispatch = useDispatch();

    // Alert state:
    const [ alertVisible, setAlertVisible ] = useState(false);
    const [ alertClass, setAlertClass ] = useState(null);
    const [ alertText, setAlertText ] = useState('');

    // Button state:
    const [ buttonLoading, setButtonLoading ] = useState(false);

    // Values state:
    const [ name, setName ] = useState('');
    const [ company, setCompany ] = useState('');
    const [ line1, setLine1 ] = useState('');
    const [ line2, setLine2 ] = useState('');
    const [ city, setCity ] = useState('');
    const [ county, setCounty ] = useState('');
    const [ postcode, setPostcode ] = useState('');
    const [ country, setCountry ] = useState('');
    const [ isDefault, setIsDefault ] = useState(false);

    // Reset onclose:
    const onBeforeClose = event => {
        setAlertVisible(false);
        setButtonLoading(false);
    };

    // Handle the form submission:
    const handleSubmit = event => {
        event.preventDefault();

        setButtonLoading(true);
        setAlertVisible(false);

        const data = {
            name: name,
            organization: company,
            line1: line1,
            line2: line2,
            city: city,
            county: county,
            postcode: postcode,
            country: country,
            default: isDefault
        }

        // Send off the request:
        APIService.post('/addresses/', data)
            .then(response => { 
                dispatch(addAddressItem(response.address));
                dispatch(closeAddAddress());

                if( typeof props.onSuccess === 'function' )
                {
                    props.onSuccess(response.address);
                }
            })
            .catch(error => { 
                setAlertClass('danger');
                setAlertText(error.response.data.message);
                setAlertVisible(true);
            })
            .finally(() => { setButtonLoading(false) });
    };

    return (
        <Modal size="medium" onBeforeClose={ onBeforeClose } onClose={(e) => { dispatch(closeAddAddress()) }} title="Add address" visible={ addAddress }>
            <p>Add a new address to your Kolekto account:</p>

            <Alert className={ `alert-${alertClass} alert-icon mb25` } visible={alertVisible}>
                { alertText }
            </Alert>

            <form onSubmit={(e) => { handleSubmit(e); }}>
                <Input 
                    {...validator.getFieldProps('name')}
                    className="my20" 
                    onChange={(e) => { setAlertVisible(false); setName(e.target.value) }}
                    value={ name }
                    label="Name" />
                
                <Input 
                    className="my20" 
                    onChange={(e) => { setAlertVisible(false); setCompany(e.target.value) }}
                    value={ company }
                    label="Company / Organization" />

                <Input 
                    className="my20" 
                    {...validator.getFieldProps('line1')}
                    onChange={(e) => { setAlertVisible(false); setLine1(e.target.value) }}
                    value={ line1 }
                    label="Line 1" />

                <Input 
                    className="my20" 
                    {...validator.getFieldProps('line2')}
                    onChange={(e) => { setAlertVisible(false); setLine2(e.target.value) }}
                    value={ line2 }
                    label="Line 2" />

                <Input 
                    className="my20" 
                    {...validator.getFieldProps('city')}
                    onChange={(e) => { setAlertVisible(false); setCity(e.target.value) }}
                    value={ city }
                    label="City" />
                
                <Row className="row-narrow">
                    <Column>
                        <Input 
                            onChange={(e) => { setAlertVisible(false); setCounty(e.target.value) }}
                            value={ county }
                            {...validator.getFieldProps('county')}
                            label="State / County" />
                    </Column>
                    <Column width={33}>
                        <Input 
                            onChange={(e) => { setAlertVisible(false); setPostcode(e.target.value) }}
                            value={ postcode }
                            {...validator.getFieldProps('postcode')}
                            label="Postal Code" />
                    </Column>
                </Row>

                <CountrySelect
                    onChange={(e) => { setCountry(e.target.value)} }
                    {...validator.getFieldProps('country')}
                    className="my20" />

                <ToggleSwitch 
                    onChange={(e) => { setIsDefault(e.target.checked) }}
                    className="my30"
                    label="Make this my default address"
                    checked={ isDefault } />

                <Button 
                    className="btn-primary btn-upper btn-rounded" 
                    text="Add address" 
                    loading={ buttonLoading } />
            </form>
        </Modal>
    );
};

export default ModalAddAddress;