import axios from 'axios';
import AuthService from './AuthService';

const API_URL = 'https://api.kolekto.io';

class APIService
{
	unprotected = [
		'login',
		'register',
		'password-reset'
	];

	get(endpoint, data)
	{
		let url = API_URL + endpoint;

		// Set the token:
		let token = AuthService.getToken();
		let config = { };

		if( token != null )
		{
			 config = {
			    headers: { Authorization: `Bearer ${token}` }
			};
		};

		if( data !== undefined )
		{
			config.params = data;
		}

		return axios.get(url, config)
			.then(response => { return response.data })
			.catch(error => {
				switch( error.response.status )
				{
					case 401:
						this.redirectToLogin();
						break;

					case 402:
						this.redirectToSubscriptions();
						break;

					default:
						throw error;
				};
			});
	};

	post(endpoint, data, headers)
	{
		let url = API_URL + endpoint;

		// Set the token:
		let token = AuthService.getToken();
		let config = {
			headers: ((headers !== undefined) ? headers : { })
		};

		if( token != null )
		{
			config.headers.Authorization = `Bearer ${token}`;
		};

		return axios.post(url, data, config)
			.then(response => { return response.data })
			.catch(error => {
				switch( error.response.status )
				{
					case 401:
						this.redirectToLogin();
						break;

					case 402:
						this.redirectToSubscriptions();
						break;

					default:
						throw error;
				};
			});
	};

	put(endpoint, data)
	{
		let url = API_URL + endpoint;

		// Set the token:
		let token = AuthService.getToken();
		let config = { };

		if( token != null )
		{
			 config = {
			    headers: { Authorization: `Bearer ${token}` }
			};
		};

		return axios.put(url, data, config)
			.then(response => { return response.data })
			.catch(error => {
				switch( error.response.status )
				{
					case 401:
						this.redirectToLogin();
						break;

					default:
						throw error;
				};
			});
	};

	patch(endpoint, data)
	{
		let url = API_URL + endpoint;

		// Set the token:
		let token = AuthService.getToken();
		let config = { };

		if( token != null )
		{
			 config = {
			    headers: { Authorization: `Bearer ${token}` }
			};
		};

		return axios.patch(url, data, config)
			.then(response => { return response.data })
			.catch(error => {
				switch( error.response.status )
				{
					case 401:
						this.redirectToLogin();
						break;

					case 402:
						this.redirectToSubscriptions();
						break;

					default:
						throw error;
				};
			});
	};

	delete(endpoint, data)
	{
		let url = API_URL + endpoint;

		// Set the token:
		let token = AuthService.getToken();
		let config = { };

		if( token != null )
		{
			config = {
			    headers: { Authorization: `Bearer ${token}` }
			};
		};

		if( data !== undefined )
		{
			config.params = data;
		}

		return axios.delete(url, config)
			.then(response => { return response.data })
			.catch(error => {
				switch( error.response.status )
				{
					case 401:
						this.redirectToLogin();
						break;

					case 402:
						this.redirectToSubscriptions();
						break;

					default:
						throw error;
				};
			});
	};

	redirectToLogin()
	{
		const path = window.location.pathname.replace(/^\/|\/$/g, '');

		if( this.unprotected.indexOf(path) === -1 )
		{
			window.location.href = '/login/';
		}

		return;
	};

	redirectToSubscriptions()
	{
		const path = window.location.pathname.replace(/^\/|\/$/g, '');

		if( path !== 'settings/subscriptions' )
		{
			window.location.href = '/settings/subscriptions/';
		}

		return;
	}

	getAPIUrl( endpoint, data )
	{
		const url = API_URL + endpoint;
		const qsa = new URLSearchParams(data).toString();

		return `${url}?${qsa}`;
	};
}

export default new APIService();
