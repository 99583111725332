import React from 'react';
import numeral from 'numeral';
import config from 'config';

const CurrencyFormat = props => {

    const number = numeral(props.value);

    let symbol, format;

    if( props.currency === undefined || props.currency === null)
    {
        symbol = null;
        format = '0,0.00';
    }
    else if( typeof props.currency === 'string' )
    {
        const currencyObject = config.currencies[props.currency];

        symbol = currencyObject.symbol;
        format = currencyObject.format;
    }
    else
    {
        symbol = props.currency.symbol;
        format = (props.format === undefined) ? '0,0.00' : props.format;
    }

    const className = [ 'currency' ];

    if( props.className ) 
    {
        className.push(props.className);
    }

    return (
        <span className={ className.join(' ') }>
            <span className="currency-symbol">{ symbol }</span>
            <span className="currency-amount">{ number.format(format) }</span>
        </span>
    )
};

export default CurrencyFormat;