import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { closeAddPaymentMethod } from 'store/actions/modalActions';
import { addPaymentMethod } from 'store/actions/userActions';

import { formatCardNumber } from 'helpers';

import { SelectGroup, SelectElement, Row, Column, Alert, Modal, Button, Input } from 'Components';
import { APIService } from 'Services';

const ModalAddPaymentMethod = (props) => {

    const dispatch = useDispatch();
    const { addPaymentMethodModal } = useSelector(state => state.modals);

    // Focus the select group:
    const [ expiryFocus, setExpiryFocus ] = useState(false);

    // Button loading:
    const [ buttonLoading, setButtonLoading ] = useState(false);
    const [ isFocused, setIsFocused ] = useState(false);

    // Values:
    const [ cardholderName, setCardholderName ] = useState('');
    const [ cardNumberFormatted, setCardNumberFormatted ] = useState('');
    const [ expiryMonth, setExpiryMonth ] = useState('');
    const [ expiryYear, setExpiryYear ] = useState('');
    const [ cvv, setCvv ] = useState('');

    // Alert state:
    const [ alertClass, setAlertClass ] = useState(null);
    const [ alertText, setAlertText ] = useState('');
    const [ alertVisible, setAlertVisible ] = useState(false);

    const months = [
        { value: '', text: 'MM' },
        { value: 1, text: '01 - January' },
        { value: 2, text: '02 - February' },
        { value: 3, text: '03 - March' },
        { value: 4, text: '04 - April' },
        { value: 5, text: '05 - May' },
        { value: 6, text: '06 - June' },
        { value: 7, text: '07 - July' },
        { value: 8, text: '08 - August' },
        { value: 9, text: '09 - September' },
        { value: 10, text: '10 - October' },
        { value: 11, text: '11 - November' },
        { value: 12, text: '12 - December' }
    ];

    // Build the year options:
    const years = [{ value: '', text: 'YY' }];
    const now = new Date().getFullYear();
    for(let i = 0; i <= 5; i++) { years.push({ value: (now + i), text: (now + i)}); }

    // Format card number:
    const handleCardNumberChange = value => {
        setCardNumberFormatted(formatCardNumber(value));
    };

    // Reset the form:
    const resetForm = () => {
        setCardholderName(null);
        setCardNumberFormatted(null);
        setExpiryMonth('');
        setExpiryYear('');
        setExpiryFocus(false);
        setCvv(null);
        setAlertVisible(false);
    };

    // Handle form submission:
    const handleSubmit = event => {
        event.preventDefault();

        setButtonLoading(true);
        setAlertVisible(false);

        const cardData = {
            cardholder: cardholderName,
            card_number: cardNumberFormatted,
            expire_month: expiryMonth,
            expire_year: expiryYear,
            cvc: cvv
        };

        APIService.post('/payment-methods/', cardData)
            .then(response => { 
                dispatch(addPaymentMethod(response.id, response));
                dispatch(closeAddPaymentMethod());
                resetForm();

                if( typeof props.onSuccess === 'function' )
                {
                    props.onSuccess(response.address);
                }
            })
            .catch(error => {
                setAlertClass('danger');
                setAlertText(error.response.data.message);
                setAlertVisible(true);
            })
            .finally(() => {
                setButtonLoading(false);
            });
    };

    // Handle close:
    const handleClose = () => {
        resetForm();
        dispatch(closeAddPaymentMethod());
    };

    return (
        <Modal size="small" title="Add payment method" onClose={(e) => handleClose() } visible={ addPaymentMethodModal }>
            <Alert className={ `alert-${alertClass} alert-icon mb25` } visible={alertVisible}>
                { alertText }
            </Alert>

            <form onSubmit={(e) => { handleSubmit(e) }}>
                <Input
                    onChange={(e) => { setCardholderName(e.target.value) }}
                    label="Cardholder name"
                    focused={ isFocused }
                    value={ cardholderName } />

                <Input
                    className="my20"
                    onChange={(e) => { handleCardNumberChange(e.target.value) }}
                    label="Card number"
                    value={ cardNumberFormatted } />

                <Row className="row-narrow mb20">
                    <Column width={ 75 }>
                        <SelectGroup label="Expiry date" className={(expiryFocus) ? 'focused' : ''}>
                            <SelectElement 
                                value={ expiryMonth }
                                onChange={(e) => { setExpiryMonth(e.target.value) }}
                                onBlur={(e) => { setExpiryFocus(false) }} 
                                onFocus={(e) => { setExpiryFocus(true) }} 
                                options={ months } />

                            <SelectElement 
                                value={ expiryYear }
                                onChange={(e) => { setExpiryYear(e.target.value) }}
                                onBlur={(e) => { setExpiryFocus(false) }} 
                                onFocus={(e) => { setExpiryFocus(true) }} 
                                options={ years } />
                        </SelectGroup>
                    </Column>    
                    <Column>
                        <Input
                            onChange={(e) => { setCvv(e.target.value )}}
                            minLength={3}
                            maxLength={4}
                            value={ cvv }
                            label="CVV" />
                    </Column>
                </Row>

                <Button 
                    loading={ buttonLoading }
                    className="btn-primary btn-rounded btn-upper"
                    text="Add payment method" />
            </form>
        </Modal>
    );
};

export default ModalAddPaymentMethod;