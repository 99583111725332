import React from 'react';
import numeral from 'numeral';

const NumberFormat = props => {
    
    const number = numeral(props.value);
    const format = (props.format === undefined) ? '0,0' : props.format;

    return (
        <span className={ props.className ? props.className : '' }>
            { number.format(format) }
        </span>
    )
};

export default NumberFormat;