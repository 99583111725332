import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'Components';

const ModalPage = (props) => {

    const history = useHistory();

    const { title, className, visible, component, buttons, contentClassName } = props;

    const handleClose = (e) => {
        e.preventDefault();

        history.push('../');
    };

	return (
		<div className={ 'modal-page ' + (className !== undefined ? className : '') + (visible === true ? ' modal-page_visible' : '') }>
			<div className="modal-page_header">
                <div className="modal-page_header-left">
                    <Button title="Back to all settings" className="btn-icon-plain" icon="chevron-left" onClick={(e) => handleClose(e)} />
                    <h1 className="modal-page_header-title">{ title }</h1>
                </div>
                <div className="modal-page_header-right">
                    { buttons }
                </div>
			</div>
            <div className={ `modal-page_content ${(contentClassName !== undefined) ? contentClassName : ''}` }>
                { component }
            </div>
        </div>
	);
}

export default ModalPage;