import React from 'react';
import { Row, IconCard, Column } from 'Components';

const MarketplaceIndex = props => {
    return (
        <div className="marketplace-index p30">
            <Row>
                <Column width={25}><IconCard link="/marketplace/items/" text="My Items" icon="tag" /></Column>
                <Column width={25}><IconCard link="/marketplace/favourites/" text="My Favourites" icon="heart" /></Column>
                <Column width={25}><IconCard link="/marketplace/offers/" text="My Offers" icon="gavel" /></Column>
                <Column width={25}><IconCard link="/marketplace/messages/" text="My Messages" icon="comments-alt" /></Column>
            </Row> 
        </div>
    );
};

export default MarketplaceIndex;