import { combineReducers } from 'redux';

import config from './reducers/configReducer';
import dashboard from './reducers/dashboardReducer';
import user from './reducers/userReducer';
import address from './reducers/addressReducer';
import settings from './reducers/settingsReducer';
import modals from './reducers/modalReducer';
import attributes from './reducers/attributeReducer';
import collections from './reducers/collectionReducer';
import drag from './reducers/dragReducer';

export default combineReducers({
  config,
  dashboard,
  user,
  address,
  settings,
  modals,
  attributes,
  collections,
  drag
});