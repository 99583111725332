import React from 'react';
import UploadedImage from './UploadedImage';

const UploadQueue = props => {

    const { images } = props;

    const className = [ 'image-uploader_queue' ];

    if( props.className !== undefined )
    {
        className.push(props.className);
    }

    const handleDelete = index => {
        if( typeof props.onDelete === 'function' )
        {
            props.onDelete(index);
        }
    };

    const loadingItems = (props.loadingCount > 0) ? new Array(props.loadingCount).fill(true) : [ ];

    return (
        <div className={ className.join(' ') }>
            { images.map((image, i) => (
                <UploadedImage key={ i } columnWidth={ props.columnWidth } onDelete={() => { handleDelete(i) }} image={image} />
            ))}

            {loadingItems.map((val, i) => ( 
                <UploadedImage key={ i } columnWidth={ props.columnWidth } image={{ loading: true }} />
            ))}
        </div>
    )

};

export default UploadQueue;