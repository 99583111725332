import React, { useEffect, useState } from 'react';
import produce from 'immer';
import { objectSelector } from 'store/selectors';

import AttributeFilter from './AttributeFilter';

const FilterFields = props => {
    const { filters, attributes, onFilterChange } = props;
    const [ checked, setChecked ] = useState({ });

    const handleToggleChange = (id, isChecked) => {
        setChecked(produce(checked, draft => {
            draft[id] = isChecked
        }));
    };

    useEffect(() => { 
        setChecked(produce(checked, draft => {
            for( let i in draft )
            {
                if( filters[i] === undefined )
                {
                    draft[i] = false;
                };
            }
        }));
    }, [ filters ]);

    const handleFilterChange = (id, checked, operator, value, minValue, maxValue) => {
        if( typeof onFilterChange === 'function' )
        {
            onFilterChange(
                id,
                checked,
                operator,
                value,
                minValue, 
                maxValue
            );
        }
    };

    return (
        <>
            <AttributeFilter 
                checked={(checked['name'] === true)}
                operator={(filters['name'] ? filters['name'].operator : 'contains')}
                value={(filters['name'] ? filters['name'].value : null)}
                onChange={(id, checked, operator, value, minValue, maxValue) => { handleFilterChange('name', checked, operator, value, minValue, maxValue) }}
                onToggle={checked => { handleToggleChange('name', checked) }}
                id="name"
                type="short_text"
                label="Item name" />

            {objectSelector(attributes).map((attribute, i) => (
                <AttributeFilter
                    key={ i }
                    checked={(checked[attribute.id] === true)}
                    operator={(filters[attribute.id] ? filters[attribute.id].operator : null)}
                    value={(filters[attribute.id] ? filters[attribute.id].value : null)}
                    minValue={(filters[attribute.id] ? filters[attribute.id].minValue : null)}
                    maxValue={(filters[attribute.id] ? filters[attribute.id].maxValue : null)}
                    onChange={(id, checked, operator, value, minValue, maxValue) => { handleFilterChange(id, checked, operator, value, minValue, maxValue) }}
                    onToggle={checked => { handleToggleChange(attribute.id, checked) }}
                    id={ attribute.id }
                    type={ attribute.type }
                    label={ attribute.name }
                    attribute={ attribute } />
            ))}
        </>
    );
};

export default FilterFields;