import React from 'react';

const GalleryItem = props => {
    const { width, image, index } = props;

    const className = [ 'gallery-view-column' ];

    return (
        <div className={ className.join(' ') } style={{ width: `${width}%`}}>
            <a href={ image.url }>
                <img src={ image.thumbnail } className="gallery-view-image" />
            </a>
        </div>
    )
};

export default GalleryItem;