import { 
    SHOW_BACKDROP,
    HIDE_BACKDROP,

    OPEN_CHANGE_PASSWORD,
    CLOSE_CHANGE_PASSWORD,

    OPEN_ADD_ADDRESS,
    CLOSE_ADD_ADDRESS,

    OPEN_EDIT_ADDRESS,
    CLOSE_EDIT_ADDRESS,

    OPEN_ADD_PAYMENT,
    CLOSE_ADD_PAYMENT,
    
    OPEN_ADD_ATTRIBUTE,
    CLOSE_ADD_ATTRIBUTE,
    OPEN_EDIT_ATTRIBUTE,
    CLOSE_EDIT_ATTRIBUTE,

    OPEN_ADD_COLLECTION,
    CLOSE_ADD_COLLECTION,
    OPEN_EDIT_COLLECTION,
    CLOSE_EDIT_COLLECTION,
    OPEN_DELETE_COLLECTION,
    CLOSE_DELETE_COLLECTION,

    OPEN_ADD_CATEGORY,
    OPEN_ADD_SUBCATEGORY,
    CLOSE_ADD_CATEGORY,
    CLOSE_ADD_SUBCATEGORY,
    OPEN_EDIT_CATEGORY,
    CLOSE_EDIT_CATEGORY,
    OPEN_DELETE_CATEGORY,
    CLOSE_DELETE_CATEGORY,

    OPEN_ENTITY_INFO,
    CLOSE_ENTITY_INFO,

    OPEN_SHOW_ITEM,
    CLOSE_SHOW_ITEM
} from '../actions/modalActions';

import produce from 'immer';

const initialState = {
    backdropIn: false,

    changePassword: false,

    addAddress: false,

    editAddress: false,
    addressToEdit: null,

    addPaymentMethodModal: false,

    addAttributeModal: false,
    editAttribute: false,
    attributeToEdit: null,

    addCollection: false,
    collectionToUse: null,
    editCollection: false,
    collectionToEdit: null,
    deleteCollection: false,
    collectionToDelete: null,

    addCategory: false,
    addSubcategory: false,
    categoryToUse: null,
    editCategory: false,
    categoryToEdit: null,
    deleteCategory: false,
    categoryToDelete: null,

    addItem: false,
    categoryToAddTo: null,

    entityInfo: false,
    entityInfoTitle: null,
    entityInfoItems: null,

    showItem: false,
    itemToShow: null
};
  
const modalReducer = (state = initialState, action) => {
    switch(action.type)
    {
        case SHOW_BACKDROP:
            return {
                ...state,
                backdropIn: true
            };

        case HIDE_BACKDROP:
            return {
                ...state,
                backdropIn: false
            };

        case OPEN_CHANGE_PASSWORD:
            return {
                ...state,
                changePassword: true
            };

        case CLOSE_CHANGE_PASSWORD:
            return {
                ...state,
                changePassword: false
            };

        case OPEN_ADD_ADDRESS:
            return {
                ...state,
                addAddress: true
            };

        case CLOSE_ADD_ADDRESS:
            return {
                ...state,
                addAddress: false
            };

        case OPEN_EDIT_ADDRESS:
            return {
                ...state,
                addressToEdit: action.payload.address,
                editAddress: true,
            };

        case CLOSE_EDIT_ADDRESS:
            return {
                ...state,
                editAddress: false,
                addressToEdit: null
            };

        case OPEN_ADD_PAYMENT:
            return {
                ...state,
                addPaymentMethodModal: true
            };

        case CLOSE_ADD_PAYMENT:
            return {
                ...state,
                addPaymentMethodModal: false
            };

        case OPEN_ADD_ATTRIBUTE:
            return {
                ...state,
                addAttributeModal: true
            };

        case CLOSE_ADD_ATTRIBUTE:
            return {
                ...state,
                addAttributeModal: false
            };

        case OPEN_EDIT_ATTRIBUTE:
            return {
                ...state,
                editAttribute: true,
                attributeToEdit: action.payload.attribute
            };  

        case CLOSE_EDIT_ATTRIBUTE:
            return {
                ...state,
                editAttribute: false,
                attributeToEdit: null
            };

        case OPEN_ADD_COLLECTION:
            return {
                ...state,
                addCollection: true
            };
        
        case CLOSE_ADD_COLLECTION:
            return {
                ...state,
                addCollection: false
            };
        
        case OPEN_EDIT_COLLECTION:
            return {
                ...state,
                editCollection: true,
                collectionToEdit: action.payload.collection
            }; 
        
        case CLOSE_EDIT_COLLECTION:
            return {
                ...state,
                editCollection: false,
                collectionToEdit: null
            };

        case OPEN_DELETE_COLLECTION:
            return {
                ...state,
                deleteCollection: true,
                collectionToDelete: action.payload.collection
            };

        case CLOSE_DELETE_COLLECTION:
            return {
                ...state,
                deleteCollection: false,
                collectionToDelete: null
            };

        case OPEN_ADD_CATEGORY:
            return {
                ...state,
                addCategory: true,
                collectionToUse: action.payload.collection
            };
        
        case CLOSE_ADD_CATEGORY:
            return {
                ...state,
                addCategory: false
            };

        case OPEN_ADD_SUBCATEGORY:
            return {
                ...state,
                addSubcategory: true,
                categoryToUse: action.payload.parent
            };
        
        case CLOSE_ADD_SUBCATEGORY:
            return {
                ...state,
                addSubcategory: false
            };
        
        case OPEN_EDIT_CATEGORY:
            return {
                ...state,
                editCategory: true,
                categoryToEdit: action.payload.category
            };

        case CLOSE_EDIT_CATEGORY:
            return {
                ...state,
                editCategory: false
            };

        case OPEN_DELETE_CATEGORY:
            return {
                ...state,
                deleteCategory: true,
                categoryToDelete: action.payload.category
            };
    
        case CLOSE_DELETE_CATEGORY:
            return {
                ...state,
                deleteCategory: false
            };

        case OPEN_ENTITY_INFO:
            return {
                ...state,
                entityInfo: true,
                entityInfoTitle: action.payload.title,
                entityInfoItems: action.payload.items
            };

        case CLOSE_ENTITY_INFO:
            return {
                ...state,
                entityInfo: false
            };

        case OPEN_SHOW_ITEM:
            return {
                ...state,
                showItem: true,
                itemToShow: action.payload.item
            };

        case CLOSE_SHOW_ITEM:
            return {
                ...state,
                showItem: false,
                itemToShow: null
            };

        default:
            return state;
    };
};

export default modalReducer;