import React, { useState, useCallback, useEffect } from 'react';
import { objectSelector } from 'store/selectors';
import { useSelector } from 'react-redux';

import { Select } from 'Components';

const SelectAddress = props => {

    const { label, onSelect } = props;
    const { addresses } = useSelector(state => state.address);

    const [ options, setOptions ] = useState([ ]);
    const [ selected, setSelected ] = useState('');
    const [ placeholder, setPlaceholder ] = useState(label);

    useEffect(() => { 
        const opts = [ ];
        
        objectSelector(addresses).map(address => {

            const lines = [ 
                    address.name, 
                    address.organization, 
                    address.line1, 
                    address.line2, 
                    address.city, 
                    address.county, 
                    address.postcode, 
                    address.country 
            ].filter(line => (line !== null && line !== ''));

            opts.push({
                value: address.id,
                text: lines.join(', ')
            });
        });

        setOptions(opts);
    }, [ addresses ]);

    const handleChange = e => {
        setSelected(e.target.value);
        setPlaceholder(undefined);

        if( typeof onSelect === 'function' )
        {
            onSelect(addresses[e.target.value]);
        }
    };

    return (options.length) 
        ? <Select onChange={ handleChange } placeholder={ placeholder } label={ label } options={ options } value={ selected } /> 
        : <></>;
};

export default SelectAddress;