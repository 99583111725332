import jwt_decode from 'jwt-decode';

import APIService from './APIService';

class AuthService 
{
    login(email, password, remember) 
    {
        return APIService
            .post('/user/login', { email: email, password: password })
            .then((response) => { 
                if( response.twoFactor !== true )
                {
                    let token = jwt_decode(response.token);
                    
                    // If we're remembering the user, store their JWT:
                    if( remember )
                    {
                        localStorage.setItem('token', response.token)
                    }

                    sessionStorage.setItem('user', JSON.stringify(token.user));
                };
                
                return response;
            });
    };

    // Fetch the user from the stored token:
    fetch()
    {
        return APIService
            .get('/user/')
            .then((response) => { return response; });
    };

    logout() 
    {
        localStorage.removeItem('token');
        sessionStorage.removeItem('user');
    };

    register(forename, surname, email, password, password2) 
    {
        return APIService.post('/users/', {
            forename: forename,
            surname: surname,
            email: email,
            password: password,
            password2: password2
        });
    };

    getUser() 
    {
        let userJSON = sessionStorage.getItem('user');

        if( userJSON === null )
        {
            if( this.getToken() !== null )
            {
                return this.fetch().then((user) => { 
                    sessionStorage.setItem(
                        'user', 
                        JSON.stringify(user)
                    );
                    
                    return user; 
                });
            }

            return null;
        }

        return JSON.parse(sessionStorage.getItem('user'));
    };

    getToken()
    {
        return localStorage.getItem('token');
    };
}

export default new AuthService();