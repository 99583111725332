import React from 'react';
import { Link } from 'react-router-dom';

import { Icon } from 'Components';

const Button = (props) => {

	// Grab the properties:
	const { href, title, name, onClick, value, icon, text, loading, type, visible } = props;

	let className = (props.className === undefined) ? [ 'btn-primary' ] : props.className.split(' ');

	const handleClick = (event) =>
	{
		if(typeof onClick == 'function')
		{
			onClick.call(
				null,
				event
			);
		}
	};

	let buttonIcon = '';
	let iconClass = '';

	if( icon !== undefined )
	{
		className.push('btn-has-icon');

		if( text === undefined && props.children )
		{
			iconClass = 'mr0';
		}

		buttonIcon = <Icon className={ iconClass } name={ icon } />;
	}

	if( loading === true )
	{
		className.push('btn-loading');
	}

	// Build the button content:
	const buttonContent = (text !== undefined) 
		? text
		: props.children;

	if( href !== undefined )
	{
		return (
			<a href={ href } className={ `btn ${className.join(' ')}` } target={ props.target ? props.target : '_self' } onClick={(e) => handleClick(e)} title={ title }>
				<span>
					{ buttonIcon }
					{ buttonContent }
				</span>
			</a>
		);
	}

	return (
		<button 
			disabled={ props.disabled === true }
			type={ type === undefined ? 'submit' : type } 
			title={ title } 
			value={ value } 
			className={ `btn ${className.join(' ')}` } 
			style={{ visibility: (props.visible === false ? 'hidden' : 'visible'), ...props.style }}
			name={name} 
			onClick={(e) => handleClick(e)}>
			<span>
				{ buttonIcon }
				{ buttonContent }
			</span>
		</button>
	);
}

export default Button;