import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { openShowItem } from 'store/actions/modalActions';

import { Button, DateFormat, Dropdown, Icon } from 'Components';

const GridItem = props => {
    const dispatch = useDispatch();
    const { item } = props;

    const { dragging } = useSelector(state => state.drag);

    const image = (item.images.length) ? <img alt={ item.name } className="item-thumb" loading="lazy" src={ item.images[0].thumbnail } />  : '';

    const handleDelete = item => {
        if( typeof props.onDelete === 'function' )
        {
            props.onDelete(item);
        }
    };

    const handleViewNotes = item => {
        if( typeof props.onViewNotes === 'function' )
        {
            props.onViewNotes(item);
        }
    };

    // Handle Marketplace:
    const handleMarketplaceAdd = item => {
        if( typeof props.onMarketplaceAdd === 'function' )
        {
            props.onMarketplaceAdd(item);
        }
    };

    const handleMarketplaceEdit = item => {
        if( typeof props.onMarketplaceEdit === 'function' )
        {
            props.onMarketplaceEdit(item);
        }
    };
    
    const handleEditItem = item => {
        if( typeof props.onEditItem === 'function' )
        {
            props.onEditItem(item);
        }
    };

    const handleItemBarcode = item => {
        if( typeof props.onShowBarcode === 'function' )
        {
            props.onShowBarcode(item);
        }
    };

    // Handle sharing manager:
    const handleManageSharing = item => {
        if( typeof props.onShareItem === 'function' )
        {
            props.onShareItem(item);
        }
    };

    const handleClick = e => {
        e.preventDefault(); 
        
        if( ! dragging )
        {
            dispatch(openShowItem(item));
        }
    };

    const getDropdownItems = item => {
        if( item.inMarketplace === true )
        {
            return [
                { icon: 'pencil', text: 'Edit item', onClick: (() => handleEditItem(item)) },
                { icon: 'sticky-note', text: 'View item notes', onClick: (() => handleViewNotes(item)) },
                { icon: 'qrcode', text: 'View asset tag', onClick: (() => handleItemBarcode(item)) },
                { icon: 'share-alt', text: 'Manage sharing address', onClick: (() => handleManageSharing(item) )},
                { sep: true },
                { icon: 'store', text: 'Manage Marketplace listing', onClick: (() => handleMarketplaceEdit(item)) },
                { icon: 'shopping-bag', text: 'View Marketplace listing', link: item.marketplaceURL, target: '_blank' },
                { sep: true },
                { icon: 'trash', text: 'Delete item', className: 'dropdown-menu_item-danger', onClick: (() => handleDelete(item)) }
            ];
        }

        return [
            { icon: 'pencil', text: 'Edit item', onClick: (() => handleEditItem(item)) },
            { icon: 'sticky-note', text: 'View item notes', onClick: (() => handleViewNotes(item)) },
            { icon: 'qrcode', text: 'View asset tag', onClick: (() => handleItemBarcode(item)) },
            { icon: 'share-alt', text: 'Manage sharing address', onClick: (() => handleManageSharing(item) )},
            { sep: true },
            { icon: 'store', text: 'Sell in Marketplace', onClick: (() => handleMarketplaceAdd(item)) },
            { sep: true },
            { icon: 'trash', text: 'Delete item', className: 'dropdown-menu_item-danger', onClick: (() => handleDelete(item)) }
        ];
    };

    return (
        <div className="grid-collection-items_cell">
            <div className="grid-collection-item" onMouseDown={ props.onMouseDown }>
                <div className="grid-collection-item_thumb"> 
                    { image }
                    <div className="grid-collection-item_thumb-actions">
                        <div className="grid-collection-item_thumb-actions-center">
                            <Button
                                onClick={(e) => { e.preventDefault(); dispatch(openShowItem(item)) }}
                                className="btn-icon-white mr10"
                                title="View item"
                                icon="eye" />

                            <Dropdown 
                                arrow={ true }
                                toggleClassName="btn-icon-white" 
                                toggleContent={ <Icon name="ellipsis-v" /> }
                                position="bottom"
                                size="small"
                                items={ getDropdownItems(item) } />
                        </div>
                    </div>
                </div>
                <div className="grid-collection-item_info" onClick={ handleClick }>
                    <span className="grid-collection-item_name">{ item.name }</span>
                    <span className="grid-collection-item_date">Added <DateFormat date={ item.created } /></span>
                </div>
            </div>
        </div>
    );
};

export default GridItem;