import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { objectSelector } from 'store/selectors';
import { produce } from 'immer';

import { closeEditCollection } from 'store/actions/modalActions';
import { updateCollection } from 'store/actions/collectionActions';
import { collectionSelector } from 'store/selectors';

import APIService from 'Services/APIService';

import { Alert, Modal, Button, TextArea, Input, ToggleSwitch } from 'Components';

const ModalEditCollection = (props) => {

    const dispatch = useDispatch();

    const editCollectionVisible = useSelector(state => state.modals.editCollection);
    const collection = useSelector(state => state.modals.collectionToEdit);
    const attributeObjects = useSelector(state => state.attributes.attributes);
    const { collections, hierarchy } = useSelector(state => state.collections);

    // Alert state:
    const [ alertVisible, setAlertVisible ] = useState(false);
    const [ alertClass, setAlertClass ] = useState(null);
    const [ alertText, setAlertText ] = useState('');

    // Button state:
    const [ buttonLoading, setButtonLoading ] = useState(false);

    // Values state:
    const [ name, setName ] = useState('');
    const [ description, setDescription ] = useState('');
    const [ attributes, setAttributes ] = useState([ ]);

    // Set the values:
    useEffect(() => { 
        if( collection !== null )
        {
            setName(collection.name);
            setDescription(collection.description);
            
            const attrIds = [ ];

            objectSelector(collection.attributes).map(attr => { attrIds.push(attr.id); });
            setAttributes(attrIds);
        }
    }, [ collection ]);
    
    // Reset onclose:
    const onBeforeClose = event => {
        setAlertVisible(false);
        setButtonLoading(false);
        //setName(null);
        //setDescription(null);
        //setAttributes([]);
    };

    // Handle the changing of the attributes:
    const handleAttributeChange = (event, id) => {
        if( event.target.checked === true )
        {
            setAttributes(
                produce(attributes, draft => { 
                    draft.push(id);
                }
            ));
        }
        else
        {
            setAttributes(
                produce(attributes, draft => {
                    draft.splice(attributes.indexOf(id), 1);
                })
            );
        }
    };

    // Handle the form submission:
    const handleSubmit = e => {
        e.preventDefault();
        setButtonLoading(true);
        setAlertVisible(false);

        APIService.put('/collection/' + collection?.id, { name: name, description: description, attributes: attributes })
            .then(response => { 
                dispatch(updateCollection(response.collection.id, response.collection));
                dispatch(closeEditCollection());
            })
            .catch(error => { 
                setAlertVisible(true);
                setAlertClass('danger');
                setAlertText(error.response.data.message);
            })
            .finally(() => { 
                setButtonLoading(false);
            });
    };

    return (
        <Modal size="medium" onBeforeOpen={ onBeforeClose } onClose={(e) => { dispatch(closeEditCollection()) }} title="Edit collection" visible={ editCollectionVisible }>

            <Alert className={ `alert-${alertClass} alert-icon mb25` } visible={alertVisible}>
                { alertText }
            </Alert>

            <form onSubmit={(e) => { handleSubmit(e); }}>
                <Input 
                    className="w75"
                    onChange={(e) => { setAlertVisible(false); setName(e.target.value) }}
                    value={ name }
                    label="Name" />
                
                <TextArea
                    height={ 100 }
                    className="mt25"
                    value={ description }
                    onChange={(e) => { setDescription(e.target.value) }}
                    label="Description" />

                <h3 className="my20">Attributes:</h3>

                <p>Choose which of your custom attributes you want to be made available to items that are added to this collection:</p>

                <div className="collection-attributes mt25">
                    { objectSelector(attributeObjects).map((attribute, index) => (
                        <ToggleSwitch 
                            label={ attribute.name } 
                            key={ index } 
                            checked={ (attributes.indexOf(attribute.id) > -1) }
                            onChange={(e) => { handleAttributeChange(e, attribute.id) }} />
                    ))}
                </div>

                <Button 
                    className="btn-primary btn-upper btn-rounded mt30" 
                    text="Edit collection" 
                    loading={ buttonLoading }
                    />
            </form>
        </Modal>
    );
};

export default ModalEditCollection;