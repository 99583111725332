import React from 'react';
import { useSelector } from 'react-redux';
import Conversation from './Conversation';

import { Icon } from 'Components';

const MessageListItem = props => {
    
    const currentUser = useSelector(state => state.user);

    const { conversation, onClick, active } = props;
    const className = [ 'messenger_message-list-item' ];

    const user = (conversation.sender.uuid != currentUser.uuid) ? conversation.sender : conversation.recipient;

    if( conversation.hasUnreadMessages === true )
    {
        className.push('messenger_message-list-item_unread');
    }

    if( active === true )
    {
        className.push('messenger_message-list-item_active');
    }

    const handleClick = event => {
        event.preventDefault();
        
        if( typeof onClick === 'function' )
        {
            onClick(conversation);
        }
    };

    const attachmentComponent = (conversation.hasAttachments)
        ? <Icon name="paperclip" className="messenger_message-list-item-subject-text-has-attachment" />
        : null;

    return (
        <div className={ className.join(' ') } onClick={ handleClick }>
            <img src={ user.avatar.thumbnail } className="messenger_message-list-item-avatar" />
            <div className="messenger_message-list-item-details">
                <span className="messenger_message-list-item-username">
                    { user.fullName }
                </span>
                <span className="messenger_message-list-item-subject">
                    <span className="messenger_message-list-item-subject-text">
                        { conversation.item.title }
                    </span>
                    <span className="messenger_message-list-item-date">{ attachmentComponent }</span>
                </span>
            </div>
        </div>
    );
};

export default MessageListItem;