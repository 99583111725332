import React, { useCallback, useState } from 'react';
import { Icon } from 'Components';

const CopyableInput = props => {

    const { type, value, toCopy, visible } = props;

    const [ buttonClass, setButtonClass ] = useState([ 'copyable_button' ]);

    const handleCopy = useCallback(e => {
        e.preventDefault();

        navigator.clipboard.writeText(toCopy || value)
            .then(() => {
                setButtonClass([ 'copyable_button', 'done' ]);

                window.setTimeout(function() { 
                    setButtonClass([ 'copyable_button' ]);
                }, 1500);
            });

    }, [ value, toCopy ]);

    return (
        <div className="copyable" style={{ display: (visible ? 'block' : 'none') }}>
            <input type={ type || 'text' } value={ value } readonly="readonly" />
            <button className={ buttonClass.join(' ') } onClick={ handleCopy }>
                <Icon name="copy" />
            </button> 
        </div>
    );
}; 

export default CopyableInput;