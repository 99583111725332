import React from 'react';

const FormGroup = (props) => {

    const { title, description } = props;

	return (
        <div className="form-group">
            <div className="form-group_explain">
                <h4 className="form-group_explain-title">{ title }</h4>
                <div className="form_group_explain-description">
                    { description }
                </div>
            </div>
            <div className="form-group_content">
                { props.children }
            </div>
        </div>
	);
}

export default FormGroup;