class HTMLEntities
{
    textarea = document.createElement('textarea');

    decode( string )
    {
        this.textarea.innerHTML = string;

        return this.textarea.value;
    };

    encode( string )
    {
        this.textarea.value = string;

        return this.textarea.innerHTML;
    };
}

export default new HTMLEntities();