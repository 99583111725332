import APIService from 'Services/APIService';

export const FETCH_ATTRIBUTES_BEGIN = 'FETCH_ATTRIBUTES_BEGIN';
export const FETCH_ATTRIBUTES_SUCCESS = 'FETCH_ATTRIBUTES_SUCCESS';
export const FETCH_ATTRIBUTES_FAILURE = 'FETCH_ATTRIBUTES_FAILURE';

export const FETCH_ATTRIBUTE_TYPES_BEGIN = 'FETCH_ATTRIBUTE_TYPES_BEGIN';
export const FETCH_ATTRIBUTE_TYPES_SUCCESS = 'FETCH_ATTRIBUTE_TYPES_SUCCESS';
export const FETCH_ATTRIBUTE_TYPES_FAILURE = 'FETCH_ATTRIBUTE_TYPES_FAILURE';

export const ADD_ATTRIBUTE = 'ADD_ATTRIBUTE';
export const REMOVE_ATTRIBUTE = 'REMOVE_ATTRIBUTE';
export const UPDATE_ATTRIBUTE = 'UPDATE_ATTRIBUTE';

const fetchAttributesBegin = () => ({ 
	type: FETCH_ATTRIBUTES_BEGIN 
});

const fetchAttributesSuccess = attributes => ({ 
	type: FETCH_ATTRIBUTES_SUCCESS, 
	payload: { 
		attributes: attributes 
	} 
});

const fetchAttributesFailure = error => ({ 
	type: FETCH_ATTRIBUTES_FAILURE, 
	payload: { 
		error: error 
	} 
});

export const fetchAttributes = () => {
	return dispatch => {
		dispatch(fetchAttributesBegin());

		return APIService.get('/attributes/')
			.then(response => { 
				dispatch(fetchAttributesSuccess(response.attributes));
				return response;
			})
			.catch(error => {
				return dispatch(fetchAttributesFailure(error));
			});
	};
};

const fetchAttributeTypesBegin = () => ({
	type: FETCH_ATTRIBUTE_TYPES_BEGIN
});

const fetchAttributeTypesSuccess = attributeTypes => ({
	type: FETCH_ATTRIBUTE_TYPES_SUCCESS,
	payload: {
		attributeTypes: attributeTypes
	}
});

const fetchAttributeTypeFailure = error => ({
	type: FETCH_ATTRIBUTE_TYPES_FAILURE,
	payload: {
		error: error
	}
});

export const fetchAttributeTypes = () => {
	return dispatch => {
		dispatch(fetchAttributeTypesBegin());

		return APIService.get('/config/attribute-types/')
			.then(response => {
				dispatch(fetchAttributeTypesSuccess(response.types));
				return response;
			})
			.catch(error => {
				return dispatch(fetchAttributeTypeFailure(error));
			});
	};
};

export const addAttribute = attribute => ({
    type: ADD_ATTRIBUTE,
    payload: {
        attribute: attribute
    }
});

export const removeAttribute = id => ({
    type: REMOVE_ATTRIBUTE,
    payload: {
        id: id
    }
});

export const updateAttribute = (id, attribute) => ({
    type: UPDATE_ATTRIBUTE,
    payload: {
        id: id,
        attribute: attribute
    }
});