import React from 'react';

import Tab from './Tab';

const TabGroup = props => {

    const { tabs } = props;

    const className = [ 'tab-group' ];

    if( props.className )
    {
        className.push(props.className);
    }

    return (
        <nav className={ className.join(' ') }>
            {tabs.map((tab, i) => 
                <Tab 
                    active={ tab.active }
                    disabled={ tab.disabled }
                    key={ i }
                    text={ tab.text }
                    icon={ tab.icon ? tab.icon : null }
                    onClick={ tab.onClick } />
            )}
        </nav>
    );
};

export default TabGroup;