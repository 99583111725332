import React from 'react';
import { Button } from 'Components';

const CloseButton = (props) => {

	const className = (props.className !== undefined ? props.className : '');
    const title = (props.title === undefined) ? 'Close' : props.title;

    const handleClick = (e) => {
        e.preventDefault();

        props.onClick(e);
    };

	return (
        <Button 
            className={ `btn-icon-plain ${className} ` } 
            icon="times" 
            title={ title } 
            onClick={ (e) => handleClick(e) } />
	);
}

export default CloseButton;