import { 
    FETCH_ATTRIBUTES_BEGIN,
    FETCH_ATTRIBUTES_SUCCESS,
    FETCH_ATTRIBUTES_FAILURE,

    FETCH_ATTRIBUTE_TYPES_BEGIN,
    FETCH_ATTRIBUTE_TYPES_SUCCESS,
    FETCH_ATTRIBUTE_TYPES_FAILURE,

    ADD_ATTRIBUTE,
    REMOVE_ATTRIBUTE,
    UPDATE_ATTRIBUTE
} from '../actions/attributeActions';

import produce from 'immer';
  
const initialState = {
    loading: false,
    error: null,

    attributeTypes: { },
    attributes: { }
};
  
const attributeReducer = (state = initialState, action) => {
    switch(action.type)
    {
        case FETCH_ATTRIBUTES_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                attributes: {}
            };

        case FETCH_ATTRIBUTES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                attributes: action.payload.attributes
            };

        case FETCH_ATTRIBUTES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                attributes: {}
            };

        case FETCH_ATTRIBUTE_TYPES_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                attributeTypes: { }
            };
        
        case FETCH_ATTRIBUTE_TYPES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                attributeTypes: action.payload.attributeTypes
            };  

        case FETCH_ATTRIBUTE_TYPES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                attributeGroups: { }
            };

        case ADD_ATTRIBUTE:
            return produce(state, draftState => {
                draftState.attributes[action.payload.attribute.id] = action.payload.attribute
             });

        case UPDATE_ATTRIBUTE:
            return produce(state, draftState => {
                draftState.attributes[action.payload.id] = action.payload.attribute
            });

        case REMOVE_ATTRIBUTE:
            return produce(state, draftState => {
                delete draftState.attributes[action.payload.id];
            });

        default:
            return state;
    };
};

export default attributeReducer;