import React, { useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Utilities from 'Utilities';

import { SubnavMenu } from 'Components';
import { NotFoundPage } from 'Components/Pages'

import MarketplaceIndex from './MarketplaceIndex';
import Favourites from './Favourites';
import Offers from './Offers';
import Items from './Items';
import Messages from './Messages';

const MarketplacePage = props => {

    const user = useSelector(state => state.user);

    // Base URL:
    const basePath = '/marketplace';

    // Update the title:
    useEffect(() => {
        document.title = Utilities.getPageTitle('Marketplace');
    }, [ props.location ]);

	return (
        <div className="marketplace-parent">
            <SubnavMenu icon="store" items={[
                {
                    label: 'My items',
                    path: '/marketplace/items/',
                    icon: 'tag'
                },
                {
                    label: 'Favourite items',
                    path: '/marketplace/favourites/',
                    icon: 'heart'
                },
                {
                    label: 'Offers',
                    path: '/marketplace/offers/',
                    icon: 'gavel',
                    hasDot: user.notifications.offers
                },
                {
                    label: 'Messages',
                    path: '/marketplace/messages/',
                    icon: 'comments-alt',
                    hasDot: user.notifications.messages
                }
            ]} />

            <div className="marketplace_main">
                <Switch>
                    <Route path={ `${basePath}/` } exact={ true } component={ MarketplaceIndex } />
                    <Route path={ `${basePath}/favourites/` } component={ Favourites } />
                    <Route path={ `${basePath}/offers/` } component={ Offers } />
                    <Route path={ `${basePath}/items/` } exact={ true } component={ Items } />
                    <Route path={ `${basePath}/messages/` } component={ Messages } />
                    <Route component={ NotFoundPage } />
                </Switch>
            </div>
        </div>
	);
};

export default MarketplacePage;