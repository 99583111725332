import { React, useEffect, useState } from 'react';
import { useToasts } from 'react-toast-notifications'; 
import { APIService } from 'Services';

import { Button, EmptyState } from 'Components';
import { SubscriptionView } from 'Components/SubscriptionView';
import AddSubscription from 'Components/Modals/AddSubscription';

const Subscriptions = props => {

    const { active } = props;
    const { addToast } = useToasts();

    const [ loading, setLoading ] = useState(false);
    const [ subscription, setSubscription ] = useState(null);
    const [ content, setContent ] = useState(null);

    // Create modal state:
    const [ createModalVisible, setCreateModalVisible ] = useState(false);

    // Fetch the subscription:
    useEffect(() => { 
        if( active )
        {
            setLoading(true);

            // Get subscription and plan:
            APIService.get('/subscriptions/')
                .then(resp => { 
                    setSubscription(resp.subscription);
                })
                .catch(error => { 
                    addToast(error.response.data.message, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                })
                .finally(() => { setLoading(false); });
        }
    }, [ active ]);

    // Handle when they cancel a subscription:
    const onSubscriptionCancel = () => {
        setSubscription(null);

        addToast('Your subscription has been successfully cancelled!', {
            appearance: 'success',
            autoDismiss: true
        });
    };

    // Set the content:
    useEffect(() => { 
        if( subscription === null )
        {
            setContent(
                <EmptyState
                    title="No Subscription"
                    description="You don't have any subscriptions for Kolekto. To get the best out of the platform, create a new subscription now."
                    image="NoItemsCart"
                    button={
                        <Button
                            onClick={ () => setCreateModalVisible(true) }
                            className="btn btn-primary btn-upper btn-rounded"
                            text="Create Subscription" />
                    } />
            );
        }
        else
        {
            setContent(<SubscriptionView onCancel={ onSubscriptionCancel } subscription={ subscription } />)
        }
    }, [ subscription ]);

    const handleSubscriptionAdd = subscription => {
        setSubscription(subscription);
        setCreateModalVisible(false);

        addToast('Your subscription was successfully created!', {
            appearance: 'success',
            autoDismiss: true
        });
    };

    return (
        <div className={ loading ? 'loading' : '' } style={{ minHeight: '100%' }}>
            { content }

            <AddSubscription 
                onClose={ () => setCreateModalVisible(false) }
                onCreate={ handleSubscriptionAdd }
                visible={ createModalVisible } />
        </div>
    );
};

export default Subscriptions;