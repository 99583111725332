import React from 'react';
import { lightboxOptions } from 'config';

import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import GalleryItem from './GalleryItem';

const GalleryView = props => {

    const { images } = props;

    const className = [ 'gallery-view' ];

    if( props.className )
    {
        className.push(props.className);
    };
    
    return (
        <SimpleReactLightbox>
            <SRLWrapper options={ lightboxOptions }>
                <div className={ className.join(' ') }>
                    {images.map((img, i) => (
                        <GalleryItem 
                            index={ i }
                            width={ props.columnWidth || 25 }
                            image={ img } 
                            key={ i } />
                    ))}
                </div>
            </SRLWrapper>
        </SimpleReactLightbox>
    );
};

export default GalleryView;