import React from 'react';

import { Column, IconCard } from 'Components';

const SettingLink = (props) => {

    const { link, text, icon, onClick } = props;

    return (
        <Column width={20}>
            <IconCard link={link} text={text} icon={icon} onClick={onClick} />
        </Column>
    );
};

export default SettingLink;