import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { objectSelector } from 'store/selectors';

import { AuthService } from 'Services';

import { openEditCategory, openEntityInfo } from 'store/actions/modalActions';
import { DateFormat, EmptyStateText, Badge, Button, Dropdown, Icon, NumberFormat, MutedText } from 'Components';

const ItemActions = props => {

    const {
        category,
        collection,
        numFilters,
        sortField,
        view,
        numItems
    } = props;

    const dispatch = useDispatch();

    const [ viewType, setViewType ] = useState(view);
    const [ filterBadge, setFilterBadge ] = useState(null);
    const [ currentSort, setCurrentSort ] = useState(sortField.name);
    const [ currentSortField, setCurrentSortField ] = useState(sortField.field);

    const handleAddItem = () => {
        if (typeof props.onAddItemOpen === 'function') {
            props.onAddItemOpen();
        }
    };

    const handleViewChange = view => {
        setViewType(view);

        if (typeof props.onViewChange === 'function') {
            props.onViewChange(view);
        }
    };

    const handleShowFilters = e => {
        if (typeof props.onShowFilterModal === 'function') {
            props.onShowFilterModal(e);
        }
    };

    const handleDownloadAssetTags = e => {
        if (typeof props.onDownloadAssetTags === 'function') {
            props.onDownloadAssetTags(e);
        }
    }

    const handleSortChange = (name, field) => {
        if (field !== currentSortField) {
            setCurrentSort(name);
            setCurrentSortField(field);

            if (typeof props.onSort === 'function') {
                props.onSort({
                    name: name,
                    field: field
                });
            }
        }
    };

    const handleShowColumnModal = () => {
        if (typeof props.onShowColumnModal === 'function') {
            props.onShowColumnModal();
        }
    }

    const actionDropdownItems = [
        { text: 'Edit category', icon: 'pencil', onClick: (() => {dispatch(openEditCategory(category)) }) },
        {
            icon: 'info-circle',
            text: 'Category information',
            onClick: (() => {
                dispatch(
                    openEntityInfo('Category info', [
                        { label: 'Name', value: category.name },
                        { label: 'Added by', value: category.addedBy.fullName },
                        { label: 'Subcategory count', value: <NumberFormat value={category.numCategories} /> },
                        { label: 'Item count', value: <NumberFormat value={category.numItems} /> },
                        { label: 'Created', value: <DateFormat date={category.created} format='user.datetime' /> },
                        { label: 'Last modified',
                            value: (category.modified === null) ? <EmptyStateText text='Never' />
                                                                : <DateFormat date={category.modified} format='user.datetime' />
                        }
                    ])
                );
            })
        },
        {
            icon: 'file-download',
            text: 'Download CSV',
            onClick: () => {
                window.location.href = `https://api.kolekto.local/category/${category.id}/download/?token=${AuthService.getToken()}`;
            }
        }
    ];

    const sortDropdownItems = [
        { text: 'Date added', checked: (currentSortField === 'created'), onClick: (() => { handleSortChange('Date added', 'created') }) },
        { text: 'Name', checked: (currentSortField === 'name'), onClick: (() => { handleSortChange('Name', 'name') }) },
        { text: 'Quantity', checked: (currentSortField === 'quantity'), onClick: (() => { handleSortChange('Quantity', 'quantity') }) }
    ];

    // Loop over the attributes and add them:
    objectSelector(collection.attributes).map((attr, i) => {
        const sortKey = `attr${attr.id}`;

        return sortDropdownItems.push({
            text: attr.name,
            checked: (currentSortField === sortKey),
            onClick: (() => { handleSortChange(attr.name, sortKey) })
        })
    });

    useEffect(() => {
        if (numFilters > 0) {
            setFilterBadge(
                <Badge className="ml10" count={numFilters} />
            );
        }
        else {
            setFilterBadge(null);
        }
    }, [ numFilters ]);

    useEffect(() => {
        setCurrentSort(sortField.name);
        setCurrentSortField(sortField.field);
    }, [ sortField ]);

    useEffect(() => {
        setViewType(view)
    }, [ view ]);

    if (view === 'list') {
        actionDropdownItems.push({
            text: 'Manage columns',
            icon: 'columns',
            onClick: (() => { handleShowColumnModal() })
        });
    }

    // Now add the new item:
    actionDropdownItems.push({
        text: 'Download asset tags',
        icon: 'qrcode',
        onClick: handleDownloadAssetTags
    });

    console.log(viewType);

    return (
        <div className="collection-actions flex space-between">
            <div className="flex-left flex">
                <MutedText className="mr20">
                    <NumberFormat value={ numItems } /> {(numItems === 1) ? 'item' : 'items'}
                </MutedText>

                <Dropdown
                    arrow={ true }
                    toggleClassName="btn-text dropdown-trigger-caret"
                    toggleContent={ <><MutedText text="Sort by:" /> <span>{ currentSort }</span></> }
                    position="bottom-start"
                    items={ sortDropdownItems }
                    />
            </div>

            <div className="flex-right">
                <div className="btn-group btn-group--white">
                    <Button className={ `${viewType === 'grid' ? 'btn-selected' : 'btn-white'}` }
                        onClick={() => handleViewChange('grid')}
                    >
                        <Icon name="th" />
                    </Button>
                    <Button className={ `${viewType === 'list' ? 'btn-selected' : 'btn-white'}` }
                        onClick={() => handleViewChange('list')}
                    >
                        <Icon name="list" />
                    </Button>
                </div>

                <Button className="mx5 btn-white" onClick={e => { handleShowFilters(e) }}>
                    <Icon name="sliders-h" className="mr10" />
                    Filters
                    { filterBadge }
                </Button>

                <Button className="mx5 btn-primary" onClick={() => { handleAddItem() }}>
                    <Icon name="plus" /> Add item
                </Button>

                <Dropdown
                    arrow={ true }
                    toggleClassName="btn btn-light btn-square"
                    toggleContent={ <Icon name="ellipsis-h" /> }
                    position="bottom-end"
                    items={actionDropdownItems} />
            </div>
        </div>
    );
};

export default ItemActions;
