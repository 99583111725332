import React from 'react';

const SectionTitle = props => {
    const { title } = props;

    const className = [ 'section-title' ];
    
    if( props.className )
    {
        className.push(props.className);
    }

    return (
        <h3 className={className.join(' ')}>
            { title }
        </h3>
    )
};

export default SectionTitle;