import React, { useState, useRef, useCallback } from 'react';
import Webcam from 'react-webcam';

import { Button, Icon, Modal } from 'Components';
import BlankImage from 'Components/Elements/BlankImage';

const WebcamButton = props => {
    const title = (props.title === undefined) ? 'Take pictures using my webcam' : props.title;
    const icon = (props.icon === undefined) ? 'webcam' : props.icon;
    const webcamRef = useRef(null);

    const { onCapture } = props;

    const [ modalVisible, setModalVisible ] = useState(false);
    const [ webcam, setWebcam ] = useState(null);
    const [ captureButtonDisabled, setCaptureButtonDisabled ] = useState(true);

    const webcamCapture = useCallback(() => {
        const capture = webcamRef.current.getScreenshot();

        // Close the webcam:
        handleModalClose();

        if( typeof onCapture === 'function' )
        {
            let arr = capture.split(','), 
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), 
                n = bstr.length, 
                u8arr = new Uint8Array(n);
            
            while(n--) { u8arr[n] = bstr.charCodeAt(n); }
            
            const captureFile = new File([u8arr], 'capture.jpg', { type: mime });

            onCapture(captureFile);
        }

      }, [ webcamRef, onCapture ]);

    const className = [ 'image-uploader_btn' ];

    if( props.className !== undefined )
    {
        className.push(props.className);
    };

    const handleModalOpen = e => {
        setModalVisible(true);
        setWebcam(
            <Webcam 
                ref={ webcamRef }
                audio={ false }
                screenshotQuality={ 1 }
                screenshotFormat='image/jpeg'
                mirrored={ true }
                onUserMedia={() => setCaptureButtonDisabled(false)}
                onUserMediaError={ () => setModalVisible(false) }
                width={ 590 } />
        );
    }

    const handleModalClose = e => {
        setModalVisible(false);
        setWebcam(null);
        setCaptureButtonDisabled(true);
    };

    const handleCapture = e => {
        e.preventDefault();
        webcamCapture();
    };

    return (
        <>
            <div className={ className.join(' ') } onClick={ handleModalOpen }>
                <span className="image-uploader_btn-content">
                    <Icon name={ icon } type="light" className="image-uploader_btn-icon" />
                    <span className="image-uploader_btn-label">{ title }</span>
                </span>
                <BlankImage />
            </div>

            <Modal title="Webcam stream" visible={ modalVisible } onClose={ handleModalClose }>
                
                { webcam }

                <Button 
                    disabled={ captureButtonDisabled }
                    onClick={ handleCapture }
                    className="mt20 btn-primary btn-upper btn-rounded" 
                    icon="camera" 
                    text="Take picture" />
            </Modal>
        </>
    );
};

export default WebcamButton;