import React, { useEffect, useState } from 'react';
import { objectSelector } from 'store/selectors';

import { APIService } from 'Services';
import { useToasts } from 'react-toast-notifications'
import { PageHeader, ItemForm } from 'Components';

const EditItem = props => {

    const { item, category } = props;
    const { addToast } = useToasts();

    const className = [ 'edit-item', 'item-form' ];

    const [ alertVisible, setAlertVisible ] = useState(false);
    const [ alertText, setAlertText ] = useState('');
    const [ buttonLoading, setButtonLoading ] = useState(false);
    const [ itemToEdit, setItemToEdit ] = useState(item);

    useEffect(() => { 
        setItemToEdit(item);
    }, [ item ]);

    if( props.visible === true )
    {
        className.push('item-form-in');
    }

    const handleClose = e => {
        if( typeof props.onClose === 'function' )
        {
            props.onClose(e);
        }

        setItemToEdit(null);
        setAlertVisible(false);
    };

    const handleSubmit = (e, data) => {
        e.preventDefault();

        const endpoint = `/item/${item.id}/`;

        setButtonLoading(true);

        data.category_id = category.id;
        
        APIService.put(endpoint, data)
            .then(response => { 
                handleClose();

                addToast('Item was successfully updated.', {
                    appearance: 'success',
                    autoDismiss: true
                });
                
                if( typeof props.onSuccess === 'function' )
                {
                    props.onSuccess(response.item);
                }
            })
            .catch(error => { 
                setAlertVisible(true);
                setAlertText(error.response.data.message);

                if( typeof props.onFailure === 'function' )
                {
                    props.onFailure(error);
                }
            })
            .finally(() => setButtonLoading(false));
    }

    const attributes = [ ];

    if( props.collection )
    {
        objectSelector(props.collection.attributes).map(attr => ( attributes.push({ id: attr.id, value: '' }) ));
    }

    return (
        <div className={ className.join(' ') }>
            <PageHeader 
                title={ item?.name }
                sticky={true} />

            <ItemForm
                attributes={ attributes }
                alertVisible={ alertVisible }
                alertText={ alertText }
                onCancel={(e) => { handleClose(e) } }
                collection={ props.collection }
                category={ props.category }
                onSubmit={ (e, data) => handleSubmit(e, data) }
                buttonText="Save changes"
                item={ itemToEdit }
                buttonLoading={ buttonLoading } />
        </div>
    );
};

export default EditItem;