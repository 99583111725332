import React, { useState } from 'react';
import { APIService } from 'Services';
import TextareaAutosize from 'react-textarea-autosize';
import { parseEmoji } from 'helpers';
import produce from 'immer';

import { Icon, Button } from 'Components';
import UploadQueue from 'Components/ImageUploader/Queue';

const ComposeForm = props => {

    const { conversation, onSend } = props; 

    const [ value, setValue ] = useState('');
    const [ shiftPressed, setShiftPressed ] = useState(false);
    const [ buttonDisabled, setButtonDisabled ] = useState(true);
    const [ inputDisabled, setInputDisabled ] = useState(false);

    // Upload states:
    const [ imageQueue, setImageQueue ] = useState([ ]);
    const [ loadingCount, setLoadingCount ] = useState(0);

    const handleKeyDown = e => {

        // Shift key pressed:
        if( e.keyCode == 16 ) 
        { 
            setShiftPressed(true); 
        }

        // Enter key has been pressed, so the form has to be submitted.
        if( e.keyCode == 13 && !shiftPressed )
        {
            e.preventDefault();
            handleSubmit(e.target.value);
        }
    };

    const handleKeyUp = e => {
        if( e.keyCode == 16 )
        {
            setShiftPressed(false);
        }
    };

    const handleChange = e => {
        setValue(parseEmoji(e.target.value));
        setButtonDisabled(e.target.value.trim() == '');
    };

    const handleSubmit = value => {
        setInputDisabled(true);

        const endpoint = `/conversation/${conversation.id}/messages/`;

        const data = {
            message: value,
            attachments: [ ]
        };

        imageQueue.map(image => { 
            data.attachments.push(image.id) 
        });

        // Send off the AJAX request
        APIService.post(endpoint, data)
            .then(response => {
                setValue('');
                setLoadingCount(0);
                setImageQueue([]);

                if( typeof onSend === 'function' )
                {
                    props.onSend(response.message);
                }
            })
            .catch(response => {

            })
            .finally(() => { setInputDisabled(false) });
    };

    let numLoading = 0;

    const handleImageSelect = (e, files) => {

        const images = [ ];

        for( let i = 0; i < files.length; i++ )
        {
            const file = files[i];
            
            if( file.type.indexOf('image') === 0 )
            {
                images.push(file);
            }
        };

        setLoadingCount(images.length);
        numLoading = images.length;

        images.map(file => {
            const data = new FormData();
            data.set('image', file);

            // Now handle the upload:
            APIService.post('/images/', data, { 'content-type': 'multipart/form-data' })
                .then(response => {
                    const preload = new Image();
                    preload.src = response.image.thumbnail;
                    preload.onload = () => {
                        setImageQueue(imageQueue => (imageQueue.concat({
                                id: response.image.id,
                                loading: false,
                                thumbnail: response.image.thumbnail
                            }))
                        );

                        // Reduce the loading count:
                        numLoading-= 1;
                        setLoadingCount(numLoading);
                        setButtonDisabled(false);

                        if( typeof props.onImageQueueChange === 'function' )
                        {
                            props.onImageQueueChange(imageQueue);
                        }

                        if( typeof props.onUpload === 'function' )
                        {
                            props.onUpload(response.image);
                        }
                    };

                    preload.onerror = error => {
                        if( typeof props.onUploadError === 'function' )
                        {
                            props.onUploadError(error);
                        }
                    };
                })
                .catch(error => {
                    if( typeof props.onUploadError === 'function' )
                    {
                        props.onUploadError(error);
                    }
                });

            return imageQueue;
        });
    };

    const handleImageDelete = index => {
        const id = imageQueue[index].id;

        setImageQueue(
            produce(imageQueue, draft => { delete draft[index] })
        );

        if( typeof props.onImageQueueChange === 'function' )
        {
            props.onImageQueueChange(imageQueue);
        }

        if( typeof props.onImageRemove === 'function' )
        {
            props.onImageRemove(id);
        }
    };

    // User cannot reply, as other user has deleted:
    if( conversation.userCanReply === false )
    {
        return (
            <div className="messenger_conversation-compose messenger_conversation-compose_cannot-reply">
                <p>
                    You cannot reply to this conversation because it has been deleted by the other user.
                </p>
            </div>
        );
    }

    return (
        <form className="messenger_conversation-compose">
            <label className="messenger_btn_attach_images">
                <input 
                    type="file" 
                    accept="image/*" 
                    multiple
                    onChange={ (e) => { handleImageSelect(e, e.target.files) } } />
                <Icon name="image" />
            </label>

            <div className="messenger_conversation-compose-form">
                <UploadQueue
                    loadingCount={ loadingCount }
                    images={ imageQueue }
                    columnWidth={ 10 }
                    onDelete={(i) => handleImageDelete(i)} />

                <TextareaAutosize 
                    value={ value } 
                    onKeyUp={ handleKeyUp } 
                    onKeyDown={ handleKeyDown } 
                    onChange={ handleChange } 
                    placeholder="Type your message"
                    disabled={ inputDisabled } />
            </div>

            <Button
                disabled={ buttonDisabled }
                className="ml10 btn_message_conversation-send"
                icon="paper-plane"
                onClick={ (e) => { e.preventDefault(); handleSubmit(value); } } />
        </form>
    );
};

export default ComposeForm;