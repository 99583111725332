import { React, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showSettingsModal } from 'store/actions/settingsActions';

// Utils:
import Utilities from 'Utilities';

import { PageHeader } from 'Components';

// Setting imports:
import Links from 'Components/Elements/Settings/Settings';
import Modals from 'Components/Elements/Settings/Modals';

const SettingsPage = (props) => {

    const dispatch = useDispatch();
    const { darkMode } = useSelector(state => state.user.preferences);

    // Update the title:
    useEffect(() => {
        document.title = Utilities.getPageTitle('Settings');
    }, [ props.location ]);

    // Set the active modal:
    useEffect(() => {
        const segments = props.location.pathname.split('/');
        const last = segments.pop() || segments.pop();

        let view = (segments.length == 1 || last === '') ? null : last;

        dispatch(showSettingsModal(view));

        if( view === null )
        {
            document.body.classList.remove('settings-view-in');
            document.body.classList.remove('settings-invoices');
        }
        else
        {
            document.body.classList.add('settings-view-in');
            document.body.classList.add('settings-' + view);
        }

        return () => {
            document.body.classList = (darkMode === true)
                ? 'dark-mode'
                : '';
        }
    }, [ props.location ]);

	return (
        <div className="content" id="settings-index">
            <PageHeader sticky={true} title="Settings" />

            <Links />
            <Modals />
        </div>
	);
};

export default SettingsPage;
