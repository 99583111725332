import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { openAddCollection } from 'store/actions/modalActions';
import { objectSelector } from 'store/selectors';

import { CollectionNavItem } from 'Components/Collections';
import { Button } from 'Components';

const Navigation = (props) => {

    const dispatch = useDispatch();
    const { collections } = useSelector(state => state.collections);

    return (
        <div className="collection-nav">
            <div className="collection-nav_header">
                <h3 className="collection-nav_title">Collections</h3>
                <Button
                    title="Create new collection"
                    className="btn-sm btn-icon-brand"
                    onClick={() => { dispatch(openAddCollection()) }}
                    icon="plus" />
                
            </div>
            <div className="collection-nav_list">
                {objectSelector(collections).map((item, i) => (
                    <CollectionNavItem item={ item } key={ i } />
                ))}
            </div>
        </div>
    )
};

export default Navigation;