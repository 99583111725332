class Storage
{
    get(key, def = null)
    {
        const value = window.localStorage.getItem(key);

        if( value === null && def !== undefined )
        {
            return def;
        }

        // We should return an object:
        try 
        {
            var json = JSON.parse(value);
            return (typeof json === 'object') ? json : value;
        } 
        catch (e) 
        {
            return value;
        }

        return value;
    };

    set(key, value)
    {
        // null in JS === object!
        if( typeof value === 'object' && value !== null )
        {
            value = JSON.stringify(value);
        }

        window.localStorage.setItem(key, value);

        // Return the value back (the actual value that was stored in localstorage):
        return value;
    };

    delete(key)
    {
        return window.localStorage.removeItem(key);
    };
};

export default new Storage();