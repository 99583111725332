import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications'
import { objectSelector } from 'store/selectors';
import { APIService } from 'Services';
import { closeShowItem } from 'store/actions/modalActions';
import produce from 'immer';

import { Alert, NoteTimeline, AttributeValue, SectionTitle, Rule, Row, Column, PageHeader, CloseButton, Button, GalleryView } from 'Components';

// Again, we won't be reusing this component:
const ItemProperty = props => {

    const { label, value } = props;

    const className = [ 'item-property' ];

    if( props.className )
    {
        className.push(props.className);
    }

    return (
        <div className={ className.join(' ') }>
            <span className="item-property_label">{ label }:</span>
            <div className="item-property_value">
                { value }
            </div>
        </div>
    );
};

// Main item view (we won't reuse this component, but it's easier to set up a
// component than continually using the null-safe operator when outputting data!)
// I have no idea if this is good practice or not to be honest.
const ItemInfo = props => {

    const { item } = props;

    const { addToast } = useToasts();

    const [ noteValue, setNoteValue ] = useState(null);
    const [ addNoteButtonLoading, setAddNoteButtonLoading ] = useState(false);
    const [ notes, setNotes ] = useState(item.notes);
    const [ noteAlertText, setNoteAlertText ] = useState(null);
    const [ noteAlertVisible, setNoteAlertVisible ] = useState(false);

    const handleNoteSubmit = e => {
        e.preventDefault();

        setAddNoteButtonLoading(true);

        const url = `/item/${item.id}/notes/`;

        APIService.post(url, { note: noteValue })
            .then(response => {
                setNotes(response.notes);
                setNoteValue('');
                item.notes = response.notes;
            })
            .catch(error => {
                setNoteAlertVisible(true);
                setNoteAlertText(error.response.data.message);
            })
            .finally(() => { setAddNoteButtonLoading(false) })
    };

    // Handle the deletion of a note:
    const handleNoteDelete = noteId => {
        const url = `/item/${item.id}/note/${noteId}/`;

        APIService.delete(url)
            .then(() => {
                setNotes(produce(notes, draft => {
                    draft.map((note, i) => {
                        if( note.id === noteId )
                        {
                            delete draft[i];
                        };

                        return;
                    });
                }));

                if( typeof props.onNoteDelete === 'function' )
                {
                    props.onNoteDelete(item.id, noteId);
                };

                addToast('Your note was successfully removed.', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                addToast(error.response.data.message, {
                    appearance: 'error',
                    autoDismiss: true
                });
            })
    };

    return (
        <div className="item-view">
            <Row>
                <Column>
                    <SectionTitle title="Item information:" />

                    <Row className="row-narrow">
                        <Column width={75}>
                            <ItemProperty label="Name" value={ item.name } />
                        </Column>
                        <Column>
                            <ItemProperty label="Quantity" value={ item.quantity } />
                        </Column>
                    </Row>

                    {objectSelector(item.attributes).map((attribute, i) => (
                        <ItemProperty
                            label={ attribute.name }
                            value={ <AttributeValue attribute={ attribute } value={ attribute.value } /> } />
                    ))}

                    <Rule />

                    <SectionTitle title="Item notes:" />

                    <Alert
                        className="mb25"
                        type="danger"
                        text={ noteAlertText }
                        visible={ noteAlertVisible } />

                    <NoteTimeline onDelete={ handleNoteDelete }
                        notes={ notes } />

                    <form className="item-notes_form mt20" onSubmit={ handleNoteSubmit }>
                        <textarea
                            required={ true }
                            placeholder="Add a new note"
                            value={ noteValue }
                            onChange={(e) => { setNoteValue(e.target.value) }} />

                        <Button
                            loading={ addNoteButtonLoading }
                            className="btn-primary btn-round"
                            icon="plus" />
                    </form>

                </Column>
                <Column>
                    <SectionTitle title="Images:" />

                    <GalleryView
                        images={ item.images } />
                </Column>
            </Row>
        </div>
    )
};

const ItemViewer = props => {

    const dispatch = useDispatch();

    const { item, visible } = props;

    const className = [ 'add-item', 'item-form' ];

    if( visible === true )
    {
        className.push('item-form-in');
    };

    const view = (item !== null && item !== undefined) ? <ItemInfo item={ item } /> : null;

    return (
        <div className={ className.join(' ') }>
            <PageHeader
                sticky={ true }
                title={ item?.name }
                subtitle={ item?.categoryHierarchy }
                sticky={true}
                buttons={<CloseButton onClick={(e) => { dispatch(closeShowItem()) }} />} />

            { view }
        </div>
    );
};

export default ItemViewer;
