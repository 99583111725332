import React from 'react';
import DefinitionListItem from './DefinitionListItem';

const DefinitionList = props => {

    const items = props.items;
    const className = [ 'definition-list' ];

    if( props.className !== undefined )
    {
        className.push(props.className);
    }

    return (
        <dl className={ className.join(' ') }>
            { items.map(item => (
                <DefinitionListItem
                    title={ item.title }
                    value={ item.value } />
            ))}
        </dl>
    );
};

export default DefinitionList;