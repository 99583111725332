import React, { useEffect, useState } from 'react';
import { objectSelector } from 'store/selectors';

import { MessageListItem, SearchForm } from './';

const MessageList = props => {

    const { conversations, loading, onMessageSelect, selected } = props;
    const [ activeMessage, setActiveMessage ] = useState(selected);

    const selectConversation = conversation => {
        setActiveMessage(conversation.id);

        if( typeof onMessageSelect === 'function' )
        {
            onMessageSelect(conversation);
        }
    };

    useEffect(() => { setActiveMessage(selected); }, [ selected ]);

    return (
        <div className="messenger_message-list">
            <header className="messenger_message-list-header">
                <SearchForm />
            </header>
            <div className={ `messenger_message-list-messages ${loading ? 'loading' : ''}` }>
                { objectSelector(conversations).map(chat => 
                    <MessageListItem 
                        active={ activeMessage === chat.id } 
                        conversation={ chat } 
                        onClick={ selectConversation } />
                ) }
            </div>
        </div>
    );
};

export default MessageList;