import React from 'react';

import { Icon } from 'Components';

const Tab = props => {

    const { active, text, icon, disabled, onClick } = props;

    const className = [ 'tab-group_tab' ];

    if( active === true )
    {
        className.push('active');
    }

    const handleClick = e => {
        e.preventDefault();

        if( onClick && !active )
        {
            onClick.call();
        }
    };

    return (
        <button onClick={ (e) => { handleClick(e); } } { ...(disabled === true) ? { disabled: true } : null } className={ className.join(' ') }>
            { (icon ? <Icon name={ icon } /> : null) }
            <span className="tab-text">{ text }</span>
        </button>
    );
};

export default Tab;