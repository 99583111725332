class ValidationService
{
    options = { };

    EMAIL_REGEXP = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    URL_REGEXP = /^[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/;

    constructor(params) 
    {
        this.options = params;
    };

    getFieldProps(field) 
    {
        let fieldData = this.options[field],
            params = { };

        if( fieldData === undefined )
        {
            return params;
        }

        if(fieldData.type) { params.type = fieldData.type.data };
        if(fieldData.minLength) { params.minLength = fieldData.minLength.data };
        if(fieldData.maxLength) { params.maxLength = fieldData.maxLength.data };
        if(fieldData.min) { params.min = fieldData.min.data };
        if(fieldData.max) { params.max = fieldData.max.data };
        if(fieldData.isRequired) { params.required = true };

        return params;
    };

    // Accepts a key-value pair of values and verifies:
    // Returns: true if all pass, otherwise, the error message thrown:
    validate(values)
    {
        for( let name in values )
        {
            let value = values[name];

            for( let rule in this.options[name] )
            {
                let data = this.options[name][rule];

                if( ! this.testCondition(rule, data, value) )
                {
                    return data.message;
                }
            }
        }

        return true;
    };

    // Test a condition:
    testCondition(rule, data, value)
    {
        if( rule === 'isRequired' && !this.isEmpty(value) )
        {
            return false;
        }

        if( rule === 'type' && !this.isEmpty(value) )
        {
            switch(data.data)
            {
                case 'int':
                    return value == parseInt(value);

                case 'number':
                case 'float':
                    return value == parseFloat(value);

                case 'email':
                    return this.EMAIL_REGEXP.test(value);

                case 'url':
                    return this.URL_REGEXP.test(value);
            }
        }

        return true;
    };

    // Test if the value is empty:
    isEmpty(value)
    {
        return (value !== undefined && value !== '' && value !== null);
    };
}

export default ValidationService;