import { React, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { closeShowItem } from 'store/actions/modalActions';

import { Modals } from 'Components/Modals/Modals';
import { Navbar } from 'Components';

import { Dashboard, Collections, Import, NotFoundPage, SettingsPage, SearchPage } from 'Components/Pages'
import Marketplace from 'Components/Pages/Marketplace';

const App = (props) => {

	const dispatch = useDispatch();

	const routes = [
		{
			path: '/',
			component: Dashboard
		},
		{
			path: '/collections/',
			component: Collections
		},
		{
			path: '/import/',
			component: Import
		},
		{
			path: '/marketplace/',
			component: Marketplace
		},
		{
			path: '/search/',
			component: SearchPage
		},
		{
			path: '/settings/',
			component: SettingsPage
		}
	];

	useEffect(() => {
		const parts = props.location.pathname.split('/').filter(i => (i !== null && i !== '' && i !== undefined));

		if( (parts[0] === 'settings' && parts.length > 1) )
		{

		}
		else
		{
			document.body.classList.remove('settings-export');
			document.body.classList.remove('settings-view-in');

			// Hide the item:
			dispatch(closeShowItem());
		}

	}, [ props.location ]);

	return (
		<div id="wrapper">
			<Navbar />

			<main id="main">
				<Switch>
					{routes.map((route, key) => (
						<Route key={key} path={ route.path } exact={route.path === '/'} component={ route.component } />
					))}

					<Route component={ NotFoundPage } />
				</Switch>

				<Modals />
			</main>
		</div>
	);
}

export default App;
