import React from 'react';

const Row = (props) => {

	//const { className } = props;

	return (
		<div className={ `row ${props.className ?? ''}` }>
			{ props.children }
		</div>
	);
}

export default Row;
