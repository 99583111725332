import React from 'react';

const NavbarMenu = (props) => {

	const { id } = props;

	return (
		<div id={ id }>
			{ props.children }
		</div>
	);
}

export default NavbarMenu;