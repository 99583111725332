import React from 'react';
import { NavLink } from 'react-router-dom';

import { Link, useHistory } from 'react-router-dom';

const SubnavMenuItem = (props) => {

    const { icon, title, label, path, hasDot, onClick } = props;

    const notifDot = (hasDot) ? <span className="notification-dot"></span> : '';

    const handleClick = (e) => {
		if(typeof onClick == 'function')
		{
			onClick.call(null, e);
		}
	};

    const className = [ 'subnav-tab' ];

    if( props.className )
    {
        className.push(props.className);
    }

    // If it's got a path:
    if( path )
    {
        return (
            <NavLink title={ title || label } to={ path } activeClassName="subnav-tab_active" className={ className.join(' ') } onClick={(e) => { handleClick(e) }}>
	            <i className={ `subnav-tab_icon far fa-${icon}` }></i>
	            <span className="subnav-tab_label">{ label }</span>
	            { notifDot }
	        </NavLink>
        );
    }

    return (
        <button className={ className.join(' ' )} onClick={(e) => { handleClick(e) }} title={ title || label }>
            <i className={ `subnav-tab_icon far fa-${icon}` }></i>
            <span className="subnav-tab_label">{ label }</span>
            { notifDot }
        </button>
    );
}

export default SubnavMenuItem;