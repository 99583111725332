import React from 'react';

import { FormHelp } from 'Components';

const InputGroup = (props) => {
    const className = [ 'input-group' ];

    if( props.className !== undefined ) 
    {
        className.push(props.className);
    }

    if( props.size !== undefined )
    {
        switch( props.size )
        {
            case 'large':
            case 'lg':
                className.push('input-group-lg');
                break;

            case 'xl':
            case 'extra-large':
                className.push('input-group-xl');
                break;

            case 'small':
            case 'sm':
                className.push('input-group-sm');
                break;

            default:
                break;
        }
    }

    const helpElement = (props.help === undefined) 
		? '' 
		: <FormHelp text={ props.help } />;

    return (
        <>
            <div className={ className.join(' ') }>
                { props.children }
            </div>
            { helpElement }
        </>
    )
}

export default InputGroup;