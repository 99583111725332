import { React, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import produce from 'immer';

import { closeAddAttribute } from 'store/actions/modalActions';
import { objectSelector, currencySelector } from 'store/selectors';
import { addAttribute } from 'store/actions/attributeActions';

import { Row, Column, Alert, Input, Select, Button, Modal } from 'Components';
import { APIService } from 'Services';

const ModalAddAttribute = (props) => {

    const { addAttributeModal } = useSelector(state => state.modals);
    const { attributeTypes } = useSelector(state => state.attributes);
    const { currencies } = useSelector(state => state.config);

    const dispatch = useDispatch();

    // Alert state:
    const [ alertVisible, setAlertVisible ] = useState(false);
    const [ alertClass, setAlertClass ] = useState(null);
    const [ alertText, setAlertText ] = useState('');

    const [ buttonLoading, setButtonLoading ] = useState(false);

    const [ attributeName, setAttributeName ] = useState('');
    const [ attributeType, setAttributeType ] = useState('');
    const [ attributeCurrency, setAttributeCurrency ] = useState('');

    // Attriute options:
    const [ attributeOptions, setAttributeOptions ] = useState([
        { value: '', focus: false } 
    ]);

    const [ typePlaceholder, setTypePlaceholder ] = useState('Attribute type');
    const [ currencyPlaceholder, setCurrencyPlaceholder ] = useState('Currency');

    // Show the error alert:
    const showError = message => {
        setAlertVisible(true);
        setAlertText(message);
        setAlertClass('danger');
    };

    // Reset onclose:
    const onBeforeClose = e => {
        setAlertVisible(false);
        setAttributeName('');

        setAttributeType('');
        setTypePlaceholder('Attribute type');

        setAttributeCurrency('');
        setCurrencyPlaceholder('Currency');
        setAttributeOptions([ { value: '', focus: false }  ]);
    };

    const handleTypeChange = e => {
        setAttributeType(e.target.value);
        setTypePlaceholder(undefined);
    };

    const handleCurrencyChange = e => {
        setAttributeCurrency(e.target.value);
        setCurrencyPlaceholder(undefined);
    };

    const handleOptionChange = (e, index) => {
        setAttributeOptions(
            produce(attributeOptions, draft => {
                draft[index].value = e.target.value
            })
        );
    };

    const handleOptionKeydown = (e, index) => {
        if (e.keyCode === 13 && attributeOptions[index] !== '') {
            handleOptionAdd(e, index);
        }
    };

    const handleOptionAdd = (e, index) => {
        e.preventDefault();
        
        setAttributeOptions(
            produce(attributeOptions, draft => {
                draft[index].focus = false;
                draft.push({ value: '', focus: true });
            })
        );
    };

    const handleOptionRemove = (e, index) => {
        e.preventDefault();

        setAttributeOptions(
            produce(attributeOptions, draft => {
                delete draft[index];
            })
        );
    };

    const handleSubmit = e => {
        e.preventDefault();

        if (attributeName === '') {
            showError('Attribute name is required.');
            return;
        }

        if (attributeType === '') {
            showError('Attribute type is required.');
            return;
        }

        if (attributeType === 'price' && attributeCurrency === '') {
            showError('Price attributes require a currency.');
            return;
        }

        setButtonLoading(true);

        const data = {
            name: attributeName,
            type: attributeType,
            meta: { }
        };

        // Handle the meta:
        if (attributeType === 'price') {
            data.meta.currency = attributeCurrency;
        }

        if (attributeType === 'enum') {
            data.values = [];

            attributeOptions.map(option => { 
                if (option.value !== '') {
                    data.values.push(option.value.trim())
                }

                return attributeOptions;
            });
        };

        APIService.post('/attributes/', data)
            .then(response => { 
                dispatch(addAttribute(response.attribute));
                dispatch(closeAddAttribute());
            })
            .catch(error => {
                setAlertVisible(true);
                setAlertText(error.response.data.message);
                setAlertClass('danger');
            })
            .finally(() => { setButtonLoading(false); })
    };

    const typeOptions = [ ];

    objectSelector(attributeTypes).map(type => { 
        typeOptions.push({ 
            value: type.typeName, 
            text: type.name 
        }); 
        
        return typeOptions;
    });

    const currencyOptions = [ ];
    
    currencySelector(currencies).map(currency => { 
        currencyOptions.push({ 
            value: currency.code, 
            text: currency.name 
        }); 
        
        return currencyOptions;
    });

    return (
        <Modal 
            size="medium" 
            onClose={(e) => { onBeforeClose(); dispatch(closeAddAttribute()) }} 
            title="New custom attribute" 
            visible={ addAttributeModal }>

            <p>
                Custom attributes allow you to add additional data to your items in Kolekto. Attributes are available to all items in your
                Kolekto account.
            </p>

            <Alert className={ `alert-${alertClass} alert-icon mb25` } visible={alertVisible}>
                { alertText }
            </Alert>

            <Input
                className="w75"
                label="Attribute name (e.g. Condition)"
                onChange={(e) => { setAttributeName(e.target.value) }}
                value={ attributeName } />

            <Select
                className="w50 my25"
                onChange={(e) => { handleTypeChange(e); }}
                label="Attribute type"
                placeholder={ typePlaceholder }
                options={ typeOptions }
                value={ attributeType } />

            <Select
                className="w50"
                onChange={(e) => { handleCurrencyChange(e); }}
                label="Currency"
                placeholder={ currencyPlaceholder }
                options={ currencyOptions }
                value={ attributeCurrency }
                visible={ attributeType === 'price' } />

            <div style={{ display: (attributeType === 'enum') ? 'block' : 'none' }}>
                <h3>Options:</h3>
                { attributeOptions.map((option, key) => {
                        
                        const button = (key === attributeOptions.length - 1)
                            ? <Button onClick={(e) => { handleOptionAdd(e, key) }} disabled={ option.value === '' } icon="plus" className="btn-success btn-square" />
                            : <Button onClick={(e) => { handleOptionRemove(e, key) }} icon="minus" className="btn-danger btn-square" />

                        return (
                            <Row key={ key } className="row-narrow my20">
                                <Column width="90">
                                    <Input 
                                        focused={ option.focus }
                                        onKeyDown={(e) => { handleOptionKeydown(e, key) }} 
                                        onChange={(e) => { handleOptionChange(e, key); }} 
                                        value={ option.value } 
                                        label="Option value" />
                                </Column>
                                <Column>
                                    { button }
                                </Column>
                            </Row>
                        );
                }) }
            </div>

            <div className="mt25">
                <Button
                    onClick={(e) => { handleSubmit(e); }}
                    className="btn-rounded btn-upper btn-primary"
                    text="Create attribute"
                    loading={ buttonLoading } />
            </div>
        </Modal>
    );
};

export default ModalAddAttribute;