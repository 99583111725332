import { 
    FETCH_CURRENCIES_BEGIN,
    FETCH_CURRENCIES_SUCCESS,
    FETCH_CURRENCIES_FAILURE
} from '../actions/configActions';
  
const initialState = {
    loading: false,
    error: null,

    currencies: { },
};
  
const configReducer = (state = initialState, action) => {
    switch(action.type)
    {
        case FETCH_CURRENCIES_BEGIN:
            return {
                ...state,
                loading: true,
                error: null,
                currencies: {}
            };

        case FETCH_CURRENCIES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                currencies: action.payload.currencies
            };

        case FETCH_CURRENCIES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                currencies: {}
            };

        default:
            return state;
    };
};

export default configReducer;