import APIService from 'Services/APIService';

export const FETCH_ADDRESSES_BEGIN = 'FETCH_ADDRESSES_BEGIN';
export const FETCH_ADDRESSES_SUCCESS = 'FETCH_ADDRESSES_SUCCESS';
export const FETCH_ADDRESSES_FAILURE = 'FETCH_ADDRESSES_FAILURE';

export const ADD_ADDRESS = 'ADD_ADDRESS';
export const SET_DEFAULT_ADDRESS = 'SET_DEFAULT_ADDRESS';
export const SET_ADDRESSES = 'SET_ADDRESSES';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';

export const DELETE_ADDRESS = 'DELETE_ADDRESS';

const fetchAddressesBegin = setLoading => ({ 
    type: FETCH_ADDRESSES_BEGIN,
    payload: {
        loading: setLoading
    }
});

const fetchAddressesSuccess = addresses => ({ 
    type: FETCH_ADDRESSES_SUCCESS, 
    payload: { 
        addresses: addresses 
    } 
});

const fetchAddressesFailure = error => ({ 
    type: FETCH_ADDRESSES_FAILURE, 
    payload: { 
        error: error 
    } 
});

export const fetchAddresses = loading => {
	return dispatch => {
		dispatch(fetchAddressesBegin(loading));
		
		return APIService.get('/addresses/')
			.then((response) => { 
                dispatch(fetchAddressesSuccess(response.addresses));
				return response;
			})
			.catch(error => {
                dispatch(fetchAddressesFailure(error));
                throw error;
			});
	};
};

export const addAddressItem = address => ({
    type: ADD_ADDRESS,
    payload: {
        address: address
    }
});

export const setDefaultAddress = id => ({
    type: SET_DEFAULT_ADDRESS,
    payload: {
        id: id
    }
});

export const setAddresses = addresses => ({
    type: SET_ADDRESSES,
    payload: {
        addresses: addresses
    }
});

export const deleteAddress = id => ({
    type: DELETE_ADDRESS,
    payload: {
        id: id
    }
});

export const updateAddress = (id, address) => ({
    type: UPDATE_ADDRESS,
    payload: {
        id: id,
        address: address
    }
});