import React, {  useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Row, Column, Select, Input, CustomAttributeInput, ToggleSwitch } from 'Components';

const AttributeFilter = props => {

    const { id, operator, checked, value, minValue, maxValue, type, label, attribute, onChange } = props;

    const [ isChecked, setIsChecked ] = useState(checked);
    const [ operatorValue, setOperatorValue ] = useState((operator ? operator : 'equals'));
    const [ valueInput, setValueInput ] = useState('');

    const [ val, setVal ] = useState(value);
    const [ minVal, setMinVal ] = useState(minValue);
    const [ maxVal, setMaxVal ] = useState(maxValue);

    const { attributes } = useSelector(state => state.attributes);

    const handleToggleChange = (e, checked) => { 
        setIsChecked(checked);

        if( typeof props.onToggle === 'function' )
        {
            props.onToggle(checked);
        }
    };
    const handleOperatorChange = e => { setOperatorValue(e.target.value) };

    const handleValueChange = value => { setVal(value) };
    const handleMinValueChange = value => { setMinVal(value) };
    const handleMaxValueChange = value => { setMaxVal(value) };

    useEffect(() => {
        if( typeof onChange === 'function' )
        {
            onChange(
                id,
                isChecked,
                operatorValue,
                val,
                minVal,
                maxVal
            );
        };
    }, [ isChecked, operatorValue, val, minVal, maxVal, id ]);

    // Reset it:
    useEffect(() => { 
        setIsChecked(checked);

        if( ! checked )
        {
            setVal('');
            setMinVal('');
            setMaxVal('');
            setOperatorValue((id === 'name') ? 'contains' : 'equals');
        }
    }, [ checked ])

    useEffect(() => {
        if( operatorValue === 'between' )
        {
            setValueInput(
                <Row className="row-narrow row-stretch">
                    <Column width={ 45 }>
                        <CustomAttributeInput
                            style={{ width: '100%' }}
                            label={ label }
                            attribute={ attributes[id] }
                            value={ minVal }
                            onChange={(id, value) => { handleMinValueChange(value) }} />
                    </Column>
                    <Column>and</Column>
                    <Column width={ 45 }>
                        <CustomAttributeInput
                            style={{ width: '100%' }}
                            label={ label }
                            attribute={ attributes[id] }
                            value={ maxVal }
                            onChange={(id, value) => { handleMaxValueChange(value) }} />
                    </Column>
                </Row>
            )
        }
        else
        {
            if( attribute !== undefined )
            {
                setValueInput(
                    <CustomAttributeInput
                        label={ label }
                        attribute={ attributes[id] }
                        value={ val }
                        onChange={(id, value) => { handleValueChange(value) }} />
                );
            }
            else
            {
                setValueInput(
                    <Input
                        label={ label }
                        value={ val }
                        onChange={(e) => { handleValueChange(e.target.value) }} />
                );
            }
        }

    }, [ id, label, operatorValue, val, minVal, maxVal ]);

    const filterOperators = [ ];

    switch( type )
    {
        case 'short_text':
        case 'long_text':
        default:
            filterOperators.push(
                { value: 'equals', text: 'Is equal to' }, 
                { value: 'contains', text: 'Contains' }
            );
            break;
        
        case 'date':
        case 'datetime':
        case 'year':
            filterOperators.push(
                { value: 'equals', text: 'Is equal to' },
                { value: 'between', text: 'Between' },
                { value: 'lt', text: 'Before' },
                { value: 'lte', text: 'On or before' },
                { value: 'gt', text: 'After' },
                { value: 'gte', text: 'On or after' }
            );
            
            break;

        case 'number':
        case 'price':
            filterOperators.push(
                { value: 'equals', text: 'Is equal to' },
                { value: 'between', text: 'Between' },
                { value: 'lt', text: 'Less than' },
                { value: 'lte', text: 'Less than or equal to' },
                { value: 'gt', text: 'Greater than' },
                { value: 'gte', text: 'Greater than or equal to' }
            );

        case 'enum':
            filterOperators.push({ value: 'equals', text: 'Is equal to' });
            break;
    }

    return (
        <div className="attribute-filter">
            <ToggleSwitch
                checked={ isChecked }
                onChange={ handleToggleChange }
                label={ label } />

            <div className="attribute-filter_data mb30" style={{ display: (isChecked ? 'block' : 'none') }}>
                <Row className="row-narrow">
                    <Column width={35}>
                        <Select
                            onChange={ handleOperatorChange }
                            value={ operatorValue }
                            options={ filterOperators }
                            label="" />
                    </Column>
                    <Column>
                        { valueInput }
                    </Column>
                </Row>
            </div>
        </div>
    )
};

export default AttributeFilter;