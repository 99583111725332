import React, { useEffect, useState } from 'react';

import { FormProgressItem } from 'Components';

const FormProgress = props => {

    const { items, activeItem } = props;
    const numSlides = items.length;
    const slideWidth = 100 / numSlides;
    const [ indicatorWidth, setIndicatorWidth ] = useState(0);
    
    useEffect(() => {
        if( activeItem < numSlides )
        {
            setIndicatorWidth(`calc(${slideWidth * activeItem}% + ${(activeItem > 0) ? 40 : 0}px)`);
        }
    }, [ activeItem ]);

    const className = [ 'form-progress', `form-progress_${items.length}-items`  ];

    if( props.className !== undefined )
    {
        className.push(props.className);
    }

    return (
        <div className={ className.join(' ') }>
            <div className="form-progress_indicator" style={{ width: indicatorWidth }} />

            { items.map((title, i) => (
                <FormProgressItem
                    active={ activeItem === i }
                    done={ activeItem > i }
                    number={ i + 1 }
                    title={ title } />
            ))}
        </div>
    );
};

export default FormProgress;