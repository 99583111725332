import React, { useState, useEffect } from 'react';

import InfiniteScroll from 'react-infinite-scroll-component';
import { APIService } from 'Services';
import Utilities from 'Utilities';

import { EmptyState, ActivityItem } from 'Components';

const Activity = props => {

    const { active } = props;

    const [ loading, setLoading ] = useState(true);
    const [ items, setItems ] = useState([]);
    const [ hasMore, setHasMore ] = useState(false);
    const [ nextPage, setNextPage ] = useState(false);

    useEffect(() => {
        if( active === true )
        {
            APIService.get('/activity/', { perPage: 25, page: 1 })
                .then(resp => { 
                    setItems(resp.activity);
                    setHasMore(resp.nextPage);
                    setNextPage(resp.nextPage);
                })
                .catch(error => { })
                .finally(() => { setLoading(false); });
        }
    }, [ active ]);

    // Update the title:
    useEffect(() => { 
        document.title = Utilities.getPageTitle('My Activity - Settings'); 
    }, [ props.location ]);

    const getNextPage = () => {
        if( nextPage !== false )
        {
            APIService.get('/activity/', { perPage: 25, page: nextPage })
                .then(resp => { 
                    setItems(resp.activity);
                    setHasMore(resp.nextPage);
                    setNextPage(resp.nextPage);
                })
        }
    };

    let content = null;

    if( ! items.length )
    {
        content = <EmptyState image="NoTasks" title="No recent activity" description="There has been no activity on your Kolekto account yet." />;
    }
    else
    {   
        content = (
            <InfiniteScroll scrollableTarget=".modal-page_visible" dataLength={items.length} next={ getNextPage } hasMore={ hasMore } loader={<h4>Loading...</h4>}>
                {items.map((item, i) => ( <ActivityItem activity={ item } key={ i } /> ))}
            </InfiniteScroll>
        );
    }

    return (
        <div className={ `${(loading === true ? 'loading' : '')}`}>
            { content }
        </div>
    );
};

export default Activity;