import React, { useEffect, useState } from 'react';

const Img = (props) => {

    const { title, alt, loading, src, width, height } = props;
    const [ isLoading, setIsLoading ] = useState(loading);

    const className = [ 'image' ];
    const transparent = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
    
    const styles = {
        width: (width === undefined) ? 200 : width,
        height: (height === undefined) ? 200 : height
    };

    useEffect(() => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            setIsLoading(false);
        };
    }, [ src ]);
    
    // Append the classname:
    if( props.className !== undefined ) 
    { 
        className.push(props.className) 
    }

    // Is it loading?
    if( isLoading === true ) 
    { 
        className.push('image-loading');
    }

	return (
        <picture className={ className.join(' ') } style={ styles } onClick={ props.onClick }>
            <img
                src={ src || transparent }
                alt={ alt || '' }
                title={ title || '' }
                />
        </picture>
	);
}

export default Img;