import React from 'react';

import { Messenger } from 'Components/Messenger';

const Messages = props => {
    return (
        <Messenger />
    );
};

export default Messages;