import React, { useState } from 'react';
import nl2br from 'react-nl2br';
import moment from 'moment';
import { parseEmoji, isSingleEmoji } from 'helpers';

import MessageGallery from './MessageGallery';
import { Button } from 'Components';

const Message = props => {

    const { message, showAvatar } = props;

    const [ showData, setShowData ] = useState(false);

    const className = [ 'messenger_message' ];
    className.push(message.isOwn ? 'messenger_message-me' : 'messenger_message-them');

    const readDate = (message.readDate === null) ? null : moment(message.readDate);
    const sendDate = moment(message.sendDate);

    const data = (message.readDate === null)
        ? <span title={ `Sent: ${sendDate.fromNow()}` }>Sent</span>
        : <span title={ `Read: ${readDate.fromNow()}` }>Read</span>;

    const avatar = (showAvatar)
        ? <img src={ message?.sender.avatar.thumbnail } className="messenger_message-avatar" />
        : null;

    const toggleShowData = () => {
        setShowData( ! showData );
    };

    const contentClassname = isSingleEmoji(message.content) 
        ? 'messenger_message-emoji-only' 
        : 'messenger_message-content';

    if( message.attachments.length )
    {
        if( message.content && message.content.trim() !== '' )
        {
            return (
                <div className="messenger_message-multipart">
                    <div className={ className.join(' ') }>
                        <div className="messenger_message-container">
                            <MessageGallery images={ message.attachments } />
                        </div>
                    </div>

                    <div className={ className.join(' ') }>
                        <div className="messenger_message-container">
                            { avatar }
                            
                            <div className={ contentClassname } onClick={ toggleShowData }>
                                { nl2br(parseEmoji(message.content.trim())) }
                            </div>
                        </div>

                        <div className="messenger_message-data">{ (showData ? data : null) }</div>
                    </div>
                </div>
            );
        }

        return (
            
            <div className={ className.join(' ') }>
                <div className="messenger_message-container">
                    { avatar }
                    <MessageGallery images={ message.attachments } />
                </div>

                <div className="messenger_message-data">{ (showData ? data : null) }</div>
            </div>
        );
    }

    return (
        <div className={ className.join(' ') }>
            <div className="messenger_message-container">
                { avatar }
                
                <div className={ contentClassname } onClick={ toggleShowData }>
                    { nl2br(parseEmoji(message.content.trim())) }
                </div>
            </div>

            <div className="messenger_message-data">{ (showData ? data : null) }</div>
        </div>
    );
};

export default Message;