import React, { useState, useRef, useEffect } from 'react';
import { useId } from 'react-id-generator';

import { FormHelp } from 'Components';

const TextArea = (props) => {

	// Grab the properties:
	const { 
		label, 
		type, 
		name, 
		required,
		help,
		focused,

		onChange,
		onInput,
		onKeyDown,
		onKeyUp,

		minLength,
		maxLength,
		min,
		max,
		value
	} = props;

	const [ htmlId ] = useId();

	const handleChange = (event) =>
	{
		if(typeof onChange == 'function')
		{
			onChange.call(
				null,
				event,
				event.target.value
			);
		}
	};

	const handleInput = (event) => {
		if(typeof onInput == 'function')
		{
			onInput.call(
				null,
				event,
				event.target.value
			);
		}
	}

	const handleKeydown = (event) => {
		if(typeof onKeyDown == 'function')
		{
			onKeyDown(
				event,
				event.target.value
			);
		}
	};

	const handleKeyup = (event) => {
		if(typeof onKeyUp == 'function')
		{
			onKeyUp(
				event,
				event.target.value
			);
		}
	};

	const getParams = () => {
		let params = { };

		params.type = (type !== undefined) ? type : 'text';
		params.name = name;

		if(required === true) { params.required = 'required' };

		if(minLength !== undefined) { params.minLength = minLength };
		if(maxLength !== undefined) { params.maxLength = maxLength };

		if(min !== undefined) { params.min = min };
		if(max !== undefined) { params.max = max };

		return (params);
	};

	const handleFocus = event => {
		if( typeof props.onFocus === 'function' )
		{
			props.onFocus(event);
		}
	};

	const handleBlur = event => {
		if( typeof props.onBlur === 'function' )
		{
			props.onBlur(event);
		}
	}

	const valueAttr = (value === undefined || value === null) ? '' : value;

	const helpElement = (help === undefined) 
		? '' 
		: <FormHelp text={ help } />;

	const className = [ 'form-control' ];

	if( props.className !== undefined )
	{
		className.push(props.className);
	}

	// Handle focus:
	const [ isFocused, setIsFocused ] = useState(focused);
	if( isFocused ) { className.push('focus'); }

	const inputEl = useRef(null);

	useEffect(() => {
		if( props.focused === true )
		{
			inputEl.current.focus();
		}
		else
		{
			inputEl.current.blur();
		}
	}, [ props.focused ]);


	const style = {
		height: `${props.height !== undefined ? props.height : 200}px`
	};

	return (
		<div className={ className.join(' ') } style={{ ...props.style }}>
			{ helpElement }
			<textarea 
				type={ (type === undefined) ? 'text' : type }
            	className={ `input ${valueAttr ? ' has-value' : ''}` }
				onChange={(e) => { handleChange(e) }}
				onInput={(e) => { handleInput(e) }}
				onKeyDown={(e) => handleKeydown(e) }
				onKeyUp={(e) => handleKeyup(e) }
				onFocus={(e) => { handleFocus(e); setIsFocused(true)}}
				onBlur={(e) => { handleBlur(e); setIsFocused(false)}}
				ref={ inputEl }
				id={ htmlId }
				value={ valueAttr }
				style={ style }
            	{...getParams()} />

			<label htmlFor={ htmlId }>{ label }</label>
		</div>
	);
}

export default TextArea;