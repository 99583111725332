import React, { useState, useEffect } from 'react';

import { Button, Modal } from 'Components';

const Confirm = (props) => {

    const confirmClassName = [ 'btn', 'btn-rounded', 'btn-upper' ];
    const cancelClassName = [ 'btn', 'btn-text-muted', 'btn-upper' ];
    const size = (props.size === undefined) ? 'small' : props.size;

    const [ cancelVisible, setCancelVisible ] = useState(true);

    useEffect(() => { 
        if( props.cancelVisible !== undefined )
        {
            setCancelVisible(props.cancelVisible);
        }
    }, [ props.cancelVisible ]);
    

    if( props.confirmButtonClassname === undefined )
    {
        confirmClassName.push('btn-primary');
    }
    else
    {
        confirmClassName.push(props.confirmButtonClassname);
    }

    if( size === 'sm' || size === 'small' )
    {
        confirmClassName.push('btn-sm');
        cancelClassName.push('btn-sm');
    }

    return (
        <Modal 
            size={ size } 
            visible={ props.visible } 
            closable={ false } 
            title={ props.title }
            content={ props.content }
            buttons={
                <>
                    <Button 
                        loading={ props.loading || false } 
                        onClick={e => { props.onConfirm(e) }} 
                        className={ confirmClassName.join(' ') } 
                        text={ props.confirmText || 'Yes' } />

                    <Button 
                        onClick={e => { props.onClose(e) }} 
                        className={ cancelClassName.join(' ') }
                        style={{ visibility: (cancelVisible ? 'visible' : 'hidden') }}
                        text={ props.cancelText || 'Cancel' } />
                </>
            } />
    );
};
  
export default Confirm;