import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { objectSelector } from 'store/selectors';

import { openAddPaymentMethod } from 'store/actions/modalActions';
import { removePaymentMethod } from 'store/actions/userActions';

import APIService from 'Services/APIService';

import { Confirm, Modal, Button, EmptyState, CreditCard } from 'Components';

const Addresses = (props) => {

    const dispatch = useDispatch();
    const { addToast } = useToasts();    

    const { paymentMethods } = useSelector(state => state.user);

    const [ confirmDeleteVisible, setConfirmDeleteVisible ] = useState(false);
    const [ confirmDeleteLoading, setConfirmDeleteLoading ] = useState(false);
    const [ deleteId, setDeleteId ] = useState(null);

    // Handle delete:
    const handleDeleteConfirmSubmit = e => {
        setConfirmDeleteLoading(true);

        APIService.delete('/payment-method/' + deleteId)
            .then(response => {
                dispatch(removePaymentMethod(deleteId));
            })
            .catch(error => {
                addToast(error.response.data.message, {
                    appearance: 'error',
                    autoDismiss: true
                });
            })
            .finally(() => {
                setConfirmDeleteLoading(false);
                setConfirmDeleteVisible(false);
            })
        
    };

    let content = '';

    if( objectSelector(paymentMethods).length === 0 )
    {
        content = (
            <EmptyState 
                button={ 
                    <Button 
                        className="btn-rounded btn-primary btn-upper" 
                        text="Add payment method" 
                        onClick={(e) => { e.preventDefault(); dispatch(openAddPaymentMethod()) }} />
                }
                description="You haven't added any payment methods to your account."
                title="No payment methods" 
                image="NoCreditCard" />
        );
    }
    else
    {
        content = (
            <div className="credit-cards">
                { 
                    objectSelector(paymentMethods).map((method, i) => (
                        <CreditCard
                            key={i} 
                            index={i}
                            method={ method } 
                            onDelete={e => { 
                                setConfirmDeleteVisible(true);
                                setDeleteId(method.id);
                            }} /> 
                    ))
                }
            </div>
        );
    }

    return (
        <>
            { content }

            <Modal
                title="Add payment method"
                visible={ false }
                />

            <Confirm 
                visible={ confirmDeleteVisible }
                loading={ confirmDeleteLoading }
                title="Delete credit card"
                onClose={e => { setConfirmDeleteVisible(false); setConfirmDeleteLoading(false); } }
                onConfirm={e => { handleDeleteConfirmSubmit(e) }}
                confirmText="Yes, delete"
                content="Are you sure you want to delete the credit card?" />
        </>
    );
};

export default Addresses;