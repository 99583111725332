import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import nl2br from 'react-nl2br';

// OverlayScrollbars:
import 'overlayscrollbars/css/OverlayScrollbars.css';
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react';

import APIService from 'Services/APIService';
import { objectSelector } from 'store/selectors';
import { checkIsEmpty } from 'helpers';
import produce from 'immer';

import { AttributeValue, Row, Column, Alert, Modal, Button, TextArea, ToggleSwitch, Input, FormProgress, FormSlider, DefinitionList, CurrencyFormat } from 'Components';
import { FormSlide } from 'Components/FormControls/FormSlider';

const ModalMarketplaceAdd = (props) => {

    const { visible, item, onSuccess } = props;

    // Value states:
    const [ title, setTitle ] = useState('');
    const [ quantity, setQuantity ] = useState(1);
    const [ description, setDescription ] = useState('');
    const [ price, setPrice ] = useState('');
    const [ allowOffers, setAllowOffers ] = useState(false);

    // Slider states:
    const [ currentSlide, setCurrentSlide ] = useState(0);
    const [ attributes, setAttributes ] = useState({ });

    // Category states:
    const [ categoriesIsLoading, setCategoriesIsLoading ] = useState(true);
    const [ subcategoriesIsLoading, setSubcategoriesIsLoading ] = useState(false);
    const [ categories, setCategories ] = useState([]);
    const [ subcategories, setSubcategories ] = useState([]);
    const [ categoryId, setCategoryId ] = useState(null);
    const [ subcategoryId, setSubcategoryId ] = useState(null);
    const [ categoryName, setCategoryName ] = useState(null);
    const [ subcategoryName, setSubcategoryName ] = useState(null);

    // Alert state:
    const [ alertVisible, setAlertVisible ] = useState(false);
    const [ alertClass, setAlertClass ] = useState(null);
    const [ alertText, setAlertText ] = useState('');

    // Button state:
    const [ nextButtonLoading, setNextButtonLoading ] = useState(false);
    const [ nextButtonDisabled, setNextButtonDisabled ] = useState(true);
    const [ nextButtonText, setNextButtonText ] = useState('Next');
    const [ backButtonVisible, setBackButtonVisible ] = useState(true);

    // Get the currencies:
    const { currencies } = useSelector(state => state.config);
    const { preferences } = useSelector(state => state.user);

    const currency = currencies[preferences.currency];

    // Load the categories:
    useEffect(() => { 
        setCategoriesIsLoading(true);

        APIService.get('/marketplace/categories/')
            .then(response => setCategories(response.categories))
            .finally(() => setCategoriesIsLoading(false));
    }, [ ]);

    // Load the subcategoires:
    useEffect(() => { 
        if( categoryId !== null )
        {
            setSubcategoriesIsLoading(true);
            setSubcategoryId(null);
            
            APIService.get(`/marketplace/category/${categoryId}`)
                .then(response => setSubcategories(response.categories))
                .finally(() => setSubcategoriesIsLoading(false));
        }
    }, [ categoryId ]);

    useEffect(() => { 
        if( item )
        {
            const attrs = { };

            objectSelector(item.attributes).map(attr => { 
                attrs[attr.attributeId] = {
                    id: attr.attributeId,
                    name: attr.name,
                    value: attr.value,
                    checked: true
                };
            });

            setAttributes(attrs);
        }
    }, [ item ]);

    // Handle attribute change:
    const handleAttributeChange = (attribute, checked) => {
        setAttributes(produce(attributes, draft => {
            draft[attribute.id].checked = checked;
        }));
    };

    // Handle category selection:
    const handleCategorySelect = category => { setCategoryName(category.name); setCategoryId(category.id) };
    const handleSubcategorySelect = category => { setSubcategoryName(category.name); setSubcategoryId(category.id) };

    // Next button enabler!
    useEffect(() => { 
        // First screen:
        if( currentSlide === 0 )
        {
            setNextButtonDisabled( checkIsEmpty(title) || checkIsEmpty(quantity) || checkIsEmpty(description) || checkIsEmpty(price) );
        }

        // Category select screen:
        else if( currentSlide === 2 )
        {
            setNextButtonDisabled(checkIsEmpty(subcategoryId));
        }

        // Otherwise, just enable it:
        else 
        {
            setNextButtonDisabled(false);
        }
    }, [ title, quantity, description, price, currentSlide, subcategoryId ]);

    // Reset onclose:
    const onBeforeClose = e => {
        e.preventDefault();

        setAlertVisible(false);
        setCurrentSlide(0);
        setNextButtonLoading(false);
        setNextButtonDisabled(true);
        setNextButtonText('Next');
        setBackButtonVisible(true);

        if( typeof props.onClose === 'function' )
        {
            props.onClose();
        }
    };

    // Handle the scrolling of the form:
    const handleFormNext = e => {

        const nextSlide = currentSlide + 1;

        if( nextSlide < 4 )
        {
            setCurrentSlide(nextSlide);
        }

        if( nextSlide === 3 )
        {
            setNextButtonText('List in Marketplace');
        }

        // We're submitting:
        if( nextSlide === 4 )
        {
            // Handle submit:
            handleSubmit(e);
        }
    };

    const handleFormPrev = e => {
        const prevSlide = currentSlide - 1;
        setCurrentSlide(prevSlide);
        setNextButtonLoading(false);
        setNextButtonDisabled(false);

        if( prevSlide < 3 )
        {
            setNextButtonText('Next');
        }
    };

    useEffect(() => { 
        setBackButtonVisible(currentSlide > 0);
    }, [ currentSlide ]);

    // Handle the form submission:
    const handleSubmit = e => {

        setNextButtonLoading(true);
        setBackButtonVisible(false);

        // Get the attributes:
        const attrs = [ ];
        objectSelector(attributes).map(attr => { if(attr.checked) attrs.push(attr.id) });

        // Form data:
        const data = {
            'itemId': item.id,
            'title': title,
            'quantity': parseInt(quantity),
            'price': parseFloat(price),
            'allowOffers': allowOffers,
            'description': description,
            'attributes': attrs,
            'categories': [ subcategoryId ]
        };

        // Handle the API call:
        APIService.post('/marketplace/items/', data)
            .then(response => { 
    
                setTitle('');
                setQuantity(1);
                setDescription('');
                setPrice('');
                setAllowOffers(false);
                setCurrentSlide(0);
                setNextButtonText('Next');
                setNextButtonDisabled(true);
                setBackButtonVisible(true);
                
                if( typeof onSuccess === 'function' )
                {
                    onSuccess(
                        item,
                        response.marketplaceId,
                        response.url
                    );
                }
            })
            .catch(error => { 
                setAlertClass('danger');
                setAlertText(error.response.data.message);
                setAlertVisible(true);
            })
            .finally(() => {
                setNextButtonLoading(false);
                setBackButtonVisible(true);
            });

        if( typeof props.onSuccess === 'function' )
        {
            props.onSuccess(null);
        }
    };

    // Get the selected attributes:
    const getSelectedAttributes = () => {
        const atts = [ ];

        objectSelector(attributes).map(attr => {
            if( attr.checked === true )
            {
                atts.push(attr.name);
            }
        });

        return atts.join(', ');
    };

    return (
        <Modal size="lg" onClose={ onBeforeClose } title={ item?.name } subtitle="Marketplace Listing" visible={ visible }>

            <Alert 
                visible={ alertVisible }
                type={ alertClass }
                className="mb25"
                text={ alertText } />

            <FormProgress
                activeItem={ currentSlide }
                items={[ 'Listing info', 'Attributes', 'Categories', 'Review' ]} />

            <FormSlider currentSlide={ currentSlide }>
                <FormSlide>
                    <Row>
                        <Column>
                            <Input 
                                className="form-control-lg" 
                                label="Listing title" 
                                value={ title } 
                                onChange={e => { setTitle(e.target.value) }} />
                        </Column>
                        <Column width="25">
                            <Input 
                                className="form-control-lg"
                                min={ 0 }
                                onChange={e => { setQuantity(e.target.value) }}
                                type="number" 
                                label="Quantity" 
                                value={ quantity } />
                        </Column>
                    </Row>

                    <Row className="my25">
                        <Column width="25">
                            <Input 
                                label="Asking price"
                                value={ price }
                                type="number"
                                onChange={e => { setPrice(e.target.value) }}
                                prepend={ <span className="input-icon">{ currency?.symbol }</span> } />
                        </Column>
                        <Column>
                            <ToggleSwitch
                                className="mb0 mt10"
                                checked={ allowOffers }
                                onChange={(e, checked) => { setAllowOffers(checked) }}
                                label="Allow buyers to make an offer?" />
                        </Column>
                    </Row>

                    <TextArea 
                        label="Description" 
                        value={ description }
                        onChange={e => { setDescription(e.target.value) }}
                        className="mt25" />                   
                </FormSlide>
                
                <FormSlide>
                    <p>
                        Item attributes will also be added to your Marketplace listing. You can change which attributes will be shared by toggling them 
                        on or off below:
                    </p>

                    { objectSelector(attributes).map(attribute => (
                        <ToggleSwitch
                            onChange={(e, checked) => { handleAttributeChange(attribute, checked) }}
                            checked={ attribute.checked }
                            label={ 
                                <>
                                    { attribute.name }
                                    <span className="text-muted"> (<AttributeValue attribute={ item?.attributes[attribute.id] } value={ attribute.value } />)</span>
                                </>
                            } />   
                    )) }
                </FormSlide>
                
                <FormSlide>
                    <p>Categories help prospective buyers find your item when browsing the Marketplace.</p>

                    <Row className="row-narrow">
                        <Column>
                            <div className={ `category-selector ${categoriesIsLoading ? 'loading' : ''}` }>
                                <OverlayScrollbarsComponent>
                                    <ol>
                                        { categories.map(cat => ( 
                                            <li className={ `${cat.id === categoryId ? 'selected' : ''}` } onClick={() => handleCategorySelect(cat) }>
                                                { cat.name }
                                            </li> 
                                        ))}
                                    </ol>
                                </OverlayScrollbarsComponent>
                            </div>
                        </Column>
                        <Column>
                            <div className={ `category-selector ${subcategoriesIsLoading ? 'loading' : ''}` }>
                                <OverlayScrollbarsComponent>
                                    <ol>
                                        { subcategories.map(cat => ( 
                                            <li className={ `${cat.id === subcategoryId ? 'selected' : ''}` } onClick={() => handleSubcategorySelect(cat) }>
                                                { cat.name }
                                            </li> 
                                        ))}
                                    </ol>
                                </OverlayScrollbarsComponent>
                            </div>
                        </Column>
                    </Row>
                </FormSlide>
                
                <FormSlide>
                    <p>
                        Please review the information below, as this what will appear in your Marketplace listing. You can make changes by clicking
                        the back button:
                    </p>

                    <DefinitionList
                        items={[
                            { title: 'Listing title', value: title },
                            { title: 'Quanity', value: quantity },
                            { title: 'Price', value: <CurrencyFormat currency={ currency } value={ price } />},
                            { title: 'Allow offers?', value: (allowOffers ? 'Yes' : 'No') },
                            { title: 'Attributes', value: getSelectedAttributes() },
                            { title: 'Category', value: `${categoryName} » ${subcategoryName}`}
                        ]}
                        />
                </FormSlide>

                <FormSlide>
                    
                </FormSlide>
            </FormSlider>

            <div className="mt25">
                <Button 
                    className="btn-primary btn-rounded btn-upper" 
                    disabled={ nextButtonDisabled }
                    text={ nextButtonText } 
                    onClick={ handleFormNext } 
                    loading={ nextButtonLoading } />

                <Button 
                    className="btn-text-muted btn-upper" 
                    text="Back" 
                    visible={ backButtonVisible }
                    onClick={ handleFormPrev } />

                <Button 
                    className="btn-text-muted btn-upper pull-right" 
                    text="Cancel" 
                    onClick={ onBeforeClose } />
            </div>

        </Modal>
    );
};

export default ModalMarketplaceAdd;