import { React, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { closeChangePassword  } from 'store/actions/modalActions';
import APIService from 'Services/APIService';

import { Alert, Modal, Button, Input } from 'Components';

const ModalChangePassword = (props) => {

    const { changePassword } = useSelector(state => state.modals);
    const dispatch = useDispatch();

    const [ alertVisible, setAlertVisible ] = useState(false);
    const [ alertText, setAlertText ] = useState(null);
    const [ alertClass, setAlertClass ] = useState(null);

    const [ password, setPassword ] = useState('');
    const [ newPassword, setNewPassword ] = useState('');
    const [ newPassword2, setNewPassword2 ] = useState('');

    const [ buttonLoading, setButtonLoading ] = useState(false);
    const [ formVisible, setFormVisible ] = useState(true);

    const handleSubmit = (e) => {
        e.preventDefault();

        setAlertVisible(false);

        if (password === null || password === '') {
            setAlertClass('danger');
            setAlertText('Please enter your current password.');
            setAlertVisible(true);

            return;
        };

        if (newPassword === null || newPassword === '') {
            setAlertClass('danger');
            setAlertText('Please enter your new password.');
            setAlertVisible(true);

            return;
        };

        if (newPassword2 === null || newPassword2 === '') {
            setAlertClass('danger');
            setAlertText('Please confirm your new password.');
            setAlertVisible(true);
            return;
        };

        if (newPassword !== newPassword2) {
            setAlertClass('danger');
            setAlertText('The passwords don\'t match.');
            setAlertVisible(true);

            return;
        };

        setButtonLoading(true);

        // Now send off the AJAX request:
        APIService
            .put('/user/reset-password', { password: password, newPassword: newPassword, newPassword2: newPassword2 })
            .then(() => { 
                setFormVisible(false);
                setAlertVisible(true);
                setAlertText('Your password has been reset!');
                setAlertClass('success');
            })
            .catch(error => { 
                setButtonLoading(false);
                setAlertVisible(true);
                setAlertText(error.response.data.message);
                setAlertClass('danger');
            });
    };

    // Reset onclose:
    const onBeforeClose = event => {
        setAlertVisible(false);
        setPassword(null);
        setNewPassword(null);
        setNewPassword2(null);
        setFormVisible(true);
        setButtonLoading(false);
    };

    let formStyles = { };

    // Are we hiding the form?
    if( formVisible !== true )
    {
        formStyles = {
            display: 'none'
        };
    }

    return (
        <Modal size="small" onBeforeClose={ onBeforeClose } onClose={(e) => { dispatch(closeChangePassword()) }} title="Change password" visible={ changePassword }>
            <p>To change your Kolekto password, confirm your current password and enter a new one using the form below:</p>

            <Alert className={ `alert-${alertClass} alert-icon mb25` } visible={alertVisible}>
                { alertText }
            </Alert>

            <div style={ formStyles }>
                <Input 
                    className="form-control-lg" 
                    type="password" 
                    onChange={(e) => { setAlertVisible(false); setPassword(e.target.value) }}
                    value={ password }
                    label="Current password" />

                <Input 
                    className="my20 form-control-lg" 
                    type="password" 
                    onChange={(e) => { setAlertVisible(false); setNewPassword(e.target.value) }}
                    value={ newPassword }
                    label="New password" />

                <Input 
                    className="my20 form-control-lg" 
                    type="password" 
                    onChange={(e) => { setAlertVisible(false); setNewPassword2(e.target.value) }}
                    value={ newPassword2 }
                    label="Confirm password" />

                <Button 
                    className="btn-primary btn-upper btn-center btn-rounded w75 btn-lg" 
                    text="Change password" 
                    loading={ buttonLoading }
                    onClick={(e) => { handleSubmit(e) }}
                    />
            </div>
        </Modal>
    );
};

export default ModalChangePassword;