import React, { useEffect, useState } from 'react';
import { objectSelector } from 'store/selectors';
import { APIService } from 'Services';
import produce from 'immer';

import FilterFields from './FilterFields';
import { Alert, Button, Modal } from 'Components';

const FilterModal = props => {

    const { clearButtonVisible, visible, attributes, onClose, onApplyFilters, onClearFilters, onResetFilters, categoryId } = props;
    const [ filters, setFilters ] = useState({});
    const [ appliedFilters, setAppliedFilters ] = useState(props.appliedFilters);

    const [ applyButtonDisabled, setApplyButtonDisabled ] = useState(false);
    const [ applyButtonLoading, setApplyButtonLoading ] = useState(false);

    const [ alertText, setAlertText ] = useState(null);
    const [ alertType, setAlertType ] = useState('danger');
    const [ alertVisible, setAlertVisible ] = useState(false);

    // When they change the URL, remove the filters:
    useEffect(() => { 
        setAppliedFilters({});
        
        if( typeof onResetFilters === 'function' )
        {
            onResetFilters();
        }
    }, [ categoryId ]);

    const handleClose = e => {
        e.preventDefault();

        if( typeof onClose === 'function' )
        {
            onClose(e);
        }
    };

    const handleFilterChange = (id, checked, operator, value, minValue, maxValue) => {
        const newFilters = produce(filters, draft => {
            if( checked === true )
            {
                if( operator !== 'between' )
                {
                    draft[id] = {
                        operator: operator,
                        value: value
                    };
                }
                else
                {
                    draft[id] = {
                        operator: operator,
                        minValue: minValue,
                        maxValue: maxValue
                    };
                }
            }
            else
            {
                delete draft[id];
            }
        });

        setFilters(newFilters);
    };

    const handleApplyFilters = e => {
        e.preventDefault();

        setApplyButtonLoading(true);
        setAlertVisible(false);

        // Now call the API to filter:
        const endpoint = `/category/${categoryId}/filter/`;

        // Build the data:
        const filterData = { attributes: { } };

        for( let i in filters )
        {
            if( i === 'name' )
            {
                filterData['name'] = {
                    operator: filters[i].operator,
                    value: filters[i].value
                };
            }
            else
            {
                filterData.attributes[i] = filters[i];
            }
        };
        
        APIService.post(endpoint, { filters: filterData })
            .then(response => { 
                if( typeof onApplyFilters === 'function' )
                {
                    setAppliedFilters(filters);

                    onApplyFilters(
                        Object.keys(filters).length,
                        response.items,
                        filters
                    );
                }
            })
            .catch(error => { 
                setAlertType('danger');
                setAlertText(error.response.data.message);
                setAlertVisible(true);
            })
            .finally(() => { setApplyButtonLoading(false) });
    };

    const handleClearFilters = e => {
        e.preventDefault();
        
        setAppliedFilters({});

        if( typeof onClearFilters === 'function' )
        {
            onClearFilters();
        }
    };

    useEffect(() => { 
        setApplyButtonDisabled(Object.keys(filters).length === 0);
    }, [ filters ]);

    return (
        <Modal size="lg" visible={ visible } title="Item filters" onClose={ handleClose }>
            <Button
                style={{ display: (clearButtonVisible === true ? 'inline-block' : 'none') }}
                onClick={ handleClearFilters }
                text="Clear filters"
                className="btn-secondary btn-sm mb25 btn-upper btn-rounded" />

            <Alert 
                className="mb25"
                type={ alertType } 
                text={ alertText } 
                visible={ alertVisible } />

            <FilterFields
                attributes={ attributes }
                filters={ appliedFilters }
                onFilterChange={ handleFilterChange } />
            
            <div className="mt25">
                <Button 
                    loading={ applyButtonLoading }
                    disabled={ applyButtonDisabled }
                    className="btn-primary btn-upper btn-rounded" 
                    onClick={ handleApplyFilters }
                    text="Apply filters" />

                <Button 
                    onClick={ handleClose }
                    className="btn-text-muted btn-upper" 
                    text="Cancel" />
            </div>
        </Modal>
    );
};

export default FilterModal;