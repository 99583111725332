import React from 'react';

const EmptyState = (props) => {

	// Grab the properties:
	const { title, description, button, image } = props;

    const imageElement = (image === undefined) ? '' : <img alt={title} className="empty-state_image" src={ `/images/state/${image}.png` } />

	return (
		<div className="empty-state">
            { imageElement }
			<h4 className="empty-state_title">{title}</h4>
			<p className="empty-state_desc">{description}</p>
			{ button }
		</div>
	);
}

export default EmptyState;