import React from 'react';

import { BlankImage, Button } from 'Components';

const UploadedImage = props => {

    const image = (props.image.loading === true) ? <BlankImage /> : <img alt="" src={ props.image.thumbnail } />;
    const style = { };
    const className = [ 'image-uploader_queue-item' ];

    if( props.image.loading === true )
    {
        className.push('loading');
    }

    const handleDelete = e => {
        e.preventDefault();
        
        if( typeof props.onDelete === 'function' )
        {
            props.onDelete(e);
        }
    }

    // Remove element:
    let remove = '';

    if( props.image.loading === false )
    {
        remove = <div className="image-uploader_queue-item-remove">
                     <Button className="btn-icon-delete" icon="trash" onClick={ handleDelete } />
                 </div>;
    }

    if( props.columnWidth !== undefined )
    {
        style.width = props.columnWidth + '%';
    }

    return (
        <div className="image-uploader_queue-column" style={{ ...style }}>
            <div className={ className.join(' ') }>
                { image }
                { remove }
            </div>
        </div>
    );
};

export default UploadedImage;