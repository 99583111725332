import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useToasts } from 'react-toast-notifications'
import { openAddAddress } from 'store/actions/modalActions';
import { fetchAddresses, deleteAddress } from 'store/actions/addressActions';
import { objectSelector } from 'store/selectors';

import { Confirm, Button, Address, EmptyState } from 'Components';
import { APIService } from 'Services';

const Addresses = props => {

    const { active } = props;

    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const [ confirmDeleteVisible, setConfirmDeleteVisible ] = useState(false);
    const [ confirmDeleteLoading, setConfirmDeleteLoading ] = useState(false);
    const [ deleteId, setDeleteId ] = useState(null);

    const { loading, addresses } = useSelector(state => state.address);

    // Handle delete:
    const handleDeleteConfirmSubmit = e => {
        setConfirmDeleteLoading(true);

        APIService.delete('/address/' + deleteId)
            .then(response => {
                dispatch(deleteAddress(deleteId));
            })
            .catch(error => {
                addToast(error.response.data.message, {
                    appearance: 'error',
                    autoDismiss: true
                });
            })
            .finally(() => {
                setConfirmDeleteLoading(false);
                setConfirmDeleteVisible(false);
            })
        
    };

    let content = null;

    // Render the content:
    if( objectSelector(addresses).length === 0 )
    {
        content = (
            <EmptyState 
                button={ 
                    <Button 
                        className="btn-rounded btn-primary btn-upper" 
                        text="Add address" 
                        onClick={(e) => { e.preventDefault(); dispatch(openAddAddress()) }} />
                }
                description="It looks like you haven't added any addresses to your account."
                title="No addresses found" 
                image="NoGPS" />
        );
    }
    else
    {
        content = objectSelector(addresses).map((address, i) => (
            <Address 
                key={i} 
                index={i}
                address={ address } 
                onDelete={e => { 
                    setConfirmDeleteVisible(true);
                    setDeleteId(address.id);
                }} /> 
        ));
    }

    useEffect(() => { 
        dispatch(fetchAddresses()) 
    }, [ active ]);

    const classNameStr = `addresses ${loading === true ? 'loading' : ''}`;

    return (
        <>
            <div className={ classNameStr }>
                { content }
            </div>

            <Confirm 
                visible={ confirmDeleteVisible }
                loading={ confirmDeleteLoading }
                title="Delete address"
                onClose={e => { setConfirmDeleteVisible(false); setConfirmDeleteLoading(false); } }
                onConfirm={e => { handleDeleteConfirmSubmit(e) }}
                confirmText="Yes, delete"
                content="Are you sure you want to delete that address?" />
            
        </>
    );
};

export default Addresses;