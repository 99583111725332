import React from 'react';

const Cell = props => {

    const className = (props.className !== undefined) ? props.className : '';
    const content = (props.text !== undefined) ? props.text : props.children;

    return (
        <td className={ className }>
            { content }
        </td>
    );
};

export default Cell;