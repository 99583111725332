import { 
    SHOW_SETTINGS_MODAL,
    HIDE_SETTINGS_MODAL
} from '../actions/settingsActions';

const initialState = {
    activeModal: null
};
  
const settingsReducer = (state = initialState, action) => {
    switch(action.type)
    {
        case SHOW_SETTINGS_MODAL:
            return {
                ...state,
                activeModal: action.activeModal
            };

        case HIDE_SETTINGS_MODAL:
            return {
                ...state,
                activeModal: null
            };

        default:
            return state;
    };
};

export default settingsReducer;