import React from 'react';

const Badge = props => {
    const { count } = props;
    const className = [ 'badge' ];

    if( props.className !== undefined )
    {
        className.push(props.className);
    }

    return (
        <span className={ className.join(' ') }>
            { count }
        </span>
    )
};

export default Badge;