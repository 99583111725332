import React from 'react';

const EmptyStateText = (props) => {

    const className = [ 'text-muted' ];
	const { text } = props;
	const style = { };
	

	if( props.className !== undefined )
	{
		className.push(props.className);
	}

	if( props.style === 'large' )
	{
		style.fontSize = '20px';
		style.textAlign ='center';

		return (
			<p className={ className.join(' ') } style={ style }>
				<i>{ text }</i>
			</p>
		);
	}

	return (
		<i className={ className.join(' ') } style={ style }>{ text }</i>
	);
}

export default EmptyStateText;