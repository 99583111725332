import React from 'react';

const Header = props => {
    return (
        <thead>
            <tr>
                { props.children }
            </tr>
        </thead>
    );
};

export default Header;