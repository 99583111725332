import React from 'react';

const BlankImage = props => {
    const className = [ 'image', 'empty-image' ];

    if( props.className !== undefined )
    {
        className.push(props.className);
    }

    return (
        <img 
            alt=""
            { ...props }
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=" 
            className={ className.join(' ') } />
    )
};

export default BlankImage;