import React from 'react';

const DefinitionListItem = props => {
    const { title, value } = props;

    return (
        <div className="definition-list_item">
            <dt>{ title }</dt>
            <dd>{ value }</dd>
        </div>
    );
};

export default DefinitionListItem;