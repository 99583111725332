import React from 'react';

const Rule = props => {

    const className = [ ];

    if( props.className )
    {
        className.push(props.className);
    }

    return (
        <hr className={ className.join(' ') } />
    )
};

export default Rule;