import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { 
    openAddAddress, 
    openAddAttribute, 
    openAddPaymentMethod 
} from 'store/actions/modalActions';

import { Button, ModalPage } from 'Components';

import {
    SettingsAttributes,
    SettingsActivity,
    SettingsAddresses,
    SettingsBilling,
    SettingsProfile,
    SettingsPreferences,
    SettingsEmailPrefs,
    SettingsTwoFactor,
    SettingsInvoices,
    SettingsExports,
    SettingsSubscriptions
} from 'Components/Pages';

const SettingModals = (props) => {
    
    const dispatch = useDispatch();

    const { activeModal } = useSelector(state => state.settings);
    
    return (
        <div id="settings-modals">
            <ModalPage
                visible={ (activeModal === 'attributes') }
                title="Custom Attributes"
                contentClassName="modal-page_content-accent"
                component={ <SettingsAttributes active={ (activeModal === 'attributes') } /> }
                buttons={
                    <Button
                        className="btn-rounded btn-primary"
                        icon="plus"
                        text="Attribute"
                        onClick={(e) => { e.preventDefault(); dispatch(openAddAttribute())} } />
                } />

            <ModalPage
                visible={ (activeModal === 'activity')}
                title="Activity History"
                contentClassName="modal-page_content-accent"
                component={ <SettingsActivity active={ (activeModal === 'activity') } /> } />

            <ModalPage
                visible={ (activeModal === 'export') }
                title="Export Data"
                component={ <SettingsExports active={ (activeModal === 'export') } /> } />

            <ModalPage 
                visible={ (activeModal === 'addresses') } 
                title="Addresses" 
                contentClassName="modal-page_content-accent"
                component={ <SettingsAddresses active={ (activeModal === 'addresses') } /> }
                buttons={ 
                    <Button 
                        className="btn-rounded btn-primary" 
                        text="New address" 
                        icon="plus" 
                        onClick={(e) => { e.preventDefault(); dispatch(openAddAddress())}} /> 
                } />

            <ModalPage
                visible={ (activeModal === 'subscriptions') }
                title="Manage Subscription"
                contentClassName="modal-page_content-accent"
                component={ <SettingsSubscriptions active={ (activeModal === 'subscriptions') } /> } />

            <ModalPage
                visible={ (activeModal === 'billing') }
                title="Billing Preferences"
                contentClassName="modal-page_content-accent"
                component={ <SettingsBilling active={ (activeModal === 'billing') } /> }
                buttons={ 
                    <Button 
                        className="btn-rounded btn-primary" 
                        text="New Payment Method" 
                        icon="plus" 
                        onClick={(e) => { e.preventDefault(); dispatch(openAddPaymentMethod())}} /> 
                } />

            <ModalPage 
                visible={ (activeModal === 'profile') } 
                title="Profile Settings" 
                component={ <SettingsProfile active={ (activeModal === 'profile') } /> } />

            <ModalPage 
                visible={ (activeModal === 'preferences') } 
                title="Application Preferences" 
                component={ <SettingsPreferences active={ (activeModal === 'profile') } /> } />

            <ModalPage
                visible={ (activeModal === 'email-preferences') }
                title="Email Preferences"
                component={ <SettingsEmailPrefs active={ (activeModal === 'email-preferences') } /> } />

            <ModalPage
                visible={ (activeModal === 'two-factor-auth') }
                title="Two-Factor Authentication"
                component={ <SettingsTwoFactor active={ (activeModal === 'two-factor-auth') } /> } />

            <ModalPage
                visible={ (activeModal === 'invoices') }
                title="Invoices"
                contentClassName="modal-page_content-accent p0"
                component={ <SettingsInvoices active={ (activeModal === 'invoices') } /> } />
        </div>
    );
};

export default SettingModals;