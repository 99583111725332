import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications'; 
import { APIService } from 'Services';
import { formatCardNumber } from 'helpers';
import config from 'config';

import { addAddressItem } from 'store/actions/addressActions';
import { addPaymentMethod } from 'store/actions/userActions';

import { Alert, Button, Input, SelectElement, SelectGroup, CountrySelect, Modal, FormSlider, FormSlide, CurrencyFormat, Row, Column } from 'Components';
import { SelectPaymentMethod, SelectAddress } from 'Components/FormControls';

const ModalAddSubscription = props => {

    const { visible, onClose, onCreate } = props;
    const { addToast } = useToasts();
    const dispatch = useDispatch();

    const [ plans, setPlans ] = useState([ ]);
    const [ chosenPlan, setChosenPlan ] = useState(null);
    const [ modalLoading, setModalLoading ] = useState(false);
    const [ currentSlide, setCurrentSlide ] = useState(0);
    const [ modalSubtitle, setModalSubtitle ] = useState('Choose a plan');
    const [ submitButtonLoading, setSubmitButtonLoading ] = useState(false);

    // Saved details:
    const [ selectedPaymentMethod, setSelectedPaymentMethod ] = useState(null);
    const [ selectedAddress, setSelectedAddress ] = useState(null);

    // Card state:
    const [ cardholderName, setCardholderName ] = useState('');
    const [ cardNumberFormatted, setCardNumberFormatted ] = useState('');
    const [ expiryMonth, setExpiryMonth ] = useState('');
    const [ expiryYear, setExpiryYear ] = useState('');
    const [ cvv, setCvv ] = useState('');
    const [ expiryFocus, setExpiryFocus ] = useState(false);

    // Address state:
    const [ addressName, setAddressName ] = useState('');
    const [ addressCompany, setAddressCompany ] = useState('');
    const [ addressLine1, setAddressLine1 ] = useState('');
    const [ addressLine2, setAddressLine2 ] = useState('');
    const [ addressCity, setAddressCity ] = useState('');
    const [ addressCounty, setAddressCounty ] = useState('');
    const [ addressPostcode, setAddressPostcode ] = useState('');
    const [ addressCountry, setAddressCountry ] = useState('');

    // Alert state:
    const [ alertType, setAlertType ] = useState('danger');
    const [ alertText, setAlertText ] = useState(null);
    const [ alertVisible, setAlertVisible ] = useState(false);

    // Build the year options:
    const years = [{ value: '', text: 'YY' }];
    const now = new Date().getFullYear();
    for(let i = 0; i <= 5; i++) { years.push({ value: (now + i), text: (now + i)}); }

    // Get the plans when they open the modal:
    useEffect(() => {
        if( visible === true )
        {
            setModalLoading(true);
            setCurrentSlide(0);
            setModalSubtitle('Choose a plan');
            setSubmitButtonLoading(false);
            setAlertVisible(false);

            APIService.get('/subscriptions/plans/')
                .then(resp => { 
                    setPlans(resp.plans);
                })
                .catch(error => {
                    addToast(error.response.data.message, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                })
                .finally(() => { setModalLoading(false); });
        }
    }, [ visible ]);

    // Format card number:
    const handleCardNumberChange = value => {
        setCardNumberFormatted(formatCardNumber(value));
    };

    const handleClose = () => {
        if( typeof onClose === 'function' )
        {
            onClose.call();
        }
    };

    const selectPlan = (e, plan) => {
        e.preventDefault();

        setChosenPlan(plan);
        setCurrentSlide(1);
        setModalSubtitle('Payment details - ' + plan.name + ' plan');
    };

    const handleBackButton = e => {
        e.preventDefault();
        setCurrentSlide(0);
        setModalSubtitle('Choose a plan')
    };

    const handleSubmit = useCallback(e => {
        e.preventDefault();

        let flag = true;

        setSubmitButtonLoading(true);
        setAlertVisible(false);

        // Validate:
        if( selectedPaymentMethod === '' )
        {
            // Validate the details:
            const cardData = {
                cardholder: cardholderName,
                card_number: cardNumberFormatted,
                expire_month: expiryMonth,
                expire_year: expiryYear,
                cvc: cvv
            };
    
            APIService.post('/payment-methods/', cardData)
                .then(response => { 
                    dispatch(addPaymentMethod(response.id, response)); 
                    
                    // Are we adding a new address?
                    if( selectedAddress === '' )
                    {
                        const address = {
                            name: addressName,
                            organization: addressCompany,
                            line1: addressLine1,
                            line2: addressLine2,
                            city: addressCity,
                            county: addressCounty,
                            postcode: addressPostcode,
                            country: addressCountry,
                            default: true
                        };

                        // Send off the request:
                        APIService.post('/addresses/', address)
                            .then(response => { 
                                dispatch(addAddressItem(response.address));   
                                
                                // Now we need to create the subscription:
                                APIService.post('/subscriptions/', { plan: chosenPlan?.id })
                                    .then(resp => { 
                                        if( typeof onCreate === 'function' )
                                        {
                                            onCreate(resp)
                                        }
                                    })
                                    .catch(error => { 
                                        setAlertText(error.response.data.message);
                                        setAlertVisible(true);
                                    })
                                    .finally(() => { setSubmitButtonLoading(false); });
                            })
                            .catch(error => { 
                                setAlertText(error.response.data.message);
                                setAlertVisible(true);
                                setSubmitButtonLoading(false);
                            });
                    }
                    else
                    {
                        // Now we need to create the subscription:
                        APIService.post('/subscriptions/', { plan: chosenPlan?.id })
                            .then(resp => { 
                                if( typeof onCreate === 'function' )
                                {
                                    onCreate(resp)
                                }
                            })
                            .catch(error => { 
                                const message = (error.response) ? error.response.data.message : 'Something went wrong.';
                                setAlertText(message);

                                setAlertVisible(true);
                            })
                            .finally(() => { setSubmitButtonLoading(false); });
                    }
                })
                .catch(error => {
                    setAlertText(error.response.data.message);
                    setAlertVisible(true);
                    setSubmitButtonLoading(false);
                });
        }

        // We're using a saved payment method:
        else
        {
            // Are we adding a new address?
            if( selectedAddress === '' )
            {
                const address = {
                    name: addressName,
                    organization: addressCompany,
                    line1: addressLine1,
                    line2: addressLine2,
                    city: addressCity,
                    county: addressCounty,
                    postcode: addressPostcode,
                    country: addressCountry,
                    default: true
                };

                // Send off the request:
                APIService.post('/addresses/', address)
                    .then(response => { 
                        dispatch(addAddressItem(response.address));   
                        
                        // Now we need to create the subscription:
                        APIService.post('/subscriptions/', { plan: chosenPlan?.id })
                            .then(resp => { 
                                if( typeof onCreate === 'function' )
                                {
                                    onCreate(resp)
                                }
                            })
                            .catch(error => { 
                                setAlertText(error.response.data.message);
                                setAlertVisible(true);
                            })
                            .finally(() => { setSubmitButtonLoading(false); });
                    })
                    .catch(error => { 
                        const message = (error.response) ? error.response.data.message : 'Something went wrong.';
                        setAlertText(message);
                        setAlertVisible(true);
                        setSubmitButtonLoading(false);
                    });
            }
            else
            {
                // Now we need to create the subscription:
                APIService.post('/subscriptions/', { plan: chosenPlan?.id })
                    .then(resp => { 
                        if( typeof onCreate === 'function' )
                        {
                            onCreate(resp)
                        }
                    })
                    .catch(error => { 
                        const message = (error.response) ? error.response.data.message : 'Something went wrong.';
                        setAlertText(message);
                        setAlertVisible(true);
                    })
                    .finally(() => { setSubmitButtonLoading(false); });
            }
        }
    }, [ chosenPlan ]);

    return (
        <Modal id="modal-create-subscription" onClose={ handleClose } loading={ modalLoading } visible={ visible } title="Create subscription" subtitle={ modalSubtitle } size="lg">
             <Alert 
                className="mb20"
                type={ alertType }
                text={ alertText }
                visible={ alertVisible } />

            <FormSlider currentSlide={ currentSlide }>
                <FormSlide>
                    <div className="pricing-table">
                        { plans.map((plan, i) => 
                            <div className={ `pricing-table_plan ${plan.isPopular ? 'primary' : ''}` } key={ i }>
                                <h5 className="pricing-table_plan-name">{ plan.name }</h5>
                                <p className="pricing-table_plan-price">
                                    <CurrencyFormat currency={ plan.currency || 'GBP' } value={ (plan.price / 100) } />
                                    <span className="pricing-table_plan-price_term">
                                        { plan.billingPeriod === 12 ? 'per year' : 'per month' }
                                    </span>
                                </p>

                                <ul className="pricing-table_plan-benefits">
                                    <li><span className="pricing-table_plan-trial-days">{ plan.trialDays }-day</span> free trial</li>
                                    <li><strong>Unlimited</strong> Collections and Items</li>
                                    <li><strong>Free</strong> access to Marketplace</li>
                                    <li>Cancel anytime</li>
                                    <li>Lifetime <strong>priority</strong> support</li>
                                </ul>

                                <div className="pricing-table_select">
                                    <Button     
                                        className={ `btn-block btn-lg ${!plan.isPopular ? 'btn-outline' : ''} btn-primary btn-rounded btn-upper` }
                                        text="Select plan" 
                                        onClick={ (e) => selectPlan(e, plan) } />
                                </div>
                            </div>
                        )}
                    </div>
                </FormSlide>
                <FormSlide>
                    <Row className="billing-info">
                        <Column>
                            <h3>Payment method:</h3>

                            <SelectPaymentMethod 
                                onSelect={(method) => { setSelectedPaymentMethod(method.id) }}
                                label="Use existing card" />

                            <h3 className="mt30 mb20">Add new card:</h3>
                            
                            <Input
                                onChange={(e) => { setCardholderName(e.target.value) }}
                                label="Cardholder name"
                                value={ cardholderName } />

                            <Input
                                className="my20"
                                onChange={(e) => { handleCardNumberChange(e.target.value) }}
                                label="Card number"
                                value={ cardNumberFormatted } />

                            <Row className="row-narrow mb20">
                                <Column width={ 75 }>
                                    <SelectGroup label="Expiry date" className={(expiryFocus) ? 'focused' : ''}>
                                        <SelectElement 
                                            value={ expiryMonth }
                                            onChange={(e) => { setExpiryMonth(e.target.value) }}
                                            onBlur={(e) => { setExpiryFocus(false) }} 
                                            onFocus={(e) => { setExpiryFocus(true) }} 
                                            options={ config.months } />

                                        <SelectElement 
                                            value={ expiryYear }
                                            onChange={(e) => { setExpiryYear(e.target.value) }}
                                            onBlur={(e) => { setExpiryFocus(false) }} 
                                            onFocus={(e) => { setExpiryFocus(true) }} 
                                            options={ years } />
                                    </SelectGroup>
                                </Column>    
                                <Column>
                                    <Input
                                        onChange={(e) => { setCvv(e.target.value )}}
                                        minLength={3}
                                        maxLength={4}
                                        value={ cvv }
                                        label="CVV" />
                                </Column>
                            </Row>

                        </Column>
                        <Column>
                            <h3>Billing address:</h3>

                            <SelectAddress 
                                onSelect={(address) => { setSelectedAddress(address.id) }}
                                label="Use existing address" />

                            <h3 className="mt30 mb20">Add new address:</h3>

                            <Input 
                                className="my20" 
                                onChange={(e) => { setAddressName(e.target.value) }}
                                value={ addressName }
                                label="Name" />
                            
                            <Input 
                                className="my20" 
                                onChange={(e) => { setAddressCompany(e.target.value) }}
                                value={ addressCompany }
                                label="Company / Organization" />

                            <Input 
                                className="my20" 
                                onChange={(e) => { setAddressLine1(e.target.value) }}
                                value={ addressLine1 }
                                label="Line 1" />

                            <Input 
                                className="my20" 
                                onChange={(e) => { setAddressLine2(e.target.value) }}
                                value={ addressLine2 }
                                label="Line 2" />

                            <Input 
                                className="my20" 
                                onChange={(e) => { setAddressCity(e.target.value) }}
                                value={ addressCity }
                                label="City" />
                            
                            <Row className="row-narrow">
                                <Column>
                                    <Input 
                                        onChange={(e) => { setAddressCounty(e.target.value) }}
                                        value={ addressCounty }
                                        label="State / County" />
                                </Column>
                                <Column width={33}>
                                    <Input 
                                        onChange={(e) => { setAddressPostcode(e.target.value) }}
                                        value={ addressPostcode }
                                        label="Postal Code" />
                                </Column>
                            </Row>

                            <CountrySelect
                                onChange={(e) => { setAddressCountry(e.target.value)} }
                                className="my20" />
                        </Column>
                    </Row>

                    <Button
                        className="btn-primary btn-rounded btn-upper"
                        text="Create subscription"
                        loading={ submitButtonLoading }
                        onClick={ handleSubmit } />

                    <Button 
                        onClick={ handleBackButton }
                        visible={ ! submitButtonLoading }
                        className="btn-text-muted btn-upper"
                        text="Back" />
                </FormSlide>
            </FormSlider>
        </Modal>
    );
};

export default ModalAddSubscription;