import React from 'react';

const Icon = (props) => {

	let className = 'icon fa';

	switch(props.type)
	{
		case 'regular':
		case 'r':
		default:
			className+= 'r';
			break;

		case 'solid':
		case 's':
			className+= 's';
			break;

		case 'duotone':
		case 'd':
			className+= 'd';
			break;

		case 'light':
		case 'l':
			className+= 'l';
			break;

		case 'brand':
		case 'b':
			className+= 'b';
			break;
	}

	className+= ' fa-' + props.name;

	if( props.className !== undefined )
	{
		className+= ' ' + props.className;
	}

	return (
		<i className={className}></i>
	);
}

export default Icon;