import { Route, Redirect } from 'react-router-dom';
import AuthService from 'Services/AuthService';
import { useSelector } from 'react-redux';

const PrivateRoute = ({ children, ...rest }) => {

	const user = useSelector(state => state.user);

	// Redirect to the login:
	if( user.loading === false && user.error !== null )
	{
		return (
			<Redirect to='/login' />
		);
	}

	return (
		<Route 
			{ ...rest } 
			render={ children } 
    		/>
  	);
};

export default PrivateRoute;