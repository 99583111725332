import { CurrencyFormat } from 'Components';
import React from 'react';

const InvoiceTable = props => {
    const { items } = props;

    return (
        <table className="invoice-table">
            <thead>
                <tr>
                    <th className="invoice-table_item">Item</th>
                    <th className="invoice-table_qty">Qty</th>
                    <th className="inoice-table_price">Price</th>
                    <th className="inovice-table_total">Total</th>
                </tr>
            </thead>
            <tbody>
                {items.map((item, i) => 
                <tr key={ i }>
                    <td className="invoice-table_item">
                        <p className="invoice-table_item-name">{ item.title }</p>
                        <p className="invoice-table_item-desc">{ item.description }</p>
                    </td>
                    <td className="invoice-table_qty">{ item.quantity }</td>
                    <td className="invoice-table_price">
                        <CurrencyFormat currency="GBP" value={ item.grossAmount / 100 } />
                    </td>
                    <td className="invoice-table_total">
                        <CurrencyFormat currency="GBP" value={ (item.grossAmount * item.quantity) / 100 } />
                    </td>
                </tr>
                )}
            </tbody>
        </table>
    );
};

export default InvoiceTable;