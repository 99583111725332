import React from 'react';
import moment from 'moment';

import { Button, Row, Column } from 'Components';

const CreditCard = (props) => {
    const { index, method } = props;

    const className = [ 'credit-card' ];
    const expiry = new moment(method?.expiry);
    
    className.push(`credit-card-${method?.brand}`);

    if( method?.expired === true )
    {
        className.push('credit-card_expired');
    }

    // Handle delete:
    const handleDelete = event => {
        event.preventDefault();

        if( typeof props.onDelete === 'function' )
        {
            props.onDelete(
                event
            );
        }
    };

    return (
        <div key={ index } className={className.join(' ')}>
            <span className="credit-card_number">**** **** **** { method?.last4 }</span>
            <Row style={{ marginTop: 'auto' }}>
                <Column>
                    <span className="credit-card_name">{ method?.cardholderName }</span>
                </Column>
                <Column>
                    <span className="credit-card_expiry">
                        { expiry.format('MM') }/{ expiry.format('YY') }
                    </span>
                </Column>
            </Row>

            <div className="credit-card_overlay">
                <Button 
                    text="Remove"
                    icon="trash"
                    onClick={(e) => { handleDelete(e); }}
                    className="btn-rounded btn-danger" />
            </div>
        </div>
    )
}

export default CreditCard;