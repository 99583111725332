import React, { useState, useEffect } from 'react';

const RowSelector = props => {
	const { checked, onChange } = props;
	const [ isChecked, setIsChecked ] = useState((checked === true));

	const handleChange = e => {
		if( typeof onChange === 'function' )
		{
			onChange.call(
				null,
				e,
				!isChecked
			);
		}

		setIsChecked(!isChecked);
	};
	
	useEffect(() => { setIsChecked(props.checked) }, [ props.checked ]);

	return (
		<label onClick={(e) => { e.stopPropagation() }}>
			<input type="checkbox" checked={ isChecked === true } onChange={(e) => handleChange(e) } />
			<span></span>
		</label>
	)
};

export default RowSelector;