import React from 'react';

const FormSlide = props => {
    return (
        <div className="form-slide">
            { props.children }
        </div>
    );
};

export default FormSlide;