import React from 'react';
import { Link } from 'react-router-dom';

const Card = (props) => {

	// Grab the properties:
    const { link, className, loading } = props;
    
    let classNameArr = [ 'card' ];

    if( className !== undefined ) { classNameArr.push(className); }
    if( loading !== undefined ) { classNameArr.push('loading'); }

    const classNameStr = classNameArr.join(' ');

    if( link !== undefined )
    {
        return (
            <Link className={ classNameStr } to={ link }>
                { props.children }
            </Link>
        );
    }

	return (
		<div className={ classNameStr }>
			{ props.children }
		</div>
	);
}

export default Card;