import { React, useState } from 'react';
import { APIService } from 'Services';

// Elements:
import LoginInput from 'Components/FormControls/LoginInput';
import { Alert, Button } from 'Components';

const ForgotPasswordForm = () => {

	// Reset fields:
	const [ email, setEmail ] = useState('');
	const [ buttonLoading, setButtonLoading ] = useState(false);

    // Alert:
	const [ alertVisible, setAlertVisible ] = useState(false);
	const [ alertText, setAlertText ] = useState('');
	const [ alertType, setAlertType ] = useState('danger');

    // Handle the form submit:
    const handleSubmit = (e) => {
    	e.preventDefault();
		
		setButtonLoading(true);

		// Send off the email:
		APIService.get('/user/reset-password/', { email: email })
			.then(resp => { 
				setAlertVisible(true);
				setAlertText('Password reset instructions have been sent to the email address provided.');
				setAlertType('success');
			})
			.catch(error => { 
				setAlertVisible(true);
				setAlertText(error.response.data.message);
			})
			.finally(() => { 
				setButtonLoading(false) 
			});
    };

	return (
	    <form id="form-password-reset" className="mt30" onSubmit={(e) => { handleSubmit(e) }}>
			<Alert 
				className="nowrap mb30"
				visible={ alertVisible } 
				type={ alertType }
				text={ alertText } />

			<LoginInput 
				onChange={(e) => setEmail(e.target.value)}
				type="email" 
				required={ true } 
				value={ email }
				id="input-reset-email" 
				label="Email address" />

			<Button 
				className="btn-shadow btn-primary btn-rounded btn-upper btn-lg  mt50"
				loading={ buttonLoading }
				text="Reset Password" />
	    </form>
	);
};

export default ForgotPasswordForm;