import React, { useState } from 'react';

import { Icon } from 'Components';

const Input = props => {
    const { onChange } = props;

    const [ query, setQuery ] = useState('');

    const handleChange = e => {
        setQuery(e.target.value);

        if( typeof onChange === 'function' )
        {
            onChange(e.target.value);
        }
    };

    return (
        <div className="search-input">
            <input
                placeholder="Enter keywords to search your collections"
                value={ query }
                onChange={ handleChange }
                type="text" />
            
            <Icon name="search" />
        </div>
	);
};

export default Input;