export const SET_IS_DRAGGING = 'SET_IS_DRAGGING';
export const SET_DRAG_DATA = 'SET_DRAG_DATA';
export const SET_TARGET_CATEGORY = 'SET_TARGET_CATEGORY';

export const setIsDragging = dragging => ({ 
    type: SET_IS_DRAGGING,
    payload: {
        dragging: dragging
    }
});

export const setDragTargetCategory = category => ({
    type: SET_TARGET_CATEGORY,
    payload: {
        category: category
    }
});