import React, { useEffect, useState } from 'react';
import APIService from 'Services/APIService';

import { Link } from 'react-router-dom';
import { ActivityItem, EmptyText } from 'Components';

const DashboardActivity = (props) => {

    const [ loading, setLoading ] = useState(true);
    const [ activity, setActivity ] = useState([]);

	useEffect(() => {
        APIService.get('/activity/', { limit: 5 })
            .then(response => {
                setActivity(response.activity);
            })
            .catch(error => {

            })
            .finally(() => { setLoading(false); });
    }, [ ]);

    let content = <EmptyText text="No recent activity" />;

    if( activity.length )
    {
        content = (
            <>
                {activity.map((item, index) => ( <ActivityItem key={ index } activity={ item } /> ))}

                <div className="text-center mt20">
                    <Link className="btn btn-link" to="/settings/activity/">
                        See all activity
                    </Link>
                </div>
            </>
        );
    }
    
	return (
        <div className={ `dashboard-activity ${(loading) ? ' loading' : ''}` }>
            { content }
        </div>
	);
}

export default DashboardActivity;