import React from 'react';

const Alert = (props) => {

	// Grab the properties:
	const { className, visible, type } = props;

	let classNameArr = [ 'alert', 'alert-icon' ];

	if( type !== undefined )
	{
		classNameArr.push('alert-' + type);
	}

	if( className !== undefined )
	{
		classNameArr.push(className);
	}

	if( visible === false )
	{
		classNameArr.push('alert-off');
	}

	const content = (props.text !== undefined) ? props.text : props.children;

	return (
		<div className={ classNameArr.join(' ') }>
			{ content }
		</div>
	);
}

export default Alert;