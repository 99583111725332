import {    
    FETCH_COLLECTIONS_BEGIN,
    FETCH_COLLECTIONS_SUCCESS,
    FETCH_COLLECTIONS_FAILURE,

    ADD_COLLECTION,
    UPDATE_COLLECTION,
    REMOVE_COLLECTION,

    ADD_CATEGORY,
    UPDATE_CATEGORY,
    UPDATE_CATEGORY_ITEM_COUNT,
    REMOVE_CATEGORY,
    GET_CATEGORY,
    SET_HIERARCHY
} from '../actions/collectionActions';

import produce from 'immer';
  
const initialState = {
    collectionsLoading: false,
    collectionsError: null,
    collections: { },

    itemsLoading: false,
    itemsError: null,
    items: { },

    hierarchy: [ ]
};

const collectionReducer = (state = initialState, action) => {
    switch(action.type)
    {
        case FETCH_COLLECTIONS_BEGIN:
            return {
                ...state,
                collectionsLoading: action.payload.loading,
                collectionsError: null,
                collections: { }
            };

        case FETCH_COLLECTIONS_SUCCESS:
            return {
                ...state,
                collectionsLoading: false,
                collections: action.payload.collections
            };

        case FETCH_COLLECTIONS_FAILURE:
            return {
                ...state,
                collectionsLoading: false,
                collectionsError: action.payload.error,
                collections: { },
            };

        case ADD_COLLECTION:
            return produce(state, draft => {
                draft.collections[action.payload.collection.id] = action.payload.collection
            });

        case UPDATE_COLLECTION:
            return produce(state, draft => {
                draft.collections[action.payload.id] = action.payload.collection
            });

        case REMOVE_COLLECTION:
            return produce(state, draft => {
                delete draft.collections[action.payload.id];
            });
        
        case ADD_CATEGORY:
            return produce(state, draft => {
                let cats = draft.collections[action.payload.collectionId].categories;
                const hierarchy = action.payload.category.hierarchy;
                
                for( let i = 0; i <= (hierarchy.length - 1); i++)
                {
                    cats = cats[hierarchy[i]].children;
                }

                cats[action.payload.id] = action.payload.category;
            });

        case UPDATE_CATEGORY:
            return produce(state, draft => {
                let cats = draft.collections[action.payload.collectionId].categories;
                const hierarchy = action.payload.category.hierarchy;
                
                for( let i = 0; i <= (hierarchy.length - 1); i++)
                {
                    cats = cats[hierarchy[i]].children;
                }

                cats[action.payload.id] = action.payload.category;
            });

        case UPDATE_CATEGORY_ITEM_COUNT:
            return produce(state, draft => {
                let cats = draft.collections[action.payload.collectionId].categories;
                const hierarchy = action.payload.category.hierarchy;
                
                for( let i = 0; i <= (hierarchy.length - 1); i++)
                {
                    cats = cats[hierarchy[i]].children;
                }

                cats[action.payload.id].numItems = cats[action.payload.id].numItems + action.payload.difference;
            });

        case REMOVE_CATEGORY:
            return produce(state, draft => {
                let cats = draft.collections[action.payload.collectionId].categories;
                const hierarchy = action.payload.category.hierarchy;
                
                for( let i = 0; i <= (hierarchy.length - 1); i++)
                {
                    cats = cats[hierarchy[i]].children;
                }

                delete cats[action.payload.id];
            });

        case SET_HIERARCHY:
            return {
                ...state,
                hierarchy: action.payload.hierarchy
            };

        default:
            return state;
    }
};

export default collectionReducer;