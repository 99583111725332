import React, { useEffect, useState } from 'react';
import { APIService } from 'Services';
import { objectSelector } from 'store/selectors';
import storage from 'store/localstorage';
import { useToasts } from 'react-toast-notifications';
import produce from 'immer';

import { 
    CurrencyFormat, 
    DateFormat,

    Dropdown, 
    Icon, 
    PageHeader, 
    ButtonGroup, 
    Button, 
    EmptyState,
    
    DataTable, 
    DataTableCell, 
    DataTableHeader, 
    DataTableHeaderCell, 
    DataTableRow, 
    DataTableBody
} from 'Components';

import GridItem from './MarketplaceGridItem';

const Favourites = props => {

    const { addToast } = useToasts();

    const [ loading, setLoading ] = useState(true);
    const [ favourites, setFavourites ] = useState([ ]);
    const [ view, setView ] = useState(storage.get('favourite-view', 'grid'));

    // Pull in the favourites:
    useEffect(() => { 
        APIService.get('/marketplace/favourites/')
            .then(resp => { setFavourites(resp.favourites); })
            .finally(() => { setLoading(false); });
    }, [ ]);

    // Handle the view change:
    const handleViewChange = (e, view) => {
        e.preventDefault();
        setView(view);
        localStorage.setItem('favourite-view', view);
    };

    // Remove a favourite:
    const removeFavourite = item => {
        const endpoint = `/marketplace/favourite/${item.id}`;

        APIService.delete(endpoint)
            .then(() => { 

                // Update the favourites:
                setFavourites(produce(favourites, draft => {
                    delete draft[`favourite_${item.id}`];
                }));

                addToast('Favourite successfully removed.', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                const message =  error.response.status ? error.response.status : 'There was a problem removing the favourite. Please try again.';

                addToast(message, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    };

    let content;

    let actionButtons = null; 

    // There are no favourites, and it's loaded:
    if( favourites.length === 0 && loading === false )
    {
        content = (
            <EmptyState 
                title="No Favourites!"
                description="You haven't added any favourite items from the Marketplace. Browse the Marketplace to find your next addition!"
                image="EmptyCollection"
                button={  
                    <Button 
                        href="https://marketplace.kolekto.io/" 
                        title="Browse the Kolekto Marketplace" 
                        target="_blank"
                        className="btn-rounded btn-primary btn-upper"
                        text="Browse Marketplace" />
                } />
        );
    }

    // There are some items:
    else if( loading === false )
    {
        // Set the action buttons:
        actionButtons = (
            <ButtonGroup>
                <Button 
                    className={ `btn-sm icon-only ${view === 'list' ? 'btn-active' : ''}` } 
                    icon="list"
                    onClick={ (e) => { handleViewChange(e, 'list') }} />
                <Button 
                    className={ `btn-sm icon-only ${view === 'grid' ? 'btn-active' : ''}` } 
                    icon="th"
                    onClick={ (e) => { handleViewChange(e, 'grid') }} />
            </ButtonGroup>
        );

        if( view === 'grid' )
        {
            content = <div className="marketplace-item-grid">
                        { objectSelector(favourites).map((favourite, i) => 
                            <div className="marketplace-item-grid-column">
                                <GridItem 
                                    actions={ 
                                        <>
                                            <Button 
                                                icon="eye"
                                                href={ favourite.item.url }
                                                target="_blank"
                                                className="btn-icon-white mr10"
                                                title={ `View item: ${favourite.item.title}`} />

                                            <Button
                                                onClick={(e) => { removeFavourite(favourite); }}
                                                icon="trash"
                                                className="btn-icon-danger"
                                                title="Remove item from My Favourites" />
                                        </>
                                    }
                                    onDelete={() => { removeFavourite(favourite) }}
                                    key={ i } 
                                    id={ favourite.id }
                                    url={ favourite.item.url }
                                    seller={ favourite.item.seller }
                                    title={ favourite.item.title }
                                    thumbnail={ favourite.item.images.length ? favourite.item.images[0].thumbnail : null } />
                            </div>
                        )}
                    </div>
        }
        else
        {
            content = <DataTable>
                        <DataTableHeader>
                            <DataTableHeaderCell>Item Info</DataTableHeaderCell>
                            <DataTableHeaderCell>Price</DataTableHeaderCell>
                            <DataTableHeaderCell>Seller</DataTableHeaderCell>
                            <DataTableHeaderCell>Added</DataTableHeaderCell>
                            <DataTableHeaderCell>&nbsp;</DataTableHeaderCell>
                        </DataTableHeader>
                        <DataTableBody>
                            { objectSelector(favourites).map((item, i) => 
                                <DataTableRow key={ i }>
                                    <DataTableCell>
                                        <div className="item-info">
                                            { (item.item.images.length) ? <img className="item-thumb marketplace-table_image" alt={ item.item.title } src={ item.item.images[0].thumbnail } /> : null }
                                            <span className="marketplace-table_title">{ item.item.title }</span>
                                        </div>
                                    </DataTableCell>
                                    <DataTableCell className="nowrap"><CurrencyFormat currency={ item.item.currency } value={ item.item.price } /></DataTableCell>
                                    <DataTableCell className="nowrap">{ item.item.seller.fullName }</DataTableCell>
                                    <DataTableCell className="nowrap"><DateFormat date={ item.created } /></DataTableCell>
                                    <DataTableCell>
                                        <Dropdown
                                            position="bottom-end"
                                            className="dropdown-btn"
                                            size="sm"
                                            toggleClassName="btn-actions"
                                            toggleContent={ <Icon name="ellipsis-h" /> }
                                            items={[
                                                { icon: 'eye', text: 'View item', link: item.item.url, target: '_blank' },
                                                { sep: true },
                                                { icon: 'trash', text: 'Remove favourite', className: 'dropdown-menu_item-danger', onClick: (() => { removeFavourite(item) }) }
                                            ]} />
                                    </DataTableCell>
                                </DataTableRow>
                            )}
                        </DataTableBody>
                    </DataTable>;
        }
    };
    
    return (
        <div className="marketplace-page">
            <PageHeader 
                sticky={ true } 
                title="My Favourites"
                buttons={ actionButtons } />

            <div className={ `marketplace-page_content ${loading ? 'loading' : ''}` }>
                { content }
            </div>
        </div>
    );
};

export default Favourites;