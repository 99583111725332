import { React, useState } from 'react';
import { useId } from 'react-id-generator';

const SelectElement = (props) => {

	const { onChange } = props;
	const options = (props.options === undefined) ? [ ] : props.options;

	const [ htmlId ] = useId();

	const handleChange = e => {
		if( onChange !== undefined )
		{
			onChange.call(null, e);
		}
    };
    
    const handleFocus = event => {
        if( typeof props.onFocus === 'function' )
        {
            props.onFocus(event);
        }
    };

    const handleBlur = event => {
        if( typeof props.onBlur === 'function' )
        {
            props.onBlur(event);
        }
    };
	
	return (
		<select 
			id={ htmlId } 
			value={(props.value || '')} 
			onFocus={(e) => { handleFocus(e) }} 
			onBlur={(e) => { handleBlur(e) }}
			className="select" 
			onChange={(e) => { handleChange(e) }}
		>
			{ options.map((option, key) => ( <option value={ option.value } key={ key }>{ option.text }</option> ))}
		</select>
	)
}

export default SelectElement;