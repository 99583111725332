import { React, useEffect, useState } from 'react';
import { Row, Column, Alert, Modal, Input, InputGroup } from 'Components';
import { APIService } from 'Services';

const TwoFactorModal = props => {

    const { onSuccess, onComplete, visible, loading, error } = props;

    // State vars:
    const [ inputState, setInputState ] = useState({
        0: { focused: false, value: '' },
        1: { focused: false, value: '' },
        2: { focused: false, value: '' },
        3: { focused: false, value: '' },
        4: { focused: false, value: '' },
        5: { focused: false, value: '' }
    });

    const [ modalLoading, setModalLoading ] = useState(false);

    const [ alertVisible, setAlertVisible ] = useState(false);
    const [ alertClass, setAlertClass ] = useState(null);
    const [ alertText, setAlertText ] = useState(null);

    const title = (props.title === undefined) ? 'Authentication' : props.title;

    // Update state index:
    const updateInput = (key, prop, value) => {
        setInputState(prevState => ({
            ...prevState,
            [key]: {
                ...prevState[key],
                [prop]: value
            }
        }));
    };

    // Reset the input state:
    const resetInputState = () => {
        // Reset the state:
        setInputState([
            { focused: false, value: '' },
            { focused: false, value: '' },
            { focused: false, value: '' },
            { focused: false, value: '' },
            { focused: false, value: '' },
            { focused: false, value: '' }
        ]);
    }

    // Trigger focus on the first input:
    useEffect(() => { 
        if(props.visible) 
        {
            setModalLoading(false);
            window.setTimeout(() => { updateInput(0, 'focused', true) }, 200) 
        }
    }, [ visible ]);

    // Check if it's complete:
    useEffect(() => {

        const code = [
            inputState[0].value, 
            inputState[1].value,
            inputState[2].value,
            inputState[3].value,
            inputState[4].value,
            inputState[5].value
        ].join('');
    
        if( code.length === 6  && !modalLoading && typeof onSuccess === 'function' )
        {
            setModalLoading(true);

            APIService
                .get('/user/2fa/google/verify/', { code: code })
                .then(response => {
                    if( typeof props.onSuccess === 'function' )
                    {
                        props.onSuccess(
                            code
                        );
                    };
                })
                .catch(error => {
                    switch( error.response.status )
                    {
                        case 400:
                            setAlertClass('danger');
                            setAlertText('Incorrect passcode, please try again.');
                            setAlertVisible(true);
                            break;
                        
                        default:
                            setAlertClass('danger');
                            setAlertText('Something went wrong. Please try again.');
                            setAlertVisible(true);
                            break;
                    }

                    resetInputState();
                    setModalLoading(false);
                    window.setTimeout(() => { updateInput(0, 'focused', true) }, 200);
                });
        }
        else if( code.length === 6 && !modalLoading )
        {
            if( typeof onComplete === 'function' )
            {
                onComplete(code);
            }
        }

    }, [ inputState ]);

    // Update loading:
    useEffect(() => {
        setModalLoading(loading);
    }, [ loading ]);

    // Handle error:
    useEffect(() => {
        if( error )
        {
            setAlertVisible(true);
            setAlertClass('danger');
            setAlertText(error);

            resetInputState();
            updateInput(0, 'focused', true);
        }
    }, [ error ]);

    const handleClose = e => {
        resetInputState();
        setModalLoading(false);

        if( typeof props.onClose === 'function' )
        {
            props.onClose.call(null, e);
        }
    };

    const handleChange = (e, index) => {
        setAlertVisible(false);
        updateInput(index, 'value', e.target.value);

        if( e.target.value.trim() !== '' )
        {
            const nextIndex = (index < 5) ? index + 1 : false;

            if( nextIndex !== false )
            {
                updateInput(index, 'focused', false);
                updateInput(nextIndex, 'focused', true);
            }
        }
    };

    const handleKeyUp = (e, index) => {
        switch(e.keyCode)
        {
            // Backspace:
            case 8:
                if( index > 0 && e.target.value === '' )
                {
                    updateInput(index, 'focused', false);
                    updateInput(index - 1, 'focused', true);
                }
                break;
            
            // Left arrow:
            case 37:
                if( index > 0 )
                {
                    updateInput(index, 'focused', false);
                    updateInput(index - 1, 'focused', true);
                }
                break;
            
            case 39:
                if( index < 5 && e.target.value !== '' )
                {
                    updateInput(index, 'focused', false);
                    updateInput(index + 1, 'focused', true);
                }
                break;
            
            default:
                e.preventDefault();
                break;
        }
    };

    return (
        <Modal loading={ modalLoading } size="small" title={ title } visible={ props.visible } onClose={ handleClose }>
            <p>{ props.text }</p>

            <Alert className={ `alert-${alertClass} alert-icon mb25` } visible={alertVisible}>
                { alertText }
            </Alert>

            <Row className="row-narrow">
                <Column>
                    <InputGroup size="xl">
                        <Input 
                            maxLength={1}
                            focused={ inputState[0].focused } 
                            className="text-center" 
                            onChange={(e) => { handleChange(e, 0) }} 
                            onKeyUp={(e) => { handleKeyUp(e, 0) }} 
                            onBlur={(e) => { updateInput(0, 'focused', false) }}
                            value={ inputState[0].value } />

                        <Input 
                            maxLength={1}
                            focused={ inputState[1].focused } 
                            className="text-center" 
                            onChange={(e) => { handleChange(e, 1) }} 
                            onKeyUp={(e) => { handleKeyUp(e, 1) }} 
                            onBlur={(e) => { updateInput(1, 'focused', false) }}
                            value={ inputState[1].value } />

                        <Input 
                            maxLength={1}
                            focused={ inputState[2].focused } 
                            className="text-center" 
                            onChange={(e) => { handleChange(e, 2) }} 
                            onKeyUp={(e) => { handleKeyUp(e, 2) }} 
                            onBlur={(e) => { updateInput(2, 'focused', false) }}
                            value={ inputState[2].value } />
                    </InputGroup>
                </Column>
                <Column>
                    <InputGroup size="xl">
                        <Input 
                            maxLength={1}
                            focused={ inputState[3].focused } 
                            className="text-center" 
                            onChange={(e) => { handleChange(e, 3) }} 
                            onKeyUp={(e) => { handleKeyUp(e, 3) }} 
                            onBlur={(e) => { updateInput(3, 'focused', false) }}
                            value={ inputState[3].value } />

                        <Input 
                            maxLength={1}
                            focused={ inputState[4].focused } 
                            className="text-center" 
                            onChange={(e) => { handleChange(e, 4) }} 
                            onKeyUp={(e) => { handleKeyUp(e, 4) }} 
                            onBlur={(e) => { updateInput(4, 'focused', false) }}
                            value={ inputState[4].value } />

                        <Input 
                            maxLength={1}
                            focused={ inputState[5].focused } 
                            className="text-center" 
                            onChange={(e) => { handleChange(e, 5) }} 
                            onKeyUp={(e) => { handleKeyUp(e, 5) }} 
                            onBlur={(e) => { updateInput(5, 'focused', false) }}
                            value={ inputState[5].value } />
                    </InputGroup>
                </Column>
            </Row>
        </Modal>
    );
};

export default TwoFactorModal;