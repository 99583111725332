import React from 'react';

const InvoiceAddress = props => {

    const  { title, address } = props;

    const lines = [ 
        address.name, 
        address.organization,
        address.line1, 
        address.line2, 
        address.city, 
        address.county, 
        address.postcode, 
        address.country 
    ];

    return (
        <div className="invoice-address">
            <span className="invoice-address-title">{ title }:</span>
            <address>
                {lines.filter(line => (line !== null && line !== '')).map((line, i) =>
                    <span key={i} className="address-line">{ line }</span>
                )}
            </address>
        </div>
    );
};

export default InvoiceAddress;