import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { objectSelector } from 'store/selectors';
import { Link } from 'react-router-dom';
import { setDragTargetCategory } from 'store/actions/dragActions';

import { DateFormat, NumberFormat, EmptyStateText, Dropdown, Icon } from 'Components';
import { openAddSubcategory, openDeleteCategory, openEditCategory, openEntityInfo } from 'store/actions/modalActions';

const CategoryNavItem = (props) => {

    const dispatch = useDispatch();

    const { collection, category, depth, url } = props;
    const [ isActive, setIsActive ] = useState(false);
    const [ isOpen, setIsOpen ] = useState(false);
    const [ restoreOpen, setRestoreOpen] = useState(null);

    const { collections, hierarchy } = useSelector(state => state.collections);
    const { dragging } = useSelector(state => state.drag);

    const subcategories = objectSelector(category.children);

    const className = [ 'collection-nav_item' ];
    let children = null;

    if( (isOpen && subcategories.length) )
    {
        className.push('collection-nav_item-open');
    }

    // Set isOpen if it's in the hierarchy:
    useEffect(() => { 
        if( hierarchy.lastIndexOf(category.id) === depth ) 
        {
            setIsOpen(true);
        }

        if( subcategories.length === 0 && hierarchy.lastIndexOf(category.id) === depth ) 
        {
            setIsActive(true);
        }
        else
        {
            setIsActive(false);
        }
    }, [ hierarchy, collections, category, subcategories, depth ]);

    // Handle the link
    const selfUrl = url + category.id + '/';
    //const linkTo = (category.numItems === 0 && subcategories.length) ? '#' : url + category.id + '/';
    const linkTo = selfUrl;

    if( subcategories.length )
    {
        className.push('collection-nav_item-has-children');

        children = <div className="collection-nav_item-children">
                       { subcategories.map((cat, i) => (
                       <CategoryNavItem 
                            url={ selfUrl } 
                            category={ cat } 
                            key={ i } 
                            depth={ (depth + 1) } 
                            collection={ collection } /> 
                        )) }
                   </div>;
    }

    const paddingLeft = (15 * (depth - 1) + 45);

    if( isActive === true )
    {
        className.push('collection-nav_item-active');
    }

    // Event handlers:
    const handleMouseEnter = e => {
        e.stopPropagation();
        setRestoreOpen(isOpen);

        if( dragging && !isOpen )  
        {
            setIsOpen(true);
        }
        if( dragging )
        {
            dispatch(setDragTargetCategory(category));
        }
    };

    const handleMouseLeave = e => {
        if( dragging )
        {
            setIsOpen(restoreOpen);
            dispatch(setDragTargetCategory(null));
        }
    };

    return (
        <div className={ className.join(' ') } onMouseEnter={ handleMouseEnter } onMouseLeave={ handleMouseLeave }>
            <div className={ `collection-nav_item-link depth${depth}` } style={{ paddingLeft: `${paddingLeft}px`}}>
                <Link to={ linkTo } onClick={() => { setIsActive(true); setIsOpen(!isOpen) }}>
                    <i className="collection-nav_item-link_icon"></i>
                    <span className="collection-nav_item-link_text">{ category.name }</span>
                </Link>

                <Dropdown
                    toggleClassName="btn-icon-white btn-sm"
                    toggleContent={ <Icon name="ellipsis-h" />}
                    position="bottom-end"
                    size="small"
                    items={[ 
                        { 
                            icon: 'info-circle', 
                            text: 'Get information',
                            onClick: (() => { 
                                dispatch(
                                    openEntityInfo('Category info', [
                                        { 
                                            label: 'Name', 
                                            value: category.name 
                                        },
                                        {
                                            label: 'Added by',
                                            value: category.addedBy.fullName
                                        },
                                        { 
                                            label: 'Subcategory count', 
                                            value: <NumberFormat value={category.numCategories} /> 
                                        },
                                        { 
                                            label: 'Item count', 
                                            value: <NumberFormat value={category.numItems} /> 
                                        },
                                        { 
                                            label: 'Created', 
                                            value: <DateFormat date={category.created} format='user.datetime' /> 
                                        },
                                        { 
                                            label: 'Last modified', 
                                            value: (category.modified === null) ? <EmptyStateText text='Never' /> 
                                                                                : <DateFormat date={category.modified} format='user.datetime' /> }
                                    ])
                                )
                            }) 
                        },
                        { icon: 'pencil', text: 'Edit category', onClick: (() => { dispatch(openEditCategory(category)) }) },
                        { icon: 'folder-plus', text: 'Add subcategory', onClick: (() => { dispatch(openAddSubcategory(category)) }) },
                        { sep: true },
                        { icon: 'trash', text: 'Delete category', onClick: (() => { dispatch(openDeleteCategory(category)) }), className: 'dropdown-menu_item-danger' }
                    ]} />
            </div>
            { children }
        </div>
    )
};

export default CategoryNavItem;