import { React, useEffect } from 'react';
import { Row, Column, DashboardCard, CurrencyFormat } from 'Components';
import StatisticCard from 'Components/Elements/StatisticCard';

import { useDispatch, useSelector } from 'react-redux';
import { fetchMetrics } from 'store/actions/dashboardActions';

const DashboardTotals = (props) => {
	const { totals } = props;

	if (!totals || totals.length === 0) {
		return <></>;
	}

	// Calculate the number of rows:
	const numCols = 4;
	const numRows = Math.ceil(totals.length / numCols);

	return (
		<>
		 	{[...Array(numRows)].map((x, r) =>
				<Row key={r} className='mt20'>
					{[...Array(numCols)].map((y, c) => {
						const index = (r * numCols) + c;

						if (index > totals.length) {
							return <></>;
						}

						return totals[index] && (
							<Column>
								<div className="card" style={{ padding: 20 }}>
									<span class="stat-card__title">{ `Total ${totals[index].name}:` }</span>
									<span class="stat-card__value">
										<CurrencyFormat currency={ totals[index].currency }
											value={ totals[index].value } />
									</span>
								</div>
							</Column>
						);
					})}
				</Row>
  			)}
		</>
	);
};

const DashboardCards = (props) => {

	const dispatch = useDispatch();

	const { metrics, loading } = useSelector(state => state.dashboard);

	useEffect(() => {
		dispatch(fetchMetrics());
	}, [ dispatch ]);

	return (
		<>
			<Row>
				<Column>
					<DashboardCard
						count={metrics.items}
						text="Items"
						icon="album-collection"
						loading={loading} />
				</Column>
				<Column>
					<DashboardCard
						count={metrics.collections}
						text="Collections"
						icon="archive"
						className="secondary"
						loading={loading} />
				</Column>
				<Column>
					<DashboardCard
						count={metrics.marketplaceItems}
						text="Marketplace items"
						icon="store"
						className="success"
						loading={loading} />
				</Column>
			</Row>

			<DashboardTotals totals={metrics.totals} />
		</>
	);
}

export default DashboardCards;
