import React from 'react';
import { NumberFormat } from 'Components';

const DashboardCard = (props) => {

	const { icon, text, count, loading } = props;
	const iconClassName = (props.className === undefined) ? 'icon-primary' : `icon-${props.className}`;

	const className = (loading) ? ' loading' : '';

	return (
		<div className={ `card dashboard-card ${className}` }>
			<span className="dashboard-card_icon">
				<i className={ `icon icon-round ${iconClassName} fas fa-${icon}` }></i>
			</span>
			<span className="dashboard-card_measure">
				<NumberFormat value={count} />
			</span>
			<span className="dashboard-card_item">{ text }</span>
		</div>
	);
}

export default DashboardCard;