import { React, useEffect, useState, useCallback } from 'react';
import { APIService } from 'Services';
import { useToasts } from 'react-toast-notifications'; 

import { Invoice, InvoiceMenuItem } from 'Components/Invoice';

const Invoices = props => {

    const { active } = props;
    const { addToast } = useToasts();

    const [ loading, setLoading ] = useState(true);
    const [ invoiceLoading, setInvoiceLoading ] = useState(false);
    const [ invoices, setInvoices ] = useState([ ]);
    const [ activeInvoice, setActiveInvoice ] = useState(null);
    const [ invoice, setInvoice ] = useState(null);

    useEffect(() => { 
        if (active === true) {
            APIService.get('/invoices/')
                .then(resp => { setInvoices(resp.invoices); })
                .finally(() => { setLoading(false) });
        }

        
    }, [ active ]);

    const showInvoice = useCallback(id => {

        if (id !== activeInvoice) {
            setActiveInvoice(id);
            setInvoiceLoading(true);

            // Load the invoice:
            APIService.get(`/invoice/${id}/`)
                .then(resp => { setInvoice(resp.invoice) })
                .catch(err => { 

                    setActiveInvoice(null);

                    addToast('There was a problem loading the invoice. Please try again.', {
                        appearance: 'error',
                        autoDismiss: true
                    });
                })
                .finally(() => { setInvoiceLoading(false); });
        }
    }, [ activeInvoice ]);

    return (
        <div className={ `settings-invoices-wrapper ${loading ? 'loading' : ''}` }>
            <div className="settings-invoices_menu">
                {invoices.map(invoice => <InvoiceMenuItem active={ activeInvoice } invoice={ invoice } onClick={ showInvoice } /> )}
            </div>
            <div className={ `settings-invoices_content ${invoiceLoading ? 'loading' : ''}` }>
                { (invoice) ? <Invoice invoice={ invoice } /> : null }
            </div>
        </div>
    );
};

export default Invoices;