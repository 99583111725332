import React from 'react';

import { DateFormat, Image } from 'Components';

const ActivityItem = (props) => {

    const className = [ 'activity-item' ];
    const activity = props.activity;

    const getActivityText = activity => {
        switch( activity.type )
        {
            case 'new_login':
                return <>{activity.userName} logged in to Kolekto.</>;

            case 'add_item':
                return <>{activity.userName} added a new item: <b>{activity.data.name}</b> in <b>{activity.data.collection}</b>.</>;

            case 'edit_item':
                return <>{activity.userName} edited an item: <b>{activity.data.name}</b>.</>;

            case 'bulk_move':
                const numItems = activity.data.items.length;
                return <>{activity.userName} moved {numItems} item{numItems === 1 ? '' : 's'} to <b>{activity.data.target}</b></>;

            case 'delete_item':
                return <>{ activity.userName} deleted an item: <b>{activity.data.name}</b></>;

            case 'shared_item':
                return <>{activity.userName} shared an item: <b>{activity.data.name}</b></>;

            case 'unshared_item':
                return <>{activity.userName} unshared an item: <b>{activity.data.name}</b></>;

            case 'add_attribute':
                return <>{ activity.userName } added a new custom attribute: <b>{activity.data.name}</b>.</>;

            case 'edit_attribute':
                return <>{ activity.userName } edited the <b>{activity.data.name}</b> attribute.</>;

            case 'delete_attribute':
                return <>{ activity.userName } deleted a custom attribute: <b>{activity.data.name}</b>.</>;

            case 'add_collection':
                return <>{ activity.userName } created a new collection: <b>{activity.data.name}</b>.</>;

            case 'edit_collection':
                return <>{ activity.userName } edited a collection: <b>{activity.data.name}</b>.</>;

            case 'delete_collection':
                return <>{ activity.userName } deleted a collection: <b>{activity.data.name}</b></>;

            case 'add_category':
                return <>{ activity.userName } created a new category: <b>{activity.data.name}</b></>;

            case 'edit_category':
                return <>{ activity.userName } edited a category: <b>{activity.data.name}</b></>;

            case 'delete_category':
                return <>{ activity.userName } deleted a category: <b>{activity.data.name}</b></>;

            case 'add_subcategory':
                return <>Created a new subcategory: <b>{activity.data.name}</b></>;

            case 'edit_address':
                return <>{ activity.userName } edited account addresses.</>;

            case 'bulk_delete':
                return <>Deleted { activity.data.items.length } item{activity.data.items.length === 1 ? '' : 's'}.</>;

            case 'import_items':
                return (
                    <>
                        Imported {activity.data.num_items} item{activity.data.num_items !== 1 ? 's' : ''} to <b>{activity.data.category} ({activity.data.collection})</b>
                    </>
                );

            default:
                return activity.type;
        }
    };

	if( props.className !== undefined )
	{
		className.push(props.className);
	}

	return (
		<div className={ className.join(' ') }>
			<Image height={40} width={40} className="activity-item_image" src={ activity.image.thumbnail } />
            <span className="activity-item_text">{ getActivityText(activity) }</span>
            <DateFormat className="activity-item_timestamp" date={ activity.timestamp } />
		</div>
	);
}

export default ActivityItem;
