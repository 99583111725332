import APIService from 'Services/APIService';

export const FETCH_CURRENCIES_BEGIN = 'FETCH_CURRENCIES_BEGIN';
export const FETCH_CURRENCIES_SUCCESS = 'FETCH_CURRENCIES_SUCCESS';
export const FETCH_CURRENCIES_FAILURE = 'FETCH_CURRENCIES_FAILURE';

const fetchCurrenciesBegin = () => ({ 
	type: FETCH_CURRENCIES_BEGIN 
});

const fetchCurrenciesSuccess = currencies => ({ 
	type: FETCH_CURRENCIES_SUCCESS, 
	payload: { 
		currencies: currencies
	} 
});

const fetchCurrenciesFailure = error => ({ 
	type: FETCH_CURRENCIES_FAILURE, 
	payload: { 
		error: error 
	} 
});

export const fetchCurrencies = () => {
	return dispatch => {
		dispatch(fetchCurrenciesBegin());

		return APIService.get('/config/currencies/')
			.then(response => { 
				dispatch(fetchCurrenciesSuccess(response.currencies));
				return response;
			})
			.catch(error => {
				return dispatch(fetchCurrenciesFailure(error));
			});
	};
};