import React from 'react';

const ActivityIndicator = (props) => {
    const { text } = props;

    return (
        <div class="activity-indicator">
            <i class="activity-indicator__loader"></i>
            <span class="activity-indicator__text">{ text }</span>
        </div>
    );
};

export default ActivityIndicator;
