import React from 'react';

const FormProgressItem = props => {

    const { number, title, active, done } = props;

    const className = [ 'form-progress_item' ];

    if( active === true )
    {
        className.push('form-progress_item-active');
    }

    if( done === true )
    {
        className.push('form-progress_item-done');
    }

    return (
        <div className={ className.join(' ') }>
            <span className="form-progress_item-number">{ number }</span>
            <span className="form-progress_item-title">{ title }</span>
        </div>
    )
};

export default FormProgressItem;