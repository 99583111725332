import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, Column, EmptyState, PageHeader, Row, Section, Select } from 'Components';
import Utilities from 'Utilities';

import { openAddCollection } from 'store/actions/modalActions';
import { fetchCollections } from 'store/actions/collectionActions';
import { objectSelector } from 'store/selectors';
import ModalImport from 'Components/Modals/ModalImport';
import { useToasts } from 'react-toast-notifications';

const Import = (props) => {

    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const [ importCollectionId, setImportCollectionId ] = useState(null);
    const [ importCategoryId, setImportCategoryId ] = useState(null);
    const [ selectedCollection, setSelectedCollection ] = useState(null);
    const [ selectedCategory, setSelectedCategory ] = useState(null);
    const [ collectionArray, setCollectionArray ] = useState([]);
    const [ selectCollectionOptions, setCollectionOptions ] = useState([]);
    const [ selectCategoryOptions, setCategoryOptions ] = useState([]);
    const [ selectFileDisabled, setSelectFileDisabled ] = useState(true);
    const [ modalVisible, setModalVisible ] = useState(false);
    const [ importFile, setImportFile ] = useState(null);
    const [ selectedFile, setSelectedFile ] = useState(null);
    const { collectionsLoading, collections } = useSelector(state => state.collections);

    // Update the title:
    useEffect(() => document.title = Utilities.getPageTitle('Import'), [ props.location ]);

    // Fetch collections:
    useEffect(() => dispatch(fetchCollections(true)), []);

    // Update collection array:
    useEffect(() => {
        const collectionArray = objectSelector(collections);

        if (collectionArray.length > 0) {
            const collection = collectionArray[0];
            setImportCollectionId(collection.id);
            setSelectedCollection(collection);
            setSelectedCategory(collection?.categories[0]);
        };

        setCollectionArray(collectionArray);
    }, [ collections ]);

    // Update collection options:
    useEffect(() => {
        const options = [];

        collectionArray.forEach((collection) => {
            options.push({
                value: collection.id,
                text: collection.name
            });
        });

        setCollectionOptions(options);
    }, [ collectionArray ]);

    // Update categories:
    useEffect(() => {
        const options = [];

        if (selectedCollection !== null) {
            const categories = objectSelector(selectedCollection.categories);

            categories.forEach((category) => {
                options.push({
                    value: category.id,
                    text: category.name
                });
            });

            if (categories.length > 0) {
                setImportCategoryId(categories[0].id);
                setSelectedCategory(categories[0]);
            }
            else {
                setImportCategoryId(null);
                setSelectedCategory(null);
            }
        }
        else {
            setImportCategoryId(null);
            setImportCollectionId(null);
        }

        setCategoryOptions(options);
    }, [ selectedCollection ]);

    // Update button state:
    useEffect(() => {
        setSelectFileDisabled(
            importCollectionId === null ||
            importCategoryId === null
        );
    }, [ importCollectionId, importCategoryId ]);

    // Handle the changing of the collection:
    const handleSelectCollection = (e) => {
        setSelectedCollection(collections[e.target.value]);
        setImportCollectionId(e.target.value);
    };

    // Handle the changing of the category:
    const handleSelectCategory = (e) => {
        setSelectedCategory(selectedCollection.categories[e.target.value]);
        setImportCategoryId(e.target.value);
    };

    // Handle the selecting of a file:
    const handleFileInput = (e) => {
        if (e.target.files.length === 1) {
            setImportFile(e.target.files[0]);
            setModalVisible(true);
            e.target.value = null;
        }
        else {
            setModalVisible(false);
            setImportFile(null);
        }
    };

    // Called when the import was successful:
    const handleImportSuccess = (imported, skipped) => {
        const message = (skipped > 0)
            ? `Successfully imported ${imported} item${imported !== 1 ? 's' : ''}, skipped ${skipped} item${skipped !== 1 ? 's' : ''}`
            : `Successfully imported ${imported} item${imported !== 1 ? 's' : ''}`;

        addToast(message, {
            appearance: 'success',
            autoDismiss: true
        });

        setModalVisible(false);
    };

	return (
        <div className={`content ${collectionsLoading ? ' loading' : ''}`} id="import">
            <PageHeader sticky={true} title="Import" />

            { collectionArray.length === 0 && (
                <EmptyState
                    image="EmptyCollection"
                    title="No collections"
                    description="In order to import items into Kolekto, you must have at least one collection in your account. Click on the button below to create a new collection now."
                    button={
                        <Button
                            className="btn-primary btn-upper btn-rounded"
                            onClick={(e) => { dispatch(openAddCollection()) }}
                            text="Create collection" />
                    } />
            )}

            { collectionArray.length > 0 && (
                <Section narrow={true}>
                    <p>
                        In addition to adding items manually via the Kolekto dashboard, sometimes you may need to import data from other
                        services or inventory software. You can use the button below to upload an export file from the service of your
                        choice to import your items into Kolekto.
                    </p>
                    <p>
                        Files should be in CSV format (with a <code>.csv</code> extension), with the first row containing the names of the fields
                        for your data. <a href="https://support.kolekto.io/collections/how-to-import-items-into-kolekto/" title="How to create import files for Kolekto" target="_blank">Learn more about import file formats</a>.
                    </p>

                    <Card>
                        <Row>
                            <Column>
                                <Select label='Collection'
                                    help='Select import collection.'
                                    onChange={handleSelectCollection}
                                    options={selectCollectionOptions}
                                    value={importCollectionId} />
                            </Column>
                            <Column>
                                <Select label='Category'
                                    options={selectCategoryOptions}
                                    value={importCategoryId}
                                    onChange={handleSelectCategory} />
                            </Column>
                            <Column>
                                <label className={`btn btn-primary btn-rounded btn-upper ${selectFileDisabled ? 'btn-disabled' : ''}`}>
                                    <input type="file"
                                        style={{ appearance: 'none', visibility: 'hidden', height: 0, width: 0 }}
                                        onChange={handleFileInput}
                                        value={selectedFile}
                                        accept="text/csv" />
                                    Select CSV
                                </label>
                            </Column>
                        </Row>
                    </Card>
                </Section>
            )}

            <ModalImport visible={modalVisible}
                onClose={() => setModalVisible(false)}
                collection={selectedCollection}
                onSuccess={handleImportSuccess}
                category={selectedCategory}
                file={importFile} />
        </div>
	);
};

export default Import;
