import React from 'react';
import SubnavMenuItem from './SubnavMenuItem';

import { Link, useHistory } from 'react-router-dom';
import Icon from '../Icon';

const SubnavMenu = (props) => {

    const items = props?.items || [ ];
    const icon = props?.icon ? <Icon className="subnav-title-icon" name={ props.icon } /> : null;

	return (
		<nav id="subnav">
            { icon }
            { items.map(item => (
                <SubnavMenuItem 
                    label={ item.label }
                    title={ item.title } 
                    icon={ item.icon } 
                    path={ item.path } 
                    className={ item.className }
                    hasDot={ item.hasDot } />) 
            )}
        </nav>
	);
}

export default SubnavMenu;