import React, { useState, useEffect } from 'react';
import produce from 'immer';

const FormSlider = props => {

    const { currentSlide } = props;
    const [ style, setStyle ] = useState({ });

    useEffect(() => { 
        const translate = 0 - (currentSlide * 100);
        setStyle(produce(style, draft => { 
            draft.transform = `translateX(${translate}%)` 
        }));
    }, [ currentSlide ]);

    return (
        <form className="form-slider">
            <div className="form-slider_stage" style={ style }>
                { props.children }
            </div>
        </form>
    );
};

export default FormSlider;