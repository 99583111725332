import { React, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useToasts } from 'react-toast-notifications'

import { Image, ToggleSwitch, Row, Column, FormExplain } from 'Components';
import PasswordModal from 'Components/Modals/Password';
import TwoFactorModal from 'Components/Modals/TwoFactor';
import { APIService, AuthService } from 'Services';

import { set2faEnabled, set2faDisabled } from 'store/actions/userActions';

const TwoFactorAuthentication = (props) => {

    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const { twoFactorEnabled } = useSelector(state => state.user.preferences);
    
    const [ passwordModalVisible, setPasswordModalVisible ] = useState(false);
    const [ passwordModalLoading, setPasswordModalLoading ] = useState(false);

    const [ checked, setChecked ] = useState(twoFactorEnabled);

    const [ twoFactorModalVisible, setTwoFactorModalVisible ] = useState(false);

    const [ qrVisible, setQrVisible ] = useState(false);
    const [ qrSrc, setQrSrc ] = useState(undefined);

    useEffect(() => {
        if( twoFactorEnabled )
        {
            setChecked(true);
            setQrVisible(true);
            setQrSrc(APIService.getAPIUrl('/user/2fa/google/qr.png', { token: AuthService.getToken() }));
        }
        else
        {
            setChecked(false);
            setQrVisible(false);
        }
    }, [ twoFactorEnabled ]);

    const handleChange = e => {
        // They're enabling, ask for their password:
        if( e.target.checked )
        {
            setPasswordModalVisible(true);
        }
        else
        {
            setTwoFactorModalVisible(true);
        }
    };

    const handlePasswordSubmit = (e, password) => {
        setPasswordModalLoading(true);

        // Send off the AJAX request:
        APIService
            .put('/user/2fa/google/', { password: password })
            .then(() => { 
                dispatch(set2faEnabled(true));
                setPasswordModalVisible(false);
            });
    };

    const disableTwoFactor = code => {
        APIService
            .delete('/user/2fa/google/', { code: code })
            .then(() => { 
                dispatch(set2faEnabled(false));
                
                addToast('Account 2FA disabled.', {
                    appearance: 'success',
                    autoDismiss: true,
                });
            })
            .catch(error => { 
                addToast('Could not disable 2FA!', {
                    appearance: 'error',
                    autoDismiss: true,
                });
            })
            .finally(() => { setTwoFactorModalVisible(false); });
    }

    return (
        <>
            <form className="container container-narrow">
                <FormExplain>
                    Two-factor authentication helps to secure your Kolekto account in case your password is compromised.<br />
                    By enabling it, you will be asked to enter a unique code from another device you own to prove it's really you.
                </FormExplain>

                <Row className="row-center text-center">
                    <Column width={ 50 }>

                        <ToggleSwitch
                            className="toggle-inline"
                            checked={ checked }
                            onChange={ handleChange }
                            label="Enable 2-Factor Authentication" />
                        
                        <div style={{ display: (qrVisible ? 'block' : 'none') }}>
                            <Image className="mt35" src={ qrSrc } />
                            <FormExplain>
                                Scan the code in the <a href="https://support.google.com/accounts/answer/1066447?co=GENIE.Platform%3DAndroid&hl=en" target="_blank" title="Learn more about Google Authenticator">Google Authenticator</a> app.
                            </FormExplain>
                        </div>
                    </Column>
                </Row>
            </form>

            <PasswordModal
                visible={ passwordModalVisible }
                loading={ passwordModalLoading }
                onSubmit={(e, password) => handlePasswordSubmit(e, password) }
                onClose={ (e) => { setPasswordModalVisible(false); }} />

            <TwoFactorModal
                text="Enter authenticator code to disable account 2FA."
                visible={ twoFactorModalVisible }
                onSuccess={(code) => { disableTwoFactor(code) }}
                onClose={() => { setTwoFactorModalVisible(false); }}
                />
        </>
    );
};

export default TwoFactorAuthentication;