import { React, useEffect, useState } from 'react';
import { useId } from 'react-id-generator';

import { FormHelp } from 'Components';

const Select = (props) => {

	const { help, label, onChange } = props;
	const options = (props.options === undefined) ? [ ] : props.options;
	const [ value, setValue ] = useState((props.value || ''));

	const initialValue = (props.value === '' || props.value === null || props.value === undefined) ? '' : props.value;

	const [ htmlId ] = useId();

	const className = [ 'form-control', 'input-select' ];

	if (props.className !== undefined) {
		className.push(props.className);
	}

	const handleChange = e => {
		if (onChange !== undefined) {
			onChange.call(null, e);
		}
	};

	const helpElement = (help === undefined)
		? ''
		: <FormHelp text={ help } />;

	let placeholderEle = '';

	if (props.placeholder !== undefined) {
		className.push('has-placeholder');
		placeholderEle = <option value="" style={{ display: 'none' }} disabled>{ props.placeholder }</option>
	}

	const styles = { };

	if (props.visible === false) {
		styles.display = 'none';
	}

	return (
		<div className={ className.join(' ' )} style={ styles }>
			{ helpElement }

            <select id={ htmlId } value={ initialValue } className="input" onChange={(e) => { handleChange(e) }}>
				{ placeholderEle }
				{ options.map((option, key) => (<option value={ option.value } key={ key }>{ option.text }</option>)) }
			</select>

			<label htmlFor={ htmlId }>{ label }</label>
		</div>
	)
}

export default Select;
