import { React, useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import produce from 'immer';

import { closeEditAttribute } from 'store/actions/modalActions';
import { updateAttribute } from 'store/actions/attributeActions';
import { currencySelector } from 'store/selectors';

import APIService from 'Services/APIService';

import { Row, Column, Alert, Modal, Button, Input, Select } from 'Components';

const ModalEditAttribute = (props) => {

    const { editAttribute, attributeToEdit } = useSelector(state => state.modals);
    const { currencies } = useSelector(state => state.config);
    const dispatch = useDispatch();

    // Alert state:
    const [ alertVisible, setAlertVisible ] = useState(false);
    const [ alertClass, setAlertClass ] = useState(null);
    const [ alertText, setAlertText ] = useState('');

    // Button state:
    const [ buttonLoading, setButtonLoading ] = useState(false);

    // Values state:
    const [ name, setName ] = useState('');
    const [ attributeCurrency, setAttributeCurrency ] = useState('');

    // Attriute options:
    const [ attributeOptions, setAttributeOptions ] = useState([]);

    const [ currencyPlaceholder, setCurrencyPlaceholder ] = useState('Currency');

    useEffect(() => { 
        if (attributeToEdit !== null) {
            setName(attributeToEdit.name);
        }

        // Add the options:
        if (attributeToEdit?.type === 'enum') {
            const options = [ ];

            attributeToEdit.metadata.map((value) => {
                options.push({
                    value: value,
                    focus: false
                });
            });

            // Add an empty one:
            options.push({
                value: '',
                focus: false
            });

            setAttributeOptions(options);
        };

        // Currency:
        if (attributeToEdit?.type === 'price') {
            setAttributeCurrency(attributeToEdit.metadata.currency);
            setCurrencyPlaceholder(undefined);
        };  
    }, [ attributeToEdit ]);

    // Show the error alert:
    const showError = message => {
        setAlertVisible(true);
        setAlertText(message);
        setAlertClass('danger');
    };

    // Reset onclose:
    const onBeforeClose = event => {
        setAlertVisible(false);
        setButtonLoading(false);
    };

    // Handle the form submission:
    const handleSubmit = useCallback((e) => {
        e.preventDefault();
        
        if (name === '') {
            showError('Attribute name is required.');
            return;
        }

        if (attributeToEdit?.type === 'price' && attributeCurrency === '') {
            showError('Price attributes require a currency.');
            return;
        }

        if (attributeToEdit?.type === 'enum' && attributeOptions.length === 0) {
            showError('List attributes must have at least one value.');
            return;
        }

        setButtonLoading(true);
        setAlertVisible(false);

        const data = {
            name: name,
            meta: { }
        };

        // Handle the meta:
        if (attributeToEdit?.type === 'price') {
            data.meta.currency = attributeCurrency;
        }

        if (attributeToEdit?.type === 'enum') {
            data.values = [];

            attributeOptions.map(option => { 
                if (option.value !== '') {
                    data.values.push(option.value.trim())
                }

                return attributeOptions;
            });
        };
        

        APIService.put('/attribute/' + attributeToEdit.id, data)
            .then(response => {
                dispatch(updateAttribute(response.attribute.id, response.attribute));
                dispatch(closeEditAttribute());

                if (typeof props.onSuccess === 'function') {
                    props.onSuccess(response.attribute);
                }
            })
            .catch(error => { 
                showError(error.response.data.message);
            })
            .finally(() => {
                setButtonLoading(false);
            }); 
    }, [ attributeToEdit, name ]);

    const handleOptionChange = (e, index) => {
        setAttributeOptions(
            produce(attributeOptions, draft => {
                draft[index].value = e.target.value
            })
        );
    };

    const handleOptionKeydown = (e, index) => {
        if (e.keyCode === 13 && attributeOptions[index] !== '') {
            handleOptionAdd(e, index);
        }
    };

    const handleOptionAdd = (e, index) => {
        e.preventDefault();
        
        setAttributeOptions(
            produce(attributeOptions, draft => {
                draft[index].focus = false;
                draft.push({ value: '', focus: true });
            })
        );
    };

    const handleOptionRemove = (e, index) => {
        e.preventDefault();

        setAttributeOptions(
            produce(attributeOptions, draft => {
                delete draft[index];
            })
        );
    };

    const handleCurrencyChange = e => {
        setAttributeCurrency(e.target.value);
        setCurrencyPlaceholder(undefined);
    };

    const currencyOptions = [ ];
    
    currencySelector(currencies).map(currency => { 
        currencyOptions.push({ 
            value: currency.code, 
            text: currency.name 
        }); 
        
        return currencyOptions;
    });

    return (
        <Modal size="medium" onBeforeOpen={ onBeforeClose } onClose={(e) => { dispatch(closeEditAttribute()) }} title="Edit attribute" visible={ editAttribute }>
            <Alert className={ `alert-${alertClass} alert-icon mb25` } visible={alertVisible}>
                { alertText }
            </Alert>

            <form onSubmit={(e) => { handleSubmit(e); }}>
                <Input 
                    className="my30" 
                    onChange={(e) => { setAlertVisible(false); setName(e.target.value) }}
                    value={ name }
                    label="Name" />

                <Select
                    className="w50 mb25"
                    onChange={(e) => { handleCurrencyChange(e); }}
                    label="Currency"
                    placeholder={ currencyPlaceholder }
                    options={ currencyOptions }
                    value={ attributeCurrency }
                    visible={ attributeToEdit?.type === 'price' } />

                <div style={{ display: (attributeToEdit?.type === 'enum') ? 'block' : 'none' }}>
                    <h3>Options:</h3>
                    { attributeOptions.map((option, key) => {
                            
                            const button = (key === attributeOptions.length - 1)
                                ? <Button onClick={(e) => { handleOptionAdd(e, key) }} disabled={ option.value === '' } icon="plus" className="btn-success btn-square" />
                                : <Button onClick={(e) => { handleOptionRemove(e, key) }} icon="minus" className="btn-danger btn-square" />

                            return (
                                <Row key={ key } className="row-narrow my20">
                                    <Column width="90">
                                        <Input 
                                            focused={ option.focus }
                                            onKeyDown={(e) => { handleOptionKeydown(e, key) }} 
                                            onChange={(e) => { handleOptionChange(e, key); }} 
                                            value={ option.value } 
                                            label="Option value" />
                                    </Column>
                                    <Column>
                                        { button }
                                    </Column>
                                </Row>
                            );
                    }) }
                </div>

                <Button 
                    className="btn-primary btn-upper btn-rounded" 
                    text="Save changes" 
                    loading={ buttonLoading }
                    />
            </form>
        </Modal>
    );
};

export default ModalEditAttribute;