import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { objectSelector } from 'store/selectors';
import { produce } from 'immer';

import { closeAddCollection } from 'store/actions/modalActions';
import { addCollection } from 'store/actions/collectionActions';

import APIService from 'Services/APIService';

import { Alert, Modal, Button, TextArea, Input, ToggleSwitch } from 'Components';

const ModalAddCollection = (props) => {

    const dispatch = useDispatch();

    const addCollectionVisible = useSelector(state => state.modals.addCollection);
    const attributeObjects = useSelector(state => state.attributes.attributes);

    // Alert state:
    const [ alertVisible, setAlertVisible ] = useState(false);
    const [ alertClass, setAlertClass ] = useState(null);
    const [ alertText, setAlertText ] = useState('');

    // Button state:
    const [ buttonLoading, setButtonLoading ] = useState(false);

    // Values state:
    const [ name, setName ] = useState('');
    const [ description, setDescription ] = useState('');
    const [ attributes, setAttributes ] = useState([ ]);
    const [ attributeDOM, setAttributeDOM ] = useState(<></>);
    
    // Reset onclose:
    const onBeforeClose = event => {
        setAlertVisible(false);
        setButtonLoading(false);
        setName('');
        setDescription('');
    };

    // Handle the changing of the attributes:
    const handleAttributeChange = (event, id) => {
        if (event.target.checked === true) {
            setAttributes(
                produce(attributes, draft => { 
                    draft.push(id);
                }
            ));
        } 
        else {
            setAttributes(
                produce(attributes, draft => {
                    draft.splice(attributes.indexOf(id), 1);
                })
            );
        }
    };

    // Handle the form submission:
    const handleSubmit = e => {
        e.preventDefault();
        setButtonLoading(true);
        setAlertVisible(false);

        APIService.post('/collections/', { name: name, description: description, attributes: attributes })
            .then(response => { 
                dispatch(addCollection(response.collection));
                dispatch(closeAddCollection());
            })
            .catch(error => { 
                setAlertVisible(true);
                setAlertClass('danger');
                setAlertText(error.response.data.message);
            })
            .finally(() => setButtonLoading(false));
    };

    useEffect(() => {
        if ( ! objectSelector(attributeObjects).length) {
            setAttributeDOM(
                <></>
            );
        }
        else {
            setAttributeDOM(
                <>
                    <h3 className="my20">Attributes:</h3>

                    <p>Choose which of your custom attributes you want to be made available to items that are added to this collection:</p>

                    <div className="collection-attributes mt25">
                        { objectSelector(attributeObjects).map((attribute, index) => (
                            <ToggleSwitch 
                                label={ attribute.name } 
                                key={ index } 
                                checked={ (attributes.indexOf(attribute.id) > -1) }
                                onChange={(e) => { handleAttributeChange(e, attribute.id) }} />
                        ))}
                    </div>
                </>
            );
        }
    }, [ attributes, attributeObjects ]);

    return (
        <Modal size="medium" onClose={(e) => { dispatch(closeAddCollection()) }} title="Add collection" visible={ addCollectionVisible }>

            <Alert className={ `alert-${alertClass} alert-icon mb25` } visible={alertVisible}>
                { alertText }
            </Alert>

            <form onSubmit={(e) => { handleSubmit(e); }}>
                <Input 
                    className="w75"
                    onChange={(e) => { setName(e.target.value) }}
                    value={ name }
                    label="Name" />
                
                <TextArea
                    height={ 100 }
                    className="mt25"
                    value={ description }
                    onChange={(e) => { setDescription(e.target.value) }}
                    label="Description" />

                { attributeDOM }

                <Button 
                    className="btn-primary btn-upper btn-rounded mt30" 
                    text="Add collection" 
                    loading={ buttonLoading }
                    />
            </form>
        </Modal>
    );
};

export default ModalAddCollection;