import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { objectSelector, collectionSelector } from 'store/selectors';

import { openEditCollection, openEntityInfo, openAddCategory, openDeleteCollection } from 'store/actions/modalActions';

import { Icon, Dropdown, EmptyStateText, DateFormat, NumberFormat } from 'Components';
import { CategoryNavItem } from './';

const CollectionNavItem = props => {
    
    const dispatch = useDispatch();
    const { item } = props;

    // Read the store:
    const { collections, hierarchy } = useSelector(state => state.collections);
    const { dragging } = useSelector(state => state.drag);

    const [ isOpen, setIsOpen ] = useState(false);
    const [ restoreOpen, setRestoreOpen] = useState(null);

    const activeCollection = collectionSelector(collections, hierarchy);

    const categories = (item.categories) ? objectSelector(item.categories) : [];
    const className = [ 'collection-nav_item' ];
    let children = null;

    if( categories.length )
    {
        className.push('collection-nav_item-has-children');

        children = <div className="collection-nav_item-children">
                       { categories.map((cat, i) => { return (
                            <CategoryNavItem 
                                url={ '/collections/' + item.id + '/' }
                                category={ cat } 
                                key={ i }
                                depth={ 1 } 
                                collection={ item } /> 
                            )
                        }) }
                   </div>;
    }    

    if( activeCollection?.id === item.id ) 
    {
        className.push('collection-nav_item-active');
    }

    if( (isOpen && categories.length) )
    {
        className.push('collection-nav_item-open');
    }

    // Set isOpen if it's in the hierarchy:
    useEffect(() => { 
        if( hierarchy.indexOf(item.id) === 0 ) 
        {
            setIsOpen(true);
        }
    }, [ collections, hierarchy, item ]);

    const linkTo = (categories.length) ? '#' : '/collections/' + item.id + '/';

    // Event handlers:
    const handleMouseEnter = e => {
        if( dragging && !isOpen )  
        {
            setRestoreOpen(isOpen);
            setIsOpen(true);
        }
    };

    const handleMouseLeave = e => {
        if( dragging === true )
        {
            //setIsOpen(restoreOpen);
        }
    };

    return (
        <div className={ className.join(' ') } onMouseEnter={ handleMouseEnter } onMouseLeave={ handleMouseLeave }>
            <div className="collection-nav_item-link">
                <Link to={ linkTo } onClick={() => { setIsOpen(!isOpen) }}>
                    <i className="collection-nav_item-link_icon"></i>
                    <span className="collection-nav_item-link_text">{ item.name }</span>
                </Link>

                <Dropdown
                    toggleClassName="btn-icon-white btn-sm"
                    toggleContent={ <Icon name="ellipsis-h" />}
                    position="bottom-end"
                    size="small"
                    items={[
                        { 
                            icon: 'info-circle', 
                            text: 'Get information', 
                            onClick: (() => { 
                                dispatch(
                                    openEntityInfo('Collection info', [
                                        { 
                                            label: 'Name', 
                                            value: item.name 
                                        },
                                        {
                                            label: 'Added by',
                                            value: item.addedBy.fullName
                                        },
                                        { 
                                            label: 'Category count', 
                                            value: <NumberFormat value={item.numCategories} /> 
                                        },
                                        { 
                                            label: 'Item count', 
                                            value: <NumberFormat value={item.numItems} /> 
                                        },
                                        { 
                                            label: 'Created', 
                                            value: <DateFormat date={item.created} format='user.datetime' /> 
                                        },
                                        { 
                                            label: 'Last modified', 
                                            value: (item.modified === null) ? <EmptyStateText text='Never' /> 
                                                                            : <DateFormat date={item.modified} format='user.datetime' /> }
                                    ])
                                )
                            }) 
                        },
                        { 
                            icon: 'pencil', 
                            text: 'Edit collection',
                            onClick: (() => { dispatch(openEditCollection(item));  })
                        },
                        { 
                            icon: 'folder-plus', 
                            text: 'Create category', 
                            onClick: (() => { dispatch(openAddCategory(item)) }) 
                        },
                        { sep: true },
                        { 
                            icon: 'trash', 
                            text: 'Delete collection', 
                            className: 'dropdown-menu_item-danger',
                            onClick: (() => { dispatch(openDeleteCollection(item)) }) 
                        }
                    ]} />
            </div>
            { children }
        </div>
    )
};

export default CollectionNavItem;