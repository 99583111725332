import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import DatePicker from 'react-datepicker';
import { Input, FormHelp } from 'Components';

const Datepicker = (props) => {

    const { label, type, help } = props;
    const { dateFormat, timeFormat } = useSelector(state => state.user.preferences);

    const [ value, setValue ] = useState(props.value);
    const [ inputValue, setInputValue ] = useState('');

    useEffect(() => {
        setValue(props.value);
    }, [ props.value ]);

    const datepickerProps = { };

    switch (type) {
        case 'year':
            datepickerProps['showYearPicker'] = true;
            datepickerProps['dateFormat'] = 'yyyy';
            break;

        case 'date':
        default:
            datepickerProps['dateFormat'] = dateFormat.replace('DD', 'dd');
            break;

        case 'time':
            datepickerProps['showTimeSelect'] = true;
            datepickerProps['dateFormat'] = timeFormat;
            datepickerProps['timeIntervals'] = 1;
            datepickerProps['showTimeSelectOnly'] = true;
            break;

        case 'datetime':
            datepickerProps['showTimeSelect'] = true;
            datepickerProps['timeFormat'] = timeFormat;
            datepickerProps['dateFormat'] = dateFormat.replace('DD', 'dd')+' '+timeFormat;
            datepickerProps['timeIntervals'] = 1;
            break;
    }

    useEffect(() => {
        if (value !== null) {
            const ts = moment(value);
            let formatted = '';

            switch (type) {
                case 'year':
                    formatted = ts.format('YYYY');
                    break;

                case 'date':
                default:
                    formatted = ts.format(dateFormat);
                    break;

                case 'time':
                    formatted = ts.format(timeFormat);
                    break;

                case 'datetime':
                    formatted = ts.format(dateFormat + ' ' + timeFormat);
                    break;
            }

            setInputValue(formatted);
        }
    }, [ type, value ]);

    const InputElement = ({ onClick }) => (
        <Input
            className={ props.className }
            onClick={ onClick }
            label={ label }
            value={ inputValue } />
    );

    const handleChange = date => {
        setValue(date);

        if (typeof props.onChange === 'function') {
            props.onChange(date);
        }
    }

    const helpElement = (help !== undefined) ? <FormHelp text={ help } /> : '';

	return (
        <>
            <DatePicker
                selected={ value }
                onChange={ date => handleChange(date) }
                customInput={ <InputElement /> }
                { ...datepickerProps } />

            { helpElement }
        </>
    );
}

export default Datepicker;
