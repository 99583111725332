import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeDeleteCategory } from 'store/actions/modalActions';
import { fetchCollections, removeCategory, setHierarchy } from 'store/actions/collectionActions';
import { categorySelector } from 'store/selectors';

import APIService from 'Services/APIService';

import { Alert, Modal, Button, Input } from 'Components';

const ModalDeleteCategory = (props) => {

    const dispatch = useDispatch();

    const deleteCategoryVisible = useSelector(state => state.modals.deleteCategory);
    const category = useSelector(state => state.modals.categoryToDelete);
    const { collections, hierarchy } = useSelector(state => state.collections);
    const currentCategory = categorySelector(collections, hierarchy);

    // Alert state:
    const [ alertVisible, setAlertVisible ] = useState(false);
    const [ alertClass, setAlertClass ] = useState(null);
    const [ alertText, setAlertText ] = useState('');

    const [ deleteDisabled, setDeleteDisabled ] = useState(true);
    const [ nameValue, setNameValue ] = useState('');

    // Button state:
    const [ buttonLoading, setButtonLoading ] = useState(false);
    
    // Reset onclose:
    const onBeforeClose = event => {
        setAlertVisible(false);
        setButtonLoading(false);
        setDeleteDisabled(true);
        setNameValue('');
    };

    const handleNameInput = e => {
        const value = e.target.value;
        setNameValue(value);
        setDeleteDisabled(value.trim() !== category?.name);
    };

    // Handle the form submission:
    const handleSubmit = e => {
        e.preventDefault();
        setButtonLoading(true);
        setAlertVisible(false);

        APIService.delete('/category/' + category?.id)
            .then(response => { 
                const redirect = (currentCategory && currentCategory?.id === category?.id);
                
                dispatch(removeCategory(category?.collectionID, category?.id, category));
                dispatch(closeDeleteCategory());

                // It's the current one:
                if( redirect )
                {
                    window.history.replaceState(
                        null, 
                        null, 
                        '/collections/'
                    );

                    dispatch(setHierarchy([ ]));
                    dispatch(fetchCollections(false));
                }
            })
            .catch(error => { 
                setAlertVisible(true);
                setAlertClass('danger');
                setAlertText(error.response.data.message);
            })
            .finally(() => { 
                setButtonLoading(false);
            });
    };

    let content = '';
    let button = '';

    // It's not empty!
    if( category?.numCategories || category?.numItems )
    {
        content = <p>Cannot delete <b>{ category?.name}</b> because it contains subcategories or items. You must delete any subcategories, and delete or move the items before deleting.</p>

        button = <Button 
                    onClick={(e) => { dispatch(closeDeleteCategory()) }}
                    className="btn-primary btn-upper btn-rounded" 
                    text="Dismiss" />
    }
    else
    {
        content = <>
                      <p className="my0">Are you sure you want to delete <b>{ category?.name }</b>? <b>This action is irreversible!</b></p>
                      <p className="mb0 mt10">Enter the category name below to confirm deletion:</p>

                      <Input
                        className="my20"
                        onChange={(e) => { handleNameInput(e) }}
                        value={ nameValue }
                        label="Category name" />
                  </>;

        button = <Button 
                     className="btn-danger btn-upper btn-rounded" 
                     text="Delete category" 
                     onClick={(e) => { handleSubmit(e) }}
                     disabled={ deleteDisabled }
                     loading={ buttonLoading } />
    }

    return (
        <Modal size="medium" onClose={(e) => { onBeforeClose(); dispatch(closeDeleteCategory()) }} title="Delete category" visible={ deleteCategoryVisible }>
            <Alert className={ `alert-${alertClass} alert-icon mb25` } visible={alertVisible}>
                { alertText }
            </Alert>

            { content }
            { button }
        </Modal>
    );
};

export default ModalDeleteCategory;