import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { objectSelector } from 'store/selectors';
import produce from 'immer';

import { 
    Alert,
    Row, 
    Column, 
    Button, 
    Input, 
    CustomAttributeInput,
    ImageUploader
} from 'Components';

const ItemForm = props => {

    const { attributes, buttonLoading, item, buttonText } = props;
    const attributeObjects = useSelector(state => state.attributes.attributes);

    const [ quantity, setQuantity ] = useState(item?.quantity);
    const [ name, setName ] = useState(item?.name);
    const [ imageIDs, setImageIDs ] = useState([ ]);

    // Attribute state:
    const [ itemAttributes, setItemAttributes ] = useState(attributes);
    const [ attributeValues, setAttributeValues ] = useState({ });
    const [ images, setImages ] = useState([ ]);
    const [ itemId, setItemId ] = useState(null);

    // Alert state:
    const [ alertText, setAlertText ] = useState(props.alertText);
    const [ alertClass, setAlertClass ] = useState('danger');
    const [ alertVisible, setAlertVisible ] = useState(props.alertVisible);

    useEffect(() => {
        setAlertText(props.alertText);
        setAlertVisible(props.alertVisible);
    }, [ props.alertText, props.alertVisible ]);

    useEffect(() => {
        
        if( item !== undefined && item !== null )
        {
            setName(item.name);
            setQuantity(item.quantity);
            setItemId(item.id);

            const newAttributeValues =  { };
            const newAttributes = [ ];
            const newImageIds  = [ ];
            const newImages = [ ];

            for(let i in item.attributes)
            {
                newAttributeValues[i] = item.attributes[i].value;
            };

            attributes.map(attr => {
                if( newAttributeValues[attr.id] )
                {
                    attr.value = newAttributeValues[attr.id];
                }

                newAttributes.push(attr);
            });

            item.images.map(img => {
                newImageIds.push(img.id);
                newImages.push(img);
            });

            setItemAttributes(newAttributes);
            setAttributeValues(newAttributeValues);
            setImages(newImages);
            setImageIDs(newImageIds);
        }

        // No item, show empty attributes:
        else
        {
            const newAttributes = [ ];

            attributes.map(attr => {
                newAttributes.push(attr);
            });

            setItemAttributes(newAttributes);
        }
    }, [ attributes ]);

    const handleCancel = e => {
        e.preventDefault();

        // Clear the form data:
        setName('');
        setQuantity('');
        setImages([ ]);
        setImageIDs([ ]);
        setAttributeValues({ });
        
        if( typeof props.onCancel === 'function' )
        {
            props.onCancel(e);
        }
    };

    // Handle changes to the attributes:
    const handleAttributeChange = (id, value) => {
        setAttributeValues(produce(attributeValues, draft => {
            draft[id] = value;
        }));
    };

    // Handle when they upload an image:
    const handleImageUpload = image => { 
        setImageIDs(imageIDs => ( imageIDs.concat(image.id) ));
    };

    // Handle when they remove an image:
    const handleImageRemove = id => { imageIDs.splice(imageIDs.indexOf(id), 1) };

    // Handle upload errors (basically show toast):
    const onUploadError = error => {

    };

    const handleSubmit = e => {
        e.preventDefault();

        if( name === undefined || name.trim() === '' )
        {
            setAlertClass('danger');
            setAlertVisible(true);
            setAlertText('Item name is required.');
        }
        else
        {
            if( typeof props.onSubmit === 'function' )
            {
                // Get the data and pass it back to the onSubmit handler:
                const data = {
                    id: itemId,
                    name: name,
                    quantity: quantity,
                    attributes: attributeValues,
                    images: imageIDs
                };

                // Clear the form data:
                setName('');
                setQuantity('');
                setImages([ ]);
                setImageIDs([ ]);
                setAttributeValues({ });
                
                props.onSubmit(e, data);
            }
        }
    };

    return (
        <div className="item-form_content py40">
            <form onSubmit={(e) => { handleSubmit(e) }}>
                <Row className="row-justify">
                    <Column width={50}>

                        <Alert
                            visible={ alertVisible }
                            type={ alertClass }
                            text={ alertText }
                            className="mb25 alert-icon" />

                        <Row className="mb25">
                            <Column width="75">
                                <Input
                                    onChange={(e) => { setName(e.target.value) }}
                                    value={ name }
                                    className={ name ? 'has-value' : '' }
                                    label="Item name" />
                            </Column>
                            <Column>
                                <Input
                                    type="number"
                                    onChange={ e => { setQuantity(e.target.value) }}
                                    onKeyDown={ e => ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault() }
                                    value={ quantity }
                                    className={ quantity ? 'has-value' : '' }
                                    label="Quantity" />
                            </Column>
                        </Row>

                        {itemAttributes.map((attr, i) => (
                            <CustomAttributeInput
                                key={ i }
                                attribute={ attributeObjects[attr.id] }
                                value={ attributeValues[attr.id] }
                                onChange={(id, value) => { handleAttributeChange(id, value) }}
                                className={ `mb25 ${attributeValues[attr.id] ? 'has-value' : ''}` } />
                        ))}

                        <div className="mt25 item-form_actions">
                            <Button 
                                onClick={ (e) => handleSubmit(e) }
                                disabled={ buttonLoading === true }
                                className={ `btn-primary btn-rounded btn-upper ${buttonLoading === true ? ' loading' : ''}` }
                                text={ buttonText } />

                            <Button
                                onClick={(e) => { handleCancel(e) }}
                                className="btn-text-muted btn-upper"
                                text="Cancel" />
                        </div>
                    </Column>
                    <Column width={45}>
                        <ImageUploader 
                            onUpload={ image => handleImageUpload(image) }
                            onRemove={ id => handleImageRemove(id) }
                            onError={ error => onUploadError(error) }
                            images={ images }
                            columnWidth={25} />
                    </Column>
                </Row>
            </form>
        </div>
    );
};

export default ItemForm;