import React, { useState } from 'react';

const LoginInput = (props) => {
	
	const { autofocus, value, className, label, type, name, required, id, onChange, minLength, maxLength, min, max } = props;

	const handleChange = (event) => {
		if(typeof onChange == 'function')
		{
			onChange.call(
				null,
				event,
				event.target.value
			);
		}
	};

	const getParams = () => {
		let params = { };

		params.type = (type !== undefined) ? type : 'text';
		params.name = name;

		if(id !== undefined) { params.id = id; };
		if(required === true) { params.required = 'required' };

		if(minLength !== undefined) { params.minLength = minLength };
		if(maxLength !== undefined) { params.maxLength = maxLength };

		if(min !== undefined) { params.min = min };
		if(max !== undefined) { params.max = max };

		if( autofocus === true ) { params.autofocus = 'autofocus' };

		return (params);
	};

	return (
		<div className={ `login-form_field ${className}` }>
            <input 
            	className={ `login-form_field-input ${value ? ' has-value' : ''}` }
            	onChange={(e) => { handleChange(e) }}
            	{...getParams()} />

			<label className="login-form_field-label" htmlFor={ id }>{ label }</label>

			<span></span>
		</div>
	);
}

export default LoginInput;