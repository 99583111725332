import { 
    FETCH_METRICS_BEGIN, 
    FETCH_METRICS_SUCCESS, 
    FETCH_METRICS_FAILURE
} from '../actions/dashboardActions';
  
const initialState = {
    metrics: {
        items: 0,
        collections: 0,
        marketplaceItems: 0
    },
    loading: true,
    error: null
};
  
const dashboardReducer = (state = initialState, action) => {
    switch(action.type)
    {
        case FETCH_METRICS_BEGIN:
            return {
                ...state,
                loading: true,
                error: null
            };

        case FETCH_METRICS_SUCCESS:
            return {
                ...state,
                loading: false,
                metrics: action.payload.metrics
            };

        case FETCH_METRICS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
                metrics: {
                    items: 0,
                    collections: 0,
                    marketplaceItems: 0
                }
            };

        default:
            return state;
    };
};

export default dashboardReducer;