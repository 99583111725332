import { React, useEffect } from 'react';

import Utilities from 'Utilities';

import {
    PageHeader,
    Section,
    DashboardCards,
    DashboardActivity,
    DashboardItems
} from 'Components';

const Dashboard = (props) => {

    // Update the title:
    useEffect(() => {
        document.title = Utilities.getPageTitle('Dashboard');
    }, [ props.location ]);

	return (
        <div className="content" id="dashboard">
            <PageHeader
                className="page-header-sticky"
                title="Dashboard" />

            <Section title="Collection summary" narrow={true}>
                <DashboardCards />
            </Section>

            <Section title="Recent items" narrow={true}>
                <DashboardItems />
            </Section>

            <Section title="Recent activity" className="pt20" narrow={true}>
                <DashboardActivity />
            </Section>
        </div>
	);
};

export default Dashboard;
