import React from 'react';

import { Section, Row } from 'Components';
import SettingLink from './SettingLink';

const Settings = (props) => 
{
    return (
        <>
            <Section title="General Preferences" className="section-sm pt0">
                <Row>
                    <SettingLink link="/settings/attributes/" text="Custom Attributes" icon="tags" />
                    <SettingLink link="/settings/activity/" text="Activity History" icon="history" />
                    <SettingLink link="/settings/export/" text="Export Data" icon="file-export" />
                    {/* <SettingLink link="/settings/backups/" text="Backups" icon="cloud-download" /> */}
                </Row>
            </Section>
            <Section title="Account Settings" className="section-sm pt0">
                <Row>
                    <SettingLink link="/settings/addresses/" text="My Addresses" icon="location" />
                    <SettingLink link="/settings/billing/" text="Billing Preferences" icon="credit-card" />
                    <SettingLink link="/settings/subscriptions/" text="Manage Subscription" icon="calendar-alt" />
                    <SettingLink link="/settings/invoices/" text="My Invoices" icon="file-invoice-dollar" />
                    {/* <SettingLink link="/settings/organization/" text="My Organization" icon="building" /> */}
                    {/* <SettingLink link="/settings/additional-users/" text="Additional Users" icon="users" /> */}
                </Row>
            </Section>
            <Section title="Profile Settings" className="section-sm pt0">
                <Row>
                    <SettingLink link="/settings/profile/" text="My Profile" icon="user" />
                    <SettingLink link="/settings/preferences/" text="App Preferences" icon="user-cog" />
                    <SettingLink link="/settings/email-preferences/" text="Email Preferences" icon="envelope" />
                    <SettingLink link="/settings/two-factor-auth/" text="Two Factor Auth" icon="user-lock" />
                </Row>
            </Section>
        </>
    );
};

export default Settings;