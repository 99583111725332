import APIService from 'Services/APIService';

export const FETCH_COLLECTIONS_BEGIN    = 'FETCH_COLLECTIONS_BEGIN';
export const FETCH_COLLECTIONS_SUCCESS  = 'FETCH_COLLECTIONS_SUCCESS';
export const FETCH_COLLECTIONS_FAILURE  = 'FETCH_COLLECTIONS_FAILURE';
export const ADD_COLLECTION             = 'ADD_COLLECTION';
export const UPDATE_COLLECTION          = 'UPDATE_COLLECTION';
export const REMOVE_COLLECTION          = 'REMOVE_COLLECTION';
export const ADD_CATEGORY               = 'ADD_CATEGORY';
export const UPDATE_CATEGORY            = 'UPDATE_CATEGORY';
export const UPDATE_CATEGORY_ITEM_COUNT = 'UPDATE_CATEGORY_ITEM_COUNT';
export const REMOVE_CATEGORY            = 'REMOVE_CATEGORY';
export const SET_HIERARCHY              = 'SET_HIERARCHY';

const fetchCollectionsBegin = setLoading => ({ 
    type: FETCH_COLLECTIONS_BEGIN,
    payload: {
        loading: (setLoading === null) ? true : setLoading
    }
});

const fetchCollectionsSuccess = collections => ({ 
    type: FETCH_COLLECTIONS_SUCCESS, 
    payload: { 
        collections: collections
    } 
});

const fetchCollectionsFailure = error => ({ 
    type: FETCH_COLLECTIONS_FAILURE, 
    payload: { 
        error: error
    } 
});

export const fetchCollections = setLoading => {
    return dispatch => {
        dispatch(fetchCollectionsBegin(setLoading));

        return APIService.get('/collections/')
            .then(response => {
                return dispatch(fetchCollectionsSuccess(response.collections));
            })
            .catch(error => {
                return dispatch(fetchCollectionsFailure(error));
            });
    };
};

export const addCollection = collection => ({
    type: ADD_COLLECTION,
    payload: {
        collection: collection
    }
});

export const updateCollection = (id, collection) => ({
    type: UPDATE_COLLECTION,
    payload: {
        id: id,
        collection: collection
    }
});

export const removeCollection = id => ({
    type: REMOVE_COLLECTION,
    payload: {
        id: id
    }
});

export const addCategory = (collectionId, id, category) => ({
    type: ADD_CATEGORY,
    payload: {
        collectionId: collectionId,
        id: id,
        category: category
    }
});

export const updateCategory = (collectionId, id, category) => ({
    type: UPDATE_CATEGORY,
    payload: {
        collectionId: collectionId,
        id: id,
        category: category
    }
});

export const updateCategoryItemCount = (collectionId, id, category, difference) => ({
    type: UPDATE_CATEGORY_ITEM_COUNT,
    payload: {
        collectionId: collectionId,
        id: id,
        difference: difference,
        category: category
    }
});

export const removeCategory = (collectionId, id, category) => ({
    type: REMOVE_CATEGORY,
    payload: {
        collectionId: collectionId,
        id: id,
        category: category
    }
});

export const setHierarchy = hierarchy => ({
    type: SET_HIERARCHY,
    payload: {
        hierarchy: hierarchy
    }
});