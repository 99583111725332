import { React, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Utilities from 'Utilities';
import { APIService } from 'Services';
import jwt_decode from 'jwt-decode';
import { useDispatch } from 'react-redux';

import { fetchAvatar, fetchUser } from 'store/actions/userActions';
import { fetchAttributes, fetchAttributeTypes } from 'store/actions/attributeActions';
import { fetchCurrencies } from 'store/actions/configActions';

// Elements:
import LoginForm from 'Components/Forms/Login/Login';
import RegisterForm from 'Components/Forms/Login/Register';
import ForgotPasswordForm from 'Components/Forms/Login/ForgotPassword';

import TwoFactorModal from 'Components/Modals/TwoFactor';

const getClassName = (path) => {
    switch( path )
    {
        case 'login':
        case 'login/':
        default:
            return '';

        case 'register':
        case 'register/':
            return 'login-form_register';

        case 'password-reset':
        case 'password-reset/':
            return 'login-form_reset';
    }
};

const getPageTitle = (path) => {
    switch(path)
    {
        case 'login':
        case 'login/':
        default:
            return 'Login';

        case 'register':
        case 'register/':
            return 'Register';

        case 'password-reset':
        case 'password-reset/':
            return 'Forgotten Password';
    }
};

const LoginPage = (props) => {

    const dispatch = useDispatch();

    const { onLoad } = props;

    // 2FA modal:
    const [ twoFactorModalVisible, setTwoFactorModalVisible ] = useState(false);
    const [ twoFactorModalLoading, setTwoFactorModalLoading ] = useState(false);
    const [ twoFactorToken, setTwoFactorToken ] = useState(null);
    const [ twoFactorService, setTwoFactorService ] = useState(null);
    const [ twoFactorError, setTwoFactorError ] = useState(null);

    // Update the title:
    useEffect(() => {
        document.title = Utilities.getPageTitle(
            getPageTitle(props.location.pathname.substr(1))
        );

        // Stop loading:
        if( typeof onLoad === 'function' ) { onLoad(); }

    }, [ props.location ]);

    // Redirect handler:
    const history = useHistory();

    // Handle 2FA login:
    const handle2FAShow = resp => {
        setTwoFactorModalVisible(true);
        setTwoFactorToken(resp.token);
        setTwoFactorService(resp.provider);
    };

    const handle2FALogin = code => {

        setTwoFactorModalLoading(true);
        setTwoFactorError(null);

        const data = {
            token: twoFactorToken,
            passcode: code
        };

        APIService.post(`/user/2fa/${twoFactorService}/`, data)
            .then(resp => {
                let token = jwt_decode(resp.token);

                localStorage.setItem('token', resp.token)
                sessionStorage.setItem('user', JSON.stringify(token.user));

                dispatch(fetchUser())
                    .then(dispatch(fetchAvatar()))
                    .then(dispatch(fetchAttributeTypes()))
                    .then(dispatch(fetchAttributes()))
                    .then(dispatch(fetchCurrencies()))
                    .then(() => { history.push('/')});
            })
            .catch(error => {
                setTwoFactorError('Incorrect code, please try again.');
                setTwoFactorModalLoading(false);
            });
    }

	return (
		<div id="wrapper">
            <div className="login-container">
                <div className="login-hero">
                    <a href="https://kolek.to/" target="_blank" rel="noreferrer" title="Kolekto - collection inventory app" className="login-brand">
                        <img src="https://ik.imagekit.io/kolekto/static/logo-white_4jm3pSGr5.svg" title="Kolekto - collection inventory app" alt="Kolekto logo" />
                    </a>

                    <div className="login-hero_content">
                        <h1 className="mb30">Catalog Everything!</h1>
                        <p>Whatever you collect, Kolekto makes it easier than ever to document, catalog and grow your collection.</p>
                        <a href="https://www.kolekto.io/" title="Collection inventory software online" target="_blank" className="mt40 btn btn-white btn-outline btn-rounded btn-lg text-upper">Learn more</a>
                    </div>
                </div>
                <div className="login-main">
                	<div className={ `login-form ${getClassName(props.location.pathname.substr(1))}` }>
                        <div className="login-slider">
                        	<div className="login-slide">
                                <h2 className="login-form_title mb20">Sign Up</h2>

                                <p className="login-form_explain mb10">Take full advantage of your Kolekto account with a <b>free trial</b>.</p>
                                <p className="login-form_explain">Already have an account? <Link to="/login">Log in now</Link>.</p>

                                <RegisterForm />
                            </div>

                            <div className="login-slide">
                                <h2 className="login-form_title mb30">Sign In</h2>

                                <p className="login-form_explain mb10">Enter your credentials below to sign in to your Kolekto account.</p>
                                <p className="login-form_explain">Don&rsquo;t have an account? <a href="https://www.kolekto.io/signup/" title="Register your Kolekto account today" target="_blank">Sign up now</a>, and take advantage of your <b>free trial</b>.</p>

                                <LoginForm
                                    onTwoFactorLogin={ handle2FAShow } />
                            </div>

                            <div className="login-slide">
                                <h2 className="login-form_title mb30">Reset Password</h2>

                                <p className="login-form_explain mb10">Enter your email address below to reset your Kolekto password.</p>
                                <p className="login-form_explain">Remembered your password? <Link to="/login">Click here</Link> to sign in.</p>

                                <ForgotPasswordForm />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <TwoFactorModal
                onClose={() => setTwoFactorModalVisible(false) }
                text="Enter the code from your authenticator device"
                error={ twoFactorError }
                loading={ twoFactorModalLoading }
                visible={ twoFactorModalVisible }
                onComplete={ handle2FALogin } />
        </div>
	);
};

export default LoginPage;
