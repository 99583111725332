import React, { useEffect, useState } from 'react';
import { objectSelector } from 'store/selectors';
import produce from 'immer';

import APIService from 'Services/APIService';

import { ActivityIndicator, Alert, Modal, Button, Row, Column, Select } from 'Components';

const getSelectedValue = (attribute, options) => {
    for (const i in options) {
        const option = options[i];
        const name = attribute.toLowerCase();
        const optionName = option.text.toLowerCase();

        if (name == optionName) {
            return option.value;
        }
    }

    return null;
};

const ModalImport = (props) => {
    const { visible, onClose, onSuccess, file, collection, category } = props;

    const [ buttonLoading, setButtonLoading ] = useState(false);
    const [ buttonDisabled, setButtonDisabled ] = useState(false);
    const [ alertClass, setAlertClass ] = useState('danger');
    const [ alertVisible, setAlertVisible ] = useState(false);
    const [ alertText, setAlertText ] = useState(null);
    const [ isUploading, setUploading ] = useState(false);
    const [ showForm, setShowForm ] = useState(true);
    const [ headers, setHeaders ] = useState([]);
    const [ headerOptions, setHeaderOptions ] = useState([]);
    const [ numItems, setNumItems ] = useState(0);
    const [ mappings, setMappings ] = useState({});

    useEffect(() => {
        setUploading(true);
    }, [ file, collection, category ]);

    // Handle file parsing:
    useEffect(() => {
        if (file !== null) {
            setUploading(true);

            const formData = new FormData();
            formData.append('file', file);

            APIService.post('/import/parse/', formData)
                .then((response) => {
                    setUploading(false);
                    setHeaders(response.headers);
                    setNumItems(response.num_items);
                })
                .catch((error) => {
                    setAlertClass('danger');
                    setAlertText(error.response.data.message);
                    setAlertVisible(true);
                })
        }
    }, [ file ]);

    // Set the header options:
    useEffect(() => {
        const options = [{ value: '', text: 'Do not import' }];

        headers.forEach((header) => {
            options.push({
                value: header.index,
                text: header.name
            });
        });

        let mappings = {
            'name': getSelectedValue('name', options),
            'quantity': getSelectedValue('quantity', options)
        };

        objectSelector(collection?.attributes).map((attribute) => {
            mappings[`attr${attribute.id}`] = getSelectedValue(attribute.name, options);
        });

        setHeaderOptions(options);
        setMappings(mappings);
    }, [ headers ]);

    // Check they've added a name mapping
    const handleNameSelect = (e) => {
        setButtonDisabled(e.target.value === '');
        setMappings(produce(draft => { draft.name = e.target.value }));
    };

    // Handle the change of attribute mappings
    const handleChange = (key, value) => {
        setMappings(produce(draft => { draft[key] = value }));
    };

    // Close the modal:
    const handleClose = () => {
        if (typeof onClose === 'function') {
            onClose();
        };
    };

    // Handle the submission:
    const handleSubmit = () => {
        const formData = new FormData();
        setButtonLoading(true);

        formData.append('file', file);
        formData.append('category', category.id);
        formData.append('mappings', JSON.stringify(mappings));

        APIService.post('/import/process/', formData)
            .then((response) => {
                if (typeof onSuccess === 'function') {
                    onSuccess(response.imported, response.skipped);
                };

                setButtonDisabled(false);
                setButtonLoading(false);
                setHeaderOptions([]);
                setMappings({});
                setNumItems(0);
            })
            .catch((error) => {
                setAlertClass('danger');
                setAlertText(error.response.data.message);
                setAlertVisible(true);
            })
            .finally(() => {
                setButtonLoading(false);
            });
    };

    return (
        <Modal size="large" onClose={handleClose} title="Import file" visible={visible}>

            <Alert className={ `alert-${alertClass} alert-icon mb25` } visible={alertVisible}>
                { alertText }
            </Alert>

            { showForm === true && (
                <>
                    { isUploading && (
                        <ActivityIndicator text='Parsing file, please wait...' />
                    )}

                    { isUploading === false && (
                        <>
                            <p>
                                Use the table below to map the fields from your CSV file into the collection attributes for
                                the imported items. If you don't want to import one of the fields, please select "Do not import" from
                                the relevant dropdown list, <b>but you must select a field for the item's name</b>.
                            </p>
                            <div>
                                <Row>
                                    <Column><b>Collection attribute</b></Column>
                                    <Column><b>Import column</b></Column>
                                </Row>
                                <Row className="my15">
                                    <Column>
                                        <span style={{ display: 'block', paddingTop: 5 }}>Item name</span>
                                    </Column>
                                    <Column>
                                        <Select options={headerOptions}
                                            onChange={handleNameSelect}
                                            value={mappings.name} />
                                    </Column>
                                </Row>
                                <Row className="my15">
                                    <Column>
                                        <span style={{ display: 'block', paddingTop: 5 }}>Quantity</span>
                                    </Column>
                                    <Column>
                                        <Select options={[ { value: '', text: 'Set as 1' }, ...headerOptions ]}
                                            onChange={(e) => handleChange('quantity', e.target.value)}
                                            value={mappings.quantity} />
                                    </Column>
                                </Row>
                                { objectSelector(collection?.attributes).map((attribute) => {
                                    return (
                                        <Row className="my15">
                                            <Column>
                                                <span style={{ display: 'block', paddingTop: 5 }}>{attribute.name}</span>
                                            </Column>
                                            <Column>
                                                <Select options={headerOptions}
                                                    onChange={(e) => handleChange(`attr${attribute.id}`, e.target.value)}
                                                    value={mappings[`attr${attribute.id}`]} />
                                            </Column>
                                        </Row>
                                    );
                                }) }
                            </div>

                            <Button className="btn-primary btn-upper btn-rounded mt30"
                                text={`Import ${numItems} item${numItems === 1 ? '' : 's'}`}
                                disabled={buttonDisabled}
                                onClick={handleSubmit}
                                loading={buttonLoading} />
                        </>
                    )}
                </>
            )}
        </Modal>
    );
};

export default ModalImport;
