import React, { useEffect, useState } from 'react';
import { APIService } from 'Services';

import { Alert, Modal, Button, NoteTimeline } from 'Components';

const NotesModal = props => {

    const { visible, onAdd, onDelete, onClose, item } = props;

    const [ noteValue, setNoteValue ] = useState('');
    const [ addButtonLoading, setAddButtonLoading ] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ notes, setNotes ] = useState(item?.notes ?? []);

    // Alert state:
    const [ alertText, setAlertText ] = useState(null);
    const [ alertVisible, setAlertVisible] = useState(false);

    const handleClose = () => {
        // Reset the states:
        setAlertVisible(false);
        setNoteValue('');
        setAddButtonLoading(false);

        if( typeof onClose === 'function' )
        {
            onClose();
        }
    };

    const handleSubmit = e => {
        e.preventDefault();

        const url = `/item/${item?.id}/notes/`;

        setAddButtonLoading(true);
        setAlertVisible(false);

        APIService.post(url, { note: noteValue })
            .then(response => {
                setNotes(response.notes);
                setNoteValue('');

                if( typeof onAdd === 'function' )
                {
                    onDelete(response.notes);
                }
            })
            .catch(error => {
                setAlertVisible(true);
                setAlertText(error.response.data.message);
            })
            .finally(() => { setAddButtonLoading(false) })
    };

    const handleDelete = id => {
        const url = `/item/${item?.id}/note/${id}/`;

        APIService.delete(url)
            .then(response => {
                setNotes(response.notes);

                if( typeof onDelete === 'function' )
                {
                    onDelete(response.notes);
                }
            })
            .catch(error => {
                setAlertVisible(true);
                setAlertText(error.response.data.message);
            });
    }

    useEffect(() => {
        setNotes(item?.notes);
    }, [ item ]);


    return (
        <Modal size="lg" visible={ visible } title="Item notes" subtitle={ item?.name } onClose={ handleClose }>

            <Alert
                className="mb20"
                type="danger"
                text={ alertText }
                visible={ alertVisible } />

            <NoteTimeline
                onDelete={ handleDelete }
                notes={ notes } />

            <div className={ `item-notes mt20 ${loading ? 'loading' : ''}`}>
                <form className="item-notes_form" onSubmit={ handleSubmit }>
                    <textarea
                        required={ true }
                        placeholder="Add a new note"
                        value={ noteValue }
                        onChange={(e) => { setNoteValue(e.target.value) }} />

                    <Button
                        loading={ addButtonLoading }
                        className="btn-primary btn-round"
                        icon="plus" />
                </form>
            </div>
        </Modal>
    );
};

export default NotesModal;
