import { CurrencyFormat } from 'Components';
import React from 'react';

const ModalMarketplaceItem = props => {

    const { item } = props;

    const image = item?.images.length
        ? <img src={ item?.images[0].thumbnail } title={ item?.title } alt={ item?.title } />
        : null;
        
    const price = (item?.acceptOffers)
        ? <>
             <CurrencyFormat currency={ item?.currency || null } value={ item?.price } />
             or best offer
          </>
        : <CurrencyFormat currency={ item?.currency || null } value={ item?.price } />

    return (
        <a href={ item?.url } target="_blank" title="View item in Marketplace" className="modal-marketplace-item">
            <div className="modal-marketplace-item_image">
                { image }
            </div>
            <div className="modal-marketplace-item_about">
                <h4 className="modal-marketplace-item_about-title">{ item?.title }</h4>
                <p class="modal-marketplace-item_about-price">
                    { price }
                </p>
            </div>
        </a>
    )
};

export default ModalMarketplaceItem;