import { React, useEffect, useState } from 'react';
import Utilities from 'Utilities';
import { APIService } from 'Services';
import useDebounce from 'hooks/useDebounce';
import { useDispatch, useSelector } from 'react-redux';
import { openShowItem } from 'store/actions/modalActions';

import { PageHeader, Section } from 'Components';
import SearchInput from './Search/Input';
import SearchResults from './Search/Results';
import ItemViewer from 'Components/Collections/ItemView/ItemViewer';

const Search = (props) => {

    const [ loading, setLoading ] = useState(false);
    const [ results, setResults ] = useState(null);
    const [ query, setQuery ] = useState('');

    const dispatch = useDispatch();
    const debouncedSearchTerm = useDebounce(query, 500);

    // Get the item info from the Store:
    const { showItem, itemToShow } = useSelector(state => state.modals);

    // Update the title:
    useEffect(() => { 
        document.title = Utilities.getPageTitle('Search'); 
    }, [ props.location ]);

    useEffect(() => {
        if( debouncedSearchTerm && debouncedSearchTerm.length > 3 ) 
        {
            setLoading(true);

            // Fire off our API call
            APIService.get('/search/', { query: debouncedSearchTerm, limit: 10 })
                .then(resp => { setResults(resp.results) })
                .catch(() => { setResults([ ]); })
                .finally(() => { setLoading(false) });
        } 
        else 
        {
            setResults(null);
        }
    
    }, [ debouncedSearchTerm ]);

    const handleClick = (e, item) => {
        e.preventDefault();
        dispatch(openShowItem(item));
    };

	return (
        <div className="content" id="search">
            <PageHeader sticky={true} title="Search" />

            <Section className="section-sm pt0">
                <SearchInput onChange={ value => setQuery(value) } />
                
                <SearchResults
                    onClick={ handleClick }
                    loading={ loading }
                    results={ results } />
            </Section>

            <ItemViewer
                visible={ showItem }
                item={ itemToShow } />
        </div>
	);
};

export default Search;