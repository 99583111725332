import { React, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import ValidationService from 'Services/ValidationService';
import AuthService from 'Services/AuthService';
import { fetchAvatar, fetchUser } from 'store/actions/userActions';
import { fetchAttributes, fetchAttributeTypes } from 'store/actions/attributeActions';
import { fetchCurrencies } from 'store/actions/configActions';

// Elements:
import { LoginInput, ToggleSwitch, Button, Alert, Row, Column } from 'Components';

const validator = new ValidationService({
    email: {
        isRequired: { message: 'Email address is required.' },
        type: { data: 'email', message: 'Please enter a valid email address' },
        minLength: { data: 10, message: 'Blah' }
    },
    password: {
        type: { data: 'password' },
        isRequired: { message: 'Please enter your password.'}
    }
});

const LoginForm = props => {

    const dispatch = useDispatch();

	// Login fields:
    const [ email, setEmail ] = useState('');
    const [ password, setPassword ] = useState('');
    const [ remember, setRemember ] = useState(true);

    // Button loading:
    const [ buttonLoading, setButtonLoading ] = useState(false);

    // Alert:
    const [ alertText, setAlertText ] = useState(null);
    const [ alertType, setAlertType ] = useState('danger');
    const [ alertVisible, setAlertVisble ] = useState(false);

    // Redirect handler:
    const history = useHistory();

    // Handle the form submit:
    const handleSubmit = (e) => {
    	e.preventDefault();

    	setButtonLoading(true);

    	let valid = validator.validate({
            email: email,
            password: password
        });

    	// Failed validation, show alert:
        if( valid !== true )
        {
            setAlertText(valid);
            setAlertVisble(true);

            return;
        }

        // Now call the login API:
        AuthService
            .login(email, password, remember)
            .then(response => {

                if( response.twoFactor === true )
                {
                    if( typeof props.onTwoFactorLogin === 'function' )
                    {
                        props.onTwoFactorLogin(response);
                    }
                }
                else
                {
                    dispatch(fetchUser())
                        .then(dispatch(fetchAvatar()))
                        .then(dispatch(fetchAttributeTypes()))
                        .then(dispatch(fetchAttributes()))
                        .then(dispatch(fetchCurrencies()))
                        .then(() => { history.push('/')});
                }
            })
            .catch(error => {
                // Show the alert:
                setAlertText('Incorrect email address or password.');
                setAlertVisble(true);
            })
            .finally(() => {
                setButtonLoading(false);
            });
    };

	return (
        <form id="form-login" className="mt30" onSubmit={(e) => { handleSubmit(e) }}>
            <Alert
                visible={ alertVisible }
                type={ alertType }
                className="mb30 nowrap"
                text={ alertText } />

            <LoginInput
                value={ email }
                onChange={(e) => setEmail(e.target.value)}
                {...validator.getFieldProps('email')}
                id="input-login-email"
                autofocus={ true }
                label="Email address" />

            <LoginInput
                value={ password }
                onChange={(e) => setPassword(e.target.value)}
                {...validator.getFieldProps('password')}
                className="mt25"
                id="input-login-password"
                label="Password" />

            <Row className="mt20">
                <Column>
                    <ToggleSwitch
                        onChange={(e) => setRemember(e.target.checked) }
                        checked={ remember }
                        label="Remember me" />
                </Column>
                <Column className="text-right">
                    <Link className="login_forgot-password" to="/password-reset/">Forgotten password?</Link>
                </Column>
            </Row>

            <Button className={ `btn-shadow btn-primary btn-rounded btn-upper btn-lg w40 mt50 ${(buttonLoading) ? 'btn-loading' : ''}`}>Sign in</Button>
        </form>
	);
};

export default LoginForm;
