import APIService from 'Services/APIService';
import AuthService from 'Services/AuthService';

export const FETCH_AVATAR_BEGIN = 'FETCH_AVATAR_BEGIN';
export const FETCH_AVATAR_SUCCESS = 'FETCH_AVATAR_SUCCESS';
export const FETCH_AVATAR_FAILURE = 'FETCH_AVATAR_FAILURE';
export const SET_AVATAR = 'SET_AVATAR';

export const FETCH_USER_BEGIN = 'FETCH_USER_BEGIN';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';

export const UPDATE_USER_BEGIN = 'UPDATE_USER_BEGIN';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export const UPDATE_PREFS_BEGIN = 'UPDATE_PREFS_BEGIN';
export const UPDATE_PREFS_SUCCESS = 'UPDATE_PREFS_SUCCESS';
export const UPDATE_PREFS_FAILURE = 'UPDATE_PREFS_FAILURE';

export const SET_FORENAME = 'SET_FORENAME';
export const SET_SURNAME = 'SET_SURNAME';
export const SET_EMAIL = 'SET_EMAIL';
export const SET_ABOUT = 'SET_ABOUT';
export const SET_DARK_MODE = 'SET_DARK_MODE';
export const SET_TIMEZONE = 'SET_TIMEZONE';
export const SET_DATE_FORMAT = 'SET_DATE_FORMAT';
export const SET_TIME_FORMAT = 'SET_TIME_FORMAT';
export const SET_2FA_ENABLED = 'SET_2FA_ENABLED';
export const SET_CURRENCY = 'SET_CURRENCY';
export const SET_COUNTRY = 'SET_COUNTRY';
export const SET_WEBSITE = 'SET_WEBSITE';

export const ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD';
export const REMOVE_PAYMENT_METHOD = 'REMOVE_PAYMENT_METHOD';
export const UPDATE_PAYMENT_METHOD = 'UPDATE_PAYMENT_METHOD';

const fetchAvatarBegin = () => ({ 
    type: FETCH_AVATAR_BEGIN 
});

const fetchAvatarSuccess = avatar => ({ 
    type: FETCH_AVATAR_SUCCESS, 
    payload: { 
        avatar
    }  
});

const fetchAvatarFailure = error => ({ 
    type: FETCH_AVATAR_FAILURE, 
    payload: { 
        error 
    } 
});

export const fetchAvatar = () => {
	return dispatch => {
        dispatch(fetchAvatarBegin());
		
		return APIService.get('/user/avatar/')
			.then((response) => { 
				dispatch(fetchAvatarSuccess(response.avatar));
				return response;
			})
			.catch(error => dispatch(fetchAvatarFailure(error)));
	};
};

export const setAvatar = avatar => {
    return dispatch => {
        dispatch({
            type: SET_AVATAR,
            avatar: avatar
        });
    }
};

const fetchUserBegin = setLoading => ({ 
    type: FETCH_USER_BEGIN,
    payload: {
        loading: setLoading
    }
});

const fetchUserSuccess = user => ({
    type: FETCH_USER_SUCCESS,
    payload: {
        user
    }
});

const fetchUserFailure = error => ({
    type: FETCH_USER_FAILURE,
    payload: {
        error
    }
});

export const fetchUser = setLoading => {
    const loading = (setLoading === undefined) ? true : setLoading;

    return dispatch => {
        dispatch(fetchUserBegin(setLoading));

        return AuthService.fetch()
            .then((response) => {
                dispatch(fetchUserSuccess(response));
                return response;
            })
            .catch(error => {
                dispatch(fetchUserFailure(error));
                throw error;
            });
    };
}

const updateUserBegin = () => ({
    type: UPDATE_USER_BEGIN
});

const updateUserSuccess = changes => ({
    type: UPDATE_USER_SUCCESS,
    payload: {
        changes
    }
});

const updateUserFailure = error => ({
    type: UPDATE_USER_FAILURE,
    payload: {
        error: error
    }
});

export const updateUser = (data) => {
    return dispatch => {
        dispatch(updateUserBegin());

        return APIService
            .put('/user/', data)
            .then((response) => dispatch(fetchUser(false)) )
            .catch(error => dispatch(updateUserFailure(error)));
    };
};

const updatePrefsBegin = () => ({
    type: UPDATE_PREFS_BEGIN
});

const updatePrefsSuccess = changes => ({
    type: UPDATE_PREFS_SUCCESS,
    payload: {
        changes
    }
});

const updatePrefsFailure = error => ({
    type: UPDATE_PREFS_FAILURE,
    payload: {
        error: error
    }
});

export const updateUserPrefs = (data) => {
    return dispatch => {
        dispatch(updatePrefsBegin());

        return APIService
            .put('/user/', data)
            .then(response => {
                return dispatch(updatePrefsSuccess(response));
            })
            .catch(error => {
                dispatch(updatePrefsFailure(error));
                throw error;
            });
    };
};

export const setForename = forename => ({
    type: SET_FORENAME,
    forename: forename
});

export const setSurname = surname => ({
    type: SET_SURNAME,
    surname: surname
});

export const setEmail = email => ({
    type: SET_EMAIL,
    email: email
});

export const setAbout = about => ({
    type: SET_ABOUT,
    about: about
});

export const setDarkMode = darkMode => ({
    type: SET_DARK_MODE,
    darkMode: darkMode
});

export const setTimezone = timezone => ({
    type: SET_TIMEZONE,
    timezone: timezone
});

export const setDateFormat = format => ({
    type: SET_DATE_FORMAT,
    format: format
});

export const setTimeFormat = format => ({
    type: SET_TIME_FORMAT,
    format: format
});

export const set2faEnabled = enabled => ({
    type: SET_2FA_ENABLED,
    payload: {
        enabled: enabled
    }
});

export const setCurrency = currency => ({
    type: SET_CURRENCY,
    payload: {
        currency: currency
    }
});

export const setWebsite = website => ({
    type: SET_WEBSITE,
    payload: {
        website: website
    }
});

export const setCountry = country => ({
    type: SET_COUNTRY,
    payload: {
        country: country
    }
});

export const addPaymentMethod = (id, method) => ({
    type: ADD_PAYMENT_METHOD,
    payload: {
        id: id,
        method: method
    }
});

export const removePaymentMethod = id => ({
    type: REMOVE_PAYMENT_METHOD,
    payload: {
        id: id
    }
});

export const updatePaymentMethod = (id, method) => ({
    type: UPDATE_PAYMENT_METHOD,
    payload: {
        id: id,
        method: method
    }
});