import React, { useEffect, useState, useCallback } from 'react';
import { objectSelector } from 'store/selectors';
import { APIService } from 'Services';
import { useToasts } from 'react-toast-notifications';
import produce from 'immer';

import { PageHeader, TabGroup, TabContent, Modal, EmptyState, Button, Alert } from 'Components';

import ModalMarketplaceItem from './ModalItem';
import OfferTable from './OfferTable';
import TextareaAutosize from 'react-textarea-autosize';

const Offers = props => {

    const { addToast } = useToasts();

    const [ loading, setLoading ] = useState(true);
    const [ offers, setOffers ] = useState({ asBuyer: [ ], asSeller: [ ] });

    // Tabs:
    const [ receivedTabActive, setReceivedTabActive ] = useState(true);
    const [ sentTabActive, setSentTabActive ] = useState(false);

    // Content:
    const [ receivedContent, setReceivedContent ] = useState(null);
    const [ sentContent, setSentContent ] = useState(null);

    // Modal:
    const [ contactModalUserType, setContactModalUserType ] = useState(null);
    const [ contactModalUser, setContactModalUser ] = useState(null);
    const [ contactModalItem, setContactModalItem ] = useState(null);
    const [ contactModalVisible, setContactModalVisible ] = useState(false);
    const [ contactModalMessageValue, setContactModalMessageValue ] = useState(null);
    const [ contactModalMessageDisabled, setContactModalMessageDisabled ] = useState(false);
    const [ contactModalButtonLoading, setContactModalButtonLoading ] = useState(false);
    const [ contactModalAlertVisible, setContactModalAlertVisible ] = useState(false);
    const [ contactModalAlertText, setContactModalAlertText ] = useState(null);

    // Message modal:
    const [ messageModalVisible, setMessageModalVisible ] = useState(false);
    const [ messageModalMessage, setMessageModalMessage ] = useState(null);
    const [ messageModalAvatar, setMessageModalAvatar ] = useState(null);
    const [ messageModalSubtitle, setMessageModalSubtitle ] = useState(null);

    // Accept offer modal:
    const [ respondModalVisible, setRespondModalVisible ] = useState(false);
    const [ respondOfferModalButtonLoading, setRespondOfferModalButtonLoading ] = useState(false);
    const [ respondOfferModalAlertVisible, setRespondOfferModalAlertVisible ] = useState(false);
    const [ respondOfferModalAlertText, setRespondOfferModalAlertText ] = useState(null);

    // Offer to respond to:
    const [ offerToRespondTo, setOfferToRespondTo ] = useState(null);

    // Load the offers:
    useEffect(() => {
        APIService.get('/marketplace/offers/')
            .then(resp => { 
                setOffers({
                    asBuyer: resp.asBuyer,
                    asSeller: resp.asSeller
                }); 
            })
            .finally(() => { setLoading(false); });
    }, [ ]);

    // Handle change of tab:
    const handleTabChange = tab => {
        setSentTabActive(tab === 'sent');
        setReceivedTabActive(tab === 'received');
    };

    // Tabs:
    const tabs = [
        { 
            text: 'Offers Received', 
            active: receivedTabActive,
            onClick: () => { handleTabChange('received'); }
        },
        { 
            text: 'Offers Sent', 
            active: sentTabActive,
            onClick: () => { handleTabChange('sent'); }
        }
    ];

    const handleContactModalClose = e => {
        setContactModalVisible(false);
        setContactModalButtonLoading(false);
        setContactModalAlertVisible(false);
        setContactModalMessageValue('');
        setContactModalMessageDisabled(false);
    };

    const handleContactModalChange = e => {
        setContactModalMessageValue(e.target.value);
    };

    const handleContactModalSubmit = useCallback(
        e => {
            e.preventDefault();

            setContactModalAlertVisible(false);

            if( contactModalMessageValue === '' || contactModalMessageValue === null )
            {
                setContactModalAlertVisible(true);
                setContactModalAlertText('Please enter a message');
                return;
            }
            
            setContactModalButtonLoading(true);
            setContactModalMessageDisabled(true);

            const requestData = {
                item: contactModalItem?.id,
                message: contactModalMessageValue,
                recipient: contactModalUser?.uuid
            };

            APIService.post('/conversations/', requestData)
                .then(resp => { 
                    setContactModalAlertVisible(false);
                    setContactModalMessageValue('');
                    setContactModalVisible(false);

                    addToast('Your message was sent successfully.', {
                        appearance: 'success',
                        autoDismiss: true
                    });
                })
                .catch(resp => {
                    const message = resp.response.data.message 
                        ? resp.response.data.message 
                        : 'There was a problem sending your message. Please try again.';

                    setContactModalAlertVisible(true);
                    setContactModalAlertText(message);
                })
                .finally(() => { 
                    setContactModalButtonLoading(false);
                    setContactModalMessageDisabled(false);
                    setContactModalButtonLoading(false);
                });
        }
    , [ contactModalMessageValue, contactModalItem, contactModalUser ]);

    const handleContactModalOpen = (item, user, userType) => {
        setContactModalVisible(true);
        setContactModalUserType(userType);
        setContactModalUser(user);
        setContactModalItem(item);
    };

    const handleMessageModalClose = e => {
        setMessageModalVisible(false);
    };

    const handleMessageModalOpen = offer => {
        setMessageModalSubtitle(offer.item.title);
        setMessageModalAvatar(offer.buyer.avatar.thumbnail);
        setMessageModalMessage(offer.message);
        setMessageModalVisible(true);
    };

    const handleRespondModalClose = e => {
        setRespondModalVisible(false);
    };

    const handleOfferResponse = useCallback((e, accept) => {
        setRespondOfferModalButtonLoading(true);

        const endpoint = `/marketplace/offer/${offerToRespondTo?.id}`;
        const data = { accepted: accept };

        APIService.patch(endpoint, data)
            .then(resp => { 
                // Update the item:
                setOffers(produce(offers, draft => { 
                    draft['asSeller'][`offer_${offerToRespondTo?.id}`].accepted = accept;
                    draft['asSeller'][`offer_${offerToRespondTo?.id}`].declined = (!accept);
                }));

                setOfferToRespondTo(null);
                setRespondModalVisible(false);
                setRespondOfferModalAlertVisible(false);

                const toastText = (accept)
                    ? 'Offer accepted successfully'
                    : 'Offer declined successfully'

                addToast(toastText, {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(resp => { 
                const message = resp.response?.data?.message 
                        ? resp.response.data.message 
                        : 'There was a problem responding to the offer. Please try again.';

                setRespondOfferModalAlertVisible(true);
                setRespondOfferModalAlertText(message);
            })
            .finally(() => {
                setRespondOfferModalButtonLoading(false);
            });
    }, [ offerToRespondTo ]);
    
    useEffect(() => { 
        setReceivedContent(objectSelector(offers.asSeller).length > 0
            ? <OfferTable 
                onContactShow={ (e, item, user, userType) => { handleContactModalOpen(item, user, userType) }} 
                onMessageShow={ offer => { handleMessageModalOpen(offer) } }
                onAccept={ offer => { setOfferToRespondTo(offer); setRespondModalVisible(true); } }
                onDecline={ offer => { setOfferToRespondTo(offer); setRespondModalVisible(true); } }
                showBuyer={ true } 
                offers={ offers.asSeller } />
            : <EmptyState
                image="NoItemsCart"
                title="No offers"
                description="You haven't received any offers for your marketplace items yet. When a potential buyer submits an offer, they will appear here." />
        );

        setSentContent(objectSelector(offers.asBuyer).length > 0
            ? <OfferTable 
                onContactShow={ (e, item, user, userType) => { handleContactModalOpen(item, user, userType) }} 
                showSeller={ true } 
                offers={ offers.asBuyer } />
            : <EmptyState
                image="NoItemsCart"
                title="No offers made"
                description="You haven't made any offers for items in the Kolekto Matketplace. Why not browse the items available to find your next collection piece?"
                button={ 
                    <a href="https://marketplace.kolekto.io/" target="_blank" title="Browse the Kolekto Marketplace" className="btn btn-primary btn-rounded btn-upper">
                        Browse Marketplace
                    </a>
                } />
        );
    }, [ offers ]);
    
    return (
        <div className="marketplace-page">
            <PageHeader sticky={ true } title="My Offers" />
            <div className={ `marketplace-page_content ${loading ? 'loading' : ''}` }>

                <TabGroup tabs={ tabs } />

                <TabContent active={ receivedTabActive }>
                    { receivedContent }
                </TabContent>

                <TabContent active={ sentTabActive }>
                    { sentContent }
                </TabContent>
            </div>

            <Modal onClose={ handleContactModalClose } visible={ contactModalVisible } title={ `Contact ${contactModalUserType}` } subtitle={ contactModalUser?.fullName }>
                <Alert 
                    visible={ contactModalAlertVisible }
                    text={ contactModalAlertText }
                    className="mb20"
                    type="danger" />

                <ModalMarketplaceItem item={ contactModalItem } />

                <div className="form-control my20">
                    <TextareaAutosize 
                        className="input"
                        value={ contactModalMessageValue } 
                        onChange={ handleContactModalChange } 
                        placeholder="Type your message"
                        minRows={ 6 }
                        disabled={ contactModalMessageDisabled } />
                </div>

                <Button
                    loading={ contactModalButtonLoading }
                    text="Send message"
                    className="btn btn-primary btn-rounded btn-upper"
                    onClick={ handleContactModalSubmit } />

                <Button
                    text="Cancel"
                    className="btn btn-text-muted btn-upper"
                    onClick={ handleContactModalClose } />

            </Modal>

            <Modal onClose={ handleMessageModalClose } visible={ messageModalVisible } title="Buyer's Message" subtitle={ messageModalSubtitle }>
                <div className="offer-message">
                    <img src={ messageModalAvatar } className="offer-message_avatar" />
                    <div className="offer-message_message">{ messageModalMessage }</div>
                </div>
            </Modal>

            <Modal onClose={ handleRespondModalClose } visible={ respondModalVisible } title="Respond to Offer">
                <Alert
                    type="danger nowrap"
                    visible={ respondOfferModalAlertVisible }
                    text={ respondOfferModalAlertText } />
                
                <p>The buyer will be notified of your response.</p>

                <p> 
                    By accepting the offer, you are entering a legal agreement with the buyer to sell the item for the 
                    specified offer price.
                </p>

                <Button
                    loading={ respondOfferModalButtonLoading  }
                    className="btn btn-primary btn-rounded btn-upper"
                    text="Accept offer"
                    onClick={ (e) => { handleOfferResponse(e, true) } } />

                <Button
                    loading={ respondOfferModalButtonLoading  }
                    className="btn pull-right btn-danger btn-rounded btn-upper"
                    text="Decline offer"
                    onClick={ (e) => { handleOfferResponse(e, true) } } />

                <Button
                    visible={ ! respondOfferModalButtonLoading }
                    className="btn btn-text-muted btn-upper"
                    onClick={ handleRespondModalClose }
                    text="Cancel" />
            </Modal>
        </div>
    );
};

export default Offers;