import React from 'react';
import { objectSelector } from 'store/selectors';

import { EmptyStateText } from 'Components';
import NoteItem from './NoteItem';

const NoteTimeline = props => {
    const { notes } = props;

    const handleDelete = id => {
        if( typeof props.onDelete === 'function' )
        {
            props.onDelete(id);
        }
    };

    let content =<EmptyStateText style="large" text="No notes" />;

    if( notes && notes.length > 0 )
    {
        content = (
            <>
                {objectSelector(notes).map((note, i) => ( 
                    <NoteItem 
                        onDelete={(id) => { handleDelete(id) }}
                        note={ note } 
                        key={ i } /> 
                ))}
            </>
        );
    }

    return (
        <div className="note-timeline">
            { content }
        </div>
    );
};  

export default NoteTimeline;