import React from 'react';

import { Image, EmptyStateText, DateFormat } from 'Components';

const Results = props => {
    const { loading, results, onClick } = props;
    
    let content = null;

    const handleClick = (e, item) => {
        if( typeof onClick === 'function' )
        {
            onClick(e, item);
        }
    }

    if( results && results.length )
    {
        content = (
            <>
                {results.map((item, index) => {
                    let img = (item.images.length)
                        ? <Image height={40} width={40} className="activity-item_image" src={ item.images[0].thumbnail } />
                        : <></>;

                    return (
                        <div class="activity-item activity-item_hover" index={index} style={{ cursor: 'pointer' }} onClick={(e) => { handleClick(e, item); }}>
                            { img }
                            <span class="activity-item_text">
                                <strong>{ item.name }</strong><br />
                                <span class="text-muted">{ item.categoryHierarchy }</span>
                            </span>
                            <DateFormat className="activity-item_timestamp" date={ item.created } />
                        </div>
                    );
                })}
            </>
        );
    }
    else if( results )
    {
        content = (
            <EmptyStateText text="Search returned no results." />
        );
    }

    return (
        <div className={ `search-results ${loading ? 'loading' : ''} ${!results?.length ? 'no-results' : ''}` }>
            { content }
        </div>
    );
};

export default Results; 