import React, { useState, useEffect, useCallback } from 'react';
import { APIService } from 'Services';
import { objectSelector } from 'store/selectors';
import { useToasts } from 'react-toast-notifications';
import produce from 'immer';

import { Button, FormGroup, Modal, ToggleSwitch } from 'Components';
import Alert from 'Components/Elements/Alert';

const Exports = props => {
    const { addToast } = useToasts();
    const { active } = props;

    const [ loading, setLoading ] = useState(true);
    const [ collections, setCollections ] = useState([ ]);

    // Value state:
    const [ collectionIDs, setCollectionIDs ] = useState([ ]);
    const [ includeImages, setIncludeImages ] = useState(true);
    const [ includeHeaders, setIncludeHeaders ] = useState(true);

    // Button state:
    const [ buttonDisabled, setButtonDisabled ] = useState(false);

    // Modal state:
    const [ modalVisible, setModalVisible ] = useState(false);
    const [ downloadButton, setDownloadButton ] = useState(<Button text="Download Export" className="btn btn-rounded btn-primary btn-upper" loading={ true } />)

    // Alert state:
    const [ alertText, setAlertText ] = useState(null);
    const [ alertVisible, setAlertVisible ] = useState(false);
    const [ alertType, setAlertType ] = useState('success');

    useEffect(() => {
        if (active === true) {
            APIService.get('/collections/')
                .then(resp => {
                    setCollections(resp?.collections);
                })
                .finally(() => setLoading(false));
        }
    }, [ active ]);

    useEffect(() => {
        setButtonDisabled(collectionIDs.length === 0);
    }, [ collectionIDs ]);

    useEffect(() => {
        const ids = [ ];
        objectSelector(collections).map(item => ids.push(item.id));
        setCollectionIDs(ids);
    }, [ collections ]);

    const handleCollectionCheck = useCallback((id) => {
        if (collectionIDs.includes(id) === false) {
            setCollectionIDs(
                produce(collectionIDs, draft => {
                    draft.push(id);
                })
            );
        }
    }, [ collectionIDs ]);

    const handleCollectionUncheck = useCallback((id) => {
        setCollectionIDs(
            produce(collectionIDs, draft => {
                const index = draft.indexOf(id);

                if (index > -1) {
                    draft.splice(index,1);
                }
            })
        );
    }, [ collectionIDs ]);

    const handleImageChange = value => {
        setIncludeImages(value);
    };

    const handleHeaderChange = value => {
        setIncludeHeaders(value);
    };

    const handleSubmit = useCallback(e => {
        e.preventDefault();

        setAlertVisible(false);
        setModalVisible(true);

        setDownloadButton(
            <Button text="Download Export" className="btn btn-rounded btn-primary btn-upper" loading={ true } />
        );

        const data = {
            collections: objectSelector(collectionIDs),
            includeImages: includeImages,
            includeHeaders: includeHeaders
        };

        APIService.post('/exports/', data)
            .then(resp => {
                setDownloadButton(
                    <Button
                        text="Download Export"
                        className="btn btn-rounded btn-primary btn-upper"
                        onClick={() => { setModalVisible(false) }}
                        target="_blank"
                        href={ `https://api.kolekto.io/exports/${resp.filename}` }
                        loading={ false } />
                );
            })
            .catch(error => {
                setAlertVisible(true);
                setAlertText(error.response.data.message ?? 'There was a problem creating your export. Please try again.');
                setAlertType('danger');
                setModalVisible(false);
            });
    }, [ collectionIDs ]);

    return (
        <div className={ (loading) ? 'loading' : '' }>
            <FormGroup title="Export your Kolekto data" description="Exports allow you to easily download all of your collection data from Kolekto. Configure your export using the settings shown here and once your export is completed, we will send you an email to download the data. Once you have created a new export, you may navigate away from this page.">

                <Alert
                    visible={ alertVisible }
                    type={ alertType }
                    className="mb30"
                    text={ alertText } />

                <ToggleSwitch
                    onCheck={ () => handleImageChange(true) }
                    onUncheck={ () => handleImageChange(false) }
                    checked={ true }
                    label="Download item images"
                    help="When enabled, all images you have uploaded to items will be included in the export." />

                <ToggleSwitch
                    onCheck={ () => handleHeaderChange(true)  }
                    onUncheck={ () => handleHeaderChange(false) }
                    checked={ true }
                    label="Include column headers"
                    help="Enabling this option will add column headers to the generated CSV file." />

                <hr />

                <h3 className="mb0">Collections:</h3>
                <p className="mt5 mb30 text-muted">Select the collections that should be included in the exported data:</p>

                {objectSelector(collections).map((collection, i) =>
                    <ToggleSwitch
                        label={ collection.name }
                        checked={ true }
                        className="mb30"
                        value={ collection.id }
                        key={i}
                        onCheck={ handleCollectionCheck }
                        onUncheck={ handleCollectionUncheck } />
                )}

                <Button
                    onClick={ handleSubmit }
                    disabled={ buttonDisabled }
                    text="Create Export"
                    className="mt50 btn btn-rounded btn-primary btn-upper" />
            </FormGroup>

            <Modal closable={ false } title="Running export..." visible={ modalVisible }>
                <p>Your export is currently in progress. Please do not close the window, or use your browser's "Back" button until it has completed.</p>
                <p>Once your export is ready, a download button will appear below.</p>

                { downloadButton }
            </Modal>
        </div>
    );
};

export default Exports;
