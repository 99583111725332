import React from 'react';

const Body = props => {

    const className = (props.className !== undefined) ? props.className : '';

    return (
        <tbody className={ className }>
            { props.children }
        </tbody>
    );
};

export default Body;