import React from 'react';

const PageHeader = (props) => {

    const { title, sticky, buttons, subtitle } = props;
    const className = [ 'page-header' ];

    if( props.className !== undefined )
    {
        className.push(props.className);
    }

    if( sticky === true )
    {
        className.push('page-header-sticky');
    }

    const content = (buttons !== undefined) ? buttons : props.children;

    let subtitleEle = '';

    if( subtitle !== undefined )
    {
        className.push('page-header-has-subtitle');
        subtitleEle = <p className="page-header-subtitle">{ subtitle }</p>;
    }

	return (
		<div className={ className.join(' ') }>
            <div className="container py0">
                <div className="page-header-left">
                    <h1 className="page-title">{ title }</h1>
                    { subtitleEle }
                </div>

                <div className="page-header-right">
                    { content }
                </div>
            </div>
        </div>
	);
}

export default PageHeader;