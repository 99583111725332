import React from 'react';

const EmptyText = (props) => {
    
    const className = [ 'text-center', 'my0', 'color-muted' ];
    const text = (props.text === undefined) ? props.children : props.text;

	return (
		<p className={ className.join(' ') }>
            { text }
        </p>
	);
}

export default EmptyText;