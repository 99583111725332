import  React from 'react';
import { Provider } from 'react-redux';
import { ToastProvider } from 'react-toast-notifications';

import store from 'store';

import Application from 'Application';

// Styles:
import 'App.css';

const Main = () => {
	return (
		<Provider store={ store }>
			<ToastProvider>
				<Application />
			</ToastProvider>
		</Provider>
	);
}

export default Main;