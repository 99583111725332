import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { objectSelector } from 'store/selectors';

import { Select } from 'Components';

const SelectPaymentMethod = props => {

    const { label, onSelect } = props;
    const { paymentMethods } = useSelector(state => state.user);

    const [ options, setOptions ] = useState([ ]);
    const [ selected, setSelected ] = useState('');
    const [ placeholder, setPlaceholder ] = useState(label);

    useEffect(() => { 
        const opts = [ ];

        objectSelector(paymentMethods).map(card => {
            opts.push({
                value: card.id,
                text: `**** **** **** ${card.last4}`
            });
        });

        setOptions(opts);
    }, [ paymentMethods ]);

    const handleChange = e => {
        setSelected(e.target.value);
        setPlaceholder(undefined);

        if( typeof onSelect === 'function' )
        {
            onSelect(paymentMethods[e.target.value]);
        }
    };

    return (options.length) 
        ? <Select onChange={ handleChange } placeholder={ placeholder } label={ label } options={ options } value={ selected } /> 
        : <></>;
};

export default SelectPaymentMethod;