import { 
    FETCH_ADDRESSES_BEGIN,
    FETCH_ADDRESSES_SUCCESS,
    FETCH_ADDRESSES_FAILURE,

    ADD_ADDRESS,
    SET_DEFAULT_ADDRESS,
    SET_ADDRESSES,
    UPDATE_ADDRESS,
    DELETE_ADDRESS,
} from '../actions/addressActions';

import produce from 'immer';
import { objectSelector } from '../selectors';
  
const initialState = {
    addresses: { },
    loading: true,
    error: null
};
  
const addressReducer = (state = initialState, action) => {
    switch(action.type)
    {
        case FETCH_ADDRESSES_BEGIN:
            return {
                ...state,
                loading: action.payload.loading,
                error: null
            };

        case FETCH_ADDRESSES_SUCCESS:
            return {
                ...state,
                loading: false,
                addresses: action.payload.addresses
            };

        case FETCH_ADDRESSES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error
            };

        case ADD_ADDRESS:
            return produce(state, draftState => {
                draftState.addresses[action.payload.address.id] = action.payload.address
            });

        case SET_ADDRESSES:
            return {
                ...state,
                addresses: action.payload.addresses
            };

        case SET_DEFAULT_ADDRESS:
            return {
                ...state,
                addresses: objectSelector(state.addresses).map(item => {
                    if( item.id === action.payload.id )
                    {
                        return { 
                            ...item, 
                            default: true
                        };
                    }

                    return {
                        ...item,
                        default: false
                    };
                })
            };

        case UPDATE_ADDRESS:
            return produce(state, draftState => {
                draftState.addresses[action.payload.id] = action.payload.address;
            });
        
        case DELETE_ADDRESS:
            return produce(state, draftState => {
                delete draftState.addresses[action.payload.id];
            });

        default:
            return state;
    };
};

export default addressReducer;