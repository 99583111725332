import React from 'react';

const Column = (props) => {

	let className = (props.width ? 'col' + props.width : 'col');

	if( props.className !== undefined )
	{
		className+= ' ' + props.className;
	}

	return (
		<div className={ className }>
			{ props.children }
		</div>
	);
}

export default Column;