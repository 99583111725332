import React from 'react';

const EntityInfo = (props) => {

	const { label, value } = props;
    const className = [ 'entity-info' ];

    if( props.className !== undefined ) { className.push(props.className); }

	return (
        <div className={ className.join(' ' )}>
            <span className="entity-info_label">{ label }</span>
            <span className="entity-info_value">{ value }</span>
        </div>
	);
}

export default EntityInfo;