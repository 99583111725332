import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeEditCategory } from 'store/actions/modalActions';
import { updateCategory } from 'store/actions/collectionActions';

import APIService from 'Services/APIService';

import { Alert, Modal, Button, TextArea, Input } from 'Components';

const ModalEditCategory = (props) => {

    const dispatch = useDispatch();
    
    const category = useSelector(state => state.modals.categoryToEdit);
    const editCategoryVisible = useSelector(state => state.modals.editCategory);

    // Alert state:
    const [ alertVisible, setAlertVisible ] = useState(false);
    const [ alertClass, setAlertClass ] = useState(null);
    const [ alertText, setAlertText ] = useState('');

    // Button state:
    const [ buttonLoading, setButtonLoading ] = useState(false);

    // Values state:
    const [ name, setName ] = useState(category?.name);
    const [ description, setDescription ] = useState(category?.description);

    // Set the values:
    useEffect(() => {
        if( category )
        {
            setName(category.name);
            setDescription(category.description);
        }
    }, [ category ]);
    
    // Reset onclose:
    const onBeforeClose = event => {
        setAlertVisible(false);
        setButtonLoading(false);
    };

    // Handle the form submission:
    const handleSubmit = e => {
        e.preventDefault();
        setButtonLoading(true);
        setAlertVisible(false);

        APIService.put(`/category/${category.id}`, { name: name, description: description })
            .then(response => { 
                dispatch(updateCategory(response.category.collectionID, response.category.id, response.category));
                dispatch(closeEditCategory());
            })
            .catch(error => { 
                setAlertVisible(true);
                setAlertClass('danger');
                setAlertText(error.response.data.message);
            })
            .finally(() => setButtonLoading(false));
    };

    return (
        <Modal size="medium" onBeforeOpen={ onBeforeClose } onClose={(e) => { dispatch(closeEditCategory()) }} title="Edit category" visible={ editCategoryVisible }>

            <Alert className={ `alert-${alertClass} alert-icon mb25` } visible={alertVisible}>
                { alertText }
            </Alert>

            <form onSubmit={(e) => { handleSubmit(e); }}>
                <Input 
                    className="w75"
                    onChange={(e) => { setName(e.target.value) }}
                    value={ name }
                    label="Name" />
                
                <TextArea
                    height={ 100 }
                    className="mt25"
                    value={ description }
                    onChange={(e) => { setDescription(e.target.value) }}
                    label="Description" />

                <Button 
                    className="btn-primary btn-upper btn-rounded mt30" 
                    text="Edit category" 
                    loading={ buttonLoading }
                    />
            </form>
        </Modal>
    );
};

export default ModalEditCategory;