import React from 'react';
import { Link } from 'react-router-dom';
import { Icon } from 'Components';

const IconCard = (props) => {

	// Grab the properties:
	const { 
        link, 
        className, 
        loading,
        onClick,
        icon,
        text
    } = props;

	const classNameStr = 'card icon-card ' + (className !== undefined ? className : '') + (loading === true ? ' loading' : '');
    const textElement = (text !== undefined) ? <span className="icon-card_text">{ text }</span> : '';

    const handleClick = (e) => {
        if( onClick !== undefined )
        {
            onClick.call(
                null,
                e
            );
        }
    };

    if( link !== undefined )
    {
        return (
            <Link className={ classNameStr } to={ link } onClick={(e) => { handleClick(e) }}>
                <Icon className="icon-card_icon" name={ icon } />
                { textElement }
            </Link>
        );
    }

	return (
		<div className={ classNameStr } onClick={(e) => { handleClick(e) }}>
			<Icon className="icon-card_icon" name={ icon } />
            { textElement }
		</div>
	);
}

export default IconCard;