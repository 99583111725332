import React, { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import produce from 'immer';
import { APIService } from 'Services';
import { useToasts } from 'react-toast-notifications';
import { lightboxOptions } from 'config';

import SimpleReactLightbox, { SRLWrapper } from 'simple-react-lightbox';
import { Icon, DateFormat, Dropdown, Modal, Button } from 'Components';
import { ComposeForm, Message } from './';

const Conversation = props => {

    const { conversation, onRead, onDelete } = props;
    const [ loading, setLoading ] = useState(false);
    const [ otherUser, setOtherUser ] = useState(null);
    const [ messages, setMessages ] = useState([ ]);

    const [ deleteConfirmVisible, setDeleteConfirmVisible ] = useState(false);
    const [ deleteConfirmLoading, setDeleteConfirmLoading ] = useState(false);

    const [ modalMediaVisible, setModalMediaVisible ] = useState(false);
    const [ modalMediaLoading, setModalMediaLoading ] = useState(true);
    const [ modalMediaItems, setModalMediaItems ] = useState([ ]);

    const user = useSelector(state => state.user);
    const { addToast } = useToasts();
    const bottomRef = useRef();
    
    // Add our class lists:
    useEffect(() => { 
        document.body.classList.add('messenger-view');

        return () => {
            document.body.classList.remove('messenger-view');
        }
    }, [ ]);

    useEffect(() => { 
        if( conversation !== null )
        {
            setDeleteConfirmLoading(false);
            setDeleteConfirmVisible(false);
            
            setLoading(true);
            setOtherUser((conversation.sender.uuid !== user.uuid) ? conversation.sender  : conversation.recipient);

            // Loading the conversation:
            APIService.get('/conversation/' + conversation.id + '/')
                .then(resp => {
                    setMessages(resp.messages);

                    // Mark as read:
                    if(conversation.hasUnreadMessages && typeof onRead === 'function' )
                    {
                        onRead(conversation.id);
                    }
                })
                .catch(error => {

                })
                .finally(() => { setLoading(false); });
        }
    }, [ conversation ]);

    // Whenever messages changes, scroll to the bottom:
    useEffect(() => {
        bottomRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
        });
    }, [ messages ]);

    // Handle the sending:
    const handleSendSuccess = message => {
        setMessages(produce(messages, draft => {
            draft.push(message);
        }));
    };

    // Handle the clicking of "print conversation"
    const handlePrint = e => {
        e.preventDefault();

        window.print();
    };

    // Handle message delete:
    const handleDelete = e => {
        e.preventDefault();
        setDeleteConfirmVisible(true);
    };

    // Close the delete confirm modal:
    const handleDeleteConfirmClose = () => {
        setDeleteConfirmLoading(false);
        setDeleteConfirmVisible(false);
    };

    // They have confirmed that we're deleting:
    const handleDeleteConfirm = e => {
        e.preventDefault();
        setDeleteConfirmLoading(true);

        const endpoint = `/conversation/${conversation?.id}`;

        APIService.delete(endpoint)
            .then(() => { 
                setDeleteConfirmVisible(false);

                addToast('Conversation successfully deleted.', {
                    appearance: 'success',
                    autoDismiss: true
                });
                
                if( typeof onDelete === 'function' )
                {
                    onDelete(conversation?.id);
                }
            })
            .catch(() => {
                addToast('There was a problem deleting the conversation. Please try again.', {
                    appearance: 'error',
                    autoDismiss: true
                });
            })
            .finally(() => { setDeleteConfirmLoading(false); });
    };

    // Show the media modal:
    const handleShowMedia = e => {
        e.preventDefault();
        setModalMediaLoading(true);
        setModalMediaVisible(true);

        const endpoint = `/conversation/${conversation?.id}/media`;

        APIService.get(endpoint)
            .then(resp => {  
                setModalMediaItems(resp.media);
            })
            .catch(() => { 
                setModalMediaVisible(false);
                addToast('Could not retrieve media. Please try again later.', {
                    appearance: 'error',
                    autoDismiss: true
                });
            })
            .finally(() => {
                setModalMediaLoading(false);
            })
    }

    // They have closed the media dialog:
    const handleCloseMedia = e => {
        setModalMediaVisible(false);
        setModalMediaItems([ ]);
    };
    
    const className = (loading) ? 'messenger_conversation loading' : 'messenger_conversation';

    return (
        <div className={ className }>
            <header className="messenger_conversation-header">
                <div className="messenger_conversation-header_left">
                    <h2 className="messenger_conversation-header_title">{ conversation?.item.title }</h2>
                    <p className="messenger_conversation-header_info">
                        Between You &amp; { otherUser?.fullName } &bull; Last message: <DateFormat date={ conversation?.lastMessage } />
                    </p>
                </div>
                <div className="messenger_conversation-header_right">
                    <Dropdown
                        toggleClassName="btn-icon-white btn-lg"
                        toggleContent={ <Icon name="ellipsis-v" />}
                        position="bottom-end"
                        items={[ 
                            { icon: 'print', text: 'Print conversation', onClick: (e => { handlePrint(e) }) },
                            //{ icon: 'image', text: 'View shared media', onClick: (e => { handleShowMedia(e) }) },
                            //{ icon: 'file-pdf', text: 'Download transcript' },
                            { sep: true },
                            { icon: 'trash', text: 'Delete conversation', className: 'dropdown-menu_item-danger', onClick: (e => { handleDelete(e) }) }
                        ]} />
                </div>
            </header>
            <div className="messenger_conversation-messages">
                <SimpleReactLightbox>
                    <SRLWrapper options={ lightboxOptions }>
                        { messages.map((message, i, arr) => 
                            <Message 
                                showAvatar={ 
                                    (i < (arr.length - 1)) 
                                        ? (arr[(i + 1)].sender.uuid !== message.sender.uuid)
                                        : true 
                                    }
                                message={ message } />
                        )}
                    </SRLWrapper>
                </SimpleReactLightbox>
                <div ref={ bottomRef } style={{ clear: 'both' }} className="list-bottom"></div>
            </div>

            <ComposeForm
                conversation={ conversation }
                onSend={ handleSendSuccess } />

            <Modal title="Delete conversation" visible={ deleteConfirmVisible } onClose={ handleDeleteConfirmClose }>
                <p>Are you sure you wish to delete the conversation?</p>
                <p>This will only delete the conversation for you; <b>{ otherUser?.fullName }</b> will still be able to view the messages and shared attachments.</p>

                <Button
                    loading={ deleteConfirmLoading }
                    onClick={ handleDeleteConfirm }
                    className="btn-rounded btn-primary"
                    text="Yes, delete" />

                <Button
                    className="btn-text-muted"
                    onClick={ handleDeleteConfirmClose }
                    text="Cancel" />
            </Modal>

            <Modal size="lg" title="Conversation media" visible={ modalMediaVisible } loading={ modalMediaLoading } onClose={ handleCloseMedia }>
                <div className="modal-conversation-media-container">
                    {modalMediaItems.map((item, i) => 
                        <div className="modal-conversation-media">
                            <img src={ item.thumbnail } key={ i } />
                        </div>
                    )}
                </div>

                <Button
                    onClick={ handleCloseMedia }
                    className="btn-rounded btn-primary"
                    text="Done" />
            </Modal>
        </div>
    );
};

export default Conversation;