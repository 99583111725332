import { React, useState, useEffect } from 'react';

import { Button } from 'Components';

import Utilities from 'Utilities';

const NotFoundPage = (props) => {

    // Update the title:
    useEffect(() => {
        document.title = Utilities.getPageTitle('Not Found');
    }, [ props.location ]);

	return (
		<div className="four-oh-four">
            <h1>404</h1>
            <h3>Page Not Found</h3>

            <p>Sorry, the page you have requested couldn't be found. Please try again, it could be a temporary problem our end.</p>

            <Button href="/" className="btn-primary btn-lg btn-rounded btn-upper">
                Return to dashboard
            </Button>
        </div>
	);
};

export default NotFoundPage;