import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Dropdown, NavbarMenu, NavbarMenuItem } from 'Components';
import AuthService from 'Services/AuthService';

const Navbar = (props) => {

	const history = useHistory();

    const handleLogout = (e) => {
    	e.preventDefault();

    	AuthService.logout();

		history.push({
			pathname: '/login',
			state: {
				message: "You have been successfully logged out"
			}
		});
	};

	const avatar = useSelector(state => state.user.avatar);

	const avatarURL = (avatar === null) ? '' : avatar.thumbnail;

	let dropdownItems = [
		{ text: 'Edit my profile', link: '/settings/profile/' },
		{ text: 'Edit my app preferences', link: '/settings/preferences/' },
		{ text: 'My recent activity', link: '/settings/activity/' },
        { sep: true },
        { text: 'Logout', className: 'dropdown-menu_item-danger', onClick: handleLogout },
	];

	return (
		<nav id="nav">
			<a href="https://kolek.to/" target="_blank" rel="noreferrer" title="Kolekto - collection inventory app" id="nav-brand">
				<img src="https://ik.imagekit.io/kolekto/static/logo-white_4jm3pSGr5.svg" alt="Kolekto logo" title="Kolekto" />
            </a>

            <NavbarMenu id="nav-tabs">
				<NavbarMenuItem id="nav-tab_dashboard" link="/" exact={true} label="Dashboard" icon="tachometer-alt-fast" />
				<NavbarMenuItem id="nav-tab_collections" link="/collections" label="Collections" icon="archive" />
				<NavbarMenuItem id="nav-tab_import" link="/import" label="Import" icon="file-import" />
				<NavbarMenuItem id="nav-tab_search" link="/search" label="Search" icon="search" />
				<NavbarMenuItem id="nav-tab_marketplace" link="/marketplace" label="Marketplace" icon="store" />
				<NavbarMenuItem id="nav-tab_settings" link="/settings" label="Settings" icon="cog" />
			</NavbarMenu>

			<NavbarMenu id="nav-bottom">
				<Dropdown
					arrow={true}
                    position="right"
                    toggleClassName="btn-text avatar"
                    toggleContent={ <img alt="User avatar" src={ avatarURL }/> }
                    items={ dropdownItems } />
			</NavbarMenu>
        </nav>
	);
}

export default Navbar;
