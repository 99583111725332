import React from 'react';

import { SectionTitle } from 'Components';

const Section = (props) => {

	const { className, title, narrow } = props;

	const classNameStr = 'section ' + (className !== undefined ? className : '');
	const containerClass = 'container' + (narrow === true ? ' container-narrow' : '');

	const titleElement = (title !== undefined) ? <SectionTitle title={title} /> : '';

	return (
		<section className={ classNameStr }>
			<div className={containerClass}>
				{ titleElement }
				{ props.children }
			</div>
		</section>
	);
}

export default Section;