import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

const DateFormat = props => {
    
    const date = moment(props.date);
    const format = (props.format === undefined) ? null : props.format;
    const [ value, setValue ] = useState(null);

    const { dateFormat, timeFormat } = useSelector(state => state.user.preferences);

    let title = date.format(dateFormat+' '+timeFormat);

    const className = [ 'moment-datetime' ];

    if( props.className !== undefined )
    {
        className.push(props.className);
    }

    useEffect(() => {
        if( format === null )
        {
            setValue(date.fromNow());
            
            const interval = setInterval(() => { setValue(date.fromNow()) }, 1000);
            return () => clearInterval(interval);
        }
        else
        {
            let dateFormatStr = format;

            switch(format)
            {
                case 'user.date':
                    dateFormatStr = dateFormat;
                    break;
                
                case 'user.time':
                    dateFormatStr = timeFormat;
                    break;
                
                case 'user.datetime':
                    dateFormatStr = dateFormat+' '+timeFormat;
                    break;

                default:
                    dateFormatStr = format;
                    break;
            };
            
            setValue(date.format(dateFormatStr));
        }

        title = date.format(dateFormat+' '+timeFormat);

    }, [ date, dateFormat, format, timeFormat ]);

    return (
        <span className={ className.join(' ')} title={ title }>
            { value }
        </span>
    )
};

export default DateFormat;