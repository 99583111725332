import React, { useEffect, useState } from 'react';
import produce from 'immer';
import APIService from 'Services/APIService';

import { MutedText, Row, Column } from 'Components';
import ImageUploadButton from './Button';
import WebcamButton from './WebcamButton';
import UploadQueue from './Queue';

const ImageUploader = props => {
    const { images } = props;

    const [ imageQueue, setImageQueue ] = useState([ ]);
    const [ loadingCount, setLoadingCount ] = useState(0);

    useEffect(() => { 
        const newQueue = [ ];

        images.map(img => {
            newQueue.push({
                id: img.id,
                loading: false,
                thumbnail: img.thumbnail
            });
        });

        setImageQueue(newQueue);
    }, [ images ]);

    // I know this is bad, but the asynchronous nature of useState() is causing issues!
    let numLoading = 0;

    const handleImageSelect = (e, files) => {

        const images = [ ];

        for( let i = 0; i < files.length; i++ )
        {
            const file = files[i];
            
            if( file.type.indexOf('image') === 0 )
            {
                images.push(file);
            }
        };

        setLoadingCount(images.length);
        numLoading = images.length;

        images.map(file => {
            const data = new FormData();
            data.set('image', file);

            // Now handle the upload:
            APIService.post('/images/', data, { 'content-type': 'multipart/form-data' })
                .then(response => {
                    const preload = new Image();
                    preload.src = response.image.thumbnail;
                    preload.onload = () => {
                        setImageQueue(imageQueue => (imageQueue.concat({
                                id: response.image.id,
                                loading: false,
                                thumbnail: response.image.thumbnail
                            }))
                        );

                        // Reduce the loading count:
                        numLoading-= 1;
                        setLoadingCount(numLoading);

                        if( typeof props.onChange === 'function' )
                        {
                            props.onChange(imageQueue);
                        }

                        if( typeof props.onUpload === 'function' )
                        {
                            props.onUpload(response.image);
                        }
                    };

                    preload.onerror = error => {
                        if( typeof props.onError === 'function' )
                        {
                            props.onError(error);
                        }
                    };
                })
                .catch(error => {
                    if( typeof props.onError === 'function' )
                    {
                        props.onError(error);
                    }
                });

            return imageQueue;
        });
    };

    const handleDelete = index => {
        const id = imageQueue[index].id;

        setImageQueue(
            produce(imageQueue, draft => { delete draft[index] })
        );

        if( typeof props.onChange === 'function' )
        {
            props.onChange(imageQueue);
        }

        if( typeof props.onRemove === 'function' )
        {
            props.onRemove(id);
        }
    };

    const handleWebcamCapture = file => {
        handleImageSelect(null, [ file ]);
    }

    return (
        <div className="image-uploader">
            <Row className="row-justify row-stretch">
                <Column width={ 45 }>
                    <ImageUploadButton 
                        onSelect={(e, files) => handleImageSelect(e, files)} />
                </Column>
                <Column>
                    <MutedText className="text-upper text-sm" text="or" />
                </Column>
                <Column width={ 45 }>
                    <WebcamButton 
                        onCapture={ handleWebcamCapture } />
                </Column>
            </Row>

            <UploadQueue
                loadingCount={ loadingCount }
                images={ imageQueue }
                columnWidth={ props.columnWidth }
                onDelete={(i) => handleDelete(i)} />
        </div>

    );
};

export default ImageUploader;