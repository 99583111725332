export const SHOW_SETTINGS_MODAL = 'SHOW_SETTINGS_MODAL';
export const HIDE_SETTINGS_MODAL = 'HIDE_SETTINGS_MODAL';

export const showSettingsModal = modalToShow => ({
	type: SHOW_SETTINGS_MODAL,
	activeModal: modalToShow
});

export const hideSettingsModal = () => ({
    type: HIDE_SETTINGS_MODAL
});