import React, { useEffect, useState } from 'react';
import { objectSelector } from 'store/selectors';
import produce from 'immer';

import { Alert, Button, CopyableInput, Modal, ToggleSwitch } from 'Components';
import { APIService } from 'Services';
import { setHierarchy } from 'store/actions/collectionActions';

const ItemShareModal = props => {

    const { visible, onClose, item, onShare, onUnshare } = props;

    const [ buttonLoading, setButtonLoading ] = useState(false);
    const [ shareEnabled, setSharingEnabled ] = useState(false);
    const [ attributes, setAttributes ] = useState([ ]);
    const [ shareURL, setShareURL ] = useState(item?.shareURL);

    // Alert state:
    const [ alertText, setAlertText ] = useState(null);
    const [ alertClass, setAlertClass ] = useState(null);
    const [ alertVisible, setAlertVisible ] = useState(false);

    const handleClose = () => {
        setButtonLoading(false);
        
        if( typeof onClose === 'function' )
        {
            onClose();
        }
    };

    useEffect(() => {
        if( item )
        {
            setSharingEnabled(item.sharingEnabled);
            setShareURL(item.shareURL);

            if( item.sharedAttributes === null )
            {
                const atts = [ ];
                objectSelector(item.attributes).map(attr => (atts.push(attr.attributeId)));
                setAttributes(atts);
            }
            else
            {
                setAttributes(item.sharedAttributes);
            }
        }
    }, [ item ]);

    const handleAttributeChange = (checked, id) => {

        if( !checked )
        {
            setAttributes(
                produce(attributes, draft => {
                    const index = draft.indexOf(id);
                    
                    if(index !== -1) 
                    {
                        draft.splice(index, 1);
                    };
                })
            );
        }
        else
        {
            setAttributes(
                produce(attributes, draft => { draft.push(id); })
            );
        }
    };

    const handleSubmit = e => {
        e.preventDefault();

        setButtonLoading(true);
        setAlertVisible(false);

        const endpoint = `/item/${item?.id}/share/`;

        // Send off the AJAX request:
        if( shareEnabled )
        {
            APIService.post(endpoint, { attributes: attributes })
                .then(resp => { 
                    setShareURL(resp.url);

                    setAlertText('Sharing preferences successfully updated.');
                    setAlertClass('success');

                    if( typeof onShare === 'function' )
                    {
                        onShare(item, resp.url, attributes);
                    };
                })
                .catch(error => {
                    setAlertText(error.response.data.message);
                    setAlertClass('danger');
                })
                .finally(() => { 
                    setButtonLoading(false);
                    setAlertVisible(true);
                });
        }
        else
        {
            APIService.delete(endpoint, { attributes: attributes })
                .then(resp => {
                    setShareURL(null);
                    
                    setAlertText('Sharing preferences successfully updated.');
                    setAlertClass('success');

                    if( typeof onUnshare === 'function' )
                    {
                        onUnshare(item);
                    }
                })
                .catch(error => {
                    setAlertText(error.response.data.message);
                    setAlertClass('danger');
                })
                .finally(() => { 
                    setAlertVisible(true);
                    setButtonLoading(false);
                });
        }
    };

    return (
        <Modal visible={ visible } title="Manage sharing" subtitle={ item?.name } onClose={ handleClose }>
            <p>
                Share URLs allow you to quickly and easily share an item with your friends and family. They don&rsquo;t need to have a Kolekto 
                account to view the item, and it will only be visible to people you send the link to, and for as long as you have sharing enabled
                for the item.
            </p>

            <Alert 
                className="mb25"
                type={ alertClass }
                visible={ alertVisible }
                text={ alertText } />

            <ToggleSwitch
                onCheck={() => setSharingEnabled(true)}
                onUncheck={() => setSharingEnabled(false)}
                checked={ shareEnabled }
                label="Enable sharing for this item" />

            <div style={{ display: (shareEnabled ? 'block' : 'none') }}>
                <CopyableInput 
                    value={ shareURL } 
                    visible={ shareURL !== null } />

                <p>You can choose which attributes are displayed on the shared webpage by enabling them below:</p>

                {objectSelector(item?.attributes).map((attr, i) => (
                    <ToggleSwitch 
                        checked={ attributes.indexOf(attr.attributeId) > -1 }
                        onChange={ (e, checked) => handleAttributeChange(checked, attr.attributeId) }
                        label={ attr.name } 
                        key={ i } />
                ))}
            </div>

            <Button
                onClick={ handleSubmit }
                loading={ buttonLoading }
                className="mt25 btn-primary btn-rounded btn-upper"
                text="Save changes" />
        </Modal>
    );
};

export default ItemShareModal;