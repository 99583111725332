// Create iterable from object:
export const objectSelector = objects => {
    if( ! objects )
    {
        return [ ];
    }

    return Object
        .keys(objects)
        .map((key) => objects[key]);
}

// Return a formatted array of currencies:
export const currencySelector = currencies => {
    return Object
        .keys(currencies)
        .map((key) => {
            const obj = currencies[key];
            obj.code = key;
            return obj;
        });
};

// Get the collection from the URL:
export const collectionSelector = (collections, slug) => {
    if( collections === null )
    {
        return null;
    }

    return collections[slug];
};

// Get the current category from the URL:
export const categorySelector = (collections, hierarchy) => {
    if( collections === null || hierarchy === undefined || hierarchy.length < 2 )
    {
        return null;
    }

    const collection = collections[hierarchy[0]];

    if( collection?.categories !== undefined )
    {
        const keys = hierarchy.slice(1);

        let item = collection.categories;

        for( let i = 0; i < keys.length; i++ )
        {
            if( i === (keys.length - 1) )
            {
                return item[keys[i]];    
            }

            item = item[keys[i]].children;    
        }
    }

    return null;
};