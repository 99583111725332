import { toArray } from 'react-emoji-render';

export const mergeRefs = (...refs) => {
    const filteredRefs = refs.filter(Boolean);
    if (!filteredRefs.length) return null;
    if (filteredRefs.length === 0) return filteredRefs[0];
    
    return inst => 
    {
        for (const ref of filteredRefs) 
        {
            if( typeof ref === 'function' ) 
            {
                ref(inst);
            } 
            else if( ref ) 
            {
                ref.current = inst;
            }
        }
    };
};

export const formatCardNumber = value => {
    const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
    
    const matches = v.match(/\d{4,16}/g);
    const match = matches && matches[0] || '';
    const parts = [ ];

    for( let i = 0, len = match.length; i < len; i+=4 ) 
    {
        parts.push(match.substring(i, i+4))
    }
    
    if( parts.length ) 
    {
        return parts.join(' ');
    }

    return value;
};

export const checkIsEmpty = value => {
    return (
        value === '' ||
        value === null ||
        value === undefined
    );
};

export const parseEmoji = value => {
    const emojisArray = toArray(value);
    
    // toArray outputs React elements for emojis and strings for other
    const newValue = emojisArray.reduce((previous, current) => {
        if(typeof current === 'string') 
        {
            return previous + current;
        }
        
        return previous + current.props.children;
    }, '');
    
    return newValue;
};

export const isSingleEmoji = value => {

    if( ! value ) 
    {
        return false;
    }
    
    if( value.trim() === '❤️' )
    {
        return true;
    }

    const regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|[\ud83c[\ude01\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|[\ud83c[\ude32\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|[\ud83c[\ude50\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;
    const clean = value.replace(regex, '');

    return (
        value.trim().length === 2 && clean.trim().length === 0
    );
}