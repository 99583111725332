import React from 'react';

const MessageGallery = props => {
    const { images } = props;

    const handleClick = image => {
        
    };

    // If there's only one image:
    if( images.length === 1 )
    {
        const image = images[0];

        return (
            <a href={ image.url }>
                <img 
                    onClick={ () => handleClick(image) }
                    src={ `${image.url}?tr=w-450` } 
                    class="messenger_message-image" />
            </a>
        );
    }

    // We need to add a relevant class:
    const className = [ 'messenger_message-gallery' ];

    switch( images.length )
    {
        case 2:
        case 3:
        case 4:
        case 5:
        case 6:
            className.push(`messenger_message-gallery-${images.length}-items`);
            //className.push('messenger_message-gallery-multi-items');
            break;

        default:
            className.push('messenger_message-gallery-multi-items');
            break;
    }

    // It's a gallery:
    return (
        <div className={ className.join(' ') }>
            { images.map(image => 
                <a href={ image.url } className="messenger_message-gallery-item">
                    <img 
                        onClick={ () => handleClick(image) }
                        src={ image.thumbnail } 
                        className="messenger_message-gallery-thumb" />
                </a>
            )}
        </div>
    );
};

export default MessageGallery;