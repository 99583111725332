import { objectSelector } from 'store/selectors';

class CategoryManager
{
    id = null;
    key = null;

    constructor(id)
    {
        this.id = id;
        this.key = `category_${this.id}`;
    };

    getViewType()
    {
        const categoryData = this.getCategoryData();

        if( categoryData.view === undefined )
        {
            return 'list';
        }

        return categoryData.view;
    };

    getSortData()
    {
        const data = this.getCategoryData();

        if( data.sortData === undefined )
        {
            return {
                name: 'Date added',
                field: 'created'
            };
        }

        return data.sortData;
    };

    setSortData( data )
    {
        return this.setData('sortData', data);
    }

    setViewType( view )
    {
        return this.setData('view', view);
    }

    getCategoryData()
    {
        if( localStorage.getItem(this.key) !== null )
        {
            return JSON.parse(
                localStorage.getItem(this.key)
            );
        }

        return { };
    };

    // Get the columns for table view:
    getColumns( collection )
    {
        const data = this.getCategoryData();

        if( data.columns === undefined )
        {
            if( collection.attributes )
            {
                const attributes = objectSelector(collection.attributes);
                const cols = [ ];

                if( attributes.length < 6 )
                {

                    attributes.map(attr => {
                        cols.push(attr.id);
                    });
                }
                else
                {
                    attributes.slice(0, 6).map(attr => { 
                        cols.push(attr.id);
                    });
                }

                return cols;
            }
        }

        return data.columns;
    };

    // Set the columns:
    setColumns( columns )
    {
        return this.setData('columns', columns);
    }

    setData( key, value )
    {
        let data = this.getCategoryData();

        data[key] = value;

        return localStorage.setItem(
            this.key, 
            JSON.stringify(data)
        );
    }
}

export default CategoryManager;