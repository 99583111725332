import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeAddCategory } from 'store/actions/modalActions';
import { addCategory, setHierarchy } from 'store/actions/collectionActions';

import APIService from 'Services/APIService';

import { Alert, Modal, Button, TextArea, Input } from 'Components';

const ModalAddCategory = (props) => {

    const dispatch = useDispatch();
    
    const collection = useSelector(state => state.modals.collectionToUse);
    const addCategoryVisible = useSelector(state => state.modals.addCategory);

    // Alert state:
    const [ alertVisible, setAlertVisible ] = useState(false);
    const [ alertClass, setAlertClass ] = useState(null);
    const [ alertText, setAlertText ] = useState('');

    // Button state:
    const [ buttonLoading, setButtonLoading ] = useState(false);

    // Values state:
    const [ name, setName ] = useState('');
    const [ description, setDescription ] = useState('');
    
    // Reset onclose:
    const onBeforeClose = event => {
        setAlertVisible(false);
        setButtonLoading(false);
        setName(null);
        setDescription(null);
    };

    // Handle the form submission:
    const handleSubmit = e => {
        e.preventDefault();
        setButtonLoading(true);
        setAlertVisible(false);

        APIService.post(`/collection/${collection.id}/categories/`, { name: name, description: description })
            .then(response => { 
                dispatch(
                    addCategory(
                        response.category.collectionID,
                        response.category.id,
                        response.category
                    )
                );

                dispatch(closeAddCategory());

                // Navigate to the URL:
                const url = [ 'collections', response.category.collectionID ];
                const hierarchy = [ response.category.collectionID ];

                response.category.hierarchy.map((id) => { url.push(id); hierarchy.push(id); return hierarchy });

                url.push(response.category.id);
                hierarchy.push(response.category.id);
                
                window.history.replaceState(
                    null, 
                    null, 
                    '/'+url.join('/')+'/'
                );

                dispatch(setHierarchy(hierarchy));
            })
            .catch(error => { 
                setAlertVisible(true);
                setAlertClass('danger');
                setAlertText(error.response.data.message);
            })
            .finally(() => setButtonLoading(false));
    };

    return (
        <Modal size="medium" onClose={(e) => { onBeforeClose(); dispatch(closeAddCategory()) }} title="Add category" visible={ addCategoryVisible }>
            <p>Add a new category to the collection <b>{ collection?.name }</b>:</p>

            <Alert className={ `alert-${alertClass} alert-icon mb25` } visible={alertVisible}>
                { alertText }
            </Alert>

            <form onSubmit={(e) => { handleSubmit(e); }}>
                <Input 
                    className="w75"
                    onChange={(e) => { setName(e.target.value) }}
                    value={ name }
                    label="Name" />
                
                <TextArea
                    height={ 100 }
                    className="mt25"
                    value={ description }
                    onChange={(e) => { setDescription(e.target.value) }}
                    label="Description" />

                <Button 
                    className="btn-primary btn-upper btn-rounded mt30" 
                    text="Add category" 
                    loading={ buttonLoading }
                    />
            </form>
        </Modal>
    );
};

export default ModalAddCategory;