import { React, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom';
import { PrivateRoute } from 'Components';

import { fetchUser } from 'store/actions/userActions';
import { fetchAttributes, fetchAttributeTypes } from 'store/actions/attributeActions';
import { fetchCurrencies } from 'store/actions/configActions';

import App from 'App';
import { LoginPage } from 'Components/Pages';

const Application = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const [ loading, setLoading ] = useState(true);
	const [ children, setChildren ] = useState('');

	// Update the store:
	useEffect(() => {
		dispatch(fetchUser())
			.then(() => { return dispatch(fetchAttributeTypes()) })
			.then(() => { return dispatch(fetchAttributes()); })
			.then(() => { return dispatch(fetchCurrencies()); })
			.then(() => {
				setChildren(
					<Router history={ history }>
						<Switch>
							<Route
								exact
								path={[ '/login/', '/password-reset/' ]}
								component={ LoginPage } />

							<PrivateRoute
								path="/"
								component={ App } />
						</Switch>
					</Router>
				);

				setLoading(false);
			});
	}, [ ]);

	// Add dark mode to body if necessary:
	const { darkMode } = useSelector(state => state.user.preferences);

	useEffect(() => {
		if (darkMode === true) {
			document.body.classList.add('dark-mode')
		}
		else {
			document.body.classList.remove('dark-mode');
		}
	}, [ darkMode ]);

	return (
		<div id="app" className={ (loading === true) ? 'loading' : '' }>
			{ children }
		</div>
	);
}

export default Application;
