import React from 'react';
import { useSelector } from 'react-redux';

import { NumberFormat, DateFormat, EmptyStateText, Icon } from 'Components';
import { CurrencyFormat } from 'Components/Formatters';

const AttributeValue = props => {
    const { attribute, value } = props;

    const className = [ 'attribute-value', 'attribute-value_' + attribute?.type ];

    const { currencies } = useSelector(state => state.config);
    const { attributes } = useSelector(state => state.attributes);

    if( props.className !== undefined )
    {
        className.push(props.className);
    }

    // Falsey value!
    if( attribute?.type === 'boolean' && (value === '' || value === null || value === undefined) )
    {
        return (
            <span className={ className.join(' ') }>
                <Icon name="times" />
            </span>
        );
    }

    if( value === '' || value === null || value === undefined || attribute === undefined )
    {
        return <EmptyStateText text="No value" />;
    }

    switch( attribute.type )
    {
        case 'short_text':
        case 'long_text':
        case 'enum':
            return <span className={ className.join(' ') }>{ value }</span>;

        case 'number':
            return (
                <span className={ className.join(' ') }>
                    <NumberFormat value={value} />
                </span>
            );

        case 'boolean':
            return (
                <span className={ className.join(' ') }>
                    <Icon name={ value == true ? 'check' : 'times' } />
                </span>
            );

        case 'date':
            return (
                <span className={ className.join(' ') }>
                    <DateFormat date={ value } format='user.date' />
                </span>
            );

        case 'year':
            return (
                <span className={ className.join(' ') }>
                    <DateFormat date={ value } format='yyyy' />
                </span>
            )

        case 'datetime':
            return (
                <span className={ className.join(' ') }>
                    <DateFormat date={ value } format='user.datetime' />
                </span>
            );

        case 'datetime':
            return (
                <span className={ className.join(' ') }>
                    <DateFormat date={ value } format='user.time' />
                </span>
            );

        case 'price':
            const currency = (attribute?.meta?.currency)
                ? currencies[attribute.meta.currency]
                : currencies[attributes[attribute.id]?.metadata?.currency];

                return <CurrencyFormat currency={ currency } value={ value } />;

        default:
            return <EmptyStateText text="No value" />
    }
};

export default AttributeValue;
