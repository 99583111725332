import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications'
import { useSelector } from 'react-redux';
import produce from 'immer';

import { APIService, AuthService } from 'Services';
import CategoryManager from 'Services/CategoryManager';
import { itemSorter } from 'store/sorters';
import { objectSelector } from 'store/selectors';
import { closeShowItem } from 'store/actions/modalActions';
import { updateCategoryItemCount } from 'store/actions/collectionActions';

import ItemActions from './ItemActions';
import ItemTable from './ItemTable';
import ItemGrid from './ItemGrid';
import StatisticCard from 'Components/Elements/StatisticCard';

// Modals:
import FilterModal from '../Modals/FilterModal';
import ColumnModal from '../Modals/ColumnModal';
import NotesModal from '../Modals/NotesModal';
import MarketplaceAddModal from '../Modals/MarketplaceAdd';
import MarketplaceEditModal from '../Modals/MarketplaceEdit';
import QRCodeModal from '../Modals/QRCodeModal';
import ItemShareModal from '../Modals/ItemShareModal';
import ItemViewer from './ItemViewer';
import EditItem from '../Forms/EditItem';

import { Confirm, EmptyState, Button, Modal, CurrencyFormat } from 'Components';

const ItemView = props => {

    const dispatch = useDispatch();

    const { category, collection, items } = props;
    const { addToast } = useToasts();
    const manager = new CategoryManager(category.id);

    // Get the item info from the Store:
    const { showItem, itemToShow } = useSelector(state => state.modals);

    // Filter state:
    const [ modalFilterVisible, setModalFilterVisible ] = useState(false);
    const [ modalClearFilterVisible, setModalClearFilterVisible ] = useState(false);
    const [ numFilters, setNumFilters ] = useState(0);
    const [ appliedFilters, setAppliedFilters ] = useState({});

    // Column state:
    const [ modalColumnsVisible, setModalColumnsVisible ] = useState(false);
    const [ columns, setColumns ] = useState(manager.getColumns(collection));

    // Item state:
    const [ itemsArray, setItemsArray ] = useState(items);
    const [ loading, setIsLoading ] = useState(false);
    const [ sortData, setSortData ] = useState(manager.getSortData());
    const [ view, setView ] = useState(manager.getViewType());

    // Delete item state:
    const [ deleteConfirmVisible, setDeleteConfirmVisible ] = useState(false);
    const [ deleteConfirmText, setDeleteConfirmText ] = useState('');
    const [ deleteLoading, setDeleteLoading ] = useState(false);
    const [ deleteItem, setDeleteItem ] = useState(null);

    // Edit item state:
    const [ editItemModalVisible, setEditItemModalVisible ] = useState(false);
    const [ itemToEdit, setItemToEdit ] = useState(null);

    // Notes state:
    const [ modalNotesVisible, setModalNotesVisible ] = useState(false);
    const [ noteItem, setNoteItem ] = useState(null);

    // QR Code state:
    const [ modalQRCodeVisible, setModalQRCodeVisible ] = useState(false);
    const [ qrItem, setQrItem ] = useState(null);

    // Marketplace state:
    const [ modalMarketplaceAddVisible, setModalMarketplaceAddVisible ] = useState(false);
    const [ modalMarketplaceEditVisible, setModalMarketplaceEditVisible ] = useState(false);
    const [ marketplaceItem, setMarketplaceItem ] = useState(null);

    // Share item:
    const [ modalShareItemVisible, setModalShareItemVisible ] = useState(false);
    const [ shareItem, setShareItem ] = useState(null);

    // Asset tag:
    const [ modalAssetTagVisible, setModalAssetTagVisible ] = useState(false);

    // We need to update the items on change:
    useEffect(() => {
        setItemsArray(itemSorter(sortData.field, items));
        setColumns(manager.getColumns(collection));
    }, [ items ]);

    // Handle the sorting onload:
    useEffect(() => {
        setView(manager.getViewType());
        setSortData(manager.getSortData());
        dispatch(closeShowItem());
    }, [ category ]);

    // When we change the item, update the body class:
    useEffect(() => {
        if (showItem === true) {
            document.body.classList.add('settings-view-in');
        }
        else {
            document.body.classList.remove('settings-view-in');
        }
    }, [ showItem ]);

    const handleOnAddItemOpen = () => {
        if (typeof props.onAddItemOpen === 'function') {
            props.onAddItemOpen();
        }
    };

    const handleViewChange = view => {
        manager.setViewType(view);
        setView(view);
    };

    const handleDelete = item => {
        setDeleteConfirmVisible(true);
        setDeleteConfirmText(<>Are you sure you want to delete "{item.name}"? <b>This action cannot be undone!</b></>);
        setDeleteItem(item);
    };

    const handleApplyFilters = (numFilters, items, filters) => {
        setNumFilters(numFilters);
        setAppliedFilters(filters);
        setModalFilterVisible(false);
        setItemsArray(items);
        setModalClearFilterVisible(true);
    };

    const handleClearFilters = () => {
        setModalClearFilterVisible(false);
        setNumFilters(0);
        setModalFilterVisible(false);
        setIsLoading(true);
        setAppliedFilters({});

        const endpoint = `/category/${category.id}/items/`;

        // Send an AJAX request to load all items:
        APIService.get(endpoint)
            .then(response => {
                setItemsArray(response.items)
            })
            .catch(error => {
                addToast('There was a problem loading the items. Please refresh the page.', {
                    appearance: 'error',
                    autoDismiss: true
                });
            })
            .finally(() => { setIsLoading(false) });
    };

    const handleResetFilters = () => {
        setModalClearFilterVisible(false);
        setNumFilters(0);
        setIsLoading(false);
        setAppliedFilters({ });
    };

    const handleSort = sortData => {
        setItemsArray(
            itemSorter(sortData.field, itemsArray)
        );

        // Store it in the localStorage:
        manager.setSortData(sortData);
    };

    const handleColumnChange = cols => {
        setColumns(cols);
        manager.setColumns(cols);
        setModalColumnsVisible(false);
    };

    const handleItemDelete = () => {
        setDeleteLoading(true);

        const endpoint = `/item/${deleteItem?.id}/`;

        // Delete the item:
        APIService.delete(endpoint)
            .then(() => {
                setItemsArray(produce(itemsArray, draft => {
                    delete draft['item' + deleteItem?.id];
                }));

                if (typeof props.onDelete === 'function') {
                    props.onDelete(deleteItem);
                }
            })
            .catch(error => {
                addToast('There was a problem deleting the item. Please try again.', {
                    appearance: 'error',
                    autoDismiss: true
                });
            })
            .finally(() => {
                setDeleteLoading(false);
                setDeleteItem(null);
                setDeleteConfirmVisible(false);
            })
    };

    const handleShowItemNotes = item => {
        setModalNotesVisible(true);
        setNoteItem(item);
    };

    const handleShowBarcode = item => {
        setQrItem(item);
        setModalQRCodeVisible(true);
    };

    const handleManageSharing = item => {
        setShareItem(item);
        setModalShareItemVisible(true);
    };

    const handleShare = (item, url, attributes) => {
        setItemsArray(produce(itemsArray, draft => {
            draft[`item${item?.id}`].sharingEnabled = true;
            draft[`item${item?.id}`].sharedAttributes = attributes;
            draft[`item${item?.id}`].shareURL = url;
        }));
    };

    const handleUnshare = item => {
        setItemsArray(produce(itemsArray, draft => {
            draft[`item${item?.id}`].sharingEnabled = false;
            draft[`item${item?.id}`].sharedAttributes = [ ];
            draft[`item${item?.id}`].shareURL = null;
        }));
    };

    const handleEditItem = item => {
        setItemToEdit(item);
        setEditItemModalVisible(true);
    };

    const handleEditItemSuccess = item => {
        setItemsArray(
            itemSorter(sortData.field, produce(itemsArray, draft => {
                draft[`item${item.id}`] = item;
            }))
        );
    };

    const handleNoteAdd = notes => {
        setItemsArray(produce(itemsArray, draft => { draft[`item${noteItem.id}`].notes = notes }));
    };

    const handleNoteRemove = notes => {
        setItemsArray(produce(itemsArray, draft => { draft[`item${noteItem.id}`].notes = notes }));
    };

    // Handle move:
    const handleMove = (moved, targetCategory) => {

        const newItems = produce(itemsArray, draft => {
            moved.map(id => {
                delete draft[`item${id}`];
            })
        });

        setItemsArray(newItems);

        if( typeof props.onMove === 'function' )
        {
            props.onMove(
                newItems,
                moved.length,
                targetCategory
            );
        }
    };

    // Show the marketplace action:
    const handleMarketplaceAdd = item => {
        setMarketplaceItem(item);
        setModalMarketplaceAddVisible(true)
    };

    // Handle marketplace listed success:
    const handleMarketplaceListedSuccess = (item, marketplaceId, url) => {
        setModalMarketplaceAddVisible(false);

        // Now update the item:
        if (item) {
            addToast(`${item?.name} was successfully listed in the Marketplace.`, {
                appearance: 'success',
                autoDismiss: true
            });

            setItemsArray(produce(itemsArray, draft => {
                draft['item' + item?.id].inMarketplace = true;
                draft['item' + item?.id].marketplaceItemId = marketplaceId;
                draft['item' + item?.id].marketplaceURL = url;
            }));
        }
    };

    // Handle marketplace edit:
    const handleMarketplaceEdit = item => {
        setMarketplaceItem(item);
        setModalMarketplaceEditVisible(true);
    };

    const handleMarketplaceDelete = item => {
        setItemsArray(produce(itemsArray, draft => {
            draft['item' + item?.id].inMarketplace = false;
            draft['item' + item?.id].marketplaceItemId = null;
        }));

        setModalMarketplaceEditVisible(false);

        addToast('Item was successfully removed from the Marketplace.', {
            appearance: 'success',
            autoDismiss: true
        });
    };

    let content = '';
    let actions = '';
    let stats = '';

    if (objectSelector(itemsArray).length === 0) {
        content = <EmptyState
                    title="No items found"
                    description="The selected category does not contain any items."
                    image="NoImages"
                    button={
                        <Button
                            className="btn-primary btn-rounded btn-upper"
                            text="Create new item"
                            onClick={ handleOnAddItemOpen } />
                    } />
    }
    else {
        actions = (
            <ItemActions
                onAddItemOpen={ handleOnAddItemOpen }
                category={ category }
                collection={ collection }
                onViewChange={ handleViewChange }
                onShowFilterModal={(e) => setModalFilterVisible(true)}
                onShowColumnModal={(e) => setModalColumnsVisible(true)}
                onDownloadAssetTags={(e) => setModalAssetTagVisible(true)}
                onSort={(field) => handleSort(field)}
                sortField={ sortData }
                view={ view }
                numItems={ objectSelector(itemsArray).length }
                numFilters={ numFilters }
                collection={ collection } />
        );

        if (category.statistics.attributes.length > 0) {
            stats = (
                <div class="category-stats">
                    { category.statistics.attributes.map((stat) => (
                        <StatisticCard
                            title={ stat.name + ':' }
                            value={ <CurrencyFormat currency={ stat.currency } value={ stat.value } />} />
                    ))}
                </div>
            )
        }


        if (view === 'list') {
            content = (
                <ItemTable
                    columns={ columns }
                    loading={ loading }
                    category={ category }
                    collection={ collection }
                    onDelete={ handleDelete }
                    onEditItem={ handleEditItem }
                    onViewNotes={ item => { handleShowItemNotes(item) }}
                    onMarketplaceAdd={ item => { handleMarketplaceAdd(item) }}
                    onMarketplaceEdit={ item => { handleMarketplaceEdit(item) }}
                    onShowBarcode={ item => { handleShowBarcode(item) }}
                    onShareItem={ item => { handleManageSharing(item) }}
                    onMove={ handleMove }
                    items={ itemsArray } />
            );
        }
        else {
            content = (
                <ItemGrid
                    loading={ loading }
                    category={ category }
                    collection={ collection }
                    onDelete={ handleDelete }
                    onEditItem={ handleEditItem }
                    onViewNotes={item => { handleShowItemNotes(item) }}
                    onMarketplaceAction={item => { handleMarketplaceAdd(item) }}
                    onMarketplaceEdit={item => { handleMarketplaceEdit(item) }}
                    onShowBarcode={ item => { handleShowBarcode(item) }}
                    onShareItem={ item => { handleManageSharing(item) }}
                    onMove={ handleMove }
                    items={ itemsArray } />
            )
        }
    }

    return (
        <>
            { actions }
            { stats }
            { content }

            <Confirm
                content={ deleteConfirmText }
                title="Delete item"
                size="medium"
                confirmText="Yes, delete"
                confirmButtonClassname="btn-danger"
                onClose={() => setDeleteConfirmVisible(false) }
                loading={ deleteLoading }
                onConfirm={ handleItemDelete }
                visible={ deleteConfirmVisible } />

            <FilterModal
                categoryId={ category.id }
                onApplyFilters={ handleApplyFilters }
                onClearFilters={ handleClearFilters }
                onResetFilters={ handleResetFilters }
                attributes={ collection.attributes }
                visible={ modalFilterVisible }
                clearButtonVisible={ modalClearFilterVisible }
                appliedFilters={ appliedFilters }
                onClose={(e) => setModalFilterVisible(false)} />

            <ColumnModal
                columns={ columns }
                collection={ collection }
                visible={ modalColumnsVisible }
                onSubmit={ handleColumnChange }
                onClose={(e) => setModalColumnsVisible(false)} />

            <NotesModal
                item={ noteItem }
                visible={ modalNotesVisible }
                onAdd={(notes => { handleNoteAdd(notes) })}
                onDelete={(notes) => { handleNoteRemove(notes) }}
                onClose={(e) => setModalNotesVisible(false) } />

            <ItemViewer
                visible={ showItem }
                item={ itemToShow } />

            <EditItem
                collection={ collection }
                category={ category }
                item={ itemToEdit }
                visible={ editItemModalVisible }
                onSuccess={(item) => { handleEditItemSuccess(item) }}
                onClose={() => { setItemToEdit(null); setEditItemModalVisible(false) }} />

            <MarketplaceAddModal
                item={ marketplaceItem }
                onSuccess={(item, id, url) => { handleMarketplaceListedSuccess(item, id, url) }}
                onClose={() => { setModalMarketplaceAddVisible(false); setMarketplaceItem(null); }}
                visible={ modalMarketplaceAddVisible } />

            <MarketplaceEditModal
                item={ marketplaceItem }
                onClose={() => { setModalMarketplaceEditVisible(false); setMarketplaceItem(null); }}
                onDelete={ handleMarketplaceDelete }
                visible={ modalMarketplaceEditVisible } />

            <QRCodeModal
                visible={ modalQRCodeVisible }
                onClose={() => { setQrItem(null); setModalQRCodeVisible(false); }}
                item={ qrItem } />

            <ItemShareModal
                visible={ modalShareItemVisible }
                onClose={() => { setShareItem(null); setModalShareItemVisible(false) }}
                onShare={ handleShare }
                onUnshare={ handleUnshare }
                item={ shareItem } />

            <Modal visible={ modalAssetTagVisible } title="Download asset tags" onClose={() => setModalAssetTagVisible(false)}>
                <p>Asset tags can be used to label items in your collections for easy reference later using the Kolekto mobile app.</p>

                <p>
                    Tags use the <a href="https://www.avery.co.uk/blank-labels/rectangle-46x25mm" title="Purchase Avery Labels online" target="_blank" rel="noreferrer">Avery
                    46x25-R</a> label format to allow for printing at home. Click the button below to download all asset tags
                    for <strong>{ category.name }</strong>:
                </p>

                <a
                    className="btn btn-rounded btn-primary btn-upper"
                    download
                    href={ `https://api.kolekto.io/category/${category.id}/asset-tags.pdf?token=${AuthService.getToken()}` }
                    target="_blank"
                    title={ `Download asset tags for ${category.name}` }
                    rel="noreferrer">Download Asset Tags</a>
            </Modal>
        </>
    )
};

export default ItemView;
