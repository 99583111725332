import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeAddSubcategory } from 'store/actions/modalActions';
import { addCategory } from 'store/actions/collectionActions';

import APIService from 'Services/APIService';

import { Alert, Modal, Button, TextArea, Input } from 'Components';

const ModalAddSubcategory = (props) => {

    const dispatch = useDispatch();

    const addSubcategoryVisible = useSelector(state => state.modals.addSubcategory);
    const parent = useSelector(state => state.modals.categoryToUse);

    // Alert state:
    const [ alertVisible, setAlertVisible ] = useState(false);
    const [ alertClass, setAlertClass ] = useState(null);
    const [ alertText, setAlertText ] = useState('');

    // Button state:
    const [ buttonLoading, setButtonLoading ] = useState(false);

    // Values state:
    const [ name, setName ] = useState('');
    const [ description, setDescription ] = useState('');
    
    // Reset onclose:
    const onBeforeClose = event => {
        setAlertVisible(false);
        setButtonLoading(false);
        setName(null);
        setDescription(null);
    };

    // Handle the form submission:
    const handleSubmit = e => {
        e.preventDefault();
        setButtonLoading(true);
        setAlertVisible(false);

        APIService.post(`/category/${parent.id}/children/`, { name: name, description: description })
            .then(response => { 
                dispatch(addCategory(response.category.collectionID, response.category.id, response.category));
                dispatch(closeAddSubcategory());
            })
            .catch(error => { 
                setAlertVisible(true);
                setAlertClass('danger');
                setAlertText(error.response.data.message);
            })
            .finally(() => setButtonLoading(false));
    };

    return (
        <Modal size="medium" onClose={(e) => { onBeforeClose(); dispatch(closeAddSubcategory()) }} title="Add subcategory" visible={ addSubcategoryVisible }>
            <p>Add a new subcategory to <b>{ parent?.name }</b>:</p>

            <Alert className={ `alert-${alertClass} alert-icon mb25` } visible={alertVisible}>
                { alertText }
            </Alert>

            <form onSubmit={(e) => { handleSubmit(e); }}>
                <Input 
                    className="w75"
                    onChange={(e) => { setAlertVisible(false); setName(e.target.value) }}
                    value={ name }
                    label="Name" />
                
                <TextArea
                    height={ 100 }
                    className="mt25"
                    value={ description }
                    onChange={(e) => { setDescription(e.target.value) }}
                    label="Description" />

                <Button 
                    className="btn-primary btn-upper btn-rounded mt30" 
                    text="Add subcategory" 
                    loading={ buttonLoading }
                    />
            </form>
        </Modal>
    );
};

export default ModalAddSubcategory;