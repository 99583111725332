import { React, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useToasts } from 'react-toast-notifications';

import APIService from 'Services/APIService';
import { setAvatar } from 'store/actions/userActions';

import { Icon } from 'Components';

const AvatarUploader = (props) => {
    const { avatar } = useSelector(state => state.user);
    const { addToast } = useToasts();
    const dispatch = useDispatch();

    const [ loading, setLoading ] = useState(false);

    const avatarUrl = (avatar === null) ? '' : avatar.thumbnail;

    const handleUpload = event => {
        if( event.target.files.length > 0 )
        {
            setLoading(true);

            const image = event.target.files[0];

            const form = new FormData();
            form.append('avatar', image);

            APIService.post('/user/avatar/', form)
                .then(response => {
                    let loader = new Image();
                    loader.src = response.avatar.thumbnail;
                    loader.onload = () => {
                        setLoading(false);
                        dispatch(setAvatar(response.avatar));
                    }
                })
                .catch(error => {
                    setLoading(false);
                    addToast(error.response.data.message, { 
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        }
    };
    
	return (
        <label className={ `avatar-uploader${ (loading === true) ? ' avatar-uploader-loading' : '' }` }>
            <img className="avatar-uploader_image" src={ avatarUrl } alt="Current avatar" />
            <div className="avatar-uploader_overlay">
                <Icon name="camera" type="light" />
                <span>Upload new avatar</span>
            </div>
            <input type="file" name="avatar" accept="image/*" onChange={(e) => handleUpload(e)  } />
        </label>
    );
}

export default AvatarUploader;