import { React, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useToasts } from 'react-toast-notifications'
import { openAddAttribute, openEditAttribute } from 'store/actions/modalActions';
import { objectSelector } from 'store/selectors';

import { removeAttribute } from 'store/actions/attributeActions';

import { APIService } from 'Services';

import { 
    Confirm, 
    Icon,
    Dropdown,
    Button, 
    EmptyState, 
    DataTable as Table, 
    DataTableHeader as TableHeader,
    DataTableBody as TableBody,
    DataTableHeaderCell as HeaderCell, 
    DataTableRow as TableRow, 
    DataTableCell as TableCell,
    NumberFormat
} from 'Components';

const Attributes = props => {

    const { active } = props;

    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const [ confirmDeleteVisible, setConfirmDeleteVisible ] = useState(false);
    const [ confirmDeleteLoading, setConfirmDeleteLoading ] = useState(false);
    const [ attributeToDelete, setAttributeToDelete ] = useState(null);

    const { attributes } = useSelector(state => state.attributes);

    // Confirm delete:
    const showConfirmDelete = (e, attribute) => {
        setConfirmDeleteVisible(true);
        setAttributeToDelete(attribute);
    };

    // Handle delete:
    const handleDeleteConfirmSubmit = e => {
        setConfirmDeleteLoading(true);

        APIService.delete('/attribute/' + attributeToDelete.id)
            .then(response => {
                dispatch(removeAttribute(attributeToDelete.id));
            })
            .catch(error => {
                addToast(error.response.data.message, {
                    appearance: 'error',
                    autoDismiss: true
                });
            })
            .finally(() => {
                setConfirmDeleteLoading(false);
                setConfirmDeleteVisible(false);
            });
    };

    let content = null;

    // Render the content:
    if( objectSelector(attributes).length === 0 )
    {
        content = (
            <EmptyState 
                button={ 
                    <Button 
                        className="btn-rounded btn-primary btn-upper" 
                        text="Add custom attribute" 
                        onClick={(e) => { e.preventDefault(); dispatch(openAddAttribute()) }} />
                }
                description={
                    <>
                        Custom attributes allow you to add detailed additional information to your items in Kolekto. <a href="#">Learn more</a>.
                    </>
                }
                title="No custom attributes" 
                image="NoTags" />
        );
    }
    else
    {
        content = (
            <Table>
                <TableHeader>
                    <HeaderCell text="Attribute name" />
                    <HeaderCell text="Attribute type" />
                    <HeaderCell text="Item count" />
                    <HeaderCell className="actions" />
                </TableHeader>
                <TableBody>
                    {objectSelector(attributes).map((attribute, i) => (
                        <TableRow key={ i }>
                            <TableCell text={ attribute.name } />
                            <TableCell text={ attribute.typeName } />
                            <TableCell text={ <NumberFormat value={ attribute.itemCount } /> } />
                            <TableCell className="actions">
                                <Dropdown
                                    position="bottom-end"
                                    size="sm"
                                    toggleClassName="btn-actions"
                                    toggleContent={ <Icon name="ellipsis-h" /> }
                                    items={[
                                        { icon: 'pencil', text: 'Edit attribute', onClick: ((e) => { dispatch(openEditAttribute(attribute)) }) },
                                        { sep: true },
                                        { icon: 'trash', text: 'Delete attribute', className: 'dropdown-menu_item-danger', onClick: ((e) => { showConfirmDelete(e, attribute) }) }
                                    ]} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
    }

    return (
        <>
            { content }

            <Confirm 
                visible={ confirmDeleteVisible }
                loading={ confirmDeleteLoading }
                title="Delete attribute"
                onClose={e => { setConfirmDeleteVisible(false); setConfirmDeleteLoading(false); } }
                onConfirm={e => { handleDeleteConfirmSubmit(e) }}
                confirmText="Yes, delete"
                content="Are you sure you want to delete the attribute? This will also delete any values that it has been given in your items." />
        </>
    );
};

export default Attributes;