import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Utilities from 'Utilities';

import { openAddCollection } from 'store/actions/modalActions';
import { fetchCollections, setHierarchy } from 'store/actions/collectionActions';

import { Button, EmptyState } from 'Components';
import { Navigation, CollectionView } from 'Components/Collections';

const Dashboard = (props) => {

    const dispatch = useDispatch();
    const location = useLocation();

    const { collectionsLoading, collections } = useSelector(state => state.collections);

    // Update the title:
    useEffect(() => { document.title = Utilities.getPageTitle('Collections'); }, [ props.location ]);

    // Update the current collection / category:
    const handleCurrent = () => {
        dispatch(
            setHierarchy(
                location.pathname.split('/').slice(2).filter(val => ( val !== '')).map(Number)
            )
        );
    };

    // Update the current collection / category:
    useEffect(() => { handleCurrent() }, [ location ]);

    // Get the collections:
    useEffect(() => { dispatch(fetchCollections(true)).then(() => { handleCurrent() }) }, [ ]);

    let content = <EmptyState 
                      image="EmptyCollection" 
                      title="No collections"
                      description="It looks like you have't added any collections"
                      button={
                          <Button 
                            className="btn-primary btn-upper btn-rounded" 
                            onClick={(e) => { dispatch(openAddCollection()) }}
                            text="Create collection" />
                      } />;

    if( collections.length !== 0 )
    {
        content = <>
                      <Navigation />
                      <CollectionView />
                  </>;
    }

	return (
        <div className={ `content collections ${collectionsLoading ? ' loading' : ''}` }>
            { content }
        </div>
	);
};

export default Dashboard;