import React, { useEffect, useState, useCallback } from 'react';
import { APIService } from 'Services';
import { useToasts } from 'react-toast-notifications'; 

import { Alert, Row, Column, Button, ToggleSwitch, Modal, Input, TextArea } from 'Components';

const MarketplaceEdit = props => {

    const { visible, item, onClose, onUpdate, onDelete } = props;

    const { addToast } = useToasts();

    const [ marketplaceItem, setMarketplaceItem ] = useState(null);
    const [ modalLoading, setModalLoading ] = useState(false);
    const [ modalButtonLoading, setModalButtonLoading ] = useState(false);

    const [ title, setTitle ] = useState('');
    const [ quantity, setQuantity ] = useState('');
    const [ price, setPrice ] = useState(0);
    const [ allowOffers, setAllowOffers ] = useState(false);
    const [ description, setDescription ] = useState('');

    // Alert state:
    const [ alertText, setAlertText ] = useState(null);
    const [ alertType, setAlertType ] = useState('success');
    const [ alertVisible, setAlertVisible ] = useState(false);

    useEffect(() => { 
        if( visible === true )
        {
            setModalLoading(true);
            setAlertVisible(false);
            setModalButtonLoading(false);

            APIService.get(`/marketplace/item/${item?.marketplaceItemId}/`)
                .then(resp => { 
                    setMarketplaceItem(resp.item);
                })
                .finally(() => setModalLoading(false));
        }
    }, [ visible ]);    

    useEffect(() => {
        setTitle(marketplaceItem?.title);
        setQuantity(marketplaceItem?.quantity);
        setPrice(marketplaceItem?.price);
        setAllowOffers(marketplaceItem?.acceptOffers);
        setDescription(marketplaceItem?.description);
    }, [ marketplaceItem ]);

    const handleClose = () => {
        if( typeof onClose === 'function' )
        {
            onClose();
        }
    };

    // Edit the item:
    const handleSubmit = e => {
        e.preventDefault();
        
        setModalButtonLoading(true);

        const endpoint = `/marketplace/item/${marketplaceItem?.id}`;

        const data = {
            title: title,
            price: price,
            quantity: quantity,
            allowOffers: allowOffers,
            description: description
        }

        APIService.put(endpoint, data)
            .then(resp => { 
                setAlertVisible(true);
                setAlertType('success');
                setAlertText('Marketplace listing updated successfully.');

                if( typeof onUpdate === 'function' )
                {
                    onUpdate(item);
                }
            })
            .catch(error => { 
                setAlertVisible(true);
                setAlertType('danger');
                setAlertText(error.response.data.message);
            })
            .finally(() => { setModalButtonLoading(false) });

    };

    const handleDelete = useCallback(e => {
        setModalButtonLoading(true);
        const endpoint = `/marketplace/item/${marketplaceItem?.id}`;

        APIService.delete(endpoint)
            .then(resp => { 
                if( typeof onDelete === 'function' )
                {
                    onDelete(item);
                }
            })
            .catch(error => { 
                setAlertVisible(true);
                setAlertType('danger');
                setAlertText(error.response.data.message);
            })
            .finally(() => { setModalButtonLoading(false) });

    }, [ marketplaceItem ]);

    return (
        <Modal loading={ modalLoading } size="lg" visible={ visible } title="Edit Marketplace Listing" subtitle={ marketplaceItem?.title } onClose={ handleClose }>

            <Alert
                className="mb25"
                type={ alertType }
                text={ alertText }
                visible={ alertVisible } />

            <Row>
                <Column>
                    <Input 
                        className="form-control has-value" 
                        label="Listing title" 
                        value={ title } 
                        onChange={e => { setTitle(e.target.value) }} />
                </Column>
                <Column width="25">
                    <Input 
                        className="form-control has-value"
                        min={ 0 }
                        onChange={e => { setQuantity(e.target.value) }}
                        type="number" 
                        label="Quantity" 
                        value={ quantity } />
                </Column>
            </Row>

            <Row className="my25">
                <Column width="25">
                    <Input 
                        label="Asking price"
                        className="has-value"
                        value={ price }
                        type="number"
                        onChange={e => { setPrice(e.target.value) }} />
                </Column>
                <Column>
                    <ToggleSwitch
                        className="mb0 mt10"
                        checked={ allowOffers }
                        onChange={(e, checked) => { setAllowOffers(checked) }}
                        label="Allow buyers to make an offer?" />
                </Column>
            </Row>

            <TextArea 
                className="my25 has-value"
                label="Description" 
                value={ description }
                onChange={e => { setDescription(e.target.value) }} />          
            
            <Button
                loading={ modalButtonLoading }
                className="btn-primary btn-rounded btn-upper"
                text="Save changes"
                onClick={ handleSubmit } />

            <Button
                visible={ ! modalButtonLoading }
                className="btn-text-muted btn-upper"
                text="Cancel"
                onClick={(e) => { e.preventDefault(); handleClose(); }} />

            <Button 
                loading={ modalButtonLoading }
                className="btn-danger btn-rounded btn-upper pull-right"
                text="Remove from Marketplace"
                onClick={ handleDelete } />
        </Modal>
    );
};

export default MarketplaceEdit;