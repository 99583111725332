import React from 'react';

const MutedText = props => {
    const className = [ 'text-muted' ];

    if( props.className !== undefined )
    {
        className.push(props.className);
    }

    const content = (props.text !== undefined) ? props.text : props.children;

    return (
        <span className={ className.join(' ') }>
            { content }
        </span>
    )
};

export default MutedText;