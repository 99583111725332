import {
    SET_IS_DRAGGING,
    SET_TARGET_CATEGORY
} from '../actions/dragActions';

const initialState = {
    dragging: false,
    targetCategory: null
};

const dragReducer = (state = initialState, action) => {
    switch( action.type )
    {
        case SET_IS_DRAGGING:
            return {
                ...state,
                dragging: action.payload.dragging,
            };

        case SET_TARGET_CATEGORY:
            return {
                ...state,
                targetCategory: action.payload.category
            };

        default:
            return state;
    }
};

export default dragReducer;