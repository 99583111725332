import APIService from 'Services/APIService';

export const FETCH_METRICS_BEGIN = 'FETCH_METRICS_BEGIN';
export const FETCH_METRICS_SUCCESS = 'FETCH_METRICS_SUCCESS';
export const FETCH_METRICS_FAILURE = 'FETCH_METRICS_FAILURE';

const fetchMetricsBegin = () => ({
	type: FETCH_METRICS_BEGIN
});

const fetchMetricsSuccess = metrics => ({
	type: FETCH_METRICS_SUCCESS, payload: { metrics }
});

const fetchMetricsFailure = error => ({
	type: FETCH_METRICS_FAILURE, payload: { error }
});

export const fetchMetrics = () => {

	return dispatch => {
		dispatch(fetchMetricsBegin());

		return APIService.get('/dashboard/info/')
			.then((response) => {
				dispatch(fetchMetricsSuccess({
					items: response.data.numItems,
					collections: response.data.numCollections,
					marketplaceItems: response.data.numMarketplaceListings,
					totals: response.data.totals
				}));
				return response;
			})
			.catch(error => {
				return dispatch(fetchMetricsFailure(error));
			});
	};
};
