import React, { useState } from 'react';

import { Alert, Button, Row, Column, DateFormat, CurrencyFormat, EmptyStateText, Modal } from 'Components';
import { APIService } from 'Services';

const SubscriptionView = props => {

    const { subscription, onCancel } = props;

    const [ cancelModalVisible, setCancelModalVisible ] = useState(false);
    const [ cancelModalButtonLoading, setCancelModalButtonLoading ] = useState(false);

    const [ alertText, setAlertText ] = useState(null);
    const [ alertVisible, setAlertVisible ] = useState(false);

    const getTimelineText = key => {
        switch( key )
        {
            case 'trial':
                return 'Trial started';
                break;

            case 'cancelled':
                return 'Subscription cancelled';
                break;
        }
    };

    const handleCancelSubscription = e => {
        e.preventDefault();
        setCancelModalVisible(true);
    };

    const closeCancelModal = e => {
        setCancelModalVisible(false);
    }

    const handleCancelConfirm = e => {
        e.preventDefault();

        setCancelModalButtonLoading(true);

        APIService.delete(`/subscription/${subscription.id}`)
            .then(() => {
                setCancelModalVisible(false);

                if( typeof onCancel === 'function' )
                {
                    onCancel();
                }
            })
            .catch(error => { 
                setAlertText(error.response.data.message);
                setAlertVisible(true);
            })
            .finally(() => { setCancelModalButtonLoading(false); })
    };
    
    return (
        <div className="subscriptionv-view">
            <div className="card">
                <h4 className="card-title-sm">Subscription details:</h4>
                
                <Row>
                    <Column className="metric">
                        <span className="metric-title">Status:</span>
                        <span className="metric-value">{ subscription.status }</span>
                    </Column>
                    <Column className="metric">
                        <span className="metric-title">Created:</span>
                        <span className="metric-value">
                            <DateFormat date={ subscription.created } format="user.date" />
                        </span>
                    </Column>
                    <Column className="metric">
                        <span className="metric-title">Next renewal:</span>
                        <span className="metric-value">
                            <DateFormat date={ subscription.renewalDate } format="user.date" />
                        </span>
                    </Column>
                    <Column className="metric">
                        <span className="metric-title">Plan:</span>
                        <span className="metric-value">{ subscription.plan.name }</span>
                    </Column>
                    <Column className="metric">
                        <span className="metric-title">Renewal price:</span>
                        <span className="metric-value"><CurrencyFormat currency="GBP" value={ (subscription.plan.price / 100) } /></span>
                    </Column>
                </Row>

                <Button
                    onClick={ handleCancelSubscription }
                    text="Cancel subscription"
                    className="btn-danger btn-rounded btn-upper mt30" />
            </div>

            <Row className="row-stretch mt30">
                <Column>
                    <div className="card">
                        <h4 className="card-title-sm">Invoices:</h4>

                        { (subscription.invoices.length) ? <></> : <EmptyStateText className="text-center" text="No invoices" /> }
                    </div>
                </Column>
                <Column>
                <div className="card">
                    <h4 className="card-title-sm">Subscription timeline:</h4>

                    <div className="subscription-timeline">
                        {subscription.timeline.map((item, i) => 
                            <div key={ i } className="subscription-timeline-item">
                                <div className="subscription-timeline-item-calendar">
                                    <DateFormat className="month" date={ item.date } format="MMM Y" />
                                    <DateFormat className="day" date={ item.date } format="D" />
                                </div>
                                <span className="text">{ getTimelineText(item.type) }</span>
                            </div>
                        )}
                    </div>
                </div>
                </Column>
            </Row>

            <Modal onClose={ closeCancelModal } visible={ cancelModalVisible } title="Cancel subscription">
                <Alert
                    className="mb20"
                    type="danger"
                    text={ alertText }
                    visible={ alertVisible } />

                <p>Are you sure you wish to cancel your Kolekto subscription?</p>

                <p>
                    Cancelling your subscription means that you will no longer be able to access some of your data in Kolekto. You will also
                    lose any outstanding time remaining on your subscription, and will have to create a new subscription to resume using the 
                    service.
                </p>

                <Button
                    loading={ cancelModalButtonLoading }
                    text="Yes, end subscription!"
                    className="btn-danger btn-rounded btn-upper"
                    onClick={ handleCancelConfirm } />

                <Button
                    visible={ !cancelModalButtonLoading }
                    text="No, keep my subscription"
                    className="btn-text-muted"
                    onClick={(e) => { e.preventDefault(); closeCancelModal() }} />
            </Modal>
        </div>
    );

};

export default SubscriptionView;