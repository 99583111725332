import { React, useState, useEffect } from 'react';
import { usePopper } from 'react-popper';
import { Link } from 'react-router-dom';

import { Icon } from 'Components';

const Dropdown = (props) => {

	const { position, toggleClassName, toggleContent, size, style } = props;

	const placement = (position === undefined) ? 'bottom' : position;

	const items = (props.items === undefined) ? [ ] : props.items;

	const [ visible, setVisible ] = useState(false);
	const [ referenceElement, setReferenceElement ] = useState(null);
	const [ popperElement, setPopperElement ] = useState(null);
	const [ arrowElement, setArrowElement ] = useState(null);

	const { styles, attributes, update } = usePopper(referenceElement, popperElement, {
		placement: placement,
	    modifiers: [
	    	{ name: 'arrow', options: { element: arrowElement } },
	    	{ name: 'offset', options: { offset: [ 0, 3 ] } }
	    ]
	});

	let className = (visible === true) ? 'dropdown dropdown-show' : 'dropdown';

	if( size !== undefined )
	{
		switch( size )
		{
			default:
				className+= '';
				break;

			case 'small':
			case 'sm':
			case 's':
				className+= ' dropdown-sm';
				break;

			case 'medium':
			case 'md':
			case 'm':
				className+= ' dropdown-md';
				break;
		}
	}

	if( props.className !== undefined )
	{
		className+= ' ' + props.className;
	}

	const toggleClass = (toggleClassName === undefined) ? 'dropdown-trigger' : `dropdown-trigger ${toggleClassName}`;

	const toggleDropdown = (e) => {
		e.preventDefault();
		e.stopPropagation();

		setVisible(!visible);
		update();
	};

	useEffect(() => {
	    document.addEventListener('click', handleClick);
	    return () => document.removeEventListener('click', handleClick);

	    function handleClick(e) { setVisible(false); }
	}, []);

	// Show the arrow?
	const arrowMarkup = (props.arrow === true) ? <div className="dropdown-menu_arrow" ref={setArrowElement} style={styles.arrow} /> : '';

	return (
		<div className={ className } style={ style }>
			<button className={ toggleClass } ref={ setReferenceElement } onClick={(e) => { toggleDropdown(e) }}>
				{ toggleContent }
			</button>
			<div className="dropdown-menu" ref={setPopperElement} style={styles.popper} {...attributes.popper}>
				{ arrowMarkup }

				{items.map((item, key) => {
					if( item.separator === true || item.sep === true )
					{
						return <hr key={key} />;
					}

					let itemClassName = [ 'dropdown-menu_item' ];
					let icon = '';

					if( item.className !== undefined )
					{
						itemClassName.push( item.className );
					}

					if( item.icon !== undefined )
					{
						itemClassName.push('dropdown-menu_item-icon');
						icon = <Icon key={ key } className="dropdown-menu_item-icon-icon" name={ item.icon } />
					}

					if( item.disabled === true )
					{
						itemClassName.push('dropdown-menu_item-disabled');
					}

					if( item.checked === true )
					{
						itemClassName.push('dropdown-menu_item-checked');
					}

					// Handle the click event:
					const handleClick = (e, onClick) => {
						e.stopPropagation();

						if( onClick !== undefined )
						{
							onClick.call(
								null,
								e
							);
						}

						setVisible(false);
					};

					if( item.link !== undefined )
					{
						if( item.link.toLowerCase().substr(0, 4) == 'http' )
						{
							return <a key={key} target={ (item.target) ? item.target : '_self' } onClick={(e) => { handleClick(e, item.onClick) }} className={ itemClassName.join(' ') } href={ item.link }>
									{ icon }
									{ item.text }
								</a>
						}

						return <Link key={key} onClick={(e) => { handleClick(e, item.onClick) }} className={ itemClassName.join(' ') } to={ item.link }>
									{ icon }
									{ item.text }
								</Link>

					}

         			return <span key={key} onClick={(e) => { handleClick(e, item.onClick) }} className={ itemClassName.join(' ') }>
         				       { icon }
         					   { item.text }
         				   </span>
        		})}
			</div>
		</div>
	);
}

export default Dropdown;
