import { objectSelector } from './selectors';

// Item sorter:
export const itemSorter = (field, itemObj) => {
    const items = objectSelector(itemObj);

    // We're sorting by an attribute:
    if( field.substr(0, 4) === 'attr' )
    {
        const attributeId = parseInt(field.substr(4));

        items.sort((a,b) => {
            
            if( b.attributes[attributeId] === undefined )
            {
                return -1;
            }

            if( a.attributes[attributeId]?.value > b.attributes[attributeId]?.value )
            {
                return 1;
            }
            else if( b.attributes[attributeId]?.value > a.attributes[attributeId]?.value )
            {
                return -1;
            }

            return 0;
        });
    }

    // We're sorting by name:
    else if( field === 'name' )
    {   
        items.sort((a, b) => {
            if( a.name > b.name )
            {
                return 1;
            }
            else if( b.name > a.name )
            {
                return -1;
            }

            return 0;
        });
    }

    // Everything else we will sort by created:
    else
    {
        items.sort((a, b) => {
            if( a.created > b.created )
            {
                return 1;
            }
            else if( b.created > a.created )
            {
                return - 1;
            }

            return 0;
        });

        items.reverse();
    }

    const returnObj = { };
    items.map(item => ( returnObj['item' + item.id] = item ));

    return returnObj;
};