import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications'
import { APIService } from 'Services';

import { setDragTargetCategory, setIsDragging } from 'store/actions/dragActions';
import { Dragger, Confirm } from 'Components';

const ItemMover = props => {
    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const { selected, item } = props;
    const currentCategory = props.category;
    const moveDelta = 5;

    const [ isMouseDown, setIsMouseDown ] = useState(false);
    const [ draggerVisible, setDraggerVisible ] = useState(false);
    const [ draggerText, setDraggerText ] = useState(null);
    const [ toMove, setToMove ] = useState([ item?.id ]);
    const [ moveTarget, setMoveTarget ] = useState(null);

    const [ mouseX, setMouseX ] = useState(0);
    const [ mouseY, setMouseY ] = useState(0);

    const [ confirmVisible, setConfirmVisible ] = useState(false);
    const [ confirmContent, setConfirmContent ] = useState(null);
    const [ confirmLoading, setConfirmLoading ] = useState(false);

    const { dragging, targetCategory } = useSelector(state => state.drag);

    // Handle drag:
    let handleMouseDown = e => {
        e.preventDefault();
        setIsMouseDown(true);

        setMouseX(e.clientX);
        setMouseY(e.clientY);
    };

    useEffect(() => {
        setToMove(selected?.length ? selected : [ item?.id ]);

        setDraggerText(
            (selected?.length > 1)
                ? `Move ${selected?.length} item${selected?.length != 1 ? 's' : ''}`
                : `Move ${item?.name}`
        );
     }, [ item, selected ]);

    const handleMouseUp = e => {
        setIsMouseDown(false);
        setDraggerVisible(false);

        window.setTimeout(() => { dispatch(setIsDragging(false)) }, 20);

        if( dragging && targetCategory !== null )
        {
            // It's a different category:
            if( targetCategory.id !== currentCategory.id  )
            {
                const confirmText = (selected.length)
                    ? <>Are you sure you wish to move <b>{selected.length}</b> item{selected.length !== 1 ? 's' : ''} to <b>{targetCategory.name}</b>?</>
                    : <>Are you sure you wish to move <b>{item.name}</b> to <b>{targetCategory.name}</b>?</>;

                setConfirmContent(confirmText);
                setConfirmVisible(true);
                setMoveTarget(targetCategory);
            }

        }

        // We need to empty the target category:
        dispatch(setDragTargetCategory(null));
    };

    const handleMouseMove = useCallback((e) => {
        if (isMouseDown === true) {
            if (
                (Math.abs(mouseX - e.clientX) >= moveDelta)
                ||
                (Math.abs(mouseY - e.clientY) >= moveDelta)
            ) {
                setDraggerVisible(true);
                dispatch(setIsDragging(true));
            }
        }
    }, [ mouseX, mouseY, isMouseDown ]);

    // Drag bindings:
    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove, false);
        document.addEventListener('mouseup', handleMouseUp, false);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove, false);
            document.removeEventListener('mouseup', handleMouseUp, false);
        }
    }, [ isMouseDown, targetCategory, dragging ]);

    // Confirm events:
    const handleConfirmClose = e => {
        setConfirmVisible(false);
    };

    const handleConfirmMove = e => {
        setConfirmLoading(true);

        const endpoint = `/category/${currentCategory.id}/items`;

        APIService.put(endpoint, { items: toMove, target: moveTarget.id })
            .then(response => {
                addToast(`Items were successfully moved to ${moveTarget.name}`, {
                    appearance: 'success',
                    autoDismiss: true
                });

                // Reset the items to move:
                setToMove(null);

                setConfirmLoading(false);
                setConfirmVisible(false);

                if (typeof props.onMove === 'function') {
                    props.onMove(
                        response.items,
                        moveTarget
                    );
                }
            })
            .catch(error => {
                setConfirmLoading(false);
                setConfirmVisible(false);
                addToast(error.response.data.message, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    };

    return (
        <div onMouseDown={ handleMouseDown }>
            { props.children }

            <Dragger
                visible={ draggerVisible }
                text={ draggerText } />

            <Confirm
                size="md"
                title="Move items"
                confirmText="Move items"
                loading={ confirmLoading }
                cancelVisible={ !confirmLoading }
                onClose={ handleConfirmClose }
                visible={ confirmVisible }
                content={ confirmContent }
                onConfirm={ handleConfirmMove } />
        </div>
    )
};

export default ItemMover;
