import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { closeEntityInfo } from 'store/actions/modalActions';

import { Modal, Button, EntityInfo } from 'Components';

const ModalEntityInfo = (props) => {

    const dispatch = useDispatch();

    const entityInfoVisible = useSelector(state => state.modals.entityInfo);
    const { entityInfoTitle, entityInfoItems  } = useSelector(state => state.modals);

    let content = '';

    if( entityInfoItems !== null )
    {
        content = entityInfoItems.map((item, i) => ( 
            <EntityInfo label={item.label} value={item.value} key={i} /> 
        ));
    }

    return (
        <Modal size="small" onClose={(e) => { dispatch(closeEntityInfo()) }} title={ entityInfoTitle } visible={ entityInfoVisible }>
            { content }
            <Button 
                onClick={(e) => { dispatch(closeEntityInfo()) }} 
                text="Close" 
                className="btn-primary btn-center btn-rounded btn-upper mt25" /> 
        </Modal>
    );
};

export default ModalEntityInfo;