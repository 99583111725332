import { React, useEffect, useState } from 'react';
import { FormHelp } from 'Components';

const ToggleSwitch = (props) => {

	const { className, checked, label, onChange, help, onCheck, onUncheck, value } = props;
	const [ isChecked, setIsChecked ] = useState((checked === true));

	const classNameStr = (className === undefined) ? 'toggle' : 'toggle ' + className;

	const handleChange = e => {
		if( typeof onChange === 'function' )
		{
			onChange.call(
				null,
				e,
				!isChecked
			);
		}
		else
		{
			setIsChecked(!isChecked);
		}
	};
	
	useEffect(() => { 
		setIsChecked(checked) 
	}, [ checked ]);

	useEffect(() => {
		if( isChecked && typeof onCheck === 'function' )
		{
			onCheck(value);
		}
		else if( !isChecked && typeof onUncheck === 'function' )
		{
			onUncheck(value);
		}
	}, [ isChecked ]);

	const helpEle = (help !== undefined) 
		? <FormHelp text={ help } />
		: '';

	return (
		<label className={ classNameStr }>
			<input value={ value } type="checkbox" checked={ isChecked === true || isChecked === 1 } onChange={(e) => handleChange(e) } />
			<span></span>
			<span className="label">{ label }</span>
			{ helpEle }
		</label>
	)
}

export default ToggleSwitch;