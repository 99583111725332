import React from 'react';

import { DateFormat, CurrencyFormat } from 'Components/Formatters';
import InvoiceAddress from './InvoiceAddress';
import InvoiceTable from './InvoiceTable';
import InvoiceActions from './InvoiceActions';

import AuthService from '../../Services/AuthService';

const Invoice = props => {

    const { invoice } = props;

    const printInvoice = invoice => {
        window.open(invoice.url + '?token=' + AuthService.getToken() + '&download=false&print=1');
    }

    const downloadInvoice = invoice => {
        window.location.href = invoice.url + '?token=' + AuthService.getToken();
    };

    return (
        <>
            <InvoiceActions 
                onPrint={ printInvoice }
                onDownload={ downloadInvoice }
                invoice={ invoice } />

            <div className="invoice">
                <header className="invoice-header">
                    <div className="invoice-header-col">
                        <img src="https://ik.imagekit.io/kolekto/static/logo-brand_D-3Tze7_ZBCD.svg" className="invoice-header_brand" alt="Kolekto logo" title="Kolekto" />

                        <div class="invoice-company-address">
                            <span className="invoice-company-address-line"><strong>Kolekto</strong></span>
                            <span className="invoice-company-address-line">13 Eastwood Drive, Boston, PE21 0NG, UK</span>
                            <span className="invoice-company-address-line">hello@kolekto.io | www.kolekto.io</span>
                        </div>
                    </div>
                    <div className="invoice-header-col">
                        <h1 className="invoice-title">Invoice</h1>
                        <p className="invoice-number">{ invoice.number }</p>
                    </div>
                </header>

                <div className="invoice-details">
                    <div className="invoice-details-col">
                        <InvoiceAddress address={ invoice.address } title="Invoice To" />
                    </div>
                    <div className="invoice-details-col">
                        <p className="invoice-date">
                            <span className="invoice-date_title">Invoice date:</span>
                            <DateFormat date={ invoice.issued } format="MMMM D, Y" />
                        </p>

                        { (invoice.paid ? <span className="invoice-status paid">Paid</span> : <span className="invoice-status pending">Pending</span>) }
                    </div>
                </div>
                
                <InvoiceTable items={ invoice.lines } />

                <hr />

                <div className="invoice-table-totals">
                    <div class="invoice-table-totals-col">
                        <p className="invoice-table-total_title">Invoice Total</p>
                    </div>
                    <div class="invoice-table-totals-col">
                        <CurrencyFormat 
                            className="invoice-total"
                            currency="GBP" 
                            value={ invoice.grossAmount / 100 } />
                    </div>
                </div>

                <footer className="invoice-footer">
                    
                </footer>
            </div>                
        </>
    );
};

export default Invoice;